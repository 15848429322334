import React from "react";
import { Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import "./index.scss";

interface IProps {
  info?: boolean;
  title: string;
  subTitle?: string;
  cardInformation: string[] | (() => React.ReactNode);
  cardInfoAlignment?: "left" | "right";
}

const PageContent: React.FC<IProps> = ({
  info,
  title,
  subTitle,
  cardInformation,
  cardInfoAlignment,
}) => {
  return (
    <div className="page-container">
      <Typography variant="h3" className="title">
        {title}
      </Typography>
      <div className={`card-information ${cardInfoAlignment ? cardInfoAlignment : ""}`}>
        {Array.isArray(cardInformation)
          ? cardInformation.map((item, idx) => <p key={idx}>{item}</p>)
          : cardInformation()}
      </div>
      <div className="sub-title">
        {subTitle} {info && <InfoOutlined className="info_icon" />}
      </div>
    </div>
  );
};

export default PageContent;
