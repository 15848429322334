import React from "react";
import BaseButton from "components/common/BaseButton";
import ContainedButton from "components/common/ContainedButton";

import "./index.scss";

interface IProps {
  error?: string;
  loading?: boolean;
  activeStep?: number;
  nextButton?: string;
  backButton?: string;
  handleBack?: () => void;
  handleNext?: () => void;
  disabled?: boolean;
  backButtonHref?: string;
  nextButtonHref?: string;
}

const ActionsSection: React.FC<IProps> = ({
  error,
  loading,
  disabled,
  handleBack,
  handleNext,
  nextButton,
  backButton,
  backButtonHref,
  nextButtonHref,
}) => {
  const actionWrapClass = backButton ? "action-wrap" : "action-wrap single-button";
  return (
    <div className={actionWrapClass}>
      {backButton ? (
        <BaseButton name={backButton} onClick={handleBack} href={backButtonHref} />
      ) : (
        <></>
      )}
      {nextButton ? (
        <ContainedButton
          error={error}
          loading={loading}
          name={nextButton}
          disabled={disabled}
          onClick={handleNext}
          href={nextButtonHref}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ActionsSection;
