import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  card: {
    borderRadius: "10px",
    padding: "30px",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  imageSection: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  mobileHeading: {
    display: "none",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  description: {
    color: theme.palette.brand.grey,
    textAlign: "left",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginBottom: "32px",
    },
  },
  uploadSection: {
    flex: 3,
    alignSelf: "flex-start",
  },
  desktopHeading: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
