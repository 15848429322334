export const verticalLanguageSettings: {
  [vertical: string]: string;
} = {
  FIT: "deInformal",
  GFF: "deInformal",
  OCG: "deInformal",
  PKV: "deFormal",
  NRG: "deInformal",
  NFLX: "deInformal",
};
