import React from "react";
import { Button, makeStyles, CircularProgress } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";

interface IProps {
  disabled?: boolean;
  onClick?: () => void;
  name: string;
  error?: string;
  loading?: boolean;
  uploadIcon?: boolean;
  href?: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: "15px",
    boxShadow: "0px 3px 20px #2f5ff199",
    borderRadius: "4px",
    textTransform: "none",
    minWidth: "193px",
    minHeight: "56px",
    padding: "0 20px",
    "&.Mui-disabled": {
      color: theme.palette.brand.offwhite,
      boxShadow: theme.shadows[5],
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "152px",
    },
  },
  icon: {
    marginRight: "5px",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.brand.offwhite,
    "&:active, &:focus, &:hover, &:visited, &:link": {
      textDecoration: "none",
    },
  },
}));

const ContainedButton: React.FC<IProps> = ({
  disabled,
  onClick,
  name,
  uploadIcon,
  loading,
  href,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (href) {
      window.location.href = href;
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={handleClick}
      className={classes.button}
      data-cy={"contained-button"}
    >
      {uploadIcon && <CloudUpload className={classes.icon} />}
      {loading ? <CircularProgress color="inherit" /> : name}
    </Button>
  );
};

export default ContainedButton;
