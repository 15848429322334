import React from "react";
import ContainedButton from "components/common/ContainedButton";
import { Typography } from "@material-ui/core";
import "./index.scss";

interface Props {
  disabled?: boolean;
  onClick?: () => void;
  buttonName: string;
  title?: string;
  description?: string;
  info?: string;
  getImage: () => React.ReactNode;
  subDescription?: string;
  href?: string;
}

const BasePageContent: React.FC<Props> = ({
  buttonName,
  title,
  href,
  description,
  info,
  getImage,
  subDescription,
  onClick,
}) => {
  return (
    <div id="base-page" data-cy="done-page">
      <div className="done-section">
        <Typography className="title">{title}</Typography>
        <Typography className="description">{description}</Typography>
        <Typography className="sub-description">{subDescription}</Typography>
        {getImage()}
        <Typography className="description">{info}</Typography>
        <ContainedButton name={buttonName} onClick={onClick} href={href} />
      </div>
    </div>
  );
};

export default BasePageContent;
