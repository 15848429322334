import React from "react";
import "./index.scss";

interface Props {
  error?: string;
}

const PageNotFound: React.FC<Props> = ({ error }) => (
  <div className="page-not-found">
    <h1>
      {"Oops! "}
      {error ? error : "Page not found."}
    </h1>
  </div>
);

export default PageNotFound;
