import React from "react";
import { Card, Typography } from "@material-ui/core";
import { UploadImage, ZipUploadImage } from "components/image-components";
import { FileUpload } from "components/common";
import { styleClasses } from "./style";

interface IProps {
  title: string;
  name: string;
  description: string;
  uploadFileHandler: (file: File, name: string) => void;
  isUploadButtonHidden?: boolean;
  isZipFile?: boolean;
}

const UploadCard: React.FC<IProps> = ({
  title,
  name,
  description,
  uploadFileHandler,
  isUploadButtonHidden,
  isZipFile,
}) => {
  const classes = styleClasses();

  return (
    <Card className={classes.card}>
      <Typography variant="h4" className={classes.mobileHeading}>
        {title}
      </Typography>
      <div className={classes.imageSection}>{isZipFile ? <ZipUploadImage /> : <UploadImage />}</div>
      <div className={classes.uploadSection}>
        <Typography variant="h4" className={classes.desktopHeading}>
          {title}
        </Typography>
        <Typography variant="body2" className={classes.description}>
          {description}
        </Typography>
        {isUploadButtonHidden !== true && (
          <FileUpload handleUploadFile={uploadFileHandler} name={name} isZipFile={isZipFile} />
        )}
      </div>
    </Card>
  );
};

export default UploadCard;
