import React from "react";
import { styleClasses } from "./style";
import ContentWrap from "./ContentWrap";

interface IProps {
  children: any;
  hasStepper?: boolean;
  product: string;
}

const PageWrap: React.FC<IProps> = ({ children, hasStepper, product }) => {
  const classes = styleClasses();

  return (
    <div className={classes.pageWrap}>
      <ContentWrap hasStepper={hasStepper} product={product}>
        {children}
      </ContentWrap>
    </div>
  );
};

export default PageWrap;
