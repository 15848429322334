import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

if (
  process.env.REACT_APP_SENTRY_ENV &&
  ["production", "qa1", "qa2"].includes(process.env.REACT_APP_SENTRY_ENV)
) {
  Sentry.init({
    dsn: "https://fe4e4aecf48e440993e3f2abdffe4941@o723518.ingest.sentry.io/5781979",
    environment: process.env.SENTRY_ENV,
  });
}

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
