import React from "react";
import { Stepper, Step, StepLabel, makeStyles } from "@material-ui/core";
import { StepReadyIcon } from "components/image-components";
import { IStep } from "constants/steps";
import { ReactComponent as ClockIcon } from "assets/images/ClockIcon.svg";
import "./index.scss";

interface IProps {
  activeStep: number;
  isStepSkipped?: (arg: number) => boolean;
  steps: IStep[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  label: {
    color: theme.palette.brand.royalblue,
    textAlign: "left",
    letterSpacing: "0px",
    opacity: "1",
  },
}));

const StepperComponent: React.FC<IProps> = ({ activeStep, isStepSkipped, steps }) => {
  const classes = useStyles();

  const stepSectionClasses = `steps-section${steps.length < 3 ? " width-66" : ""}`;

  return (
    <div className={stepSectionClasses}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          return (
            <Step key={step.label}>
              {isStepSkipped && isStepSkipped(index) ? (
                <StepLabel StepIconComponent={() => <ClockIcon />} className={classes.label}>
                  {step.label}
                </StepLabel>
              ) : step.done ? (
                <StepLabel StepIconComponent={() => <StepReadyIcon />} className={classes.label}>
                  {step.label}
                </StepLabel>
              ) : (
                <StepLabel className={classes.label}>{step.label}</StepLabel>
              )}
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StepperComponent;
