import React from "react";

function ZipUploadImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="203"
      height="129"
      viewBox="0 0 203 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9">
        <path
          d="M54.1955 8.67712L53.7246 8.13844C55.6637 6.66826 57.7138 5.33669 59.8582 4.15457L60.2398 4.75525C58.1286 5.92163 56.1085 7.23234 54.1955 8.67712Z"
          fill="url(#paint0_linear_965_1763)"
        />
        <path
          d="M38.8523 28.6118L38.1582 28.3677C40.7449 21.9717 44.74 16.1806 49.8734 11.3858L50.4011 11.8819C45.3385 16.6027 41.3997 22.3086 38.8523 28.6118Z"
          fill="url(#paint1_linear_965_1763)"
        />
        <path
          d="M183.656 59.3511C183.473 38.9784 165.722 22.512 143.842 22.512C138.739 22.5098 133.681 23.4303 128.936 25.2247C121.118 10.2852 104.69 0 85.6882 0C62.7043 0 43.4956 15.0403 38.7218 35.1456C17.106 36.4284 0 53.2048 0 73.7016C0 95.0471 18.543 112.347 41.405 112.347H173.203C189.416 112.347 202.56 100.085 202.56 84.9518C202.56 73.2598 194.705 63.2846 183.656 59.3511Z"
          fill="url(#paint2_linear_965_1763)"
        />
      </g>
      <path
        d="M135.099 42.7951C135.501 41.6879 136.226 40.7294 137.18 40.046C138.134 39.3627 139.271 38.9865 140.441 38.9672H176.961C177.592 38.9441 178.221 39.0604 178.802 39.3081C179.384 39.5558 179.905 39.9288 180.328 40.4009C180.751 40.8735 181.065 41.4335 181.249 42.0417C181.434 42.6499 181.484 43.2914 181.396 43.921L171.961 123.656C171.761 125.013 171.088 126.255 170.062 127.158C169.037 128.062 167.726 128.568 166.363 128.587H50.4844C49.8551 128.613 49.228 128.498 48.6477 128.252C48.0673 128.006 47.548 127.634 47.1266 127.162C46.7045 126.692 46.3897 126.135 46.2045 125.529C46.0192 124.923 45.9681 124.284 46.0545 123.656L53.9653 56.6641C54.1655 55.3067 54.8386 54.0653 55.8641 53.1619C56.8897 52.2586 58.2008 51.7521 59.5631 51.7332H122.621C125.354 51.6857 128.008 50.8065 130.235 49.2112C132.462 47.6159 134.157 45.3792 135.099 42.7951Z"
        fill="#055FF0"
      />
      <path d="M48.1396 124.915H169.219L176.122 42.8503H55.0376L48.1396 124.915Z" fill="#F0F0F0" />
      <path d="M48.1396 124.915H169.219L174.215 42.8503H55.0376L48.1396 124.915Z" fill="#E0E0E0" />
      <path d="M48.1396 124.915H169.219L173.385 40.2446H52.3003L48.1396 124.915Z" fill="#F0F0F0" />
      <path d="M48.1396 124.915H169.219L170.866 40.2446H52.3003L48.1396 124.915Z" fill="#E0E0E0" />
      <path d="M48.1396 124.915H169.219L169.94 37.9469H48.8605L48.1396 124.915Z" fill="#F0F0F0" />
      <path d="M48.1398 124.915H169.22L166.738 39.5921H46.4336L48.1398 124.915Z" fill="#E0E0E0" />
      <path d="M48.1399 124.915H169.22L165.629 40.2446H44.5449L48.1399 124.915Z" fill="#F0F0F0" />
      <path d="M48.1405 124.915H169.22L163.499 41.5224H42.4287L48.1405 124.915Z" fill="#E0E0E0" />
      <path d="M48.1399 124.915H169.22L162.586 42.2345H41.5156L48.1399 124.915Z" fill="#F0F0F0" />
      <path
        d="M108.775 47.9743C108.785 47.1715 109.036 46.3905 109.496 45.7343C109.955 45.0781 110.601 44.5773 111.348 44.298C111.846 44.1142 112.374 44.0314 112.904 44.0544H149.401C150.815 44.1091 152.171 44.6352 153.256 45.5501C154.341 46.465 155.094 47.7169 155.396 49.1094L169.826 123.555C170.364 126.344 168.558 128.61 165.788 128.61H49.9096C48.4948 128.556 47.1379 128.031 46.0519 127.116C44.966 126.201 44.2127 124.948 43.9103 123.555L32.0304 62.1833C31.4921 59.3939 33.2987 57.1284 36.068 57.1284H99.1307C104.605 57.1284 108.579 53.3326 108.775 47.9743Z"
        fill="#055FF0"
      />
      <path
        d="M74.4409 100.408C73.8023 100.408 73.1828 100.189 72.6849 99.786C72.1871 99.3832 71.8409 98.8211 71.7036 98.1929C71.6482 97.9111 71.6541 97.6205 71.7211 97.3412C71.788 97.062 71.9144 96.8007 72.0914 96.5754C72.2745 96.3418 72.5093 96.1545 72.777 96.0284C73.0447 95.9024 73.338 95.841 73.6334 95.8493C74.2807 95.8507 74.9084 96.0733 75.4139 96.4807C75.9194 96.888 76.2728 97.456 76.4163 98.0918C76.4743 98.3741 76.468 98.666 76.3976 98.9454C76.3273 99.2248 76.1948 99.4845 76.0103 99.7048C75.8183 99.9337 75.5774 100.116 75.3056 100.238C75.0338 100.359 74.7382 100.418 74.4409 100.408Z"
        fill="white"
      />
      <path
        d="M80.3847 99.8335L79.9969 97.669L87.0455 84.7513L78.464 85.1695L78.0078 82.5777L90.4579 81.9159L90.8457 84.0482L83.674 97.0946L93.1223 96.8281L93.6104 99.5256L80.3847 99.8335Z"
        fill="white"
      />
      <path
        d="M96.8919 78.3177C96.3542 78.3531 95.8221 78.1897 95.3955 77.8581C94.9692 77.5517 94.678 77.0903 94.5835 76.5714C94.5296 76.3292 94.5295 76.078 94.5831 75.8357C94.6367 75.5934 94.7428 75.366 94.8937 75.1698C95.0495 74.9727 95.2462 74.8121 95.47 74.6994C95.6938 74.5867 95.9393 74.5246 96.1894 74.5173C96.7408 74.4718 97.2892 74.6357 97.7268 74.9768C98.1661 75.3013 98.4649 75.7833 98.5617 76.3232C98.6157 76.5637 98.6133 76.8134 98.5547 77.0528C98.4961 77.2921 98.3829 77.5143 98.2241 77.7019C98.0563 77.8917 97.8514 78.0444 97.6221 78.1504C97.3928 78.2564 97.1442 78.3133 96.8919 78.3177ZM99.1456 99.3968L95.9065 81.6264L99.1 81.4563L102.367 99.3233L99.1456 99.3968Z"
        fill="white"
      />
      <path
        d="M109.948 106.382L105.285 81.144L108.574 80.9694L108.857 82.4905C109.434 81.8082 110.149 81.2575 110.954 80.8748C111.758 80.4921 112.635 80.2862 113.524 80.2709C115.108 80.1588 116.693 80.4761 118.114 81.19C119.535 81.944 120.741 83.0513 121.618 84.4068C122.621 85.9501 123.298 87.6848 123.607 89.5031C123.982 91.2177 123.958 92.9965 123.538 94.7005C123.186 96.077 122.382 97.2932 121.257 98.1516C120.034 99.0093 118.573 99.4579 117.083 99.4337C116.083 99.4786 115.084 99.3229 114.144 98.9758C113.204 98.6287 112.343 98.0971 111.608 97.4118L112.977 104.902L109.948 106.382ZM116.125 96.5157C117.804 96.4697 118.99 95.8493 119.67 94.6775C120.349 93.5057 120.491 91.8008 120.085 89.6225C119.696 87.5393 118.918 85.9171 117.754 84.756C117.226 84.2034 116.586 83.7727 115.877 83.4934C115.168 83.2142 114.407 83.093 113.648 83.1384C112.735 83.16 111.846 83.4265 111.07 83.9104C110.364 84.3659 109.813 85.0282 109.491 85.8083L111.038 94.1904C111.772 94.9225 112.623 95.5238 113.556 95.9688C114.355 96.3457 115.229 96.5328 116.111 96.5157H116.125Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_965_1763"
          x1="58.2467"
          y1="5.61558"
          x2="52.8621"
          y2="9.29721"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4184F0" />
          <stop offset="0.47" stopColor="#41DAE5" />
          <stop offset="1" stopColor="#53FAD4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_965_1763"
          x1="47.3688"
          y1="19.0746"
          x2="34.034"
          y2="23.5746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4184F0" />
          <stop offset="0.47" stopColor="#41DAE5" />
          <stop offset="1" stopColor="#53FAD4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_965_1763"
          x1="145.023"
          y1="41.3695"
          x2="3.19704"
          y2="162.764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4184F0" />
          <stop offset="0.47" stopColor="#41DAE5" />
          <stop offset="1" stopColor="#53FAD4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ZipUploadImage;
