import React from "react";
import { Card, Typography } from "@material-ui/core";
import { styleClasses } from "./style";

interface IProps {
  cardTitle: string;
  amount?: string | 0;
}

const OfferAmount: React.FC<IProps> = ({ amount, cardTitle }) => {
  const classes = styleClasses();
  return (
    <Card className={classes.offerAmount}>
      <div>
        <Typography>{cardTitle}</Typography>
        <Typography variant="h2">{amount} €</Typography>
      </div>
    </Card>
  );
};

export default OfferAmount;
