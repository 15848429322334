import React, { useState, useEffect } from "react";
import {
  PageContent,
  ErrorAlert,
  PageWrap,
  ActionsSection,
  AddressCard,
  IBanCard,
  SignatureCard,
} from "components/common";
import { OfferAmount, TermsAndConditions } from "./components";
import { useHistory } from "react-router-dom";
import { URL_ACCEPT_OFFER_SUCCESS } from "../../../../../constants";

import { checkIfValidationError } from "helpers/utils";
import { humanizedIban } from "helpers/ibanHumanizer";

import { useCustomerAcceptOfferMutation } from "types/graphql.types";

import { OFFER_ACCEPT } from "../const";
import { verticalLanguageSettings } from "../../const";

interface IProps {
  offerId: string;
  amount: string | 0 | undefined;
  product: string;
  aliasId: string;
  company: string;
  isSpv: boolean;
  caseId: string;
}

interface State {
  iban: string;
  street: string;
  postalCode: string;
  city: string;
  countryCode: string;
  [key: string]: string | boolean;
  error: string;
  isValidationError: boolean;
  signature: string;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

const AcceptOffer: React.FC<IProps> = ({
  offerId,
  amount,
  product,
  aliasId,
  company,
  isSpv,
  caseId,
}) => {
  const [offerData, setOfferData] = useState<State>({
    iban: "",
    city: "",
    street: "",
    postalCode: "",
    error: "",
    countryCode: ["OCG"].includes(product) ? "" : "DE",
    isValidationError: false,
    signature: "",
  });

  const history = useHistory();
  const { street, postalCode, city, iban, countryCode, signature } = offerData;
  const isDisabled = !street || !postalCode || !city || !iban || !countryCode || !signature;

  const [customerAcceptOfferMutation, { loading: submittingAv }] = useCustomerAcceptOfferMutation();

  const handleError = (message: string) => {
    setOfferData((prevOfferData) => ({
      ...prevOfferData,
      error: message,
      isValidationError: checkIfValidationError(message),
    }));
  };
  const handleSuccess = () => {
    history.push(`${URL_ACCEPT_OFFER_SUCCESS}?product=${product}`);
  };

  const createAcceptOffer = () => {
    const variables = {
      input: {
        configName: `${product.toLowerCase()}-claim-purchasing`,
        input: {
          case: {
            id: caseId,
            offer: {
              address: {
                street,
                postalCode,
                city,
                countryCode,
              },
              bankAccount: {
                iban,
              },
              signature,
            },
          },
        },
      },
    };
    customerAcceptOfferMutation({
      variables,
    }).then((res) => {
      if (res?.errors) {
        res.errors.forEach(({ message }) => {
          handleError(message);
        });
        return;
      }
      handleSuccess();
    });
  };

  const handleChange = (value: string, name: string) => {
    const inputValue =
      name === "iban"
        ? value.replace(/ /g, "")
        : name === "signature"
        ? value.replace("data:image/png;base64,", "")
        : value;

    setOfferData({
      ...offerData,
      [name]: inputValue,
    });
  };

  const formalLanguage = verticalLanguageSettings[product];
  const language = formalLanguage === "en" ? "en" : "de";

  useEffect(() => {
    window.dataLayer.push({ alias_id: aliasId });
  }, [aliasId]);

  return (
    <PageWrap product={product}>
      <PageContent
        title={OFFER_ACCEPT.pageContent.title[language]}
        cardInformation={OFFER_ACCEPT.pageContent.cardInformation[product]}
      />
      <OfferAmount cardTitle={OFFER_ACCEPT.OfferAmount.cardTitle[formalLanguage]} amount={amount} />
      <IBanCard
        cardDetails={{
          title: OFFER_ACCEPT.iBanCard.cardDetails.title[formalLanguage],
          description: OFFER_ACCEPT.iBanCard.cardDetails.description[language],
        }}
        onChange={handleChange}
        error={offerData.error}
        value={humanizedIban(iban)}
      />
      <AddressCard
        cardDetails={{
          title: OFFER_ACCEPT.addressCard.cardDetails.title[formalLanguage],
          description: OFFER_ACCEPT.addressCard.cardDetails.description[language],
        }}
        onChange={handleChange}
        error={offerData.error}
        city={city}
        street={street}
        postalCode={postalCode}
        country={countryCode}
        product={product}
        language={language}
      />
      <SignatureCard
        handleChange={handleChange}
        cardDetails={{
          title: OFFER_ACCEPT.SignatureCard.cardDetails.title[formalLanguage],
          description: OFFER_ACCEPT.SignatureCard.cardDetails.description[formalLanguage],
        }}
      />
      <TermsAndConditions
        notice={OFFER_ACCEPT.notice[language]}
        isSpv={isSpv}
        company={company}
        language={language}
      />
      {!offerData.isValidationError && offerData.error && <ErrorAlert error={offerData.error} />}
      <ActionsSection
        disabled={isDisabled}
        handleNext={createAcceptOffer}
        loading={submittingAv}
        nextButton={OFFER_ACCEPT.buttonText[language]}
      />
    </PageWrap>
  );
};

export default AcceptOffer;
