import React from "react";
import { CloudUpload } from "@material-ui/icons";

import "./index.scss";
interface Props {
  handleUploadFile: (file: File, name: string) => void;
  name: string;
  isIconHidden?: boolean;
  isZipFile?: boolean;
}

const FileUpload: React.FC<Props> = ({ handleUploadFile, name, isIconHidden, isZipFile }) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleUploadFile((e.target?.files as FileList)[0], name);
  };

  const acceptFileType = isZipFile
    ? "application/zip,application/x-zip-compressed"
    : "application/pdf,image/jpeg,image/gif,image/png";

  return (
    <>
      <input
        className="upload-wrap"
        id={name}
        type="file"
        value=""
        onChange={handleOnChange}
        data-cy={name}
        accept={acceptFileType}
      />
      <div className="upload-btn">
        <label htmlFor={name}>
          {isIconHidden && <CloudUpload />}
          <p>Hochladen</p>
        </label>
      </div>
    </>
  );
};

export default FileUpload;
