import React from "react";
import { DocType, Product_Enum } from "types/graphql.types";

const nflxCardInfomation = () => (
  <>
    <p>
      Bitte lade Deine Datenauskunft von Netflix hoch. Deine ZIP-Datei sollte mindestens die
      folgenden Dateien enthalten:
    </p>
    <ul>
      <li>Billing History (aus Ordner: Payment & Billing)</li>
      <li>Account Details (aus Ordner: Account)</li>
      <li>MessagesSentbyNetflix (aus Ordner: Messages)</li>
    </ul>
  </>
);

//product Document configurations
export const UPLOAD_CARD_DATA: Partial<Record<
  Product_Enum,
  {
    title: string;
    cardInformation: string[] | (() => React.ReactNode);
    cardInfoAlignment?: "left" | "right";
  }
>> = {
  [Product_Enum.Gff]: {
    title: "Dokumente hochladen",
    cardInformation: [
      "Lade die Buchungsbestätigung Deines Fluges als Bild oder PDF hoch. Achte darauf, dass die Buchungsnummer gut zu lesen ist.",
    ],
  },
  [Product_Enum.Nflx]: {
    title: "Netflix-Datenauskunft",
    cardInformation: nflxCardInfomation,
    cardInfoAlignment: "left",
  },
};

export const DOCUMENTS_REQUIRED: Partial<Record<
  DocType,
  {
    title: string;

    description: string;
    docType: DocType;
    isZipFile?: boolean;
  }
>> = {
  [DocType.GffProof]: {
    title: "Buchungsbestätigung",
    description:
      "Klicke auf “Hochladen”, um Deine Buchungsbestätigung als Foto oder PDF einzureichen.",
    docType: DocType.GffProof,
  },
  [DocType.NflxDataPackage]: {
    title: "Datenauskunft",
    description: "Lade hier Deine Datenauskunft von Netflix als ZIP-Datei hoch.",
    docType: DocType.NflxDataPackage,
    isZipFile: true,
  },
};
