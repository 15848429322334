import { PageNotFound } from "components/common";
import { URL_ACCEPT_OFFER_SUCCESS } from "../../constants";
import React, { useRef } from "react";
import { Route, Switch } from "react-router-dom";
import { OfferScreen, Success } from "./components";

const Offer: React.FC = () => {
  const aliasId = useRef<string>("");
  const setAliasId = (v: string) => (aliasId.current = v);

  if (window.location.pathname === URL_ACCEPT_OFFER_SUCCESS && !aliasId.current) {
    return <PageNotFound />;
  }

  return (
    <Switch>
      <Route path={URL_ACCEPT_OFFER_SUCCESS}>
        <Success />
      </Route>
      <Route>
        <OfferScreen setAliasId={setAliasId} />
      </Route>
    </Switch>
  );
};

export default Offer;
