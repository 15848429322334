import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: string;
  json: { [key: string]: any };
  jsonb: any;
  numeric: number;
  timestamp: any;
  uuid: string;
};

export type AdminAddDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminAddDocumentsOutput = {
  __typename?: 'AdminAddDocumentsOutput';
  documentIds?: Maybe<Array<Scalars['uuid']>>;
  id: Scalars['uuid'];
};

export type AdminApproveOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminApproveOfferOutput = {
  __typename?: 'AdminApproveOfferOutput';
  caseId: Scalars['uuid'];
  offerId: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminAssignmentResendInput = {
  id: Scalars['uuid'];
};

export type AdminAssignmentResendOutput = {
  __typename?: 'AdminAssignmentResendOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminCancelOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminCancelOfferOutput = {
  __typename?: 'AdminCancelOfferOutput';
  caseState: Scalars['String'];
  id: Scalars['uuid'];
  offerState: Scalars['String'];
};

export type AdminCaseCommentAddInput = {
  caseId: Scalars['uuid'];
  comment: Scalars['String'];
};

export type AdminCaseCommentAddOutput = {
  __typename?: 'AdminCaseCommentAddOutput';
  caseId: Scalars['uuid'];
  comment: Scalars['String'];
  id: Scalars['uuid'];
  userId: Scalars['uuid'];
};

export type AdminCaseDeleteInput = {
  id: Scalars['uuid'];
};

export type AdminCaseDeleteOutput = {
  __typename?: 'AdminCaseDeleteOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminCaseDocumentsCompleteInput = {
  id: Scalars['uuid'];
};

export type AdminCaseDocumentsCompleteOutput = {
  __typename?: 'AdminCaseDocumentsCompleteOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminCaseRejectionRevokeInput = {
  id: Scalars['uuid'];
};

export type AdminCaseRejectionRevokeOutput = {
  __typename?: 'AdminCaseRejectionRevokeOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminCaseRevokeInput = {
  id: Scalars['uuid'];
};

export type AdminCaseRevokeOutput = {
  __typename?: 'AdminCaseRevokeOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminConfirmRejectionInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminConfirmRejectionOutput = {
  __typename?: 'AdminConfirmRejectionOutput';
  id: Scalars['uuid'];
  otherRejectionReason?: Maybe<Scalars['String']>;
  rejectionReason: Scalars['String'];
  state: Scalars['String'];
};

export type AdminCreateOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminCreateOfferOutput = {
  __typename?: 'AdminCreateOfferOutput';
  caseId: Scalars['uuid'];
  offerId: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminDeleteDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminDeleteDocumentsOutput = {
  __typename?: 'AdminDeleteDocumentsOutput';
  caseId: Scalars['uuid'];
  documentIds?: Maybe<Array<Scalars['uuid']>>;
  isDeleted: Scalars['Boolean'];
};

export type AdminDownloadDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminDownloadDocumentsOutput = {
  __typename?: 'AdminDownloadDocumentsOutput';
  url: Scalars['String'];
};

export type AdminFitClaimUpdateInput = {
  claimId: Scalars['uuid'];
  companyId: Scalars['String'];
  hasPaid: Scalars['Boolean'];
  lockdownMonths: Scalars['jsonb'];
  membershipNumber?: Maybe<Scalars['String']>;
  monthlyAmount: Scalars['numeric'];
};

export type AdminFitClaimUpdateOutput = {
  __typename?: 'AdminFitClaimUpdateOutput';
  claimId: Scalars['uuid'];
  companyId: Scalars['String'];
  hasPaid: Scalars['Boolean'];
  lockdownMonths: Scalars['jsonb'];
  membershipNumber?: Maybe<Scalars['String']>;
  monthlyAmount: Scalars['numeric'];
};

export type AdminGetSignedUrlsInput = {
  documents: Array<GetSignedUrlDocumentInput>;
  download: Scalars['Boolean'];
};

export type AdminGetSignedUrlsOutput = {
  __typename?: 'AdminGetSignedUrlsOutput';
  documents: Scalars['json'];
};

export type AdminGffClaimUpdateInput = {
  bookingReference?: Maybe<Scalars['String']>;
  claimId: Scalars['uuid'];
  companyId: Scalars['String'];
  flightType: Scalars['String'];
  flights: Array<GffFlight>;
  isEstimatedClaimAmount?: Maybe<Scalars['Boolean']>;
  persons: Array<GffPerson>;
  ticketAmount?: Maybe<Scalars['numeric']>;
};

export type AdminGffClaimUpdateOutput = {
  __typename?: 'AdminGffClaimUpdateOutput';
  bookingReference?: Maybe<Scalars['String']>;
  claimId: Scalars['uuid'];
  companyId: Scalars['String'];
  flightType: Scalars['String'];
  flights?: Maybe<Scalars['jsonb']>;
  isEstimatedClaimAmount?: Maybe<Scalars['Boolean']>;
  persons?: Maybe<Scalars['jsonb']>;
  ticketAmount?: Maybe<Scalars['numeric']>;
};

export type AdminHandleGdprDeletionRequestInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminHandleGdprDeletionRequestOutput = {
  __typename?: 'AdminHandleGdprDeletionRequestOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminListCasesInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminListCasesOutput = {
  __typename?: 'AdminListCasesOutput';
  items: Scalars['json'];
  total: Scalars['Int'];
};

export type AdminOfferAddressChangeInput = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  id: Scalars['uuid'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
  vertical?: Maybe<Scalars['String']>;
};

export type AdminOfferAddressChangeOutput = {
  __typename?: 'AdminOfferAddressChangeOutput';
  id: Scalars['uuid'];
};

export type AdminOfferDeleteInput = {
  id: Scalars['uuid'];
};

export type AdminOfferDeleteOutput = {
  __typename?: 'AdminOfferDeleteOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminOfferResendInput = {
  id: Scalars['uuid'];
};

export type AdminOfferResendOutput = {
  __typename?: 'AdminOfferResendOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminOfferSendInput = {
  id: Scalars['uuid'];
};

export type AdminOfferSendOutput = {
  __typename?: 'AdminOfferSendOutput';
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminPkvClaimUpdateInput = {
  caseType: Scalars['String'];
  claimId: Scalars['uuid'];
  companyId: Scalars['String'];
  insuranceIncreaseDate: Scalars['String'];
  insuredPersons: Array<InsuredPerson>;
  isEstimatedClaimAmount: Scalars['Boolean'];
  otherCompany?: Maybe<Scalars['String']>;
  sourceOfEstimation?: Maybe<Scalars['String']>;
};

export type AdminPkvClaimUpdateOutput = {
  __typename?: 'AdminPkvClaimUpdateOutput';
  caseType: Scalars['String'];
  claimId: Scalars['uuid'];
  companyId: Scalars['String'];
  insuranceIncreaseDate?: Maybe<Scalars['String']>;
  insuredPersons: Scalars['jsonb'];
  isEstimatedClaimAmount?: Maybe<Scalars['Boolean']>;
  otherCompany?: Maybe<Scalars['String']>;
  sourceOfEstimation?: Maybe<Scalars['String']>;
};

export type AdminRefuseOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminRefuseOfferOutput = {
  __typename?: 'AdminRefuseOfferOutput';
  caseId: Scalars['uuid'];
  offerId: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminRejectCaseInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminRejectCaseOutput = {
  __typename?: 'AdminRejectCaseOutput';
  id: Scalars['uuid'];
  rejectionReason?: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

export type AdminRemoveCasesFromLawsuitInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminRemoveCasesFromLawsuitOutput = {
  __typename?: 'AdminRemoveCasesFromLawsuitOutput';
  lawsuitId: Scalars['uuid'];
  status: Scalars['String'];
};

export type AdminRequestDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminRequestDocumentsOutput = {
  __typename?: 'AdminRequestDocumentsOutput';
  documentRequestReason: Scalars['String'];
  id: Scalars['uuid'];
  otherDocumentReason?: Maybe<Scalars['String']>;
  requestedDocuments: Scalars['json'];
  state: Scalars['String'];
};

export type AdminResendOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminResendOfferOutput = {
  __typename?: 'AdminResendOfferOutput';
  caseId: Scalars['uuid'];
  offerId: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminSendOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminSendOfferOutput = {
  __typename?: 'AdminSendOfferOutput';
  caseId: Scalars['uuid'];
  offerId: Scalars['uuid'];
  state: Scalars['String'];
};

export type AdminUpdateClaimInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminUpdateClaimOutput = {
  __typename?: 'AdminUpdateClaimOutput';
  data: Scalars['json'];
  id: Scalars['uuid'];
};

export type AdminUpdateCommentInput = {
  comment: Scalars['String'];
  id: Scalars['uuid'];
};

export type AdminUpdateCommentOutput = {
  __typename?: 'AdminUpdateCommentOutput';
  id: Scalars['uuid'];
};

export type AdminUpdateCustomerInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminUpdateCustomerOutput = {
  __typename?: 'AdminUpdateCustomerOutput';
  data: Scalars['json'];
  id: Scalars['uuid'];
};

export type AdminUpdateDocumentInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminUpdateDocumentOrderInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type AdminUpdateDocumentOrderOutput = {
  __typename?: 'AdminUpdateDocumentOrderOutput';
  data: Scalars['json'];
};

export type AdminUpdateDocumentOutput = {
  __typename?: 'AdminUpdateDocumentOutput';
  data: Scalars['json'];
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CaseAddDocumentsInput = {
  documents: DocumentsInput;
  id: Scalars['uuid'];
};

export type CaseAddDocumentsOutput = {
  __typename?: 'CaseAddDocumentsOutput';
  aliasId: Scalars['String'];
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type CaseDocsUploadGetInput = {
  id: Scalars['uuid'];
};

export type CaseDocsUploadGetOutput = {
  __typename?: 'CaseDocsUploadGetOutput';
  companyId: Scalars['String'];
  id: Scalars['uuid'];
  missingDocuments: Array<DocType>;
  vertical: Scalars['String'];
};

export type CpsUnifiedCaseGetInput = {
  id: Scalars['uuid'];
};

export type CpsUnifiedCaseGetOutput = {
  __typename?: 'CpsUnifiedCaseGetOutput';
  aliasId: Scalars['String'];
  claim: Scalars['jsonb'];
  createdAt: Scalars['String'];
  customer: Scalars['jsonb'];
  id: Scalars['uuid'];
  offer: Scalars['jsonb'];
  vertical: Scalars['String'];
};

export type CustomerAcceptExternalOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type CustomerAcceptExternalOfferOutput = {
  __typename?: 'CustomerAcceptExternalOfferOutput';
  aliasId: Scalars['String'];
  offerId: Scalars['uuid'];
  state: Scalars['String'];
};

export type CustomerAcceptOfferInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type CustomerAcceptOfferOutput = {
  __typename?: 'CustomerAcceptOfferOutput';
  aliasId: Scalars['String'];
  offerId: Scalars['uuid'];
  state: Scalars['String'];
};

export type CustomerAddDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type CustomerAddDocumentsOutput = {
  __typename?: 'CustomerAddDocumentsOutput';
  aliasId: Scalars['String'];
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type CustomerSubmitCaseInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type CustomerSubmitCaseOutput = {
  __typename?: 'CustomerSubmitCaseOutput';
  aliasId: Scalars['String'];
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type CustomerValidateGdprDataInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type CustomerValidateGdprDataOutput = {
  __typename?: 'CustomerValidateGdprDataOutput';
  companyId: Scalars['String'];
  email: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
};

export enum DocType {
  FitPaymentProof = 'FIT_PAYMENT_PROOF',
  GffProof = 'GFF_PROOF',
  NflxDataPackage = 'NFLX_DATA_PACKAGE',
  NrgProof = 'NRG_PROOF',
  OcgProof = 'OCG_PROOF',
  PkvProof = 'PKV_PROOF'
}

export type DocsSignUrlInput = {
  contentType: Scalars['String'];
  docType: Scalars['String'];
  name: Scalars['String'];
};

export type DocsSignUrlOutput = {
  __typename?: 'DocsSignURLOutput';
  fields: Scalars['json'];
  id: Scalars['uuid'];
  url: Scalars['String'];
};

export type DocumentsInput = {
  proof: Array<Scalars['uuid']>;
};

export type GetSignedUrlDocumentInput = {
  displayFileName: Scalars['String'];
  key: Scalars['String'];
  type: Scalars['String'];
};

export type GffCaseCreateInput = {
  companyId: Scalars['String'];
  documents?: Maybe<DocumentsInput>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  flightType: Scalars['String'];
  lastName: Scalars['String'];
  marketing?: Maybe<Scalars['jsonb']>;
  newsletterOptin: Scalars['Boolean'];
  persons: Array<GffPerson>;
  phoneNumber?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  salutation?: Maybe<SalutationType>;
};

export type GffCaseCreateOutput = {
  __typename?: 'GffCaseCreateOutput';
  aliasId: Scalars['String'];
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type GffFlight = {
  arrival_airport: Scalars['String'];
  departure_airport: Scalars['String'];
  flight_date: Scalars['String'];
  flight_number: Scalars['String'];
};

export type GffPerson = {
  name: Scalars['String'];
  ticket_number?: Maybe<Scalars['String']>;
};

export type InsuredPerson = {
  insurance_number: Scalars['String'];
  insured_since: Scalars['String'];
  name: Scalars['String'];
  tariffs: Array<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LawsuitnowAddDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowAddDocumentsOutput = {
  __typename?: 'LawsuitnowAddDocumentsOutput';
  documents: Scalars['json'];
  lawsuitId: Scalars['uuid'];
};

export type LawsuitnowAddEventInput = {
  actionName: Scalars['String'];
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowAddEventOutput = {
  __typename?: 'LawsuitnowAddEventOutput';
  data: Scalars['jsonb'];
  lawsuitId: Scalars['uuid'];
  type: Scalars['String'];
};

export type LawsuitnowCaseViewInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowCaseViewOutput = {
  __typename?: 'LawsuitnowCaseViewOutput';
  data: Scalars['json'];
};

export type LawsuitnowDeleteDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowDeleteDocumentsOutput = {
  __typename?: 'LawsuitnowDeleteDocumentsOutput';
  documentIds?: Maybe<Array<Scalars['uuid']>>;
  lawsuitId: Scalars['uuid'];
};

export type LawsuitnowDownloadDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowDownloadDocumentsOutput = {
  __typename?: 'LawsuitnowDownloadDocumentsOutput';
  url: Scalars['String'];
};

export type LawsuitnowSignDocumentUrlsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowSignDocumentUrlsOutput = {
  __typename?: 'LawsuitnowSignDocumentUrlsOutput';
  documents: Scalars['json'];
};

export type LawsuitnowUpdateCasesInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowUpdateCasesOutput = {
  __typename?: 'LawsuitnowUpdateCasesOutput';
  cases: Scalars['json'];
  lawsuitId: Scalars['uuid'];
};

export type LawsuitnowUpdateDocumentsInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowUpdateDocumentsOutput = {
  __typename?: 'LawsuitnowUpdateDocumentsOutput';
  documents: Scalars['json'];
  lawsuitId: Scalars['uuid'];
};

export type LawsuitnowUpdateLawsuitInput = {
  configName: Scalars['String'];
  input: Scalars['json'];
};

export type LawsuitnowUpdateLawsuitOutput = {
  __typename?: 'LawsuitnowUpdateLawsuitOutput';
  courtFileId?: Maybe<Scalars['String']>;
  courtId: Scalars['uuid'];
  id: Scalars['uuid'];
  lawyerFileId?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  idToken: Scalars['String'];
};

export type OcgCaseCreateInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  marketing?: Maybe<Scalars['jsonb']>;
  moneySpent: Scalars['numeric'];
  newsletterOptin: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  salutation?: Maybe<SalutationType>;
};

export type OcgCaseCreateOutput = {
  __typename?: 'OcgCaseCreateOutput';
  aliasId: Scalars['String'];
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type OfferAcceptInput = {
  city: Scalars['String'];
  countryCode: Scalars['String'];
  iban: Scalars['String'];
  id: Scalars['uuid'];
  postalCode: Scalars['String'];
  signature: Scalars['String'];
  street: Scalars['String'];
};

export type OfferAcceptOutput = {
  __typename?: 'OfferAcceptOutput';
  aliasId: Scalars['String'];
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export type OfferGetInput = {
  id: Scalars['uuid'];
};

export type OfferGetOutput = {
  __typename?: 'OfferGetOutput';
  alias_id: Scalars['String'];
  case_id: Scalars['String'];
  company: Scalars['String'];
  id: Scalars['uuid'];
  isSpv: Scalars['Boolean'];
  offer_amount: Scalars['numeric'];
  product_id: Scalars['String'];
  state: Scalars['String'];
};

export type OptionalInsuredPerson = {
  insurance_number?: Maybe<Scalars['String']>;
  insured_since?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tariffs?: Maybe<Array<Scalars['String']>>;
};

export type PkvCaseCreateInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  insuredPersons?: Maybe<Array<OptionalInsuredPerson>>;
  lastName: Scalars['String'];
  marketing?: Maybe<Scalars['jsonb']>;
  newsletterOptin: Scalars['Boolean'];
  otherCompany?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  salutation?: Maybe<SalutationType>;
};

export type PkvCaseCreateOutput = {
  __typename?: 'PkvCaseCreateOutput';
  aliasId: Scalars['String'];
  id: Scalars['uuid'];
  state: Scalars['String'];
};

export enum SalutationType {
  Feminine = 'FEMININE',
  Masculine = 'MASCULINE'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  role: Scalars['String'];
};

export type UserCreateOutput = {
  __typename?: 'UserCreateOutput';
  id: Scalars['uuid'];
  role: Scalars['String'];
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  city: Scalars['String'];
  country_code: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  postal_code: Scalars['String'];
  street: Scalars['String'];
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: Maybe<Array<Address_Bool_Exp>>;
  _not?: Maybe<Address_Bool_Exp>;
  _or?: Maybe<Array<Address_Bool_Exp>>;
  city?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  postal_code?: Maybe<String_Comparison_Exp>;
  street?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey'
}

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: Maybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Street = 'street'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Street = 'street'
}

export type Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};

/** Simple API keys to directly and simply handling Hasura security */
export type Api_Keys = {
  __typename?: 'api_keys';
  api_key: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "api_keys" */
export type Api_Keys_Aggregate = {
  __typename?: 'api_keys_aggregate';
  aggregate?: Maybe<Api_Keys_Aggregate_Fields>;
  nodes: Array<Api_Keys>;
};

/** aggregate fields of "api_keys" */
export type Api_Keys_Aggregate_Fields = {
  __typename?: 'api_keys_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Api_Keys_Max_Fields>;
  min?: Maybe<Api_Keys_Min_Fields>;
};


/** aggregate fields of "api_keys" */
export type Api_Keys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Api_Keys_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "api_keys". All fields are combined with a logical 'AND'. */
export type Api_Keys_Bool_Exp = {
  _and?: Maybe<Array<Api_Keys_Bool_Exp>>;
  _not?: Maybe<Api_Keys_Bool_Exp>;
  _or?: Maybe<Array<Api_Keys_Bool_Exp>>;
  api_key?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "api_keys" */
export enum Api_Keys_Constraint {
  /** unique or primary key constraint on columns "name" */
  ApiKeysPkey = 'api_keys_pkey'
}

/** input type for inserting data into table "api_keys" */
export type Api_Keys_Insert_Input = {
  api_key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Api_Keys_Max_Fields = {
  __typename?: 'api_keys_max_fields';
  api_key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Api_Keys_Min_Fields = {
  __typename?: 'api_keys_min_fields';
  api_key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "api_keys" */
export type Api_Keys_Mutation_Response = {
  __typename?: 'api_keys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Api_Keys>;
};

/** on_conflict condition type for table "api_keys" */
export type Api_Keys_On_Conflict = {
  constraint: Api_Keys_Constraint;
  update_columns?: Array<Api_Keys_Update_Column>;
  where?: Maybe<Api_Keys_Bool_Exp>;
};

/** Ordering options when selecting data from "api_keys". */
export type Api_Keys_Order_By = {
  api_key?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: api_keys */
export type Api_Keys_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "api_keys" */
export enum Api_Keys_Select_Column {
  /** column name */
  ApiKey = 'api_key',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "api_keys" */
export type Api_Keys_Set_Input = {
  api_key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "api_keys" */
export type Api_Keys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Keys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Keys_Stream_Cursor_Value_Input = {
  api_key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "api_keys" */
export enum Api_Keys_Update_Column {
  /** column name */
  ApiKey = 'api_key',
  /** column name */
  Name = 'name'
}

export type Api_Keys_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Api_Keys_Set_Input>;
  where: Api_Keys_Bool_Exp;
};

/** columns and relationships of "assignment" */
export type Assignment = {
  __typename?: 'assignment';
  created_at: Scalars['timestamp'];
  /** An array relationship */
  gdpr_offer_assignments: Array<Gdpr_Offer_Assignment>;
  /** An aggregate relationship */
  gdpr_offer_assignments_aggregate: Gdpr_Offer_Assignment_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  offer: Base_Offer;
  offer_id: Scalars['uuid'];
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  sent_file?: Maybe<Scalars['String']>;
  state?: Maybe<Assignment_State_Enum>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "assignment" */
export type AssignmentGdpr_Offer_AssignmentsArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Assignment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};


/** columns and relationships of "assignment" */
export type AssignmentGdpr_Offer_Assignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Assignment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};

/** aggregated selection of "assignment" */
export type Assignment_Aggregate = {
  __typename?: 'assignment_aggregate';
  aggregate?: Maybe<Assignment_Aggregate_Fields>;
  nodes: Array<Assignment>;
};

export type Assignment_Aggregate_Bool_Exp = {
  count?: Maybe<Assignment_Aggregate_Bool_Exp_Count>;
};

export type Assignment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Assignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Assignment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "assignment" */
export type Assignment_Aggregate_Fields = {
  __typename?: 'assignment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Assignment_Max_Fields>;
  min?: Maybe<Assignment_Min_Fields>;
};


/** aggregate fields of "assignment" */
export type Assignment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "assignment" */
export type Assignment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Assignment_Max_Order_By>;
  min?: Maybe<Assignment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "assignment" */
export type Assignment_Arr_Rel_Insert_Input = {
  data: Array<Assignment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Assignment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "assignment". All fields are combined with a logical 'AND'. */
export type Assignment_Bool_Exp = {
  _and?: Maybe<Array<Assignment_Bool_Exp>>;
  _not?: Maybe<Assignment_Bool_Exp>;
  _or?: Maybe<Array<Assignment_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  gdpr_offer_assignments?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
  gdpr_offer_assignments_aggregate?: Maybe<Gdpr_Offer_Assignment_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Base_Offer_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
  received_at?: Maybe<Timestamp_Comparison_Exp>;
  received_file?: Maybe<String_Comparison_Exp>;
  sent_at?: Maybe<Timestamp_Comparison_Exp>;
  sent_file?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Assignment_State_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignment" */
export enum Assignment_Constraint {
  /** unique or primary key constraint on columns "id" */
  AssignmentPkey = 'assignment_pkey'
}

/** input type for inserting data into table "assignment" */
export type Assignment_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  gdpr_offer_assignments?: Maybe<Gdpr_Offer_Assignment_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Base_Offer_Obj_Rel_Insert_Input>;
  offer_id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  sent_file?: Maybe<Scalars['String']>;
  state?: Maybe<Assignment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Assignment_Max_Fields = {
  __typename?: 'assignment_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  sent_file?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "assignment" */
export type Assignment_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  received_file?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  sent_file?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Assignment_Min_Fields = {
  __typename?: 'assignment_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  sent_file?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "assignment" */
export type Assignment_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  received_file?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  sent_file?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "assignment" */
export type Assignment_Mutation_Response = {
  __typename?: 'assignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignment>;
};

/** input type for inserting object relation for remote table "assignment" */
export type Assignment_Obj_Rel_Insert_Input = {
  data: Assignment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Assignment_On_Conflict>;
};

/** on_conflict condition type for table "assignment" */
export type Assignment_On_Conflict = {
  constraint: Assignment_Constraint;
  update_columns?: Array<Assignment_Update_Column>;
  where?: Maybe<Assignment_Bool_Exp>;
};

/** Ordering options when selecting data from "assignment". */
export type Assignment_Order_By = {
  created_at?: Maybe<Order_By>;
  gdpr_offer_assignments_aggregate?: Maybe<Gdpr_Offer_Assignment_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  offer?: Maybe<Base_Offer_Order_By>;
  offer_id?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  received_file?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  sent_file?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: assignment */
export type Assignment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "assignment" */
export enum Assignment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  ReceivedFile = 'received_file',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  SentFile = 'sent_file',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "assignment" */
export type Assignment_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  sent_file?: Maybe<Scalars['String']>;
  state?: Maybe<Assignment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "assignment_state" */
export type Assignment_State = {
  __typename?: 'assignment_state';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "assignment_state" */
export type Assignment_State_Aggregate = {
  __typename?: 'assignment_state_aggregate';
  aggregate?: Maybe<Assignment_State_Aggregate_Fields>;
  nodes: Array<Assignment_State>;
};

/** aggregate fields of "assignment_state" */
export type Assignment_State_Aggregate_Fields = {
  __typename?: 'assignment_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Assignment_State_Max_Fields>;
  min?: Maybe<Assignment_State_Min_Fields>;
};


/** aggregate fields of "assignment_state" */
export type Assignment_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Assignment_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "assignment_state". All fields are combined with a logical 'AND'. */
export type Assignment_State_Bool_Exp = {
  _and?: Maybe<Array<Assignment_State_Bool_Exp>>;
  _not?: Maybe<Assignment_State_Bool_Exp>;
  _or?: Maybe<Array<Assignment_State_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "assignment_state" */
export enum Assignment_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  AssignmentStatePkey = 'assignment_state_pkey'
}

export enum Assignment_State_Enum {
  /** Assignment created */
  Created = 'CREATED',
  /** Assignment deleted */
  Deleted = 'DELETED',
  /** Assignment expired */
  Expired = 'EXPIRED',
  /** Assignment received */
  Received = 'RECEIVED',
  /** Assignment sent */
  Sent = 'SENT'
}

/** Boolean expression to compare columns of type "assignment_state_enum". All fields are combined with logical 'AND'. */
export type Assignment_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Assignment_State_Enum>;
  _in?: Maybe<Array<Assignment_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Assignment_State_Enum>;
  _nin?: Maybe<Array<Assignment_State_Enum>>;
};

/** input type for inserting data into table "assignment_state" */
export type Assignment_State_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Assignment_State_Max_Fields = {
  __typename?: 'assignment_state_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Assignment_State_Min_Fields = {
  __typename?: 'assignment_state_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "assignment_state" */
export type Assignment_State_Mutation_Response = {
  __typename?: 'assignment_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Assignment_State>;
};

/** on_conflict condition type for table "assignment_state" */
export type Assignment_State_On_Conflict = {
  constraint: Assignment_State_Constraint;
  update_columns?: Array<Assignment_State_Update_Column>;
  where?: Maybe<Assignment_State_Bool_Exp>;
};

/** Ordering options when selecting data from "assignment_state". */
export type Assignment_State_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: assignment_state */
export type Assignment_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "assignment_state" */
export enum Assignment_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "assignment_state" */
export type Assignment_State_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "assignment_state" */
export type Assignment_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assignment_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assignment_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "assignment_state" */
export enum Assignment_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Assignment_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Assignment_State_Set_Input>;
  where: Assignment_State_Bool_Exp;
};

/** Streaming cursor of the table "assignment" */
export type Assignment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Assignment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Assignment_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  sent_file?: Maybe<Scalars['String']>;
  state?: Maybe<Assignment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "assignment" */
export enum Assignment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  ReceivedFile = 'received_file',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  SentFile = 'sent_file',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Assignment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Assignment_Set_Input>;
  where: Assignment_Bool_Exp;
};

/** columns and relationships of "bank_account" */
export type Bank_Account = {
  __typename?: 'bank_account';
  created_at: Scalars['timestamp'];
  iban: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "bank_account" */
export type Bank_Account_Aggregate = {
  __typename?: 'bank_account_aggregate';
  aggregate?: Maybe<Bank_Account_Aggregate_Fields>;
  nodes: Array<Bank_Account>;
};

/** aggregate fields of "bank_account" */
export type Bank_Account_Aggregate_Fields = {
  __typename?: 'bank_account_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bank_Account_Max_Fields>;
  min?: Maybe<Bank_Account_Min_Fields>;
};


/** aggregate fields of "bank_account" */
export type Bank_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bank_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bank_account". All fields are combined with a logical 'AND'. */
export type Bank_Account_Bool_Exp = {
  _and?: Maybe<Array<Bank_Account_Bool_Exp>>;
  _not?: Maybe<Bank_Account_Bool_Exp>;
  _or?: Maybe<Array<Bank_Account_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  iban?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "bank_account" */
export enum Bank_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  BankAccountPkey = 'bank_account_pkey'
}

/** input type for inserting data into table "bank_account" */
export type Bank_Account_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Bank_Account_Max_Fields = {
  __typename?: 'bank_account_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Bank_Account_Min_Fields = {
  __typename?: 'bank_account_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "bank_account" */
export type Bank_Account_Mutation_Response = {
  __typename?: 'bank_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bank_Account>;
};

/** input type for inserting object relation for remote table "bank_account" */
export type Bank_Account_Obj_Rel_Insert_Input = {
  data: Bank_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Bank_Account_On_Conflict>;
};

/** on_conflict condition type for table "bank_account" */
export type Bank_Account_On_Conflict = {
  constraint: Bank_Account_Constraint;
  update_columns?: Array<Bank_Account_Update_Column>;
  where?: Maybe<Bank_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "bank_account". */
export type Bank_Account_Order_By = {
  created_at?: Maybe<Order_By>;
  iban?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: bank_account */
export type Bank_Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bank_account" */
export enum Bank_Account_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "bank_account" */
export type Bank_Account_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "bank_account" */
export type Bank_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bank_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bank_Account_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "bank_account" */
export enum Bank_Account_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Bank_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Bank_Account_Set_Input>;
  where: Bank_Account_Bool_Exp;
};

/** columns and relationships of "base_case" */
export type Base_Case = {
  __typename?: 'base_case';
  alias_id: Scalars['String'];
  created_at: Scalars['timestamp'];
  /** An object relationship */
  customer: Customer;
  customer_id: Scalars['uuid'];
  document_request_reason?: Maybe<Scalars['String']>;
  /** An array relationship */
  documents: Array<Base_Case_Document>;
  /** An aggregate relationship */
  documents_aggregate: Base_Case_Document_Aggregate;
  /** An object relationship */
  fit_claim?: Maybe<Fit_Claim>;
  /** An array relationship */
  fit_claims: Array<Fit_Claim>;
  /** An aggregate relationship */
  fit_claims_aggregate: Fit_Claim_Aggregate;
  /** An object relationship */
  gdpr_claim?: Maybe<Gdpr_Claim>;
  /** An object relationship */
  gff_claim?: Maybe<Gff_Claim>;
  id: Scalars['uuid'];
  marketing?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  nflx_claim?: Maybe<Nflx_Claim>;
  /** An object relationship */
  nrg_claim?: Maybe<Nrg_Claim>;
  /** An object relationship */
  ocg_claim?: Maybe<Ocg_Claim>;
  /** An array relationship */
  offers: Array<Base_Offer>;
  /** An aggregate relationship */
  offers_aggregate: Base_Offer_Aggregate;
  other_document_reason?: Maybe<Scalars['String']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  /** An object relationship */
  pkv_claim?: Maybe<Pkv_Claim>;
  /** An object relationship */
  product: Product;
  product_id: Product_Enum;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  requested_documents?: Maybe<Scalars['jsonb']>;
  state: Scalars['String'];
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "base_case" */
export type Base_CaseDocumentsArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Order_By>>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};


/** columns and relationships of "base_case" */
export type Base_CaseDocuments_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Order_By>>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};


/** columns and relationships of "base_case" */
export type Base_CaseFit_ClaimsArgs = {
  distinct_on?: Maybe<Array<Fit_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Claim_Order_By>>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};


/** columns and relationships of "base_case" */
export type Base_CaseFit_Claims_AggregateArgs = {
  distinct_on?: Maybe<Array<Fit_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Claim_Order_By>>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};


/** columns and relationships of "base_case" */
export type Base_CaseMarketingArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "base_case" */
export type Base_CaseOffersArgs = {
  distinct_on?: Maybe<Array<Base_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Offer_Order_By>>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};


/** columns and relationships of "base_case" */
export type Base_CaseOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Offer_Order_By>>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};


/** columns and relationships of "base_case" */
export type Base_CaseRequested_DocumentsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "base_case" */
export type Base_Case_Aggregate = {
  __typename?: 'base_case_aggregate';
  aggregate?: Maybe<Base_Case_Aggregate_Fields>;
  nodes: Array<Base_Case>;
};

/** aggregate fields of "base_case" */
export type Base_Case_Aggregate_Fields = {
  __typename?: 'base_case_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Base_Case_Max_Fields>;
  min?: Maybe<Base_Case_Min_Fields>;
};


/** aggregate fields of "base_case" */
export type Base_Case_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Base_Case_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Base_Case_Append_Input = {
  marketing?: Maybe<Scalars['jsonb']>;
  requested_documents?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "base_case". All fields are combined with a logical 'AND'. */
export type Base_Case_Bool_Exp = {
  _and?: Maybe<Array<Base_Case_Bool_Exp>>;
  _not?: Maybe<Base_Case_Bool_Exp>;
  _or?: Maybe<Array<Base_Case_Bool_Exp>>;
  alias_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Customer_Bool_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  document_request_reason?: Maybe<String_Comparison_Exp>;
  documents?: Maybe<Base_Case_Document_Bool_Exp>;
  documents_aggregate?: Maybe<Base_Case_Document_Aggregate_Bool_Exp>;
  fit_claim?: Maybe<Fit_Claim_Bool_Exp>;
  fit_claims?: Maybe<Fit_Claim_Bool_Exp>;
  fit_claims_aggregate?: Maybe<Fit_Claim_Aggregate_Bool_Exp>;
  gdpr_claim?: Maybe<Gdpr_Claim_Bool_Exp>;
  gff_claim?: Maybe<Gff_Claim_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  marketing?: Maybe<Jsonb_Comparison_Exp>;
  nflx_claim?: Maybe<Nflx_Claim_Bool_Exp>;
  nrg_claim?: Maybe<Nrg_Claim_Bool_Exp>;
  ocg_claim?: Maybe<Ocg_Claim_Bool_Exp>;
  offers?: Maybe<Base_Offer_Bool_Exp>;
  offers_aggregate?: Maybe<Base_Offer_Aggregate_Bool_Exp>;
  other_document_reason?: Maybe<String_Comparison_Exp>;
  other_rejection_reason?: Maybe<String_Comparison_Exp>;
  pkv_claim?: Maybe<Pkv_Claim_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Product_Enum_Comparison_Exp>;
  promo_code?: Maybe<String_Comparison_Exp>;
  rejection_reason?: Maybe<String_Comparison_Exp>;
  requested_documents?: Maybe<Jsonb_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_case" */
export enum Base_Case_Constraint {
  /** unique or primary key constraint on columns "alias_id" */
  BaseCaseAliasIdKey = 'base_case_alias_id_key',
  /** unique or primary key constraint on columns "customer_id" */
  BaseCaseCustomerIdKey = 'base_case_customer_id_key',
  /** unique or primary key constraint on columns "id" */
  BaseCasePkey = 'base_case_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Base_Case_Delete_At_Path_Input = {
  marketing?: Maybe<Array<Scalars['String']>>;
  requested_documents?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Base_Case_Delete_Elem_Input = {
  marketing?: Maybe<Scalars['Int']>;
  requested_documents?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Base_Case_Delete_Key_Input = {
  marketing?: Maybe<Scalars['String']>;
  requested_documents?: Maybe<Scalars['String']>;
};

/** columns and relationships of "base_case_document" */
export type Base_Case_Document = {
  __typename?: 'base_case_document';
  add_to_lawsuit: Scalars['Boolean'];
  /** An object relationship */
  case?: Maybe<Base_Case>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamp'];
  display_file_name: Scalars['String'];
  document_type: Scalars['String'];
  file_name: Scalars['String'];
  id: Scalars['uuid'];
  is_deleted: Scalars['Boolean'];
  is_uploaded: Scalars['Boolean'];
  sort_order: Scalars['Int'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "base_case_document" */
export type Base_Case_Document_Aggregate = {
  __typename?: 'base_case_document_aggregate';
  aggregate?: Maybe<Base_Case_Document_Aggregate_Fields>;
  nodes: Array<Base_Case_Document>;
};

export type Base_Case_Document_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Base_Case_Document_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Base_Case_Document_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Base_Case_Document_Aggregate_Bool_Exp_Count>;
};

export type Base_Case_Document_Aggregate_Bool_Exp_Bool_And = {
  arguments: Base_Case_Document_Select_Column_Base_Case_Document_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Base_Case_Document_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Base_Case_Document_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Base_Case_Document_Select_Column_Base_Case_Document_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Base_Case_Document_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Base_Case_Document_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Base_Case_Document_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Base_Case_Document_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "base_case_document" */
export type Base_Case_Document_Aggregate_Fields = {
  __typename?: 'base_case_document_aggregate_fields';
  avg?: Maybe<Base_Case_Document_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Base_Case_Document_Max_Fields>;
  min?: Maybe<Base_Case_Document_Min_Fields>;
  stddev?: Maybe<Base_Case_Document_Stddev_Fields>;
  stddev_pop?: Maybe<Base_Case_Document_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Base_Case_Document_Stddev_Samp_Fields>;
  sum?: Maybe<Base_Case_Document_Sum_Fields>;
  var_pop?: Maybe<Base_Case_Document_Var_Pop_Fields>;
  var_samp?: Maybe<Base_Case_Document_Var_Samp_Fields>;
  variance?: Maybe<Base_Case_Document_Variance_Fields>;
};


/** aggregate fields of "base_case_document" */
export type Base_Case_Document_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Base_Case_Document_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "base_case_document" */
export type Base_Case_Document_Aggregate_Order_By = {
  avg?: Maybe<Base_Case_Document_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Base_Case_Document_Max_Order_By>;
  min?: Maybe<Base_Case_Document_Min_Order_By>;
  stddev?: Maybe<Base_Case_Document_Stddev_Order_By>;
  stddev_pop?: Maybe<Base_Case_Document_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Base_Case_Document_Stddev_Samp_Order_By>;
  sum?: Maybe<Base_Case_Document_Sum_Order_By>;
  var_pop?: Maybe<Base_Case_Document_Var_Pop_Order_By>;
  var_samp?: Maybe<Base_Case_Document_Var_Samp_Order_By>;
  variance?: Maybe<Base_Case_Document_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "base_case_document" */
export type Base_Case_Document_Arr_Rel_Insert_Input = {
  data: Array<Base_Case_Document_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Base_Case_Document_On_Conflict>;
};

/** aggregate avg on columns */
export type Base_Case_Document_Avg_Fields = {
  __typename?: 'base_case_document_avg_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "base_case_document" */
export type Base_Case_Document_Avg_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "base_case_document". All fields are combined with a logical 'AND'. */
export type Base_Case_Document_Bool_Exp = {
  _and?: Maybe<Array<Base_Case_Document_Bool_Exp>>;
  _not?: Maybe<Base_Case_Document_Bool_Exp>;
  _or?: Maybe<Array<Base_Case_Document_Bool_Exp>>;
  add_to_lawsuit?: Maybe<Boolean_Comparison_Exp>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  display_file_name?: Maybe<String_Comparison_Exp>;
  document_type?: Maybe<String_Comparison_Exp>;
  file_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_deleted?: Maybe<Boolean_Comparison_Exp>;
  is_uploaded?: Maybe<Boolean_Comparison_Exp>;
  sort_order?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** columns and relationships of "base_case_document_category" */
export type Base_Case_Document_Category = {
  __typename?: 'base_case_document_category';
  add_to_lawsuit: Scalars['Boolean'];
  id: Scalars['String'];
  is_deleted: Scalars['Boolean'];
  label: Scalars['String'];
  vertical?: Maybe<Scalars['String']>;
};

/** aggregated selection of "base_case_document_category" */
export type Base_Case_Document_Category_Aggregate = {
  __typename?: 'base_case_document_category_aggregate';
  aggregate?: Maybe<Base_Case_Document_Category_Aggregate_Fields>;
  nodes: Array<Base_Case_Document_Category>;
};

/** aggregate fields of "base_case_document_category" */
export type Base_Case_Document_Category_Aggregate_Fields = {
  __typename?: 'base_case_document_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Base_Case_Document_Category_Max_Fields>;
  min?: Maybe<Base_Case_Document_Category_Min_Fields>;
};


/** aggregate fields of "base_case_document_category" */
export type Base_Case_Document_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Base_Case_Document_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "base_case_document_category". All fields are combined with a logical 'AND'. */
export type Base_Case_Document_Category_Bool_Exp = {
  _and?: Maybe<Array<Base_Case_Document_Category_Bool_Exp>>;
  _not?: Maybe<Base_Case_Document_Category_Bool_Exp>;
  _or?: Maybe<Array<Base_Case_Document_Category_Bool_Exp>>;
  add_to_lawsuit?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  is_deleted?: Maybe<Boolean_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  vertical?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_case_document_category" */
export enum Base_Case_Document_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseCaseDocumentCategoryPkey = 'base_case_document_category_pkey'
}

/** input type for inserting data into table "base_case_document_category" */
export type Base_Case_Document_Category_Insert_Input = {
  add_to_lawsuit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Base_Case_Document_Category_Max_Fields = {
  __typename?: 'base_case_document_category_max_fields';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Base_Case_Document_Category_Min_Fields = {
  __typename?: 'base_case_document_category_min_fields';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "base_case_document_category" */
export type Base_Case_Document_Category_Mutation_Response = {
  __typename?: 'base_case_document_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Case_Document_Category>;
};

/** on_conflict condition type for table "base_case_document_category" */
export type Base_Case_Document_Category_On_Conflict = {
  constraint: Base_Case_Document_Category_Constraint;
  update_columns?: Array<Base_Case_Document_Category_Update_Column>;
  where?: Maybe<Base_Case_Document_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "base_case_document_category". */
export type Base_Case_Document_Category_Order_By = {
  add_to_lawsuit?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_deleted?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  vertical?: Maybe<Order_By>;
};

/** primary key columns input for table: base_case_document_category */
export type Base_Case_Document_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "base_case_document_category" */
export enum Base_Case_Document_Category_Select_Column {
  /** column name */
  AddToLawsuit = 'add_to_lawsuit',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Label = 'label',
  /** column name */
  Vertical = 'vertical'
}

/** input type for updating data in table "base_case_document_category" */
export type Base_Case_Document_Category_Set_Input = {
  add_to_lawsuit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "base_case_document_category" */
export type Base_Case_Document_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Case_Document_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Case_Document_Category_Stream_Cursor_Value_Input = {
  add_to_lawsuit?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** update columns of table "base_case_document_category" */
export enum Base_Case_Document_Category_Update_Column {
  /** column name */
  AddToLawsuit = 'add_to_lawsuit',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Label = 'label',
  /** column name */
  Vertical = 'vertical'
}

export type Base_Case_Document_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Base_Case_Document_Category_Set_Input>;
  where: Base_Case_Document_Category_Bool_Exp;
};

/** unique or primary key constraints on table "base_case_document" */
export enum Base_Case_Document_Constraint {
  /** unique or primary key constraint on columns "file_name" */
  BaseCaseDocumentFileNameKey = 'base_case_document_file_name_key',
  /** unique or primary key constraint on columns "id" */
  BaseCaseDocumentPkey = 'base_case_document_pkey'
}

/** input type for incrementing numeric columns in table "base_case_document" */
export type Base_Case_Document_Inc_Input = {
  sort_order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "base_case_document" */
export type Base_Case_Document_Insert_Input = {
  add_to_lawsuit?: Maybe<Scalars['Boolean']>;
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  display_file_name?: Maybe<Scalars['String']>;
  document_type?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_uploaded?: Maybe<Scalars['Boolean']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Base_Case_Document_Max_Fields = {
  __typename?: 'base_case_document_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  display_file_name?: Maybe<Scalars['String']>;
  document_type?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "base_case_document" */
export type Base_Case_Document_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_file_name?: Maybe<Order_By>;
  document_type?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sort_order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Base_Case_Document_Min_Fields = {
  __typename?: 'base_case_document_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  display_file_name?: Maybe<Scalars['String']>;
  document_type?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "base_case_document" */
export type Base_Case_Document_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_file_name?: Maybe<Order_By>;
  document_type?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sort_order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "base_case_document" */
export type Base_Case_Document_Mutation_Response = {
  __typename?: 'base_case_document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Case_Document>;
};

/** on_conflict condition type for table "base_case_document" */
export type Base_Case_Document_On_Conflict = {
  constraint: Base_Case_Document_Constraint;
  update_columns?: Array<Base_Case_Document_Update_Column>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};

/** Ordering options when selecting data from "base_case_document". */
export type Base_Case_Document_Order_By = {
  add_to_lawsuit?: Maybe<Order_By>;
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_file_name?: Maybe<Order_By>;
  document_type?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_deleted?: Maybe<Order_By>;
  is_uploaded?: Maybe<Order_By>;
  sort_order?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: base_case_document */
export type Base_Case_Document_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "base_case_document" */
export enum Base_Case_Document_Select_Column {
  /** column name */
  AddToLawsuit = 'add_to_lawsuit',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayFileName = 'display_file_name',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsUploaded = 'is_uploaded',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "base_case_document_aggregate_bool_exp_bool_and_arguments_columns" columns of table "base_case_document" */
export enum Base_Case_Document_Select_Column_Base_Case_Document_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AddToLawsuit = 'add_to_lawsuit',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsUploaded = 'is_uploaded'
}

/** select "base_case_document_aggregate_bool_exp_bool_or_arguments_columns" columns of table "base_case_document" */
export enum Base_Case_Document_Select_Column_Base_Case_Document_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AddToLawsuit = 'add_to_lawsuit',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsUploaded = 'is_uploaded'
}

/** input type for updating data in table "base_case_document" */
export type Base_Case_Document_Set_Input = {
  add_to_lawsuit?: Maybe<Scalars['Boolean']>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  display_file_name?: Maybe<Scalars['String']>;
  document_type?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_uploaded?: Maybe<Scalars['Boolean']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Base_Case_Document_Stddev_Fields = {
  __typename?: 'base_case_document_stddev_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "base_case_document" */
export type Base_Case_Document_Stddev_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Base_Case_Document_Stddev_Pop_Fields = {
  __typename?: 'base_case_document_stddev_pop_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "base_case_document" */
export type Base_Case_Document_Stddev_Pop_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Base_Case_Document_Stddev_Samp_Fields = {
  __typename?: 'base_case_document_stddev_samp_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "base_case_document" */
export type Base_Case_Document_Stddev_Samp_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** Streaming cursor of the table "base_case_document" */
export type Base_Case_Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Case_Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Case_Document_Stream_Cursor_Value_Input = {
  add_to_lawsuit?: Maybe<Scalars['Boolean']>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  display_file_name?: Maybe<Scalars['String']>;
  document_type?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  is_uploaded?: Maybe<Scalars['Boolean']>;
  sort_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Base_Case_Document_Sum_Fields = {
  __typename?: 'base_case_document_sum_fields';
  sort_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "base_case_document" */
export type Base_Case_Document_Sum_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** update columns of table "base_case_document" */
export enum Base_Case_Document_Update_Column {
  /** column name */
  AddToLawsuit = 'add_to_lawsuit',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayFileName = 'display_file_name',
  /** column name */
  DocumentType = 'document_type',
  /** column name */
  FileName = 'file_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  IsUploaded = 'is_uploaded',
  /** column name */
  SortOrder = 'sort_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Base_Case_Document_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Base_Case_Document_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Base_Case_Document_Set_Input>;
  where: Base_Case_Document_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Base_Case_Document_Var_Pop_Fields = {
  __typename?: 'base_case_document_var_pop_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "base_case_document" */
export type Base_Case_Document_Var_Pop_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Base_Case_Document_Var_Samp_Fields = {
  __typename?: 'base_case_document_var_samp_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "base_case_document" */
export type Base_Case_Document_Var_Samp_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Base_Case_Document_Variance_Fields = {
  __typename?: 'base_case_document_variance_fields';
  sort_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "base_case_document" */
export type Base_Case_Document_Variance_Order_By = {
  sort_order?: Maybe<Order_By>;
};

/** input type for inserting data into table "base_case" */
export type Base_Case_Insert_Input = {
  alias_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['uuid']>;
  document_request_reason?: Maybe<Scalars['String']>;
  documents?: Maybe<Base_Case_Document_Arr_Rel_Insert_Input>;
  fit_claim?: Maybe<Fit_Claim_Obj_Rel_Insert_Input>;
  fit_claims?: Maybe<Fit_Claim_Arr_Rel_Insert_Input>;
  gdpr_claim?: Maybe<Gdpr_Claim_Obj_Rel_Insert_Input>;
  gff_claim?: Maybe<Gff_Claim_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  marketing?: Maybe<Scalars['jsonb']>;
  nflx_claim?: Maybe<Nflx_Claim_Obj_Rel_Insert_Input>;
  nrg_claim?: Maybe<Nrg_Claim_Obj_Rel_Insert_Input>;
  ocg_claim?: Maybe<Ocg_Claim_Obj_Rel_Insert_Input>;
  offers?: Maybe<Base_Offer_Arr_Rel_Insert_Input>;
  other_document_reason?: Maybe<Scalars['String']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  pkv_claim?: Maybe<Pkv_Claim_Obj_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Product_Enum>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  requested_documents?: Maybe<Scalars['jsonb']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Base_Case_Max_Fields = {
  __typename?: 'base_case_max_fields';
  alias_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  document_request_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_document_reason?: Maybe<Scalars['String']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Base_Case_Min_Fields = {
  __typename?: 'base_case_min_fields';
  alias_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  document_request_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  other_document_reason?: Maybe<Scalars['String']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "base_case" */
export type Base_Case_Mutation_Response = {
  __typename?: 'base_case_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Case>;
};

/** input type for inserting object relation for remote table "base_case" */
export type Base_Case_Obj_Rel_Insert_Input = {
  data: Base_Case_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Base_Case_On_Conflict>;
};

/** on_conflict condition type for table "base_case" */
export type Base_Case_On_Conflict = {
  constraint: Base_Case_Constraint;
  update_columns?: Array<Base_Case_Update_Column>;
  where?: Maybe<Base_Case_Bool_Exp>;
};

/** Ordering options when selecting data from "base_case". */
export type Base_Case_Order_By = {
  alias_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customer_Order_By>;
  customer_id?: Maybe<Order_By>;
  document_request_reason?: Maybe<Order_By>;
  documents_aggregate?: Maybe<Base_Case_Document_Aggregate_Order_By>;
  fit_claim?: Maybe<Fit_Claim_Order_By>;
  fit_claims_aggregate?: Maybe<Fit_Claim_Aggregate_Order_By>;
  gdpr_claim?: Maybe<Gdpr_Claim_Order_By>;
  gff_claim?: Maybe<Gff_Claim_Order_By>;
  id?: Maybe<Order_By>;
  marketing?: Maybe<Order_By>;
  nflx_claim?: Maybe<Nflx_Claim_Order_By>;
  nrg_claim?: Maybe<Nrg_Claim_Order_By>;
  ocg_claim?: Maybe<Ocg_Claim_Order_By>;
  offers_aggregate?: Maybe<Base_Offer_Aggregate_Order_By>;
  other_document_reason?: Maybe<Order_By>;
  other_rejection_reason?: Maybe<Order_By>;
  pkv_claim?: Maybe<Pkv_Claim_Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  promo_code?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  requested_documents?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: base_case */
export type Base_Case_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Base_Case_Prepend_Input = {
  marketing?: Maybe<Scalars['jsonb']>;
  requested_documents?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "base_case" */
export enum Base_Case_Select_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DocumentRequestReason = 'document_request_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Marketing = 'marketing',
  /** column name */
  OtherDocumentReason = 'other_document_reason',
  /** column name */
  OtherRejectionReason = 'other_rejection_reason',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PromoCode = 'promo_code',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  RequestedDocuments = 'requested_documents',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "base_case" */
export type Base_Case_Set_Input = {
  alias_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  document_request_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketing?: Maybe<Scalars['jsonb']>;
  other_document_reason?: Maybe<Scalars['String']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  product_id?: Maybe<Product_Enum>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  requested_documents?: Maybe<Scalars['jsonb']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "base_case" */
export type Base_Case_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Case_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Case_Stream_Cursor_Value_Input = {
  alias_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  document_request_reason?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  marketing?: Maybe<Scalars['jsonb']>;
  other_document_reason?: Maybe<Scalars['String']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  product_id?: Maybe<Product_Enum>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Scalars['String']>;
  requested_documents?: Maybe<Scalars['jsonb']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "base_case" */
export enum Base_Case_Update_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DocumentRequestReason = 'document_request_reason',
  /** column name */
  Id = 'id',
  /** column name */
  Marketing = 'marketing',
  /** column name */
  OtherDocumentReason = 'other_document_reason',
  /** column name */
  OtherRejectionReason = 'other_rejection_reason',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  PromoCode = 'promo_code',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  RequestedDocuments = 'requested_documents',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Base_Case_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Base_Case_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Base_Case_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Base_Case_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Base_Case_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Base_Case_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Base_Case_Set_Input>;
  where: Base_Case_Bool_Exp;
};

/** columns and relationships of "base_offer" */
export type Base_Offer = {
  __typename?: 'base_offer';
  accepted_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  assignments: Array<Assignment>;
  /** An aggregate relationship */
  assignments_aggregate: Assignment_Aggregate;
  /** An object relationship */
  bank_account?: Maybe<Bank_Account>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  claim_amount: Scalars['numeric'];
  created_at: Scalars['timestamp'];
  external: Scalars['jsonb'];
  has_external: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** Returns 'true' if offer is active */
  is_active?: Maybe<Scalars['Boolean']>;
  offer_amount: Scalars['numeric'];
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason: Scalars['jsonb'];
  /** An array relationship */
  payments: Array<Payment>;
  /** An aggregate relationship */
  payments_aggregate: Payment_Aggregate;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "base_offer" */
export type Base_OfferAssignmentsArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


/** columns and relationships of "base_offer" */
export type Base_OfferAssignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


/** columns and relationships of "base_offer" */
export type Base_OfferExternalArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "base_offer" */
export type Base_OfferPayment_On_Hold_ReasonArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "base_offer" */
export type Base_OfferPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "base_offer" */
export type Base_OfferPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** aggregated selection of "base_offer" */
export type Base_Offer_Aggregate = {
  __typename?: 'base_offer_aggregate';
  aggregate?: Maybe<Base_Offer_Aggregate_Fields>;
  nodes: Array<Base_Offer>;
};

export type Base_Offer_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Base_Offer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Base_Offer_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Base_Offer_Aggregate_Bool_Exp_Count>;
};

export type Base_Offer_Aggregate_Bool_Exp_Bool_And = {
  arguments: Base_Offer_Select_Column_Base_Offer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Base_Offer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Base_Offer_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Base_Offer_Select_Column_Base_Offer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Base_Offer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Base_Offer_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Base_Offer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Base_Offer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "base_offer" */
export type Base_Offer_Aggregate_Fields = {
  __typename?: 'base_offer_aggregate_fields';
  avg?: Maybe<Base_Offer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Base_Offer_Max_Fields>;
  min?: Maybe<Base_Offer_Min_Fields>;
  stddev?: Maybe<Base_Offer_Stddev_Fields>;
  stddev_pop?: Maybe<Base_Offer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Base_Offer_Stddev_Samp_Fields>;
  sum?: Maybe<Base_Offer_Sum_Fields>;
  var_pop?: Maybe<Base_Offer_Var_Pop_Fields>;
  var_samp?: Maybe<Base_Offer_Var_Samp_Fields>;
  variance?: Maybe<Base_Offer_Variance_Fields>;
};


/** aggregate fields of "base_offer" */
export type Base_Offer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Base_Offer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "base_offer" */
export type Base_Offer_Aggregate_Order_By = {
  avg?: Maybe<Base_Offer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Base_Offer_Max_Order_By>;
  min?: Maybe<Base_Offer_Min_Order_By>;
  stddev?: Maybe<Base_Offer_Stddev_Order_By>;
  stddev_pop?: Maybe<Base_Offer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Base_Offer_Stddev_Samp_Order_By>;
  sum?: Maybe<Base_Offer_Sum_Order_By>;
  var_pop?: Maybe<Base_Offer_Var_Pop_Order_By>;
  var_samp?: Maybe<Base_Offer_Var_Samp_Order_By>;
  variance?: Maybe<Base_Offer_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Base_Offer_Append_Input = {
  external?: Maybe<Scalars['jsonb']>;
  payment_on_hold_reason?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "base_offer" */
export type Base_Offer_Arr_Rel_Insert_Input = {
  data: Array<Base_Offer_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Base_Offer_On_Conflict>;
};

/** aggregate avg on columns */
export type Base_Offer_Avg_Fields = {
  __typename?: 'base_offer_avg_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "base_offer" */
export type Base_Offer_Avg_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "base_offer". All fields are combined with a logical 'AND'. */
export type Base_Offer_Bool_Exp = {
  _and?: Maybe<Array<Base_Offer_Bool_Exp>>;
  _not?: Maybe<Base_Offer_Bool_Exp>;
  _or?: Maybe<Array<Base_Offer_Bool_Exp>>;
  accepted_at?: Maybe<Timestamp_Comparison_Exp>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Uuid_Comparison_Exp>;
  assignments?: Maybe<Assignment_Bool_Exp>;
  assignments_aggregate?: Maybe<Assignment_Aggregate_Bool_Exp>;
  bank_account?: Maybe<Bank_Account_Bool_Exp>;
  bank_account_id?: Maybe<Uuid_Comparison_Exp>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  claim_amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  external?: Maybe<Jsonb_Comparison_Exp>;
  has_external?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  offer_amount?: Maybe<Numeric_Comparison_Exp>;
  payment_on_hold?: Maybe<Boolean_Comparison_Exp>;
  payment_on_hold_reason?: Maybe<Jsonb_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  payments_aggregate?: Maybe<Payment_Aggregate_Bool_Exp>;
  rejected_at?: Maybe<Timestamp_Comparison_Exp>;
  rejection_reason?: Maybe<String_Comparison_Exp>;
  sent_at?: Maybe<Timestamp_Comparison_Exp>;
  signature?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "base_offer" */
export enum Base_Offer_Constraint {
  /** unique or primary key constraint on columns "address_id" */
  BaseOfferAddressIdKey = 'base_offer_address_id_key',
  /** unique or primary key constraint on columns "bank_account_id" */
  BaseOfferBankAccountIdKey = 'base_offer_bank_account_id_key',
  /** unique or primary key constraint on columns "id" */
  BaseOfferPkey = 'base_offer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Base_Offer_Delete_At_Path_Input = {
  external?: Maybe<Array<Scalars['String']>>;
  payment_on_hold_reason?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Base_Offer_Delete_Elem_Input = {
  external?: Maybe<Scalars['Int']>;
  payment_on_hold_reason?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Base_Offer_Delete_Key_Input = {
  external?: Maybe<Scalars['String']>;
  payment_on_hold_reason?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "base_offer" */
export type Base_Offer_Inc_Input = {
  claim_amount?: Maybe<Scalars['numeric']>;
  offer_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "base_offer" */
export type Base_Offer_Insert_Input = {
  accepted_at?: Maybe<Scalars['timestamp']>;
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['uuid']>;
  assignments?: Maybe<Assignment_Arr_Rel_Insert_Input>;
  bank_account?: Maybe<Bank_Account_Obj_Rel_Insert_Input>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  external?: Maybe<Scalars['jsonb']>;
  has_external?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['jsonb']>;
  payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Base_Offer_Max_Fields = {
  __typename?: 'base_offer_max_fields';
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "base_offer" */
export type Base_Offer_Max_Order_By = {
  accepted_at?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  bank_account_id?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
  rejected_at?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Base_Offer_Min_Fields = {
  __typename?: 'base_offer_min_fields';
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "base_offer" */
export type Base_Offer_Min_Order_By = {
  accepted_at?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  bank_account_id?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
  rejected_at?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "base_offer" */
export type Base_Offer_Mutation_Response = {
  __typename?: 'base_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Offer>;
};

/** input type for inserting object relation for remote table "base_offer" */
export type Base_Offer_Obj_Rel_Insert_Input = {
  data: Base_Offer_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Base_Offer_On_Conflict>;
};

/** on_conflict condition type for table "base_offer" */
export type Base_Offer_On_Conflict = {
  constraint: Base_Offer_Constraint;
  update_columns?: Array<Base_Offer_Update_Column>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};

/** Ordering options when selecting data from "base_offer". */
export type Base_Offer_Order_By = {
  accepted_at?: Maybe<Order_By>;
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  assignments_aggregate?: Maybe<Assignment_Aggregate_Order_By>;
  bank_account?: Maybe<Bank_Account_Order_By>;
  bank_account_id?: Maybe<Order_By>;
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external?: Maybe<Order_By>;
  has_external?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
  payment_on_hold?: Maybe<Order_By>;
  payment_on_hold_reason?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  rejected_at?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: base_offer */
export type Base_Offer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Base_Offer_Prepend_Input = {
  external?: Maybe<Scalars['jsonb']>;
  payment_on_hold_reason?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "base_offer" */
export enum Base_Offer_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BankAccountId = 'bank_account_id',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  External = 'external',
  /** column name */
  HasExternal = 'has_external',
  /** column name */
  Id = 'id',
  /** column name */
  OfferAmount = 'offer_amount',
  /** column name */
  PaymentOnHold = 'payment_on_hold',
  /** column name */
  PaymentOnHoldReason = 'payment_on_hold_reason',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Signature = 'signature',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "base_offer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "base_offer" */
export enum Base_Offer_Select_Column_Base_Offer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasExternal = 'has_external',
  /** column name */
  PaymentOnHold = 'payment_on_hold'
}

/** select "base_offer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "base_offer" */
export enum Base_Offer_Select_Column_Base_Offer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasExternal = 'has_external',
  /** column name */
  PaymentOnHold = 'payment_on_hold'
}

/** input type for updating data in table "base_offer" */
export type Base_Offer_Set_Input = {
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  external?: Maybe<Scalars['jsonb']>;
  has_external?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['jsonb']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Base_Offer_Stddev_Fields = {
  __typename?: 'base_offer_stddev_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "base_offer" */
export type Base_Offer_Stddev_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Base_Offer_Stddev_Pop_Fields = {
  __typename?: 'base_offer_stddev_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "base_offer" */
export type Base_Offer_Stddev_Pop_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Base_Offer_Stddev_Samp_Fields = {
  __typename?: 'base_offer_stddev_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "base_offer" */
export type Base_Offer_Stddev_Samp_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** Streaming cursor of the table "base_offer" */
export type Base_Offer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Offer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Offer_Stream_Cursor_Value_Input = {
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  external?: Maybe<Scalars['jsonb']>;
  has_external?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['jsonb']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Base_Offer_Sum_Fields = {
  __typename?: 'base_offer_sum_fields';
  claim_amount?: Maybe<Scalars['numeric']>;
  offer_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "base_offer" */
export type Base_Offer_Sum_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** update columns of table "base_offer" */
export enum Base_Offer_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BankAccountId = 'bank_account_id',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  External = 'external',
  /** column name */
  HasExternal = 'has_external',
  /** column name */
  Id = 'id',
  /** column name */
  OfferAmount = 'offer_amount',
  /** column name */
  PaymentOnHold = 'payment_on_hold',
  /** column name */
  PaymentOnHoldReason = 'payment_on_hold_reason',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Signature = 'signature',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Base_Offer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Base_Offer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Base_Offer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Base_Offer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Base_Offer_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Base_Offer_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Base_Offer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Base_Offer_Set_Input>;
  where: Base_Offer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Base_Offer_Var_Pop_Fields = {
  __typename?: 'base_offer_var_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "base_offer" */
export type Base_Offer_Var_Pop_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Base_Offer_Var_Samp_Fields = {
  __typename?: 'base_offer_var_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "base_offer" */
export type Base_Offer_Var_Samp_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Base_Offer_Variance_Fields = {
  __typename?: 'base_offer_variance_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "base_offer" */
export type Base_Offer_Variance_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** columns and relationships of "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_Full = {
  __typename?: 'bi_tableau_kpi_full';
  address_id?: Maybe<Scalars['uuid']>;
  adgroup?: Maybe<Scalars['String']>;
  adgroup_name?: Maybe<Scalars['String']>;
  adname?: Maybe<Scalars['String']>;
  adwordsdevice?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  assignment_created_at?: Maybe<Scalars['timestamp']>;
  assignment_received_at?: Maybe<Scalars['timestamp']>;
  assignment_sent_at?: Maybe<Scalars['timestamp']>;
  assignment_state?: Maybe<Scalars['String']>;
  assignment_updated_at?: Maybe<Scalars['timestamp']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  booking_reference?: Maybe<Scalars['String']>;
  campaign_name?: Maybe<Scalars['String']>;
  case_created_at?: Maybe<Scalars['timestamp']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_rejection_reason?: Maybe<Scalars['String']>;
  case_type?: Maybe<Scalars['String']>;
  case_updated_at?: Maybe<Scalars['timestamp']>;
  channel?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_first_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_last_name?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  departure_date?: Maybe<Scalars['jsonb']>;
  dig_signature_offer?: Maybe<Scalars['Int']>;
  digital_signature_received_at?: Maybe<Scalars['timestamp']>;
  document_request_reason?: Maybe<Scalars['String']>;
  first_visit_url?: Maybe<Scalars['String']>;
  flag_case_accepted?: Maybe<Scalars['Int']>;
  flag_case_rejected?: Maybe<Scalars['Int']>;
  flag_digital_signature?: Maybe<Scalars['Int']>;
  flag_fts?: Maybe<Scalars['Int']>;
  flag_offer_accepted?: Maybe<Scalars['Int']>;
  flag_purchased?: Maybe<Scalars['Int']>;
  flight_type?: Maybe<Scalars['String']>;
  full_pkv_claim_detail?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  insurance_number?: Maybe<Scalars['String']>;
  insured_since?: Maybe<Scalars['String']>;
  internal_comments?: Maybe<Scalars['String']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  is_sports_betting?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  last_visit_url?: Maybe<Scalars['String']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  lockdown_months?: Maybe<Scalars['String']>;
  membership_number?: Maybe<Scalars['String']>;
  meter_number?: Maybe<Scalars['String']>;
  money_spent?: Maybe<Scalars['numeric']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  most_recent_offer_accepted_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_created_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_id?: Maybe<Scalars['uuid']>;
  most_recent_offer_sent_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_state?: Maybe<Scalars['String']>;
  num_of_assign_sent?: Maybe<Scalars['bigint']>;
  num_offers?: Maybe<Scalars['bigint']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['jsonb']>;
  payout_amount?: Maybe<Scalars['numeric']>;
  postal_code?: Maybe<Scalars['String']>;
  potential_claim_amount?: Maybe<Scalars['numeric']>;
  potential_offer_amount?: Maybe<Scalars['numeric']>;
  promo_code?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  source_marketing?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tariffs?: Maybe<Scalars['String']>;
  ticket_number?: Maybe<Scalars['jsonb']>;
  vertical?: Maybe<Scalars['String']>;
};


/** columns and relationships of "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_FullDeparture_DateArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_FullPayment_On_Hold_ReasonArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_FullTicket_NumberArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_Full_Aggregate = {
  __typename?: 'bi_tableau_kpi_full_aggregate';
  aggregate?: Maybe<Bi_Tableau_Kpi_Full_Aggregate_Fields>;
  nodes: Array<Bi_Tableau_Kpi_Full>;
};

/** aggregate fields of "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_Full_Aggregate_Fields = {
  __typename?: 'bi_tableau_kpi_full_aggregate_fields';
  avg?: Maybe<Bi_Tableau_Kpi_Full_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Bi_Tableau_Kpi_Full_Max_Fields>;
  min?: Maybe<Bi_Tableau_Kpi_Full_Min_Fields>;
  stddev?: Maybe<Bi_Tableau_Kpi_Full_Stddev_Fields>;
  stddev_pop?: Maybe<Bi_Tableau_Kpi_Full_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bi_Tableau_Kpi_Full_Stddev_Samp_Fields>;
  sum?: Maybe<Bi_Tableau_Kpi_Full_Sum_Fields>;
  var_pop?: Maybe<Bi_Tableau_Kpi_Full_Var_Pop_Fields>;
  var_samp?: Maybe<Bi_Tableau_Kpi_Full_Var_Samp_Fields>;
  variance?: Maybe<Bi_Tableau_Kpi_Full_Variance_Fields>;
};


/** aggregate fields of "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_Full_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bi_Tableau_Kpi_Full_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Bi_Tableau_Kpi_Full_Avg_Fields = {
  __typename?: 'bi_tableau_kpi_full_avg_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  dig_signature_offer?: Maybe<Scalars['Float']>;
  flag_case_accepted?: Maybe<Scalars['Float']>;
  flag_case_rejected?: Maybe<Scalars['Float']>;
  flag_digital_signature?: Maybe<Scalars['Float']>;
  flag_fts?: Maybe<Scalars['Float']>;
  flag_offer_accepted?: Maybe<Scalars['Float']>;
  flag_purchased?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  money_spent?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  num_of_assign_sent?: Maybe<Scalars['Float']>;
  num_offers?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
  payout_amount?: Maybe<Scalars['Float']>;
  potential_claim_amount?: Maybe<Scalars['Float']>;
  potential_offer_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "bi_tableau_kpi_full". All fields are combined with a logical 'AND'. */
export type Bi_Tableau_Kpi_Full_Bool_Exp = {
  _and?: Maybe<Array<Bi_Tableau_Kpi_Full_Bool_Exp>>;
  _not?: Maybe<Bi_Tableau_Kpi_Full_Bool_Exp>;
  _or?: Maybe<Array<Bi_Tableau_Kpi_Full_Bool_Exp>>;
  address_id?: Maybe<Uuid_Comparison_Exp>;
  adgroup?: Maybe<String_Comparison_Exp>;
  adgroup_name?: Maybe<String_Comparison_Exp>;
  adname?: Maybe<String_Comparison_Exp>;
  adwordsdevice?: Maybe<String_Comparison_Exp>;
  alias_id?: Maybe<String_Comparison_Exp>;
  assignment_created_at?: Maybe<Timestamp_Comparison_Exp>;
  assignment_received_at?: Maybe<Timestamp_Comparison_Exp>;
  assignment_sent_at?: Maybe<Timestamp_Comparison_Exp>;
  assignment_state?: Maybe<String_Comparison_Exp>;
  assignment_updated_at?: Maybe<Timestamp_Comparison_Exp>;
  bank_account_id?: Maybe<Uuid_Comparison_Exp>;
  booking_reference?: Maybe<String_Comparison_Exp>;
  campaign_name?: Maybe<String_Comparison_Exp>;
  case_created_at?: Maybe<Timestamp_Comparison_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  case_rejection_reason?: Maybe<String_Comparison_Exp>;
  case_type?: Maybe<String_Comparison_Exp>;
  case_updated_at?: Maybe<Timestamp_Comparison_Exp>;
  channel?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  claim_amount?: Maybe<Numeric_Comparison_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  company_name?: Maybe<String_Comparison_Exp>;
  contract_end_date?: Maybe<Date_Comparison_Exp>;
  contract_number?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  customer_email?: Maybe<String_Comparison_Exp>;
  customer_first_name?: Maybe<String_Comparison_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  customer_last_name?: Maybe<String_Comparison_Exp>;
  customer_type?: Maybe<String_Comparison_Exp>;
  departure_date?: Maybe<Jsonb_Comparison_Exp>;
  dig_signature_offer?: Maybe<Int_Comparison_Exp>;
  digital_signature_received_at?: Maybe<Timestamp_Comparison_Exp>;
  document_request_reason?: Maybe<String_Comparison_Exp>;
  first_visit_url?: Maybe<String_Comparison_Exp>;
  flag_case_accepted?: Maybe<Int_Comparison_Exp>;
  flag_case_rejected?: Maybe<Int_Comparison_Exp>;
  flag_digital_signature?: Maybe<Int_Comparison_Exp>;
  flag_fts?: Maybe<Int_Comparison_Exp>;
  flag_offer_accepted?: Maybe<Int_Comparison_Exp>;
  flag_purchased?: Maybe<Int_Comparison_Exp>;
  flight_type?: Maybe<String_Comparison_Exp>;
  full_pkv_claim_detail?: Maybe<String_Comparison_Exp>;
  iban?: Maybe<String_Comparison_Exp>;
  insurance_increase_date?: Maybe<Date_Comparison_Exp>;
  insurance_number?: Maybe<String_Comparison_Exp>;
  insured_since?: Maybe<String_Comparison_Exp>;
  internal_comments?: Maybe<String_Comparison_Exp>;
  is_estimated_claim_amount?: Maybe<Boolean_Comparison_Exp>;
  is_sports_betting?: Maybe<Boolean_Comparison_Exp>;
  keyword?: Maybe<String_Comparison_Exp>;
  last_visit_url?: Maybe<String_Comparison_Exp>;
  lawyer_fee?: Maybe<Numeric_Comparison_Exp>;
  lockdown_months?: Maybe<String_Comparison_Exp>;
  membership_number?: Maybe<String_Comparison_Exp>;
  meter_number?: Maybe<String_Comparison_Exp>;
  money_spent?: Maybe<Numeric_Comparison_Exp>;
  monthly_amount?: Maybe<Numeric_Comparison_Exp>;
  most_recent_offer_accepted_at?: Maybe<Timestamp_Comparison_Exp>;
  most_recent_offer_created_at?: Maybe<Timestamp_Comparison_Exp>;
  most_recent_offer_id?: Maybe<Uuid_Comparison_Exp>;
  most_recent_offer_sent_at?: Maybe<Timestamp_Comparison_Exp>;
  most_recent_offer_state?: Maybe<String_Comparison_Exp>;
  num_of_assign_sent?: Maybe<Bigint_Comparison_Exp>;
  num_offers?: Maybe<Bigint_Comparison_Exp>;
  offer_amount?: Maybe<Numeric_Comparison_Exp>;
  payment_on_hold?: Maybe<Boolean_Comparison_Exp>;
  payment_on_hold_reason?: Maybe<Jsonb_Comparison_Exp>;
  payout_amount?: Maybe<Numeric_Comparison_Exp>;
  postal_code?: Maybe<String_Comparison_Exp>;
  potential_claim_amount?: Maybe<Numeric_Comparison_Exp>;
  potential_offer_amount?: Maybe<Numeric_Comparison_Exp>;
  promo_code?: Maybe<String_Comparison_Exp>;
  referrer?: Maybe<String_Comparison_Exp>;
  source_marketing?: Maybe<String_Comparison_Exp>;
  source_of_estimation?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  street?: Maybe<String_Comparison_Exp>;
  tariffs?: Maybe<String_Comparison_Exp>;
  ticket_number?: Maybe<Jsonb_Comparison_Exp>;
  vertical?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Bi_Tableau_Kpi_Full_Max_Fields = {
  __typename?: 'bi_tableau_kpi_full_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  adgroup?: Maybe<Scalars['String']>;
  adgroup_name?: Maybe<Scalars['String']>;
  adname?: Maybe<Scalars['String']>;
  adwordsdevice?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  assignment_created_at?: Maybe<Scalars['timestamp']>;
  assignment_received_at?: Maybe<Scalars['timestamp']>;
  assignment_sent_at?: Maybe<Scalars['timestamp']>;
  assignment_state?: Maybe<Scalars['String']>;
  assignment_updated_at?: Maybe<Scalars['timestamp']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  booking_reference?: Maybe<Scalars['String']>;
  campaign_name?: Maybe<Scalars['String']>;
  case_created_at?: Maybe<Scalars['timestamp']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_rejection_reason?: Maybe<Scalars['String']>;
  case_type?: Maybe<Scalars['String']>;
  case_updated_at?: Maybe<Scalars['timestamp']>;
  channel?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_first_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_last_name?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  dig_signature_offer?: Maybe<Scalars['Int']>;
  digital_signature_received_at?: Maybe<Scalars['timestamp']>;
  document_request_reason?: Maybe<Scalars['String']>;
  first_visit_url?: Maybe<Scalars['String']>;
  flag_case_accepted?: Maybe<Scalars['Int']>;
  flag_case_rejected?: Maybe<Scalars['Int']>;
  flag_digital_signature?: Maybe<Scalars['Int']>;
  flag_fts?: Maybe<Scalars['Int']>;
  flag_offer_accepted?: Maybe<Scalars['Int']>;
  flag_purchased?: Maybe<Scalars['Int']>;
  flight_type?: Maybe<Scalars['String']>;
  full_pkv_claim_detail?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  insurance_number?: Maybe<Scalars['String']>;
  insured_since?: Maybe<Scalars['String']>;
  internal_comments?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  last_visit_url?: Maybe<Scalars['String']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  lockdown_months?: Maybe<Scalars['String']>;
  membership_number?: Maybe<Scalars['String']>;
  meter_number?: Maybe<Scalars['String']>;
  money_spent?: Maybe<Scalars['numeric']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  most_recent_offer_accepted_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_created_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_id?: Maybe<Scalars['uuid']>;
  most_recent_offer_sent_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_state?: Maybe<Scalars['String']>;
  num_of_assign_sent?: Maybe<Scalars['bigint']>;
  num_offers?: Maybe<Scalars['bigint']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payout_amount?: Maybe<Scalars['numeric']>;
  postal_code?: Maybe<Scalars['String']>;
  potential_claim_amount?: Maybe<Scalars['numeric']>;
  potential_offer_amount?: Maybe<Scalars['numeric']>;
  promo_code?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  source_marketing?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tariffs?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bi_Tableau_Kpi_Full_Min_Fields = {
  __typename?: 'bi_tableau_kpi_full_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  adgroup?: Maybe<Scalars['String']>;
  adgroup_name?: Maybe<Scalars['String']>;
  adname?: Maybe<Scalars['String']>;
  adwordsdevice?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  assignment_created_at?: Maybe<Scalars['timestamp']>;
  assignment_received_at?: Maybe<Scalars['timestamp']>;
  assignment_sent_at?: Maybe<Scalars['timestamp']>;
  assignment_state?: Maybe<Scalars['String']>;
  assignment_updated_at?: Maybe<Scalars['timestamp']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  booking_reference?: Maybe<Scalars['String']>;
  campaign_name?: Maybe<Scalars['String']>;
  case_created_at?: Maybe<Scalars['timestamp']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_rejection_reason?: Maybe<Scalars['String']>;
  case_type?: Maybe<Scalars['String']>;
  case_updated_at?: Maybe<Scalars['timestamp']>;
  channel?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_first_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_last_name?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  dig_signature_offer?: Maybe<Scalars['Int']>;
  digital_signature_received_at?: Maybe<Scalars['timestamp']>;
  document_request_reason?: Maybe<Scalars['String']>;
  first_visit_url?: Maybe<Scalars['String']>;
  flag_case_accepted?: Maybe<Scalars['Int']>;
  flag_case_rejected?: Maybe<Scalars['Int']>;
  flag_digital_signature?: Maybe<Scalars['Int']>;
  flag_fts?: Maybe<Scalars['Int']>;
  flag_offer_accepted?: Maybe<Scalars['Int']>;
  flag_purchased?: Maybe<Scalars['Int']>;
  flight_type?: Maybe<Scalars['String']>;
  full_pkv_claim_detail?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  insurance_number?: Maybe<Scalars['String']>;
  insured_since?: Maybe<Scalars['String']>;
  internal_comments?: Maybe<Scalars['String']>;
  keyword?: Maybe<Scalars['String']>;
  last_visit_url?: Maybe<Scalars['String']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  lockdown_months?: Maybe<Scalars['String']>;
  membership_number?: Maybe<Scalars['String']>;
  meter_number?: Maybe<Scalars['String']>;
  money_spent?: Maybe<Scalars['numeric']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  most_recent_offer_accepted_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_created_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_id?: Maybe<Scalars['uuid']>;
  most_recent_offer_sent_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_state?: Maybe<Scalars['String']>;
  num_of_assign_sent?: Maybe<Scalars['bigint']>;
  num_offers?: Maybe<Scalars['bigint']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payout_amount?: Maybe<Scalars['numeric']>;
  postal_code?: Maybe<Scalars['String']>;
  potential_claim_amount?: Maybe<Scalars['numeric']>;
  potential_offer_amount?: Maybe<Scalars['numeric']>;
  promo_code?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  source_marketing?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tariffs?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "bi_tableau_kpi_full". */
export type Bi_Tableau_Kpi_Full_Order_By = {
  address_id?: Maybe<Order_By>;
  adgroup?: Maybe<Order_By>;
  adgroup_name?: Maybe<Order_By>;
  adname?: Maybe<Order_By>;
  adwordsdevice?: Maybe<Order_By>;
  alias_id?: Maybe<Order_By>;
  assignment_created_at?: Maybe<Order_By>;
  assignment_received_at?: Maybe<Order_By>;
  assignment_sent_at?: Maybe<Order_By>;
  assignment_state?: Maybe<Order_By>;
  assignment_updated_at?: Maybe<Order_By>;
  bank_account_id?: Maybe<Order_By>;
  booking_reference?: Maybe<Order_By>;
  campaign_name?: Maybe<Order_By>;
  case_created_at?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  case_rejection_reason?: Maybe<Order_By>;
  case_type?: Maybe<Order_By>;
  case_updated_at?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  company_name?: Maybe<Order_By>;
  contract_end_date?: Maybe<Order_By>;
  contract_number?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  customer_email?: Maybe<Order_By>;
  customer_first_name?: Maybe<Order_By>;
  customer_id?: Maybe<Order_By>;
  customer_last_name?: Maybe<Order_By>;
  customer_type?: Maybe<Order_By>;
  departure_date?: Maybe<Order_By>;
  dig_signature_offer?: Maybe<Order_By>;
  digital_signature_received_at?: Maybe<Order_By>;
  document_request_reason?: Maybe<Order_By>;
  first_visit_url?: Maybe<Order_By>;
  flag_case_accepted?: Maybe<Order_By>;
  flag_case_rejected?: Maybe<Order_By>;
  flag_digital_signature?: Maybe<Order_By>;
  flag_fts?: Maybe<Order_By>;
  flag_offer_accepted?: Maybe<Order_By>;
  flag_purchased?: Maybe<Order_By>;
  flight_type?: Maybe<Order_By>;
  full_pkv_claim_detail?: Maybe<Order_By>;
  iban?: Maybe<Order_By>;
  insurance_increase_date?: Maybe<Order_By>;
  insurance_number?: Maybe<Order_By>;
  insured_since?: Maybe<Order_By>;
  internal_comments?: Maybe<Order_By>;
  is_estimated_claim_amount?: Maybe<Order_By>;
  is_sports_betting?: Maybe<Order_By>;
  keyword?: Maybe<Order_By>;
  last_visit_url?: Maybe<Order_By>;
  lawyer_fee?: Maybe<Order_By>;
  lockdown_months?: Maybe<Order_By>;
  membership_number?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
  money_spent?: Maybe<Order_By>;
  monthly_amount?: Maybe<Order_By>;
  most_recent_offer_accepted_at?: Maybe<Order_By>;
  most_recent_offer_created_at?: Maybe<Order_By>;
  most_recent_offer_id?: Maybe<Order_By>;
  most_recent_offer_sent_at?: Maybe<Order_By>;
  most_recent_offer_state?: Maybe<Order_By>;
  num_of_assign_sent?: Maybe<Order_By>;
  num_offers?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
  payment_on_hold?: Maybe<Order_By>;
  payment_on_hold_reason?: Maybe<Order_By>;
  payout_amount?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  potential_claim_amount?: Maybe<Order_By>;
  potential_offer_amount?: Maybe<Order_By>;
  promo_code?: Maybe<Order_By>;
  referrer?: Maybe<Order_By>;
  source_marketing?: Maybe<Order_By>;
  source_of_estimation?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  tariffs?: Maybe<Order_By>;
  ticket_number?: Maybe<Order_By>;
  vertical?: Maybe<Order_By>;
};

/** select columns of table "bi_tableau_kpi_full" */
export enum Bi_Tableau_Kpi_Full_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  Adgroup = 'adgroup',
  /** column name */
  AdgroupName = 'adgroup_name',
  /** column name */
  Adname = 'adname',
  /** column name */
  Adwordsdevice = 'adwordsdevice',
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  AssignmentCreatedAt = 'assignment_created_at',
  /** column name */
  AssignmentReceivedAt = 'assignment_received_at',
  /** column name */
  AssignmentSentAt = 'assignment_sent_at',
  /** column name */
  AssignmentState = 'assignment_state',
  /** column name */
  AssignmentUpdatedAt = 'assignment_updated_at',
  /** column name */
  BankAccountId = 'bank_account_id',
  /** column name */
  BookingReference = 'booking_reference',
  /** column name */
  CampaignName = 'campaign_name',
  /** column name */
  CaseCreatedAt = 'case_created_at',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CaseRejectionReason = 'case_rejection_reason',
  /** column name */
  CaseType = 'case_type',
  /** column name */
  CaseUpdatedAt = 'case_updated_at',
  /** column name */
  Channel = 'channel',
  /** column name */
  City = 'city',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  ContractEndDate = 'contract_end_date',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerFirstName = 'customer_first_name',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  CustomerLastName = 'customer_last_name',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  DepartureDate = 'departure_date',
  /** column name */
  DigSignatureOffer = 'dig_signature_offer',
  /** column name */
  DigitalSignatureReceivedAt = 'digital_signature_received_at',
  /** column name */
  DocumentRequestReason = 'document_request_reason',
  /** column name */
  FirstVisitUrl = 'first_visit_url',
  /** column name */
  FlagCaseAccepted = 'flag_case_accepted',
  /** column name */
  FlagCaseRejected = 'flag_case_rejected',
  /** column name */
  FlagDigitalSignature = 'flag_digital_signature',
  /** column name */
  FlagFts = 'flag_fts',
  /** column name */
  FlagOfferAccepted = 'flag_offer_accepted',
  /** column name */
  FlagPurchased = 'flag_purchased',
  /** column name */
  FlightType = 'flight_type',
  /** column name */
  FullPkvClaimDetail = 'full_pkv_claim_detail',
  /** column name */
  Iban = 'iban',
  /** column name */
  InsuranceIncreaseDate = 'insurance_increase_date',
  /** column name */
  InsuranceNumber = 'insurance_number',
  /** column name */
  InsuredSince = 'insured_since',
  /** column name */
  InternalComments = 'internal_comments',
  /** column name */
  IsEstimatedClaimAmount = 'is_estimated_claim_amount',
  /** column name */
  IsSportsBetting = 'is_sports_betting',
  /** column name */
  Keyword = 'keyword',
  /** column name */
  LastVisitUrl = 'last_visit_url',
  /** column name */
  LawyerFee = 'lawyer_fee',
  /** column name */
  LockdownMonths = 'lockdown_months',
  /** column name */
  MembershipNumber = 'membership_number',
  /** column name */
  MeterNumber = 'meter_number',
  /** column name */
  MoneySpent = 'money_spent',
  /** column name */
  MonthlyAmount = 'monthly_amount',
  /** column name */
  MostRecentOfferAcceptedAt = 'most_recent_offer_accepted_at',
  /** column name */
  MostRecentOfferCreatedAt = 'most_recent_offer_created_at',
  /** column name */
  MostRecentOfferId = 'most_recent_offer_id',
  /** column name */
  MostRecentOfferSentAt = 'most_recent_offer_sent_at',
  /** column name */
  MostRecentOfferState = 'most_recent_offer_state',
  /** column name */
  NumOfAssignSent = 'num_of_assign_sent',
  /** column name */
  NumOffers = 'num_offers',
  /** column name */
  OfferAmount = 'offer_amount',
  /** column name */
  PaymentOnHold = 'payment_on_hold',
  /** column name */
  PaymentOnHoldReason = 'payment_on_hold_reason',
  /** column name */
  PayoutAmount = 'payout_amount',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  PotentialClaimAmount = 'potential_claim_amount',
  /** column name */
  PotentialOfferAmount = 'potential_offer_amount',
  /** column name */
  PromoCode = 'promo_code',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  SourceMarketing = 'source_marketing',
  /** column name */
  SourceOfEstimation = 'source_of_estimation',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  Tariffs = 'tariffs',
  /** column name */
  TicketNumber = 'ticket_number',
  /** column name */
  Vertical = 'vertical'
}

/** aggregate stddev on columns */
export type Bi_Tableau_Kpi_Full_Stddev_Fields = {
  __typename?: 'bi_tableau_kpi_full_stddev_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  dig_signature_offer?: Maybe<Scalars['Float']>;
  flag_case_accepted?: Maybe<Scalars['Float']>;
  flag_case_rejected?: Maybe<Scalars['Float']>;
  flag_digital_signature?: Maybe<Scalars['Float']>;
  flag_fts?: Maybe<Scalars['Float']>;
  flag_offer_accepted?: Maybe<Scalars['Float']>;
  flag_purchased?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  money_spent?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  num_of_assign_sent?: Maybe<Scalars['Float']>;
  num_offers?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
  payout_amount?: Maybe<Scalars['Float']>;
  potential_claim_amount?: Maybe<Scalars['Float']>;
  potential_offer_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Bi_Tableau_Kpi_Full_Stddev_Pop_Fields = {
  __typename?: 'bi_tableau_kpi_full_stddev_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  dig_signature_offer?: Maybe<Scalars['Float']>;
  flag_case_accepted?: Maybe<Scalars['Float']>;
  flag_case_rejected?: Maybe<Scalars['Float']>;
  flag_digital_signature?: Maybe<Scalars['Float']>;
  flag_fts?: Maybe<Scalars['Float']>;
  flag_offer_accepted?: Maybe<Scalars['Float']>;
  flag_purchased?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  money_spent?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  num_of_assign_sent?: Maybe<Scalars['Float']>;
  num_offers?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
  payout_amount?: Maybe<Scalars['Float']>;
  potential_claim_amount?: Maybe<Scalars['Float']>;
  potential_offer_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Bi_Tableau_Kpi_Full_Stddev_Samp_Fields = {
  __typename?: 'bi_tableau_kpi_full_stddev_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  dig_signature_offer?: Maybe<Scalars['Float']>;
  flag_case_accepted?: Maybe<Scalars['Float']>;
  flag_case_rejected?: Maybe<Scalars['Float']>;
  flag_digital_signature?: Maybe<Scalars['Float']>;
  flag_fts?: Maybe<Scalars['Float']>;
  flag_offer_accepted?: Maybe<Scalars['Float']>;
  flag_purchased?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  money_spent?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  num_of_assign_sent?: Maybe<Scalars['Float']>;
  num_offers?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
  payout_amount?: Maybe<Scalars['Float']>;
  potential_claim_amount?: Maybe<Scalars['Float']>;
  potential_offer_amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "bi_tableau_kpi_full" */
export type Bi_Tableau_Kpi_Full_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bi_Tableau_Kpi_Full_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bi_Tableau_Kpi_Full_Stream_Cursor_Value_Input = {
  address_id?: Maybe<Scalars['uuid']>;
  adgroup?: Maybe<Scalars['String']>;
  adgroup_name?: Maybe<Scalars['String']>;
  adname?: Maybe<Scalars['String']>;
  adwordsdevice?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  assignment_created_at?: Maybe<Scalars['timestamp']>;
  assignment_received_at?: Maybe<Scalars['timestamp']>;
  assignment_sent_at?: Maybe<Scalars['timestamp']>;
  assignment_state?: Maybe<Scalars['String']>;
  assignment_updated_at?: Maybe<Scalars['timestamp']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  booking_reference?: Maybe<Scalars['String']>;
  campaign_name?: Maybe<Scalars['String']>;
  case_created_at?: Maybe<Scalars['timestamp']>;
  case_id?: Maybe<Scalars['uuid']>;
  case_rejection_reason?: Maybe<Scalars['String']>;
  case_type?: Maybe<Scalars['String']>;
  case_updated_at?: Maybe<Scalars['timestamp']>;
  channel?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_first_name?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['uuid']>;
  customer_last_name?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  departure_date?: Maybe<Scalars['jsonb']>;
  dig_signature_offer?: Maybe<Scalars['Int']>;
  digital_signature_received_at?: Maybe<Scalars['timestamp']>;
  document_request_reason?: Maybe<Scalars['String']>;
  first_visit_url?: Maybe<Scalars['String']>;
  flag_case_accepted?: Maybe<Scalars['Int']>;
  flag_case_rejected?: Maybe<Scalars['Int']>;
  flag_digital_signature?: Maybe<Scalars['Int']>;
  flag_fts?: Maybe<Scalars['Int']>;
  flag_offer_accepted?: Maybe<Scalars['Int']>;
  flag_purchased?: Maybe<Scalars['Int']>;
  flight_type?: Maybe<Scalars['String']>;
  full_pkv_claim_detail?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  insurance_number?: Maybe<Scalars['String']>;
  insured_since?: Maybe<Scalars['String']>;
  internal_comments?: Maybe<Scalars['String']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  is_sports_betting?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  last_visit_url?: Maybe<Scalars['String']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  lockdown_months?: Maybe<Scalars['String']>;
  membership_number?: Maybe<Scalars['String']>;
  meter_number?: Maybe<Scalars['String']>;
  money_spent?: Maybe<Scalars['numeric']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  most_recent_offer_accepted_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_created_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_id?: Maybe<Scalars['uuid']>;
  most_recent_offer_sent_at?: Maybe<Scalars['timestamp']>;
  most_recent_offer_state?: Maybe<Scalars['String']>;
  num_of_assign_sent?: Maybe<Scalars['bigint']>;
  num_offers?: Maybe<Scalars['bigint']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['jsonb']>;
  payout_amount?: Maybe<Scalars['numeric']>;
  postal_code?: Maybe<Scalars['String']>;
  potential_claim_amount?: Maybe<Scalars['numeric']>;
  potential_offer_amount?: Maybe<Scalars['numeric']>;
  promo_code?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  source_marketing?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tariffs?: Maybe<Scalars['String']>;
  ticket_number?: Maybe<Scalars['jsonb']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Bi_Tableau_Kpi_Full_Sum_Fields = {
  __typename?: 'bi_tableau_kpi_full_sum_fields';
  claim_amount?: Maybe<Scalars['numeric']>;
  dig_signature_offer?: Maybe<Scalars['Int']>;
  flag_case_accepted?: Maybe<Scalars['Int']>;
  flag_case_rejected?: Maybe<Scalars['Int']>;
  flag_digital_signature?: Maybe<Scalars['Int']>;
  flag_fts?: Maybe<Scalars['Int']>;
  flag_offer_accepted?: Maybe<Scalars['Int']>;
  flag_purchased?: Maybe<Scalars['Int']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  money_spent?: Maybe<Scalars['numeric']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  num_of_assign_sent?: Maybe<Scalars['bigint']>;
  num_offers?: Maybe<Scalars['bigint']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payout_amount?: Maybe<Scalars['numeric']>;
  potential_claim_amount?: Maybe<Scalars['numeric']>;
  potential_offer_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Bi_Tableau_Kpi_Full_Var_Pop_Fields = {
  __typename?: 'bi_tableau_kpi_full_var_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  dig_signature_offer?: Maybe<Scalars['Float']>;
  flag_case_accepted?: Maybe<Scalars['Float']>;
  flag_case_rejected?: Maybe<Scalars['Float']>;
  flag_digital_signature?: Maybe<Scalars['Float']>;
  flag_fts?: Maybe<Scalars['Float']>;
  flag_offer_accepted?: Maybe<Scalars['Float']>;
  flag_purchased?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  money_spent?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  num_of_assign_sent?: Maybe<Scalars['Float']>;
  num_offers?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
  payout_amount?: Maybe<Scalars['Float']>;
  potential_claim_amount?: Maybe<Scalars['Float']>;
  potential_offer_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Bi_Tableau_Kpi_Full_Var_Samp_Fields = {
  __typename?: 'bi_tableau_kpi_full_var_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  dig_signature_offer?: Maybe<Scalars['Float']>;
  flag_case_accepted?: Maybe<Scalars['Float']>;
  flag_case_rejected?: Maybe<Scalars['Float']>;
  flag_digital_signature?: Maybe<Scalars['Float']>;
  flag_fts?: Maybe<Scalars['Float']>;
  flag_offer_accepted?: Maybe<Scalars['Float']>;
  flag_purchased?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  money_spent?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  num_of_assign_sent?: Maybe<Scalars['Float']>;
  num_offers?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
  payout_amount?: Maybe<Scalars['Float']>;
  potential_claim_amount?: Maybe<Scalars['Float']>;
  potential_offer_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Bi_Tableau_Kpi_Full_Variance_Fields = {
  __typename?: 'bi_tableau_kpi_full_variance_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  dig_signature_offer?: Maybe<Scalars['Float']>;
  flag_case_accepted?: Maybe<Scalars['Float']>;
  flag_case_rejected?: Maybe<Scalars['Float']>;
  flag_digital_signature?: Maybe<Scalars['Float']>;
  flag_fts?: Maybe<Scalars['Float']>;
  flag_offer_accepted?: Maybe<Scalars['Float']>;
  flag_purchased?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  money_spent?: Maybe<Scalars['Float']>;
  monthly_amount?: Maybe<Scalars['Float']>;
  num_of_assign_sent?: Maybe<Scalars['Float']>;
  num_offers?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
  payout_amount?: Maybe<Scalars['Float']>;
  potential_claim_amount?: Maybe<Scalars['Float']>;
  potential_offer_amount?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "case_comment" */
export type Case_Comment = {
  __typename?: 'case_comment';
  case_id: Scalars['uuid'];
  comment: Scalars['String'];
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "case_comment" */
export type Case_Comment_Aggregate = {
  __typename?: 'case_comment_aggregate';
  aggregate?: Maybe<Case_Comment_Aggregate_Fields>;
  nodes: Array<Case_Comment>;
};

/** aggregate fields of "case_comment" */
export type Case_Comment_Aggregate_Fields = {
  __typename?: 'case_comment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Case_Comment_Max_Fields>;
  min?: Maybe<Case_Comment_Min_Fields>;
};


/** aggregate fields of "case_comment" */
export type Case_Comment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Comment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "case_comment". All fields are combined with a logical 'AND'. */
export type Case_Comment_Bool_Exp = {
  _and?: Maybe<Array<Case_Comment_Bool_Exp>>;
  _not?: Maybe<Case_Comment_Bool_Exp>;
  _or?: Maybe<Array<Case_Comment_Bool_Exp>>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_comment" */
export enum Case_Comment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseCommentPkey = 'case_comment_pkey'
}

/** input type for inserting data into table "case_comment" */
export type Case_Comment_Insert_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Case_Comment_Max_Fields = {
  __typename?: 'case_comment_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Case_Comment_Min_Fields = {
  __typename?: 'case_comment_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "case_comment" */
export type Case_Comment_Mutation_Response = {
  __typename?: 'case_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Comment>;
};

/** on_conflict condition type for table "case_comment" */
export type Case_Comment_On_Conflict = {
  constraint: Case_Comment_Constraint;
  update_columns?: Array<Case_Comment_Update_Column>;
  where?: Maybe<Case_Comment_Bool_Exp>;
};

/** Ordering options when selecting data from "case_comment". */
export type Case_Comment_Order_By = {
  case_id?: Maybe<Order_By>;
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: case_comment */
export type Case_Comment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "case_comment" */
export enum Case_Comment_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "case_comment" */
export type Case_Comment_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "case_comment" */
export type Case_Comment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Comment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Comment_Stream_Cursor_Value_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "case_comment" */
export enum Case_Comment_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Case_Comment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Case_Comment_Set_Input>;
  where: Case_Comment_Bool_Exp;
};

/** columns and relationships of "case_list_view" */
export type Case_List_View = {
  __typename?: 'case_list_view';
  address_hash?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  fit_claim?: Maybe<Fit_Claim>;
  /** An object relationship */
  gdpr_claim?: Maybe<Gdpr_Claim>;
  /** An object relationship */
  gff_claim?: Maybe<Gff_Claim>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  nflx_claim?: Maybe<Nflx_Claim>;
  /** An object relationship */
  nrg_claim?: Maybe<Nrg_Claim>;
  /** An object relationship */
  ocg_claim?: Maybe<Ocg_Claim>;
  /** An object relationship */
  offer?: Maybe<Base_Offer>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_state?: Maybe<Scalars['String']>;
  /** An object relationship */
  pkv_claim?: Maybe<Pkv_Claim>;
  product_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "case_list_view" */
export type Case_List_View_Aggregate = {
  __typename?: 'case_list_view_aggregate';
  aggregate?: Maybe<Case_List_View_Aggregate_Fields>;
  nodes: Array<Case_List_View>;
};

/** aggregate fields of "case_list_view" */
export type Case_List_View_Aggregate_Fields = {
  __typename?: 'case_list_view_aggregate_fields';
  avg?: Maybe<Case_List_View_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Case_List_View_Max_Fields>;
  min?: Maybe<Case_List_View_Min_Fields>;
  stddev?: Maybe<Case_List_View_Stddev_Fields>;
  stddev_pop?: Maybe<Case_List_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_List_View_Stddev_Samp_Fields>;
  sum?: Maybe<Case_List_View_Sum_Fields>;
  var_pop?: Maybe<Case_List_View_Var_Pop_Fields>;
  var_samp?: Maybe<Case_List_View_Var_Samp_Fields>;
  variance?: Maybe<Case_List_View_Variance_Fields>;
};


/** aggregate fields of "case_list_view" */
export type Case_List_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_List_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Case_List_View_Avg_Fields = {
  __typename?: 'case_list_view_avg_fields';
  claim_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "case_list_view". All fields are combined with a logical 'AND'. */
export type Case_List_View_Bool_Exp = {
  _and?: Maybe<Array<Case_List_View_Bool_Exp>>;
  _not?: Maybe<Case_List_View_Bool_Exp>;
  _or?: Maybe<Array<Case_List_View_Bool_Exp>>;
  address_hash?: Maybe<String_Comparison_Exp>;
  alias_id?: Maybe<String_Comparison_Exp>;
  claim_amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  fit_claim?: Maybe<Fit_Claim_Bool_Exp>;
  gdpr_claim?: Maybe<Gdpr_Claim_Bool_Exp>;
  gff_claim?: Maybe<Gff_Claim_Bool_Exp>;
  iban?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  nflx_claim?: Maybe<Nflx_Claim_Bool_Exp>;
  nrg_claim?: Maybe<Nrg_Claim_Bool_Exp>;
  ocg_claim?: Maybe<Ocg_Claim_Bool_Exp>;
  offer?: Maybe<Base_Offer_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
  offer_state?: Maybe<String_Comparison_Exp>;
  pkv_claim?: Maybe<Pkv_Claim_Bool_Exp>;
  product_id?: Maybe<String_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type Case_List_View_Max_Fields = {
  __typename?: 'case_list_view_max_fields';
  address_hash?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_state?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Case_List_View_Min_Fields = {
  __typename?: 'case_list_view_min_fields';
  address_hash?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_state?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "case_list_view". */
export type Case_List_View_Order_By = {
  address_hash?: Maybe<Order_By>;
  alias_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  fit_claim?: Maybe<Fit_Claim_Order_By>;
  gdpr_claim?: Maybe<Gdpr_Claim_Order_By>;
  gff_claim?: Maybe<Gff_Claim_Order_By>;
  iban?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  nflx_claim?: Maybe<Nflx_Claim_Order_By>;
  nrg_claim?: Maybe<Nrg_Claim_Order_By>;
  ocg_claim?: Maybe<Ocg_Claim_Order_By>;
  offer?: Maybe<Base_Offer_Order_By>;
  offer_id?: Maybe<Order_By>;
  offer_state?: Maybe<Order_By>;
  pkv_claim?: Maybe<Pkv_Claim_Order_By>;
  product_id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** select columns of table "case_list_view" */
export enum Case_List_View_Select_Column {
  /** column name */
  AddressHash = 'address_hash',
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  OfferState = 'offer_state',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Case_List_View_Stddev_Fields = {
  __typename?: 'case_list_view_stddev_fields';
  claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Case_List_View_Stddev_Pop_Fields = {
  __typename?: 'case_list_view_stddev_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Case_List_View_Stddev_Samp_Fields = {
  __typename?: 'case_list_view_stddev_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "case_list_view" */
export type Case_List_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_List_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_List_View_Stream_Cursor_Value_Input = {
  address_hash?: Maybe<Scalars['String']>;
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  offer_id?: Maybe<Scalars['uuid']>;
  offer_state?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Case_List_View_Sum_Fields = {
  __typename?: 'case_list_view_sum_fields';
  claim_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Case_List_View_Var_Pop_Fields = {
  __typename?: 'case_list_view_var_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Case_List_View_Var_Samp_Fields = {
  __typename?: 'case_list_view_var_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Case_List_View_Variance_Fields = {
  __typename?: 'case_list_view_variance_fields';
  claim_amount?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "customer" */
export type Customer = {
  __typename?: 'customer';
  created_at?: Maybe<Scalars['timestamp']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  newsletter_optin: Scalars['Boolean'];
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "customer" */
export type Customer_Aggregate = {
  __typename?: 'customer_aggregate';
  aggregate?: Maybe<Customer_Aggregate_Fields>;
  nodes: Array<Customer>;
};

/** aggregate fields of "customer" */
export type Customer_Aggregate_Fields = {
  __typename?: 'customer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Customer_Max_Fields>;
  min?: Maybe<Customer_Min_Fields>;
};


/** aggregate fields of "customer" */
export type Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Customer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "customer". All fields are combined with a logical 'AND'. */
export type Customer_Bool_Exp = {
  _and?: Maybe<Array<Customer_Bool_Exp>>;
  _not?: Maybe<Customer_Bool_Exp>;
  _or?: Maybe<Array<Customer_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  newsletter_optin?: Maybe<Boolean_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  salutation?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer" */
export enum Customer_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerPkey = 'customer_pkey'
}

/** input type for inserting data into table "customer" */
export type Customer_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  newsletter_optin?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Customer_Max_Fields = {
  __typename?: 'customer_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Customer_Min_Fields = {
  __typename?: 'customer_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "customer" */
export type Customer_Mutation_Response = {
  __typename?: 'customer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer>;
};

/** input type for inserting object relation for remote table "customer" */
export type Customer_Obj_Rel_Insert_Input = {
  data: Customer_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Customer_On_Conflict>;
};

/** on_conflict condition type for table "customer" */
export type Customer_On_Conflict = {
  constraint: Customer_Constraint;
  update_columns?: Array<Customer_Update_Column>;
  where?: Maybe<Customer_Bool_Exp>;
};

/** Ordering options when selecting data from "customer". */
export type Customer_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  newsletter_optin?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  salutation?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: customer */
export type Customer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customer" */
export enum Customer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NewsletterOptin = 'newsletter_optin',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "customer" */
export type Customer_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  newsletter_optin?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "customer" */
export type Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  newsletter_optin?: Maybe<Scalars['Boolean']>;
  phone_number?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "customer" */
export enum Customer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  NewsletterOptin = 'newsletter_optin',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Customer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Customer_Set_Input>;
  where: Customer_Bool_Exp;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "email_queue" */
export type Email_Queue = {
  __typename?: 'email_queue';
  attachments: Scalars['jsonb'];
  bcc?: Maybe<Scalars['jsonb']>;
  cc?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamp'];
  from: Scalars['jsonb'];
  id: Scalars['uuid'];
  reply_to?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  subject?: Maybe<Scalars['String']>;
  template_id: Scalars['String'];
  to: Scalars['jsonb'];
  updated_at: Scalars['timestamp'];
  variables: Scalars['jsonb'];
};


/** columns and relationships of "email_queue" */
export type Email_QueueAttachmentsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "email_queue" */
export type Email_QueueBccArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "email_queue" */
export type Email_QueueCcArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "email_queue" */
export type Email_QueueFromArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "email_queue" */
export type Email_QueueReply_ToArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "email_queue" */
export type Email_QueueToArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "email_queue" */
export type Email_QueueVariablesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "email_queue" */
export type Email_Queue_Aggregate = {
  __typename?: 'email_queue_aggregate';
  aggregate?: Maybe<Email_Queue_Aggregate_Fields>;
  nodes: Array<Email_Queue>;
};

/** aggregate fields of "email_queue" */
export type Email_Queue_Aggregate_Fields = {
  __typename?: 'email_queue_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Email_Queue_Max_Fields>;
  min?: Maybe<Email_Queue_Min_Fields>;
};


/** aggregate fields of "email_queue" */
export type Email_Queue_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Queue_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Queue_Append_Input = {
  attachments?: Maybe<Scalars['jsonb']>;
  bcc?: Maybe<Scalars['jsonb']>;
  cc?: Maybe<Scalars['jsonb']>;
  from?: Maybe<Scalars['jsonb']>;
  reply_to?: Maybe<Scalars['jsonb']>;
  to?: Maybe<Scalars['jsonb']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "email_queue". All fields are combined with a logical 'AND'. */
export type Email_Queue_Bool_Exp = {
  _and?: Maybe<Array<Email_Queue_Bool_Exp>>;
  _not?: Maybe<Email_Queue_Bool_Exp>;
  _or?: Maybe<Array<Email_Queue_Bool_Exp>>;
  attachments?: Maybe<Jsonb_Comparison_Exp>;
  bcc?: Maybe<Jsonb_Comparison_Exp>;
  cc?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  from?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  reply_to?: Maybe<Jsonb_Comparison_Exp>;
  sent_at?: Maybe<Timestamp_Comparison_Exp>;
  subject?: Maybe<String_Comparison_Exp>;
  template_id?: Maybe<String_Comparison_Exp>;
  to?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  variables?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_queue" */
export enum Email_Queue_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailQueuePkey = 'email_queue_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Queue_Delete_At_Path_Input = {
  attachments?: Maybe<Array<Scalars['String']>>;
  bcc?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  from?: Maybe<Array<Scalars['String']>>;
  reply_to?: Maybe<Array<Scalars['String']>>;
  to?: Maybe<Array<Scalars['String']>>;
  variables?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Queue_Delete_Elem_Input = {
  attachments?: Maybe<Scalars['Int']>;
  bcc?: Maybe<Scalars['Int']>;
  cc?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  reply_to?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
  variables?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Queue_Delete_Key_Input = {
  attachments?: Maybe<Scalars['String']>;
  bcc?: Maybe<Scalars['String']>;
  cc?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  reply_to?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  variables?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "email_queue" */
export type Email_Queue_Insert_Input = {
  attachments?: Maybe<Scalars['jsonb']>;
  bcc?: Maybe<Scalars['jsonb']>;
  cc?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamp']>;
  from?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  reply_to?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  subject?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Email_Queue_Max_Fields = {
  __typename?: 'email_queue_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  subject?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Email_Queue_Min_Fields = {
  __typename?: 'email_queue_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  subject?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "email_queue" */
export type Email_Queue_Mutation_Response = {
  __typename?: 'email_queue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Queue>;
};

/** on_conflict condition type for table "email_queue" */
export type Email_Queue_On_Conflict = {
  constraint: Email_Queue_Constraint;
  update_columns?: Array<Email_Queue_Update_Column>;
  where?: Maybe<Email_Queue_Bool_Exp>;
};

/** Ordering options when selecting data from "email_queue". */
export type Email_Queue_Order_By = {
  attachments?: Maybe<Order_By>;
  bcc?: Maybe<Order_By>;
  cc?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reply_to?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  subject?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  variables?: Maybe<Order_By>;
};

/** primary key columns input for table: email_queue */
export type Email_Queue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Queue_Prepend_Input = {
  attachments?: Maybe<Scalars['jsonb']>;
  bcc?: Maybe<Scalars['jsonb']>;
  cc?: Maybe<Scalars['jsonb']>;
  from?: Maybe<Scalars['jsonb']>;
  reply_to?: Maybe<Scalars['jsonb']>;
  to?: Maybe<Scalars['jsonb']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "email_queue" */
export enum Email_Queue_Select_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Cc = 'cc',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  ReplyTo = 'reply_to',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Subject = 'subject',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Variables = 'variables'
}

/** input type for updating data in table "email_queue" */
export type Email_Queue_Set_Input = {
  attachments?: Maybe<Scalars['jsonb']>;
  bcc?: Maybe<Scalars['jsonb']>;
  cc?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamp']>;
  from?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  reply_to?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  subject?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "email_queue" */
export type Email_Queue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Queue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Queue_Stream_Cursor_Value_Input = {
  attachments?: Maybe<Scalars['jsonb']>;
  bcc?: Maybe<Scalars['jsonb']>;
  cc?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamp']>;
  from?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  reply_to?: Maybe<Scalars['jsonb']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  subject?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "email_queue" */
export enum Email_Queue_Update_Column {
  /** column name */
  Attachments = 'attachments',
  /** column name */
  Bcc = 'bcc',
  /** column name */
  Cc = 'cc',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  ReplyTo = 'reply_to',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Subject = 'subject',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  To = 'to',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Variables = 'variables'
}

export type Email_Queue_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Email_Queue_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Email_Queue_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Email_Queue_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Email_Queue_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Email_Queue_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Email_Queue_Set_Input>;
  where: Email_Queue_Bool_Exp;
};

/** columns and relationships of "fit_claim" */
export type Fit_Claim = {
  __typename?: 'fit_claim';
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  /** An object relationship */
  company?: Maybe<Fit_Company>;
  company_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamp'];
  has_paid: Scalars['Boolean'];
  id: Scalars['uuid'];
  lockdown_months?: Maybe<Scalars['jsonb']>;
  membership_number?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  other_company?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "fit_claim" */
export type Fit_ClaimLockdown_MonthsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "fit_claim" */
export type Fit_Claim_Aggregate = {
  __typename?: 'fit_claim_aggregate';
  aggregate?: Maybe<Fit_Claim_Aggregate_Fields>;
  nodes: Array<Fit_Claim>;
};

export type Fit_Claim_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Fit_Claim_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Fit_Claim_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Fit_Claim_Aggregate_Bool_Exp_Count>;
};

export type Fit_Claim_Aggregate_Bool_Exp_Bool_And = {
  arguments: Fit_Claim_Select_Column_Fit_Claim_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Fit_Claim_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Fit_Claim_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Fit_Claim_Select_Column_Fit_Claim_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Fit_Claim_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Fit_Claim_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Fit_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Fit_Claim_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "fit_claim" */
export type Fit_Claim_Aggregate_Fields = {
  __typename?: 'fit_claim_aggregate_fields';
  avg?: Maybe<Fit_Claim_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fit_Claim_Max_Fields>;
  min?: Maybe<Fit_Claim_Min_Fields>;
  stddev?: Maybe<Fit_Claim_Stddev_Fields>;
  stddev_pop?: Maybe<Fit_Claim_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fit_Claim_Stddev_Samp_Fields>;
  sum?: Maybe<Fit_Claim_Sum_Fields>;
  var_pop?: Maybe<Fit_Claim_Var_Pop_Fields>;
  var_samp?: Maybe<Fit_Claim_Var_Samp_Fields>;
  variance?: Maybe<Fit_Claim_Variance_Fields>;
};


/** aggregate fields of "fit_claim" */
export type Fit_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fit_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fit_claim" */
export type Fit_Claim_Aggregate_Order_By = {
  avg?: Maybe<Fit_Claim_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Fit_Claim_Max_Order_By>;
  min?: Maybe<Fit_Claim_Min_Order_By>;
  stddev?: Maybe<Fit_Claim_Stddev_Order_By>;
  stddev_pop?: Maybe<Fit_Claim_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Fit_Claim_Stddev_Samp_Order_By>;
  sum?: Maybe<Fit_Claim_Sum_Order_By>;
  var_pop?: Maybe<Fit_Claim_Var_Pop_Order_By>;
  var_samp?: Maybe<Fit_Claim_Var_Samp_Order_By>;
  variance?: Maybe<Fit_Claim_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fit_Claim_Append_Input = {
  lockdown_months?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "fit_claim" */
export type Fit_Claim_Arr_Rel_Insert_Input = {
  data: Array<Fit_Claim_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Fit_Claim_On_Conflict>;
};

/** aggregate avg on columns */
export type Fit_Claim_Avg_Fields = {
  __typename?: 'fit_claim_avg_fields';
  monthly_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fit_claim" */
export type Fit_Claim_Avg_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fit_claim". All fields are combined with a logical 'AND'. */
export type Fit_Claim_Bool_Exp = {
  _and?: Maybe<Array<Fit_Claim_Bool_Exp>>;
  _not?: Maybe<Fit_Claim_Bool_Exp>;
  _or?: Maybe<Array<Fit_Claim_Bool_Exp>>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  company?: Maybe<Fit_Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  has_paid?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lockdown_months?: Maybe<Jsonb_Comparison_Exp>;
  membership_number?: Maybe<String_Comparison_Exp>;
  monthly_amount?: Maybe<Numeric_Comparison_Exp>;
  other_company?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "fit_claim" */
export enum Fit_Claim_Constraint {
  /** unique or primary key constraint on columns "id" */
  FitClaimPkey = 'fit_claim_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fit_Claim_Delete_At_Path_Input = {
  lockdown_months?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fit_Claim_Delete_Elem_Input = {
  lockdown_months?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fit_Claim_Delete_Key_Input = {
  lockdown_months?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "fit_claim" */
export type Fit_Claim_Inc_Input = {
  monthly_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "fit_claim" */
export type Fit_Claim_Insert_Input = {
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  company?: Maybe<Fit_Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  has_paid?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  lockdown_months?: Maybe<Scalars['jsonb']>;
  membership_number?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  other_company?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Fit_Claim_Max_Fields = {
  __typename?: 'fit_claim_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  membership_number?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  other_company?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "fit_claim" */
export type Fit_Claim_Max_Order_By = {
  case_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membership_number?: Maybe<Order_By>;
  monthly_amount?: Maybe<Order_By>;
  other_company?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Fit_Claim_Min_Fields = {
  __typename?: 'fit_claim_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  membership_number?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  other_company?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "fit_claim" */
export type Fit_Claim_Min_Order_By = {
  case_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membership_number?: Maybe<Order_By>;
  monthly_amount?: Maybe<Order_By>;
  other_company?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "fit_claim" */
export type Fit_Claim_Mutation_Response = {
  __typename?: 'fit_claim_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fit_Claim>;
};

/** input type for inserting object relation for remote table "fit_claim" */
export type Fit_Claim_Obj_Rel_Insert_Input = {
  data: Fit_Claim_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Fit_Claim_On_Conflict>;
};

/** on_conflict condition type for table "fit_claim" */
export type Fit_Claim_On_Conflict = {
  constraint: Fit_Claim_Constraint;
  update_columns?: Array<Fit_Claim_Update_Column>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};

/** Ordering options when selecting data from "fit_claim". */
export type Fit_Claim_Order_By = {
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  company?: Maybe<Fit_Company_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  has_paid?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lockdown_months?: Maybe<Order_By>;
  membership_number?: Maybe<Order_By>;
  monthly_amount?: Maybe<Order_By>;
  other_company?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: fit_claim */
export type Fit_Claim_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fit_Claim_Prepend_Input = {
  lockdown_months?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "fit_claim" */
export enum Fit_Claim_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasPaid = 'has_paid',
  /** column name */
  Id = 'id',
  /** column name */
  LockdownMonths = 'lockdown_months',
  /** column name */
  MembershipNumber = 'membership_number',
  /** column name */
  MonthlyAmount = 'monthly_amount',
  /** column name */
  OtherCompany = 'other_company',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "fit_claim_aggregate_bool_exp_bool_and_arguments_columns" columns of table "fit_claim" */
export enum Fit_Claim_Select_Column_Fit_Claim_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasPaid = 'has_paid'
}

/** select "fit_claim_aggregate_bool_exp_bool_or_arguments_columns" columns of table "fit_claim" */
export enum Fit_Claim_Select_Column_Fit_Claim_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasPaid = 'has_paid'
}

/** input type for updating data in table "fit_claim" */
export type Fit_Claim_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  has_paid?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  lockdown_months?: Maybe<Scalars['jsonb']>;
  membership_number?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  other_company?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Fit_Claim_Stddev_Fields = {
  __typename?: 'fit_claim_stddev_fields';
  monthly_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fit_claim" */
export type Fit_Claim_Stddev_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fit_Claim_Stddev_Pop_Fields = {
  __typename?: 'fit_claim_stddev_pop_fields';
  monthly_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fit_claim" */
export type Fit_Claim_Stddev_Pop_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fit_Claim_Stddev_Samp_Fields = {
  __typename?: 'fit_claim_stddev_samp_fields';
  monthly_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fit_claim" */
export type Fit_Claim_Stddev_Samp_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** Streaming cursor of the table "fit_claim" */
export type Fit_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fit_Claim_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fit_Claim_Stream_Cursor_Value_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  has_paid?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  lockdown_months?: Maybe<Scalars['jsonb']>;
  membership_number?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['numeric']>;
  other_company?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Fit_Claim_Sum_Fields = {
  __typename?: 'fit_claim_sum_fields';
  monthly_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "fit_claim" */
export type Fit_Claim_Sum_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** update columns of table "fit_claim" */
export enum Fit_Claim_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasPaid = 'has_paid',
  /** column name */
  Id = 'id',
  /** column name */
  LockdownMonths = 'lockdown_months',
  /** column name */
  MembershipNumber = 'membership_number',
  /** column name */
  MonthlyAmount = 'monthly_amount',
  /** column name */
  OtherCompany = 'other_company',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Fit_Claim_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Fit_Claim_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Fit_Claim_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Fit_Claim_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Fit_Claim_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Fit_Claim_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Fit_Claim_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fit_Claim_Set_Input>;
  where: Fit_Claim_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fit_Claim_Var_Pop_Fields = {
  __typename?: 'fit_claim_var_pop_fields';
  monthly_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fit_claim" */
export type Fit_Claim_Var_Pop_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fit_Claim_Var_Samp_Fields = {
  __typename?: 'fit_claim_var_samp_fields';
  monthly_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fit_claim" */
export type Fit_Claim_Var_Samp_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Fit_Claim_Variance_Fields = {
  __typename?: 'fit_claim_variance_fields';
  monthly_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fit_claim" */
export type Fit_Claim_Variance_Order_By = {
  monthly_amount?: Maybe<Order_By>;
};

/** This is not an enum table, id is a text value */
export type Fit_Company = {
  __typename?: 'fit_company';
  id: Scalars['String'];
  is_supported: Scalars['Boolean'];
  name: Scalars['String'];
};

/** aggregated selection of "fit_company" */
export type Fit_Company_Aggregate = {
  __typename?: 'fit_company_aggregate';
  aggregate?: Maybe<Fit_Company_Aggregate_Fields>;
  nodes: Array<Fit_Company>;
};

/** aggregate fields of "fit_company" */
export type Fit_Company_Aggregate_Fields = {
  __typename?: 'fit_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fit_Company_Max_Fields>;
  min?: Maybe<Fit_Company_Min_Fields>;
};


/** aggregate fields of "fit_company" */
export type Fit_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fit_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "fit_company". All fields are combined with a logical 'AND'. */
export type Fit_Company_Bool_Exp = {
  _and?: Maybe<Array<Fit_Company_Bool_Exp>>;
  _not?: Maybe<Fit_Company_Bool_Exp>;
  _or?: Maybe<Array<Fit_Company_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_supported?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fit_company" */
export enum Fit_Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  FitCompanyPkey = 'fit_company_pkey'
}

/** input type for inserting data into table "fit_company" */
export type Fit_Company_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fit_Company_Max_Fields = {
  __typename?: 'fit_company_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fit_Company_Min_Fields = {
  __typename?: 'fit_company_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fit_company" */
export type Fit_Company_Mutation_Response = {
  __typename?: 'fit_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fit_Company>;
};

/** input type for inserting object relation for remote table "fit_company" */
export type Fit_Company_Obj_Rel_Insert_Input = {
  data: Fit_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Fit_Company_On_Conflict>;
};

/** on_conflict condition type for table "fit_company" */
export type Fit_Company_On_Conflict = {
  constraint: Fit_Company_Constraint;
  update_columns?: Array<Fit_Company_Update_Column>;
  where?: Maybe<Fit_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "fit_company". */
export type Fit_Company_Order_By = {
  id?: Maybe<Order_By>;
  is_supported?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: fit_company */
export type Fit_Company_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "fit_company" */
export enum Fit_Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "fit_company" */
export type Fit_Company_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "fit_company" */
export type Fit_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fit_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fit_Company_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "fit_company" */
export enum Fit_Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

export type Fit_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fit_Company_Set_Input>;
  where: Fit_Company_Bool_Exp;
};

/** columns and relationships of "fit_fts_transaction" */
export type Fit_Fts_Transaction = {
  __typename?: 'fit_fts_transaction';
  case_id: Scalars['uuid'];
  id: Scalars['uuid'];
  transaction: Scalars['String'];
};

/** aggregated selection of "fit_fts_transaction" */
export type Fit_Fts_Transaction_Aggregate = {
  __typename?: 'fit_fts_transaction_aggregate';
  aggregate?: Maybe<Fit_Fts_Transaction_Aggregate_Fields>;
  nodes: Array<Fit_Fts_Transaction>;
};

/** aggregate fields of "fit_fts_transaction" */
export type Fit_Fts_Transaction_Aggregate_Fields = {
  __typename?: 'fit_fts_transaction_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fit_Fts_Transaction_Max_Fields>;
  min?: Maybe<Fit_Fts_Transaction_Min_Fields>;
};


/** aggregate fields of "fit_fts_transaction" */
export type Fit_Fts_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fit_Fts_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "fit_fts_transaction". All fields are combined with a logical 'AND'. */
export type Fit_Fts_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Fit_Fts_Transaction_Bool_Exp>>;
  _not?: Maybe<Fit_Fts_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Fit_Fts_Transaction_Bool_Exp>>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  transaction?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fit_fts_transaction" */
export enum Fit_Fts_Transaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  FitFtsTransactionPkey = 'fit_fts_transaction_pkey'
}

/** input type for inserting data into table "fit_fts_transaction" */
export type Fit_Fts_Transaction_Insert_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fit_Fts_Transaction_Max_Fields = {
  __typename?: 'fit_fts_transaction_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fit_Fts_Transaction_Min_Fields = {
  __typename?: 'fit_fts_transaction_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fit_fts_transaction" */
export type Fit_Fts_Transaction_Mutation_Response = {
  __typename?: 'fit_fts_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fit_Fts_Transaction>;
};

/** on_conflict condition type for table "fit_fts_transaction" */
export type Fit_Fts_Transaction_On_Conflict = {
  constraint: Fit_Fts_Transaction_Constraint;
  update_columns?: Array<Fit_Fts_Transaction_Update_Column>;
  where?: Maybe<Fit_Fts_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "fit_fts_transaction". */
export type Fit_Fts_Transaction_Order_By = {
  case_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  transaction?: Maybe<Order_By>;
};

/** primary key columns input for table: fit_fts_transaction */
export type Fit_Fts_Transaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fit_fts_transaction" */
export enum Fit_Fts_Transaction_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Id = 'id',
  /** column name */
  Transaction = 'transaction'
}

/** input type for updating data in table "fit_fts_transaction" */
export type Fit_Fts_Transaction_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "fit_fts_transaction" */
export type Fit_Fts_Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fit_Fts_Transaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fit_Fts_Transaction_Stream_Cursor_Value_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  transaction?: Maybe<Scalars['String']>;
};

/** update columns of table "fit_fts_transaction" */
export enum Fit_Fts_Transaction_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  Id = 'id',
  /** column name */
  Transaction = 'transaction'
}

export type Fit_Fts_Transaction_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fit_Fts_Transaction_Set_Input>;
  where: Fit_Fts_Transaction_Bool_Exp;
};

/** columns and relationships of "fs_bank_transaction" */
export type Fs_Bank_Transaction = {
  __typename?: 'fs_bank_transaction';
  amount: Scalars['numeric'];
  currency: Scalars['String'];
  date: Scalars['date'];
  id: Scalars['uuid'];
  payment_reference?: Maybe<Scalars['String']>;
  receiver_iban?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  remote_id: Scalars['String'];
  sender_iban?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "fs_bank_transaction" */
export type Fs_Bank_Transaction_Aggregate = {
  __typename?: 'fs_bank_transaction_aggregate';
  aggregate?: Maybe<Fs_Bank_Transaction_Aggregate_Fields>;
  nodes: Array<Fs_Bank_Transaction>;
};

/** aggregate fields of "fs_bank_transaction" */
export type Fs_Bank_Transaction_Aggregate_Fields = {
  __typename?: 'fs_bank_transaction_aggregate_fields';
  avg?: Maybe<Fs_Bank_Transaction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fs_Bank_Transaction_Max_Fields>;
  min?: Maybe<Fs_Bank_Transaction_Min_Fields>;
  stddev?: Maybe<Fs_Bank_Transaction_Stddev_Fields>;
  stddev_pop?: Maybe<Fs_Bank_Transaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fs_Bank_Transaction_Stddev_Samp_Fields>;
  sum?: Maybe<Fs_Bank_Transaction_Sum_Fields>;
  var_pop?: Maybe<Fs_Bank_Transaction_Var_Pop_Fields>;
  var_samp?: Maybe<Fs_Bank_Transaction_Var_Samp_Fields>;
  variance?: Maybe<Fs_Bank_Transaction_Variance_Fields>;
};


/** aggregate fields of "fs_bank_transaction" */
export type Fs_Bank_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fs_Bank_Transaction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fs_Bank_Transaction_Avg_Fields = {
  __typename?: 'fs_bank_transaction_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fs_bank_transaction". All fields are combined with a logical 'AND'. */
export type Fs_Bank_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Fs_Bank_Transaction_Bool_Exp>>;
  _not?: Maybe<Fs_Bank_Transaction_Bool_Exp>;
  _or?: Maybe<Array<Fs_Bank_Transaction_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  payment_reference?: Maybe<String_Comparison_Exp>;
  receiver_iban?: Maybe<String_Comparison_Exp>;
  receiver_name?: Maybe<String_Comparison_Exp>;
  remote_id?: Maybe<String_Comparison_Exp>;
  sender_iban?: Maybe<String_Comparison_Exp>;
  sender_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fs_bank_transaction" */
export enum Fs_Bank_Transaction_Constraint {
  /** unique or primary key constraint on columns "id" */
  FsBankTransactionPkey = 'fs_bank_transaction_pkey',
  /** unique or primary key constraint on columns "remote_id" */
  FsBankTransactionRemoteIdKey = 'fs_bank_transaction_remote_id_key'
}

/** input type for incrementing numeric columns in table "fs_bank_transaction" */
export type Fs_Bank_Transaction_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "fs_bank_transaction" */
export type Fs_Bank_Transaction_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  receiver_iban?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  remote_id?: Maybe<Scalars['String']>;
  sender_iban?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fs_Bank_Transaction_Max_Fields = {
  __typename?: 'fs_bank_transaction_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  receiver_iban?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  remote_id?: Maybe<Scalars['String']>;
  sender_iban?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fs_Bank_Transaction_Min_Fields = {
  __typename?: 'fs_bank_transaction_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  receiver_iban?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  remote_id?: Maybe<Scalars['String']>;
  sender_iban?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fs_bank_transaction" */
export type Fs_Bank_Transaction_Mutation_Response = {
  __typename?: 'fs_bank_transaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fs_Bank_Transaction>;
};

/** on_conflict condition type for table "fs_bank_transaction" */
export type Fs_Bank_Transaction_On_Conflict = {
  constraint: Fs_Bank_Transaction_Constraint;
  update_columns?: Array<Fs_Bank_Transaction_Update_Column>;
  where?: Maybe<Fs_Bank_Transaction_Bool_Exp>;
};

/** Ordering options when selecting data from "fs_bank_transaction". */
export type Fs_Bank_Transaction_Order_By = {
  amount?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_reference?: Maybe<Order_By>;
  receiver_iban?: Maybe<Order_By>;
  receiver_name?: Maybe<Order_By>;
  remote_id?: Maybe<Order_By>;
  sender_iban?: Maybe<Order_By>;
  sender_name?: Maybe<Order_By>;
};

/** primary key columns input for table: fs_bank_transaction */
export type Fs_Bank_Transaction_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fs_bank_transaction" */
export enum Fs_Bank_Transaction_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  ReceiverIban = 'receiver_iban',
  /** column name */
  ReceiverName = 'receiver_name',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  SenderIban = 'sender_iban',
  /** column name */
  SenderName = 'sender_name'
}

/** input type for updating data in table "fs_bank_transaction" */
export type Fs_Bank_Transaction_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  receiver_iban?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  remote_id?: Maybe<Scalars['String']>;
  sender_iban?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Fs_Bank_Transaction_Stddev_Fields = {
  __typename?: 'fs_bank_transaction_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fs_Bank_Transaction_Stddev_Pop_Fields = {
  __typename?: 'fs_bank_transaction_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fs_Bank_Transaction_Stddev_Samp_Fields = {
  __typename?: 'fs_bank_transaction_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fs_bank_transaction" */
export type Fs_Bank_Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fs_Bank_Transaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fs_Bank_Transaction_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['numeric']>;
  currency?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  receiver_iban?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  remote_id?: Maybe<Scalars['String']>;
  sender_iban?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Fs_Bank_Transaction_Sum_Fields = {
  __typename?: 'fs_bank_transaction_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** update columns of table "fs_bank_transaction" */
export enum Fs_Bank_Transaction_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Currency = 'currency',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  ReceiverIban = 'receiver_iban',
  /** column name */
  ReceiverName = 'receiver_name',
  /** column name */
  RemoteId = 'remote_id',
  /** column name */
  SenderIban = 'sender_iban',
  /** column name */
  SenderName = 'sender_name'
}

export type Fs_Bank_Transaction_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Fs_Bank_Transaction_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fs_Bank_Transaction_Set_Input>;
  where: Fs_Bank_Transaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fs_Bank_Transaction_Var_Pop_Fields = {
  __typename?: 'fs_bank_transaction_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fs_Bank_Transaction_Var_Samp_Fields = {
  __typename?: 'fs_bank_transaction_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fs_Bank_Transaction_Variance_Fields = {
  __typename?: 'fs_bank_transaction_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "fs_customer_payout" */
export type Fs_Customer_Payout = {
  __typename?: 'fs_customer_payout';
  alias_id: Scalars['String'];
  amount: Scalars['numeric'];
  case_id: Scalars['uuid'];
  created_at: Scalars['timestamp'];
  currency: Scalars['String'];
  customer_iban: Scalars['String'];
  customer_name: Scalars['String'];
  id: Scalars['uuid'];
  payment_reference: Scalars['String'];
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical: Scalars['String'];
};

/** aggregated selection of "fs_customer_payout" */
export type Fs_Customer_Payout_Aggregate = {
  __typename?: 'fs_customer_payout_aggregate';
  aggregate?: Maybe<Fs_Customer_Payout_Aggregate_Fields>;
  nodes: Array<Fs_Customer_Payout>;
};

/** aggregate fields of "fs_customer_payout" */
export type Fs_Customer_Payout_Aggregate_Fields = {
  __typename?: 'fs_customer_payout_aggregate_fields';
  avg?: Maybe<Fs_Customer_Payout_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Fs_Customer_Payout_Max_Fields>;
  min?: Maybe<Fs_Customer_Payout_Min_Fields>;
  stddev?: Maybe<Fs_Customer_Payout_Stddev_Fields>;
  stddev_pop?: Maybe<Fs_Customer_Payout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Fs_Customer_Payout_Stddev_Samp_Fields>;
  sum?: Maybe<Fs_Customer_Payout_Sum_Fields>;
  var_pop?: Maybe<Fs_Customer_Payout_Var_Pop_Fields>;
  var_samp?: Maybe<Fs_Customer_Payout_Var_Samp_Fields>;
  variance?: Maybe<Fs_Customer_Payout_Variance_Fields>;
};


/** aggregate fields of "fs_customer_payout" */
export type Fs_Customer_Payout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fs_Customer_Payout_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Fs_Customer_Payout_Avg_Fields = {
  __typename?: 'fs_customer_payout_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fs_customer_payout". All fields are combined with a logical 'AND'. */
export type Fs_Customer_Payout_Bool_Exp = {
  _and?: Maybe<Array<Fs_Customer_Payout_Bool_Exp>>;
  _not?: Maybe<Fs_Customer_Payout_Bool_Exp>;
  _or?: Maybe<Array<Fs_Customer_Payout_Bool_Exp>>;
  alias_id?: Maybe<String_Comparison_Exp>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  customer_iban?: Maybe<String_Comparison_Exp>;
  customer_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  payment_reference?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  vertical?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fs_customer_payout" */
export enum Fs_Customer_Payout_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_40edaf867820173f266aba7a2ff = 'PK_40edaf867820173f266aba7a2ff',
  /** unique or primary key constraint on columns "alias_id" */
  UqD9cebce0029cfb1bdfeb3c3b65c = 'UQ_d9cebce0029cfb1bdfeb3c3b65c'
}

/** input type for incrementing numeric columns in table "fs_customer_payout" */
export type Fs_Customer_Payout_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "fs_customer_payout" */
export type Fs_Customer_Payout_Insert_Input = {
  alias_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  customer_iban?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fs_Customer_Payout_Max_Fields = {
  __typename?: 'fs_customer_payout_max_fields';
  alias_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  customer_iban?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fs_Customer_Payout_Min_Fields = {
  __typename?: 'fs_customer_payout_min_fields';
  alias_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  customer_iban?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fs_customer_payout" */
export type Fs_Customer_Payout_Mutation_Response = {
  __typename?: 'fs_customer_payout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fs_Customer_Payout>;
};

/** on_conflict condition type for table "fs_customer_payout" */
export type Fs_Customer_Payout_On_Conflict = {
  constraint: Fs_Customer_Payout_Constraint;
  update_columns?: Array<Fs_Customer_Payout_Update_Column>;
  where?: Maybe<Fs_Customer_Payout_Bool_Exp>;
};

/** Ordering options when selecting data from "fs_customer_payout". */
export type Fs_Customer_Payout_Order_By = {
  alias_id?: Maybe<Order_By>;
  amount?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  customer_iban?: Maybe<Order_By>;
  customer_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_reference?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vertical?: Maybe<Order_By>;
};

/** primary key columns input for table: fs_customer_payout */
export type Fs_Customer_Payout_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue = {
  __typename?: 'fs_customer_payout_queue';
  created_at: Scalars['timestamp'];
  finished_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  started_at?: Maybe<Scalars['timestamp']>;
  status: Scalars['String'];
};

/** aggregated selection of "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_Aggregate = {
  __typename?: 'fs_customer_payout_queue_aggregate';
  aggregate?: Maybe<Fs_Customer_Payout_Queue_Aggregate_Fields>;
  nodes: Array<Fs_Customer_Payout_Queue>;
};

/** aggregate fields of "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_Aggregate_Fields = {
  __typename?: 'fs_customer_payout_queue_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Fs_Customer_Payout_Queue_Max_Fields>;
  min?: Maybe<Fs_Customer_Payout_Queue_Min_Fields>;
};


/** aggregate fields of "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fs_Customer_Payout_Queue_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "fs_customer_payout_queue". All fields are combined with a logical 'AND'. */
export type Fs_Customer_Payout_Queue_Bool_Exp = {
  _and?: Maybe<Array<Fs_Customer_Payout_Queue_Bool_Exp>>;
  _not?: Maybe<Fs_Customer_Payout_Queue_Bool_Exp>;
  _or?: Maybe<Array<Fs_Customer_Payout_Queue_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  finished_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  started_at?: Maybe<Timestamp_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fs_customer_payout_queue" */
export enum Fs_Customer_Payout_Queue_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkEcfa19f4a9ee6f53eed3200343d = 'PK_ecfa19f4a9ee6f53eed3200343d'
}

/** input type for inserting data into table "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  finished_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Fs_Customer_Payout_Queue_Max_Fields = {
  __typename?: 'fs_customer_payout_queue_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  finished_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Fs_Customer_Payout_Queue_Min_Fields = {
  __typename?: 'fs_customer_payout_queue_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  finished_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_Mutation_Response = {
  __typename?: 'fs_customer_payout_queue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Fs_Customer_Payout_Queue>;
};

/** on_conflict condition type for table "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_On_Conflict = {
  constraint: Fs_Customer_Payout_Queue_Constraint;
  update_columns?: Array<Fs_Customer_Payout_Queue_Update_Column>;
  where?: Maybe<Fs_Customer_Payout_Queue_Bool_Exp>;
};

/** Ordering options when selecting data from "fs_customer_payout_queue". */
export type Fs_Customer_Payout_Queue_Order_By = {
  created_at?: Maybe<Order_By>;
  finished_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  started_at?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: fs_customer_payout_queue */
export type Fs_Customer_Payout_Queue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "fs_customer_payout_queue" */
export enum Fs_Customer_Payout_Queue_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  finished_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "fs_customer_payout_queue" */
export type Fs_Customer_Payout_Queue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fs_Customer_Payout_Queue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fs_Customer_Payout_Queue_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  finished_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  started_at?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
};

/** update columns of table "fs_customer_payout_queue" */
export enum Fs_Customer_Payout_Queue_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  Status = 'status'
}

export type Fs_Customer_Payout_Queue_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fs_Customer_Payout_Queue_Set_Input>;
  where: Fs_Customer_Payout_Queue_Bool_Exp;
};

/** select columns of table "fs_customer_payout" */
export enum Fs_Customer_Payout_Select_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerIban = 'customer_iban',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vertical = 'vertical'
}

/** input type for updating data in table "fs_customer_payout" */
export type Fs_Customer_Payout_Set_Input = {
  alias_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  customer_iban?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Fs_Customer_Payout_Stddev_Fields = {
  __typename?: 'fs_customer_payout_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Fs_Customer_Payout_Stddev_Pop_Fields = {
  __typename?: 'fs_customer_payout_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Fs_Customer_Payout_Stddev_Samp_Fields = {
  __typename?: 'fs_customer_payout_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fs_customer_payout" */
export type Fs_Customer_Payout_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fs_Customer_Payout_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fs_Customer_Payout_Stream_Cursor_Value_Input = {
  alias_id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['numeric']>;
  case_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  currency?: Maybe<Scalars['String']>;
  customer_iban?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  payment_reference?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Fs_Customer_Payout_Sum_Fields = {
  __typename?: 'fs_customer_payout_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** update columns of table "fs_customer_payout" */
export enum Fs_Customer_Payout_Update_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  Amount = 'amount',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerIban = 'customer_iban',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentReference = 'payment_reference',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vertical = 'vertical'
}

export type Fs_Customer_Payout_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Fs_Customer_Payout_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fs_Customer_Payout_Set_Input>;
  where: Fs_Customer_Payout_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fs_Customer_Payout_Var_Pop_Fields = {
  __typename?: 'fs_customer_payout_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Fs_Customer_Payout_Var_Samp_Fields = {
  __typename?: 'fs_customer_payout_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Fs_Customer_Payout_Variance_Fields = {
  __typename?: 'fs_customer_payout_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "gdpr_case" */
export type Gdpr_Case = {
  __typename?: 'gdpr_case';
  alias_id: Scalars['String'];
  /** An object relationship */
  claim: Gdpr_Claim;
  claim_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  customer: Customer;
  customer_id: Scalars['uuid'];
  id: Scalars['uuid'];
  marketing?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  offers: Array<Gdpr_Offer>;
  /** An aggregate relationship */
  offers_aggregate: Gdpr_Offer_Aggregate;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Enum>;
  state: Gdpr_Case_State_Enum;
  updated_at?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "gdpr_case" */
export type Gdpr_CaseMarketingArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "gdpr_case" */
export type Gdpr_CaseOffersArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Order_By>>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};


/** columns and relationships of "gdpr_case" */
export type Gdpr_CaseOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Order_By>>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};

/** aggregated selection of "gdpr_case" */
export type Gdpr_Case_Aggregate = {
  __typename?: 'gdpr_case_aggregate';
  aggregate?: Maybe<Gdpr_Case_Aggregate_Fields>;
  nodes: Array<Gdpr_Case>;
};

/** aggregate fields of "gdpr_case" */
export type Gdpr_Case_Aggregate_Fields = {
  __typename?: 'gdpr_case_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Case_Max_Fields>;
  min?: Maybe<Gdpr_Case_Min_Fields>;
};


/** aggregate fields of "gdpr_case" */
export type Gdpr_Case_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Case_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Gdpr_Case_Append_Input = {
  marketing?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "gdpr_case". All fields are combined with a logical 'AND'. */
export type Gdpr_Case_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Case_Bool_Exp>>;
  _not?: Maybe<Gdpr_Case_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Case_Bool_Exp>>;
  alias_id?: Maybe<String_Comparison_Exp>;
  claim?: Maybe<Gdpr_Claim_Bool_Exp>;
  claim_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Customer_Bool_Exp>;
  customer_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  marketing?: Maybe<Jsonb_Comparison_Exp>;
  offers?: Maybe<Gdpr_Offer_Bool_Exp>;
  offers_aggregate?: Maybe<Gdpr_Offer_Aggregate_Bool_Exp>;
  other_rejection_reason?: Maybe<String_Comparison_Exp>;
  promo_code?: Maybe<String_Comparison_Exp>;
  rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Enum_Comparison_Exp>;
  state?: Maybe<Gdpr_Case_State_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_case" */
export enum Gdpr_Case_Constraint {
  /** unique or primary key constraint on columns "alias_id" */
  GdprCaseAliasIdKey = 'gdpr_case_alias_id_key',
  /** unique or primary key constraint on columns "id" */
  GdprCasePkey = 'gdpr_case_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Gdpr_Case_Delete_At_Path_Input = {
  marketing?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Gdpr_Case_Delete_Elem_Input = {
  marketing?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Gdpr_Case_Delete_Key_Input = {
  marketing?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "gdpr_case" */
export type Gdpr_Case_Insert_Input = {
  alias_id?: Maybe<Scalars['String']>;
  claim?: Maybe<Gdpr_Claim_Obj_Rel_Insert_Input>;
  claim_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Customer_Obj_Rel_Insert_Input>;
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  marketing?: Maybe<Scalars['jsonb']>;
  offers?: Maybe<Gdpr_Offer_Arr_Rel_Insert_Input>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Enum>;
  state?: Maybe<Gdpr_Case_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Gdpr_Case_Max_Fields = {
  __typename?: 'gdpr_case_max_fields';
  alias_id?: Maybe<Scalars['String']>;
  claim_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Gdpr_Case_Min_Fields = {
  __typename?: 'gdpr_case_min_fields';
  alias_id?: Maybe<Scalars['String']>;
  claim_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "gdpr_case" */
export type Gdpr_Case_Mutation_Response = {
  __typename?: 'gdpr_case_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Case>;
};

/** input type for inserting object relation for remote table "gdpr_case" */
export type Gdpr_Case_Obj_Rel_Insert_Input = {
  data: Gdpr_Case_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Gdpr_Case_On_Conflict>;
};

/** on_conflict condition type for table "gdpr_case" */
export type Gdpr_Case_On_Conflict = {
  constraint: Gdpr_Case_Constraint;
  update_columns?: Array<Gdpr_Case_Update_Column>;
  where?: Maybe<Gdpr_Case_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_case". */
export type Gdpr_Case_Order_By = {
  alias_id?: Maybe<Order_By>;
  claim?: Maybe<Gdpr_Claim_Order_By>;
  claim_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Customer_Order_By>;
  customer_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  marketing?: Maybe<Order_By>;
  offers_aggregate?: Maybe<Gdpr_Offer_Aggregate_Order_By>;
  other_rejection_reason?: Maybe<Order_By>;
  promo_code?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_case */
export type Gdpr_Case_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Gdpr_Case_Prepend_Input = {
  marketing?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason = {
  __typename?: 'gdpr_case_rejection_reason';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_Aggregate = {
  __typename?: 'gdpr_case_rejection_reason_aggregate';
  aggregate?: Maybe<Gdpr_Case_Rejection_Reason_Aggregate_Fields>;
  nodes: Array<Gdpr_Case_Rejection_Reason>;
};

/** aggregate fields of "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_Aggregate_Fields = {
  __typename?: 'gdpr_case_rejection_reason_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Case_Rejection_Reason_Max_Fields>;
  min?: Maybe<Gdpr_Case_Rejection_Reason_Min_Fields>;
};


/** aggregate fields of "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Case_Rejection_Reason_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gdpr_case_rejection_reason". All fields are combined with a logical 'AND'. */
export type Gdpr_Case_Rejection_Reason_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Case_Rejection_Reason_Bool_Exp>>;
  _not?: Maybe<Gdpr_Case_Rejection_Reason_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Case_Rejection_Reason_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_case_rejection_reason" */
export enum Gdpr_Case_Rejection_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */
  GdprCaseRejectionReasonPkey = 'gdpr_case_rejection_reason_pkey'
}

export enum Gdpr_Case_Rejection_Reason_Enum {
  /** Fraud suspicion */
  FraudSuspicion = 'FRAUD_SUSPICION',
  /** Customer and claim owner does not match */
  IdentityMismatch = 'IDENTITY_MISMATCH',
  /** Multiple submission */
  MultipleSubmission = 'MULTIPLE_SUBMISSION',
  /** Not part of a data breach */
  NoBreach = 'NO_BREACH',
  /** Other */
  Other = 'OTHER'
}

/** Boolean expression to compare columns of type "gdpr_case_rejection_reason_enum". All fields are combined with logical 'AND'. */
export type Gdpr_Case_Rejection_Reason_Enum_Comparison_Exp = {
  _eq?: Maybe<Gdpr_Case_Rejection_Reason_Enum>;
  _in?: Maybe<Array<Gdpr_Case_Rejection_Reason_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Gdpr_Case_Rejection_Reason_Enum>;
  _nin?: Maybe<Array<Gdpr_Case_Rejection_Reason_Enum>>;
};

/** input type for inserting data into table "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gdpr_Case_Rejection_Reason_Max_Fields = {
  __typename?: 'gdpr_case_rejection_reason_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gdpr_Case_Rejection_Reason_Min_Fields = {
  __typename?: 'gdpr_case_rejection_reason_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_Mutation_Response = {
  __typename?: 'gdpr_case_rejection_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Case_Rejection_Reason>;
};

/** on_conflict condition type for table "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_On_Conflict = {
  constraint: Gdpr_Case_Rejection_Reason_Constraint;
  update_columns?: Array<Gdpr_Case_Rejection_Reason_Update_Column>;
  where?: Maybe<Gdpr_Case_Rejection_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_case_rejection_reason". */
export type Gdpr_Case_Rejection_Reason_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_case_rejection_reason */
export type Gdpr_Case_Rejection_Reason_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "gdpr_case_rejection_reason" */
export enum Gdpr_Case_Rejection_Reason_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "gdpr_case_rejection_reason" */
export type Gdpr_Case_Rejection_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Case_Rejection_Reason_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Case_Rejection_Reason_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "gdpr_case_rejection_reason" */
export enum Gdpr_Case_Rejection_Reason_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Gdpr_Case_Rejection_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Case_Rejection_Reason_Set_Input>;
  where: Gdpr_Case_Rejection_Reason_Bool_Exp;
};

/** select columns of table "gdpr_case" */
export enum Gdpr_Case_Select_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  ClaimId = 'claim_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Marketing = 'marketing',
  /** column name */
  OtherRejectionReason = 'other_rejection_reason',
  /** column name */
  PromoCode = 'promo_code',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdpr_case" */
export type Gdpr_Case_Set_Input = {
  alias_id?: Maybe<Scalars['String']>;
  claim_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  marketing?: Maybe<Scalars['jsonb']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Enum>;
  state?: Maybe<Gdpr_Case_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "gdpr_case_state" */
export type Gdpr_Case_State = {
  __typename?: 'gdpr_case_state';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "gdpr_case_state" */
export type Gdpr_Case_State_Aggregate = {
  __typename?: 'gdpr_case_state_aggregate';
  aggregate?: Maybe<Gdpr_Case_State_Aggregate_Fields>;
  nodes: Array<Gdpr_Case_State>;
};

/** aggregate fields of "gdpr_case_state" */
export type Gdpr_Case_State_Aggregate_Fields = {
  __typename?: 'gdpr_case_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Case_State_Max_Fields>;
  min?: Maybe<Gdpr_Case_State_Min_Fields>;
};


/** aggregate fields of "gdpr_case_state" */
export type Gdpr_Case_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Case_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gdpr_case_state". All fields are combined with a logical 'AND'. */
export type Gdpr_Case_State_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Case_State_Bool_Exp>>;
  _not?: Maybe<Gdpr_Case_State_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Case_State_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_case_state" */
export enum Gdpr_Case_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  GdprCaseStatePkey = 'gdpr_case_state_pkey'
}

export enum Gdpr_Case_State_Enum {
  /** Case Accepted */
  Accepted = 'ACCEPTED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Duplicate */
  Duplicate = 'DUPLICATE',
  /** GDPR Deleted */
  GdprDeleted = 'GDPR_DELETED',
  /** Newly Submitted Case */
  New = 'NEW',
  /** Rejected */
  Rejected = 'REJECTED',
  /** Rejection Confirmed */
  RejectionConfirmed = 'REJECTION_CONFIRMED',
  /** Revoked */
  Revoked = 'REVOKED'
}

/** Boolean expression to compare columns of type "gdpr_case_state_enum". All fields are combined with logical 'AND'. */
export type Gdpr_Case_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Gdpr_Case_State_Enum>;
  _in?: Maybe<Array<Gdpr_Case_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Gdpr_Case_State_Enum>;
  _nin?: Maybe<Array<Gdpr_Case_State_Enum>>;
};

/** input type for inserting data into table "gdpr_case_state" */
export type Gdpr_Case_State_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gdpr_Case_State_Max_Fields = {
  __typename?: 'gdpr_case_state_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gdpr_Case_State_Min_Fields = {
  __typename?: 'gdpr_case_state_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gdpr_case_state" */
export type Gdpr_Case_State_Mutation_Response = {
  __typename?: 'gdpr_case_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Case_State>;
};

/** on_conflict condition type for table "gdpr_case_state" */
export type Gdpr_Case_State_On_Conflict = {
  constraint: Gdpr_Case_State_Constraint;
  update_columns?: Array<Gdpr_Case_State_Update_Column>;
  where?: Maybe<Gdpr_Case_State_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_case_state". */
export type Gdpr_Case_State_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_case_state */
export type Gdpr_Case_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "gdpr_case_state" */
export enum Gdpr_Case_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "gdpr_case_state" */
export type Gdpr_Case_State_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "gdpr_case_state" */
export type Gdpr_Case_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Case_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Case_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "gdpr_case_state" */
export enum Gdpr_Case_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Gdpr_Case_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Case_State_Set_Input>;
  where: Gdpr_Case_State_Bool_Exp;
};

/** Streaming cursor of the table "gdpr_case" */
export type Gdpr_Case_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Case_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Case_Stream_Cursor_Value_Input = {
  alias_id?: Maybe<Scalars['String']>;
  claim_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  marketing?: Maybe<Scalars['jsonb']>;
  other_rejection_reason?: Maybe<Scalars['String']>;
  promo_code?: Maybe<Scalars['String']>;
  rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Enum>;
  state?: Maybe<Gdpr_Case_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "gdpr_case" */
export enum Gdpr_Case_Update_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  ClaimId = 'claim_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  Marketing = 'marketing',
  /** column name */
  OtherRejectionReason = 'other_rejection_reason',
  /** column name */
  PromoCode = 'promo_code',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdpr_Case_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Gdpr_Case_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Gdpr_Case_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Gdpr_Case_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Gdpr_Case_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Gdpr_Case_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Case_Set_Input>;
  where: Gdpr_Case_Bool_Exp;
};

/** columns and relationships of "gdpr_claim" */
export type Gdpr_Claim = {
  __typename?: 'gdpr_claim';
  api_response?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  /** An object relationship */
  company?: Maybe<Gdpr_Company>;
  company_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamp']>;
  email: Scalars['String'];
  email_compromised: Scalars['Boolean'];
  id: Scalars['uuid'];
  personal_statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};


/** columns and relationships of "gdpr_claim" */
export type Gdpr_ClaimApi_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "gdpr_claim" */
export type Gdpr_Claim_Aggregate = {
  __typename?: 'gdpr_claim_aggregate';
  aggregate?: Maybe<Gdpr_Claim_Aggregate_Fields>;
  nodes: Array<Gdpr_Claim>;
};

/** aggregate fields of "gdpr_claim" */
export type Gdpr_Claim_Aggregate_Fields = {
  __typename?: 'gdpr_claim_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Claim_Max_Fields>;
  min?: Maybe<Gdpr_Claim_Min_Fields>;
};


/** aggregate fields of "gdpr_claim" */
export type Gdpr_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Gdpr_Claim_Append_Input = {
  api_response?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "gdpr_claim". All fields are combined with a logical 'AND'. */
export type Gdpr_Claim_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Claim_Bool_Exp>>;
  _not?: Maybe<Gdpr_Claim_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Claim_Bool_Exp>>;
  api_response?: Maybe<Jsonb_Comparison_Exp>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  company?: Maybe<Gdpr_Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_compromised?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  personal_statement?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** columns and relationships of "gdpr_claim_company" */
export type Gdpr_Claim_Company = {
  __typename?: 'gdpr_claim_company';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "gdpr_claim_company" */
export type Gdpr_Claim_Company_Aggregate = {
  __typename?: 'gdpr_claim_company_aggregate';
  aggregate?: Maybe<Gdpr_Claim_Company_Aggregate_Fields>;
  nodes: Array<Gdpr_Claim_Company>;
};

/** aggregate fields of "gdpr_claim_company" */
export type Gdpr_Claim_Company_Aggregate_Fields = {
  __typename?: 'gdpr_claim_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Claim_Company_Max_Fields>;
  min?: Maybe<Gdpr_Claim_Company_Min_Fields>;
};


/** aggregate fields of "gdpr_claim_company" */
export type Gdpr_Claim_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Claim_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gdpr_claim_company". All fields are combined with a logical 'AND'. */
export type Gdpr_Claim_Company_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Claim_Company_Bool_Exp>>;
  _not?: Maybe<Gdpr_Claim_Company_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Claim_Company_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_claim_company" */
export enum Gdpr_Claim_Company_Constraint {
  /** unique or primary key constraint on columns "value" */
  GdprClaimCompanyPkey = 'gdpr_claim_company_pkey'
}

/** input type for inserting data into table "gdpr_claim_company" */
export type Gdpr_Claim_Company_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gdpr_Claim_Company_Max_Fields = {
  __typename?: 'gdpr_claim_company_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gdpr_Claim_Company_Min_Fields = {
  __typename?: 'gdpr_claim_company_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gdpr_claim_company" */
export type Gdpr_Claim_Company_Mutation_Response = {
  __typename?: 'gdpr_claim_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Claim_Company>;
};

/** on_conflict condition type for table "gdpr_claim_company" */
export type Gdpr_Claim_Company_On_Conflict = {
  constraint: Gdpr_Claim_Company_Constraint;
  update_columns?: Array<Gdpr_Claim_Company_Update_Column>;
  where?: Maybe<Gdpr_Claim_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_claim_company". */
export type Gdpr_Claim_Company_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_claim_company */
export type Gdpr_Claim_Company_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "gdpr_claim_company" */
export enum Gdpr_Claim_Company_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "gdpr_claim_company" */
export type Gdpr_Claim_Company_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "gdpr_claim_company" */
export type Gdpr_Claim_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Claim_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Claim_Company_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "gdpr_claim_company" */
export enum Gdpr_Claim_Company_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Gdpr_Claim_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Claim_Company_Set_Input>;
  where: Gdpr_Claim_Company_Bool_Exp;
};

/** unique or primary key constraints on table "gdpr_claim" */
export enum Gdpr_Claim_Constraint {
  /** unique or primary key constraint on columns "case_id" */
  GdprClaimCaseIdKey = 'gdpr_claim_case_id_key',
  /** unique or primary key constraint on columns "id" */
  GdprClaimPkey = 'gdpr_claim_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Gdpr_Claim_Delete_At_Path_Input = {
  api_response?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Gdpr_Claim_Delete_Elem_Input = {
  api_response?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Gdpr_Claim_Delete_Key_Input = {
  api_response?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "gdpr_claim" */
export type Gdpr_Claim_Insert_Input = {
  api_response?: Maybe<Scalars['jsonb']>;
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  company?: Maybe<Gdpr_Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  email_compromised?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  personal_statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Gdpr_Claim_Max_Fields = {
  __typename?: 'gdpr_claim_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personal_statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Gdpr_Claim_Min_Fields = {
  __typename?: 'gdpr_claim_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  personal_statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "gdpr_claim" */
export type Gdpr_Claim_Mutation_Response = {
  __typename?: 'gdpr_claim_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Claim>;
};

/** input type for inserting object relation for remote table "gdpr_claim" */
export type Gdpr_Claim_Obj_Rel_Insert_Input = {
  data: Gdpr_Claim_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Gdpr_Claim_On_Conflict>;
};

/** on_conflict condition type for table "gdpr_claim" */
export type Gdpr_Claim_On_Conflict = {
  constraint: Gdpr_Claim_Constraint;
  update_columns?: Array<Gdpr_Claim_Update_Column>;
  where?: Maybe<Gdpr_Claim_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_claim". */
export type Gdpr_Claim_Order_By = {
  api_response?: Maybe<Order_By>;
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  company?: Maybe<Gdpr_Company_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_compromised?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  personal_statement?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_claim */
export type Gdpr_Claim_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Gdpr_Claim_Prepend_Input = {
  api_response?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "gdpr_claim" */
export enum Gdpr_Claim_Select_Column {
  /** column name */
  ApiResponse = 'api_response',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailCompromised = 'email_compromised',
  /** column name */
  Id = 'id',
  /** column name */
  PersonalStatement = 'personal_statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdpr_claim" */
export type Gdpr_Claim_Set_Input = {
  api_response?: Maybe<Scalars['jsonb']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  email_compromised?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  personal_statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "gdpr_claim" */
export type Gdpr_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Claim_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Claim_Stream_Cursor_Value_Input = {
  api_response?: Maybe<Scalars['jsonb']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  email_compromised?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  personal_statement?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "gdpr_claim" */
export enum Gdpr_Claim_Update_Column {
  /** column name */
  ApiResponse = 'api_response',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailCompromised = 'email_compromised',
  /** column name */
  Id = 'id',
  /** column name */
  PersonalStatement = 'personal_statement',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdpr_Claim_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Gdpr_Claim_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Gdpr_Claim_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Gdpr_Claim_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Gdpr_Claim_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Gdpr_Claim_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Claim_Set_Input>;
  where: Gdpr_Claim_Bool_Exp;
};

/** columns and relationships of "gdpr_company" */
export type Gdpr_Company = {
  __typename?: 'gdpr_company';
  id: Scalars['String'];
  is_supported: Scalars['Boolean'];
  name: Scalars['String'];
};

/** aggregated selection of "gdpr_company" */
export type Gdpr_Company_Aggregate = {
  __typename?: 'gdpr_company_aggregate';
  aggregate?: Maybe<Gdpr_Company_Aggregate_Fields>;
  nodes: Array<Gdpr_Company>;
};

/** aggregate fields of "gdpr_company" */
export type Gdpr_Company_Aggregate_Fields = {
  __typename?: 'gdpr_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Company_Max_Fields>;
  min?: Maybe<Gdpr_Company_Min_Fields>;
};


/** aggregate fields of "gdpr_company" */
export type Gdpr_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gdpr_company". All fields are combined with a logical 'AND'. */
export type Gdpr_Company_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Company_Bool_Exp>>;
  _not?: Maybe<Gdpr_Company_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Company_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_supported?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_company" */
export enum Gdpr_Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  GdprCompanyPkey = 'gdpr_company_pkey'
}

/** input type for inserting data into table "gdpr_company" */
export type Gdpr_Company_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gdpr_Company_Max_Fields = {
  __typename?: 'gdpr_company_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gdpr_Company_Min_Fields = {
  __typename?: 'gdpr_company_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gdpr_company" */
export type Gdpr_Company_Mutation_Response = {
  __typename?: 'gdpr_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Company>;
};

/** input type for inserting object relation for remote table "gdpr_company" */
export type Gdpr_Company_Obj_Rel_Insert_Input = {
  data: Gdpr_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Gdpr_Company_On_Conflict>;
};

/** on_conflict condition type for table "gdpr_company" */
export type Gdpr_Company_On_Conflict = {
  constraint: Gdpr_Company_Constraint;
  update_columns?: Array<Gdpr_Company_Update_Column>;
  where?: Maybe<Gdpr_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_company". */
export type Gdpr_Company_Order_By = {
  id?: Maybe<Order_By>;
  is_supported?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_company */
export type Gdpr_Company_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "gdpr_company" */
export enum Gdpr_Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "gdpr_company" */
export type Gdpr_Company_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "gdpr_company" */
export type Gdpr_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Company_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "gdpr_company" */
export enum Gdpr_Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

export type Gdpr_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Company_Set_Input>;
  where: Gdpr_Company_Bool_Exp;
};

/** columns and relationships of "gdpr_offer" */
export type Gdpr_Offer = {
  __typename?: 'gdpr_offer';
  accepted_at?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  address?: Maybe<Address>;
  address_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  bank_account?: Maybe<Bank_Account>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  case: Gdpr_Case;
  case_id: Scalars['uuid'];
  claim_amount: Scalars['numeric'];
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** Returns 'true' if offer is active */
  is_active?: Maybe<Scalars['Boolean']>;
  offer_amount: Scalars['numeric'];
  /** An array relationship */
  offers_payments: Array<Gdpr_Offer_Payment>;
  /** An aggregate relationship */
  offers_payments_aggregate: Gdpr_Offer_Payment_Aggregate;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state: Gdpr_Offer_State_Enum;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "gdpr_offer" */
export type Gdpr_OfferOffers_PaymentsArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};


/** columns and relationships of "gdpr_offer" */
export type Gdpr_OfferOffers_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};

/** aggregated selection of "gdpr_offer" */
export type Gdpr_Offer_Aggregate = {
  __typename?: 'gdpr_offer_aggregate';
  aggregate?: Maybe<Gdpr_Offer_Aggregate_Fields>;
  nodes: Array<Gdpr_Offer>;
};

export type Gdpr_Offer_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Gdpr_Offer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Gdpr_Offer_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Gdpr_Offer_Aggregate_Bool_Exp_Count>;
};

export type Gdpr_Offer_Aggregate_Bool_Exp_Bool_And = {
  arguments: Gdpr_Offer_Select_Column_Gdpr_Offer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Gdpr_Offer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Gdpr_Offer_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Gdpr_Offer_Select_Column_Gdpr_Offer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Gdpr_Offer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Gdpr_Offer_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Gdpr_Offer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gdpr_offer" */
export type Gdpr_Offer_Aggregate_Fields = {
  __typename?: 'gdpr_offer_aggregate_fields';
  avg?: Maybe<Gdpr_Offer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Offer_Max_Fields>;
  min?: Maybe<Gdpr_Offer_Min_Fields>;
  stddev?: Maybe<Gdpr_Offer_Stddev_Fields>;
  stddev_pop?: Maybe<Gdpr_Offer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gdpr_Offer_Stddev_Samp_Fields>;
  sum?: Maybe<Gdpr_Offer_Sum_Fields>;
  var_pop?: Maybe<Gdpr_Offer_Var_Pop_Fields>;
  var_samp?: Maybe<Gdpr_Offer_Var_Samp_Fields>;
  variance?: Maybe<Gdpr_Offer_Variance_Fields>;
};


/** aggregate fields of "gdpr_offer" */
export type Gdpr_Offer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gdpr_offer" */
export type Gdpr_Offer_Aggregate_Order_By = {
  avg?: Maybe<Gdpr_Offer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Gdpr_Offer_Max_Order_By>;
  min?: Maybe<Gdpr_Offer_Min_Order_By>;
  stddev?: Maybe<Gdpr_Offer_Stddev_Order_By>;
  stddev_pop?: Maybe<Gdpr_Offer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Gdpr_Offer_Stddev_Samp_Order_By>;
  sum?: Maybe<Gdpr_Offer_Sum_Order_By>;
  var_pop?: Maybe<Gdpr_Offer_Var_Pop_Order_By>;
  var_samp?: Maybe<Gdpr_Offer_Var_Samp_Order_By>;
  variance?: Maybe<Gdpr_Offer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "gdpr_offer" */
export type Gdpr_Offer_Arr_Rel_Insert_Input = {
  data: Array<Gdpr_Offer_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Gdpr_Offer_On_Conflict>;
};

/** columns and relationships of "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment = {
  __typename?: 'gdpr_offer_assignment';
  /** An object relationship */
  assignment: Assignment;
  assignment_id: Scalars['uuid'];
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  offer?: Maybe<Base_Offer>;
  offer_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Aggregate = {
  __typename?: 'gdpr_offer_assignment_aggregate';
  aggregate?: Maybe<Gdpr_Offer_Assignment_Aggregate_Fields>;
  nodes: Array<Gdpr_Offer_Assignment>;
};

export type Gdpr_Offer_Assignment_Aggregate_Bool_Exp = {
  count?: Maybe<Gdpr_Offer_Assignment_Aggregate_Bool_Exp_Count>;
};

export type Gdpr_Offer_Assignment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Aggregate_Fields = {
  __typename?: 'gdpr_offer_assignment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Offer_Assignment_Max_Fields>;
  min?: Maybe<Gdpr_Offer_Assignment_Min_Fields>;
};


/** aggregate fields of "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Gdpr_Offer_Assignment_Max_Order_By>;
  min?: Maybe<Gdpr_Offer_Assignment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Arr_Rel_Insert_Input = {
  data: Array<Gdpr_Offer_Assignment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Gdpr_Offer_Assignment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "gdpr_offer_assignment". All fields are combined with a logical 'AND'. */
export type Gdpr_Offer_Assignment_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Offer_Assignment_Bool_Exp>>;
  _not?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Offer_Assignment_Bool_Exp>>;
  assignment?: Maybe<Assignment_Bool_Exp>;
  assignment_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Base_Offer_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_offer_assignment" */
export enum Gdpr_Offer_Assignment_Constraint {
  /** unique or primary key constraint on columns "id" */
  GdprOfferAssignmentPkey = 'gdpr_offer_assignment_pkey'
}

/** input type for inserting data into table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Insert_Input = {
  assignment?: Maybe<Assignment_Obj_Rel_Insert_Input>;
  assignment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Base_Offer_Obj_Rel_Insert_Input>;
  offer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Gdpr_Offer_Assignment_Max_Fields = {
  __typename?: 'gdpr_offer_assignment_max_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Max_Order_By = {
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Gdpr_Offer_Assignment_Min_Fields = {
  __typename?: 'gdpr_offer_assignment_min_fields';
  assignment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Min_Order_By = {
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Mutation_Response = {
  __typename?: 'gdpr_offer_assignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Offer_Assignment>;
};

/** on_conflict condition type for table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_On_Conflict = {
  constraint: Gdpr_Offer_Assignment_Constraint;
  update_columns?: Array<Gdpr_Offer_Assignment_Update_Column>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_offer_assignment". */
export type Gdpr_Offer_Assignment_Order_By = {
  assignment?: Maybe<Assignment_Order_By>;
  assignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer?: Maybe<Base_Offer_Order_By>;
  offer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_offer_assignment */
export type Gdpr_Offer_Assignment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "gdpr_offer_assignment" */
export enum Gdpr_Offer_Assignment_Select_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Set_Input = {
  assignment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "gdpr_offer_assignment" */
export type Gdpr_Offer_Assignment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Offer_Assignment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Offer_Assignment_Stream_Cursor_Value_Input = {
  assignment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "gdpr_offer_assignment" */
export enum Gdpr_Offer_Assignment_Update_Column {
  /** column name */
  AssignmentId = 'assignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdpr_Offer_Assignment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Offer_Assignment_Set_Input>;
  where: Gdpr_Offer_Assignment_Bool_Exp;
};

/** aggregate avg on columns */
export type Gdpr_Offer_Avg_Fields = {
  __typename?: 'gdpr_offer_avg_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Avg_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "gdpr_offer". All fields are combined with a logical 'AND'. */
export type Gdpr_Offer_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Offer_Bool_Exp>>;
  _not?: Maybe<Gdpr_Offer_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Offer_Bool_Exp>>;
  accepted_at?: Maybe<Timestamp_Comparison_Exp>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Uuid_Comparison_Exp>;
  bank_account?: Maybe<Bank_Account_Bool_Exp>;
  bank_account_id?: Maybe<Uuid_Comparison_Exp>;
  case?: Maybe<Gdpr_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  claim_amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  offer_amount?: Maybe<Numeric_Comparison_Exp>;
  offers_payments?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
  offers_payments_aggregate?: Maybe<Gdpr_Offer_Payment_Aggregate_Bool_Exp>;
  payment_on_hold?: Maybe<Boolean_Comparison_Exp>;
  payment_on_hold_reason?: Maybe<String_Comparison_Exp>;
  rejected_at?: Maybe<Timestamp_Comparison_Exp>;
  rejection_reason?: Maybe<String_Comparison_Exp>;
  sent_at?: Maybe<Timestamp_Comparison_Exp>;
  signature?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Gdpr_Offer_State_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_offer" */
export enum Gdpr_Offer_Constraint {
  /** unique or primary key constraint on columns "id" */
  GdprOfferPkey = 'gdpr_offer_pkey'
}

/** input type for incrementing numeric columns in table "gdpr_offer" */
export type Gdpr_Offer_Inc_Input = {
  claim_amount?: Maybe<Scalars['numeric']>;
  offer_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "gdpr_offer" */
export type Gdpr_Offer_Insert_Input = {
  accepted_at?: Maybe<Scalars['timestamp']>;
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account?: Maybe<Bank_Account_Obj_Rel_Insert_Input>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case?: Maybe<Gdpr_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  offers_payments?: Maybe<Gdpr_Offer_Payment_Arr_Rel_Insert_Input>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Gdpr_Offer_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Gdpr_Offer_Max_Fields = {
  __typename?: 'gdpr_offer_max_fields';
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Max_Order_By = {
  accepted_at?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  bank_account_id?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
  payment_on_hold_reason?: Maybe<Order_By>;
  rejected_at?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Gdpr_Offer_Min_Fields = {
  __typename?: 'gdpr_offer_min_fields';
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Min_Order_By = {
  accepted_at?: Maybe<Order_By>;
  address_id?: Maybe<Order_By>;
  bank_account_id?: Maybe<Order_By>;
  case_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
  payment_on_hold_reason?: Maybe<Order_By>;
  rejected_at?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "gdpr_offer" */
export type Gdpr_Offer_Mutation_Response = {
  __typename?: 'gdpr_offer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Offer>;
};

/** input type for inserting object relation for remote table "gdpr_offer" */
export type Gdpr_Offer_Obj_Rel_Insert_Input = {
  data: Gdpr_Offer_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Gdpr_Offer_On_Conflict>;
};

/** on_conflict condition type for table "gdpr_offer" */
export type Gdpr_Offer_On_Conflict = {
  constraint: Gdpr_Offer_Constraint;
  update_columns?: Array<Gdpr_Offer_Update_Column>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_offer". */
export type Gdpr_Offer_Order_By = {
  accepted_at?: Maybe<Order_By>;
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  bank_account?: Maybe<Bank_Account_Order_By>;
  bank_account_id?: Maybe<Order_By>;
  case?: Maybe<Gdpr_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
  offers_payments_aggregate?: Maybe<Gdpr_Offer_Payment_Aggregate_Order_By>;
  payment_on_hold?: Maybe<Order_By>;
  payment_on_hold_reason?: Maybe<Order_By>;
  rejected_at?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  sent_at?: Maybe<Order_By>;
  signature?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "gdpr_offer_payment" */
export type Gdpr_Offer_Payment = {
  __typename?: 'gdpr_offer_payment';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  /** An object relationship */
  offer: Gdpr_Offer;
  offer_id: Scalars['uuid'];
  /** An object relationship */
  payment: Payment;
  payment_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Aggregate = {
  __typename?: 'gdpr_offer_payment_aggregate';
  aggregate?: Maybe<Gdpr_Offer_Payment_Aggregate_Fields>;
  nodes: Array<Gdpr_Offer_Payment>;
};

export type Gdpr_Offer_Payment_Aggregate_Bool_Exp = {
  count?: Maybe<Gdpr_Offer_Payment_Aggregate_Bool_Exp_Count>;
};

export type Gdpr_Offer_Payment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Aggregate_Fields = {
  __typename?: 'gdpr_offer_payment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Offer_Payment_Max_Fields>;
  min?: Maybe<Gdpr_Offer_Payment_Min_Fields>;
};


/** aggregate fields of "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Gdpr_Offer_Payment_Max_Order_By>;
  min?: Maybe<Gdpr_Offer_Payment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Arr_Rel_Insert_Input = {
  data: Array<Gdpr_Offer_Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Gdpr_Offer_Payment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "gdpr_offer_payment". All fields are combined with a logical 'AND'. */
export type Gdpr_Offer_Payment_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Offer_Payment_Bool_Exp>>;
  _not?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Offer_Payment_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Gdpr_Offer_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
  payment?: Maybe<Payment_Bool_Exp>;
  payment_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_offer_payment" */
export enum Gdpr_Offer_Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  GdprOfferPaymentPkey = 'gdpr_offer_payment_pkey'
}

/** input type for inserting data into table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Gdpr_Offer_Obj_Rel_Insert_Input>;
  offer_id?: Maybe<Scalars['uuid']>;
  payment?: Maybe<Payment_Obj_Rel_Insert_Input>;
  payment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Gdpr_Offer_Payment_Max_Fields = {
  __typename?: 'gdpr_offer_payment_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Gdpr_Offer_Payment_Min_Fields = {
  __typename?: 'gdpr_offer_payment_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Mutation_Response = {
  __typename?: 'gdpr_offer_payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Offer_Payment>;
};

/** on_conflict condition type for table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_On_Conflict = {
  constraint: Gdpr_Offer_Payment_Constraint;
  update_columns?: Array<Gdpr_Offer_Payment_Update_Column>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_offer_payment". */
export type Gdpr_Offer_Payment_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer?: Maybe<Gdpr_Offer_Order_By>;
  offer_id?: Maybe<Order_By>;
  payment?: Maybe<Payment_Order_By>;
  payment_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_offer_payment */
export type Gdpr_Offer_Payment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "gdpr_offer_payment" */
export enum Gdpr_Offer_Payment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "gdpr_offer_payment" */
export type Gdpr_Offer_Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Offer_Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Offer_Payment_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "gdpr_offer_payment" */
export enum Gdpr_Offer_Payment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdpr_Offer_Payment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Offer_Payment_Set_Input>;
  where: Gdpr_Offer_Payment_Bool_Exp;
};

/** primary key columns input for table: gdpr_offer */
export type Gdpr_Offer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "gdpr_offer" */
export enum Gdpr_Offer_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BankAccountId = 'bank_account_id',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferAmount = 'offer_amount',
  /** column name */
  PaymentOnHold = 'payment_on_hold',
  /** column name */
  PaymentOnHoldReason = 'payment_on_hold_reason',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Signature = 'signature',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "gdpr_offer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "gdpr_offer" */
export enum Gdpr_Offer_Select_Column_Gdpr_Offer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PaymentOnHold = 'payment_on_hold'
}

/** select "gdpr_offer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "gdpr_offer" */
export enum Gdpr_Offer_Select_Column_Gdpr_Offer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PaymentOnHold = 'payment_on_hold'
}

/** input type for updating data in table "gdpr_offer" */
export type Gdpr_Offer_Set_Input = {
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Gdpr_Offer_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "gdpr_offer_state" */
export type Gdpr_Offer_State = {
  __typename?: 'gdpr_offer_state';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "gdpr_offer_state" */
export type Gdpr_Offer_State_Aggregate = {
  __typename?: 'gdpr_offer_state_aggregate';
  aggregate?: Maybe<Gdpr_Offer_State_Aggregate_Fields>;
  nodes: Array<Gdpr_Offer_State>;
};

/** aggregate fields of "gdpr_offer_state" */
export type Gdpr_Offer_State_Aggregate_Fields = {
  __typename?: 'gdpr_offer_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gdpr_Offer_State_Max_Fields>;
  min?: Maybe<Gdpr_Offer_State_Min_Fields>;
};


/** aggregate fields of "gdpr_offer_state" */
export type Gdpr_Offer_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gdpr_Offer_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gdpr_offer_state". All fields are combined with a logical 'AND'. */
export type Gdpr_Offer_State_Bool_Exp = {
  _and?: Maybe<Array<Gdpr_Offer_State_Bool_Exp>>;
  _not?: Maybe<Gdpr_Offer_State_Bool_Exp>;
  _or?: Maybe<Array<Gdpr_Offer_State_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gdpr_offer_state" */
export enum Gdpr_Offer_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  GdprOfferStatePkey = 'gdpr_offer_state_pkey'
}

export enum Gdpr_Offer_State_Enum {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Assignment Expired */
  AssignmentExpired = 'ASSIGNMENT_EXPIRED',
  /** Assignment Received */
  AssignmentReceived = 'ASSIGNMENT_RECEIVED',
  /** Assignment Sent */
  AssignmentSent = 'ASSIGNMENT_SENT',
  /** Block Listed */
  BlockListed = 'BLOCK_LISTED',
  /** Created */
  Created = 'CREATED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Expired */
  Expired = 'EXPIRED',
  /** Payment Failed */
  PaymentFailed = 'PAYMENT_FAILED',
  /** Payment Initiated */
  PaymentInitiated = 'PAYMENT_INITIATED',
  /** Payment Successful */
  PaymentSuccessful = 'PAYMENT_SUCCESSFUL',
  /** Refused */
  Refused = 'REFUSED',
  /** Revoked */
  Revoked = 'REVOKED',
  /** Sent */
  Sent = 'SENT'
}

/** Boolean expression to compare columns of type "gdpr_offer_state_enum". All fields are combined with logical 'AND'. */
export type Gdpr_Offer_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Gdpr_Offer_State_Enum>;
  _in?: Maybe<Array<Gdpr_Offer_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Gdpr_Offer_State_Enum>;
  _nin?: Maybe<Array<Gdpr_Offer_State_Enum>>;
};

/** input type for inserting data into table "gdpr_offer_state" */
export type Gdpr_Offer_State_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gdpr_Offer_State_Max_Fields = {
  __typename?: 'gdpr_offer_state_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gdpr_Offer_State_Min_Fields = {
  __typename?: 'gdpr_offer_state_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gdpr_offer_state" */
export type Gdpr_Offer_State_Mutation_Response = {
  __typename?: 'gdpr_offer_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gdpr_Offer_State>;
};

/** on_conflict condition type for table "gdpr_offer_state" */
export type Gdpr_Offer_State_On_Conflict = {
  constraint: Gdpr_Offer_State_Constraint;
  update_columns?: Array<Gdpr_Offer_State_Update_Column>;
  where?: Maybe<Gdpr_Offer_State_Bool_Exp>;
};

/** Ordering options when selecting data from "gdpr_offer_state". */
export type Gdpr_Offer_State_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: gdpr_offer_state */
export type Gdpr_Offer_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "gdpr_offer_state" */
export enum Gdpr_Offer_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "gdpr_offer_state" */
export type Gdpr_Offer_State_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "gdpr_offer_state" */
export type Gdpr_Offer_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Offer_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Offer_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "gdpr_offer_state" */
export enum Gdpr_Offer_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Gdpr_Offer_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Offer_State_Set_Input>;
  where: Gdpr_Offer_State_Bool_Exp;
};

/** aggregate stddev on columns */
export type Gdpr_Offer_Stddev_Fields = {
  __typename?: 'gdpr_offer_stddev_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Stddev_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Gdpr_Offer_Stddev_Pop_Fields = {
  __typename?: 'gdpr_offer_stddev_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Stddev_Pop_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Gdpr_Offer_Stddev_Samp_Fields = {
  __typename?: 'gdpr_offer_stddev_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Stddev_Samp_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** Streaming cursor of the table "gdpr_offer" */
export type Gdpr_Offer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gdpr_Offer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gdpr_Offer_Stream_Cursor_Value_Input = {
  accepted_at?: Maybe<Scalars['timestamp']>;
  address_id?: Maybe<Scalars['uuid']>;
  bank_account_id?: Maybe<Scalars['uuid']>;
  case_id?: Maybe<Scalars['uuid']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  offer_amount?: Maybe<Scalars['numeric']>;
  payment_on_hold?: Maybe<Scalars['Boolean']>;
  payment_on_hold_reason?: Maybe<Scalars['String']>;
  rejected_at?: Maybe<Scalars['timestamp']>;
  rejection_reason?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['timestamp']>;
  signature?: Maybe<Scalars['String']>;
  state?: Maybe<Gdpr_Offer_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Gdpr_Offer_Sum_Fields = {
  __typename?: 'gdpr_offer_sum_fields';
  claim_amount?: Maybe<Scalars['numeric']>;
  offer_amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Sum_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** update columns of table "gdpr_offer" */
export enum Gdpr_Offer_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BankAccountId = 'bank_account_id',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OfferAmount = 'offer_amount',
  /** column name */
  PaymentOnHold = 'payment_on_hold',
  /** column name */
  PaymentOnHoldReason = 'payment_on_hold_reason',
  /** column name */
  RejectedAt = 'rejected_at',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Signature = 'signature',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Gdpr_Offer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Gdpr_Offer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gdpr_Offer_Set_Input>;
  where: Gdpr_Offer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gdpr_Offer_Var_Pop_Fields = {
  __typename?: 'gdpr_offer_var_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Var_Pop_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Gdpr_Offer_Var_Samp_Fields = {
  __typename?: 'gdpr_offer_var_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Var_Samp_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Gdpr_Offer_Variance_Fields = {
  __typename?: 'gdpr_offer_variance_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  offer_amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gdpr_offer" */
export type Gdpr_Offer_Variance_Order_By = {
  claim_amount?: Maybe<Order_By>;
  offer_amount?: Maybe<Order_By>;
};

/** columns and relationships of "generated_report" */
export type Generated_Report = {
  __typename?: 'generated_report';
  generated_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  report_name: Scalars['String'];
  variables?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "generated_report" */
export type Generated_ReportVariablesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "generated_report" */
export type Generated_Report_Aggregate = {
  __typename?: 'generated_report_aggregate';
  aggregate?: Maybe<Generated_Report_Aggregate_Fields>;
  nodes: Array<Generated_Report>;
};

/** aggregate fields of "generated_report" */
export type Generated_Report_Aggregate_Fields = {
  __typename?: 'generated_report_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Generated_Report_Max_Fields>;
  min?: Maybe<Generated_Report_Min_Fields>;
};


/** aggregate fields of "generated_report" */
export type Generated_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Generated_Report_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Generated_Report_Append_Input = {
  variables?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "generated_report". All fields are combined with a logical 'AND'. */
export type Generated_Report_Bool_Exp = {
  _and?: Maybe<Array<Generated_Report_Bool_Exp>>;
  _not?: Maybe<Generated_Report_Bool_Exp>;
  _or?: Maybe<Array<Generated_Report_Bool_Exp>>;
  generated_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  report_name?: Maybe<String_Comparison_Exp>;
  variables?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "generated_report" */
export enum Generated_Report_Constraint {
  /** unique or primary key constraint on columns "id" */
  GeneratedReportPkey = 'generated_report_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Generated_Report_Delete_At_Path_Input = {
  variables?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Generated_Report_Delete_Elem_Input = {
  variables?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Generated_Report_Delete_Key_Input = {
  variables?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "generated_report" */
export type Generated_Report_Insert_Input = {
  generated_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  report_name?: Maybe<Scalars['String']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Generated_Report_Max_Fields = {
  __typename?: 'generated_report_max_fields';
  generated_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  report_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Generated_Report_Min_Fields = {
  __typename?: 'generated_report_min_fields';
  generated_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  report_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "generated_report" */
export type Generated_Report_Mutation_Response = {
  __typename?: 'generated_report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Generated_Report>;
};

/** on_conflict condition type for table "generated_report" */
export type Generated_Report_On_Conflict = {
  constraint: Generated_Report_Constraint;
  update_columns?: Array<Generated_Report_Update_Column>;
  where?: Maybe<Generated_Report_Bool_Exp>;
};

/** Ordering options when selecting data from "generated_report". */
export type Generated_Report_Order_By = {
  generated_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  report_name?: Maybe<Order_By>;
  variables?: Maybe<Order_By>;
};

/** primary key columns input for table: generated_report */
export type Generated_Report_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Generated_Report_Prepend_Input = {
  variables?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "generated_report" */
export enum Generated_Report_Select_Column {
  /** column name */
  GeneratedAt = 'generated_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Variables = 'variables'
}

/** input type for updating data in table "generated_report" */
export type Generated_Report_Set_Input = {
  generated_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  report_name?: Maybe<Scalars['String']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "generated_report" */
export type Generated_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Generated_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Generated_Report_Stream_Cursor_Value_Input = {
  generated_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  report_name?: Maybe<Scalars['String']>;
  variables?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "generated_report" */
export enum Generated_Report_Update_Column {
  /** column name */
  GeneratedAt = 'generated_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReportName = 'report_name',
  /** column name */
  Variables = 'variables'
}

export type Generated_Report_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Generated_Report_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Generated_Report_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Generated_Report_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Generated_Report_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Generated_Report_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Generated_Report_Set_Input>;
  where: Generated_Report_Bool_Exp;
};

/** columns and relationships of "gff_airport" */
export type Gff_Airport = {
  __typename?: 'gff_airport';
  code: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  search: Scalars['String'];
};

/** aggregated selection of "gff_airport" */
export type Gff_Airport_Aggregate = {
  __typename?: 'gff_airport_aggregate';
  aggregate?: Maybe<Gff_Airport_Aggregate_Fields>;
  nodes: Array<Gff_Airport>;
};

/** aggregate fields of "gff_airport" */
export type Gff_Airport_Aggregate_Fields = {
  __typename?: 'gff_airport_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gff_Airport_Max_Fields>;
  min?: Maybe<Gff_Airport_Min_Fields>;
};


/** aggregate fields of "gff_airport" */
export type Gff_Airport_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gff_Airport_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gff_airport". All fields are combined with a logical 'AND'. */
export type Gff_Airport_Bool_Exp = {
  _and?: Maybe<Array<Gff_Airport_Bool_Exp>>;
  _not?: Maybe<Gff_Airport_Bool_Exp>;
  _or?: Maybe<Array<Gff_Airport_Bool_Exp>>;
  code?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  search?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gff_airport" */
export enum Gff_Airport_Constraint {
  /** unique or primary key constraint on columns "code" */
  GffAirportPkey = 'gff_airport_pkey'
}

/** input type for inserting data into table "gff_airport" */
export type Gff_Airport_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gff_Airport_Max_Fields = {
  __typename?: 'gff_airport_max_fields';
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gff_Airport_Min_Fields = {
  __typename?: 'gff_airport_min_fields';
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gff_airport" */
export type Gff_Airport_Mutation_Response = {
  __typename?: 'gff_airport_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gff_Airport>;
};

/** on_conflict condition type for table "gff_airport" */
export type Gff_Airport_On_Conflict = {
  constraint: Gff_Airport_Constraint;
  update_columns?: Array<Gff_Airport_Update_Column>;
  where?: Maybe<Gff_Airport_Bool_Exp>;
};

/** Ordering options when selecting data from "gff_airport". */
export type Gff_Airport_Order_By = {
  code?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  search?: Maybe<Order_By>;
};

/** primary key columns input for table: gff_airport */
export type Gff_Airport_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "gff_airport" */
export enum Gff_Airport_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  Name = 'name',
  /** column name */
  Search = 'search'
}

/** input type for updating data in table "gff_airport" */
export type Gff_Airport_Set_Input = {
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "gff_airport" */
export type Gff_Airport_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gff_Airport_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gff_Airport_Stream_Cursor_Value_Input = {
  code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

/** update columns of table "gff_airport" */
export enum Gff_Airport_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Country = 'country',
  /** column name */
  Name = 'name',
  /** column name */
  Search = 'search'
}

export type Gff_Airport_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gff_Airport_Set_Input>;
  where: Gff_Airport_Bool_Exp;
};

/** columns and relationships of "gff_claim" */
export type Gff_Claim = {
  __typename?: 'gff_claim';
  booking_reference?: Maybe<Scalars['String']>;
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  /** An object relationship */
  company: Gff_Company;
  company_id: Scalars['String'];
  flight_type: Scalars['String'];
  flights: Scalars['jsonb'];
  id: Scalars['uuid'];
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  persons: Scalars['jsonb'];
  ticket_amount?: Maybe<Scalars['numeric']>;
};


/** columns and relationships of "gff_claim" */
export type Gff_ClaimFlightsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "gff_claim" */
export type Gff_ClaimPersonsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "gff_claim" */
export type Gff_Claim_Aggregate = {
  __typename?: 'gff_claim_aggregate';
  aggregate?: Maybe<Gff_Claim_Aggregate_Fields>;
  nodes: Array<Gff_Claim>;
};

/** aggregate fields of "gff_claim" */
export type Gff_Claim_Aggregate_Fields = {
  __typename?: 'gff_claim_aggregate_fields';
  avg?: Maybe<Gff_Claim_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Gff_Claim_Max_Fields>;
  min?: Maybe<Gff_Claim_Min_Fields>;
  stddev?: Maybe<Gff_Claim_Stddev_Fields>;
  stddev_pop?: Maybe<Gff_Claim_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Gff_Claim_Stddev_Samp_Fields>;
  sum?: Maybe<Gff_Claim_Sum_Fields>;
  var_pop?: Maybe<Gff_Claim_Var_Pop_Fields>;
  var_samp?: Maybe<Gff_Claim_Var_Samp_Fields>;
  variance?: Maybe<Gff_Claim_Variance_Fields>;
};


/** aggregate fields of "gff_claim" */
export type Gff_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gff_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Gff_Claim_Append_Input = {
  flights?: Maybe<Scalars['jsonb']>;
  persons?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Gff_Claim_Avg_Fields = {
  __typename?: 'gff_claim_avg_fields';
  ticket_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gff_claim". All fields are combined with a logical 'AND'. */
export type Gff_Claim_Bool_Exp = {
  _and?: Maybe<Array<Gff_Claim_Bool_Exp>>;
  _not?: Maybe<Gff_Claim_Bool_Exp>;
  _or?: Maybe<Array<Gff_Claim_Bool_Exp>>;
  booking_reference?: Maybe<String_Comparison_Exp>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  company?: Maybe<Gff_Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  flight_type?: Maybe<String_Comparison_Exp>;
  flights?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_estimated_claim_amount?: Maybe<Boolean_Comparison_Exp>;
  persons?: Maybe<Jsonb_Comparison_Exp>;
  ticket_amount?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "gff_claim" */
export enum Gff_Claim_Constraint {
  /** unique or primary key constraint on columns "case_id" */
  GffClaimCaseIdKey = 'gff_claim_case_id_key',
  /** unique or primary key constraint on columns "id" */
  GffClaimPkey = 'gff_claim_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Gff_Claim_Delete_At_Path_Input = {
  flights?: Maybe<Array<Scalars['String']>>;
  persons?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Gff_Claim_Delete_Elem_Input = {
  flights?: Maybe<Scalars['Int']>;
  persons?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Gff_Claim_Delete_Key_Input = {
  flights?: Maybe<Scalars['String']>;
  persons?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "gff_claim" */
export type Gff_Claim_Inc_Input = {
  ticket_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "gff_claim" */
export type Gff_Claim_Insert_Input = {
  booking_reference?: Maybe<Scalars['String']>;
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  company?: Maybe<Gff_Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  flight_type?: Maybe<Scalars['String']>;
  flights?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  persons?: Maybe<Scalars['jsonb']>;
  ticket_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Gff_Claim_Max_Fields = {
  __typename?: 'gff_claim_max_fields';
  booking_reference?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  flight_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ticket_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Gff_Claim_Min_Fields = {
  __typename?: 'gff_claim_min_fields';
  booking_reference?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  flight_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  ticket_amount?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "gff_claim" */
export type Gff_Claim_Mutation_Response = {
  __typename?: 'gff_claim_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gff_Claim>;
};

/** input type for inserting object relation for remote table "gff_claim" */
export type Gff_Claim_Obj_Rel_Insert_Input = {
  data: Gff_Claim_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Gff_Claim_On_Conflict>;
};

/** on_conflict condition type for table "gff_claim" */
export type Gff_Claim_On_Conflict = {
  constraint: Gff_Claim_Constraint;
  update_columns?: Array<Gff_Claim_Update_Column>;
  where?: Maybe<Gff_Claim_Bool_Exp>;
};

/** Ordering options when selecting data from "gff_claim". */
export type Gff_Claim_Order_By = {
  booking_reference?: Maybe<Order_By>;
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  company?: Maybe<Gff_Company_Order_By>;
  company_id?: Maybe<Order_By>;
  flight_type?: Maybe<Order_By>;
  flights?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_estimated_claim_amount?: Maybe<Order_By>;
  persons?: Maybe<Order_By>;
  ticket_amount?: Maybe<Order_By>;
};

/** primary key columns input for table: gff_claim */
export type Gff_Claim_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Gff_Claim_Prepend_Input = {
  flights?: Maybe<Scalars['jsonb']>;
  persons?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "gff_claim" */
export enum Gff_Claim_Select_Column {
  /** column name */
  BookingReference = 'booking_reference',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  FlightType = 'flight_type',
  /** column name */
  Flights = 'flights',
  /** column name */
  Id = 'id',
  /** column name */
  IsEstimatedClaimAmount = 'is_estimated_claim_amount',
  /** column name */
  Persons = 'persons',
  /** column name */
  TicketAmount = 'ticket_amount'
}

/** input type for updating data in table "gff_claim" */
export type Gff_Claim_Set_Input = {
  booking_reference?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  flight_type?: Maybe<Scalars['String']>;
  flights?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  persons?: Maybe<Scalars['jsonb']>;
  ticket_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Gff_Claim_Stddev_Fields = {
  __typename?: 'gff_claim_stddev_fields';
  ticket_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Gff_Claim_Stddev_Pop_Fields = {
  __typename?: 'gff_claim_stddev_pop_fields';
  ticket_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Gff_Claim_Stddev_Samp_Fields = {
  __typename?: 'gff_claim_stddev_samp_fields';
  ticket_amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gff_claim" */
export type Gff_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gff_Claim_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gff_Claim_Stream_Cursor_Value_Input = {
  booking_reference?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  flight_type?: Maybe<Scalars['String']>;
  flights?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  persons?: Maybe<Scalars['jsonb']>;
  ticket_amount?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Gff_Claim_Sum_Fields = {
  __typename?: 'gff_claim_sum_fields';
  ticket_amount?: Maybe<Scalars['numeric']>;
};

/** update columns of table "gff_claim" */
export enum Gff_Claim_Update_Column {
  /** column name */
  BookingReference = 'booking_reference',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  FlightType = 'flight_type',
  /** column name */
  Flights = 'flights',
  /** column name */
  Id = 'id',
  /** column name */
  IsEstimatedClaimAmount = 'is_estimated_claim_amount',
  /** column name */
  Persons = 'persons',
  /** column name */
  TicketAmount = 'ticket_amount'
}

export type Gff_Claim_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Gff_Claim_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Gff_Claim_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Gff_Claim_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Gff_Claim_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Gff_Claim_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Gff_Claim_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gff_Claim_Set_Input>;
  where: Gff_Claim_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Gff_Claim_Var_Pop_Fields = {
  __typename?: 'gff_claim_var_pop_fields';
  ticket_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Gff_Claim_Var_Samp_Fields = {
  __typename?: 'gff_claim_var_samp_fields';
  ticket_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Gff_Claim_Variance_Fields = {
  __typename?: 'gff_claim_variance_fields';
  ticket_amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "gff_company" */
export type Gff_Company = {
  __typename?: 'gff_company';
  id: Scalars['String'];
  is_supported: Scalars['Boolean'];
  name: Scalars['String'];
};

/** aggregated selection of "gff_company" */
export type Gff_Company_Aggregate = {
  __typename?: 'gff_company_aggregate';
  aggregate?: Maybe<Gff_Company_Aggregate_Fields>;
  nodes: Array<Gff_Company>;
};

/** aggregate fields of "gff_company" */
export type Gff_Company_Aggregate_Fields = {
  __typename?: 'gff_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gff_Company_Max_Fields>;
  min?: Maybe<Gff_Company_Min_Fields>;
};


/** aggregate fields of "gff_company" */
export type Gff_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Gff_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gff_company". All fields are combined with a logical 'AND'. */
export type Gff_Company_Bool_Exp = {
  _and?: Maybe<Array<Gff_Company_Bool_Exp>>;
  _not?: Maybe<Gff_Company_Bool_Exp>;
  _or?: Maybe<Array<Gff_Company_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_supported?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gff_company" */
export enum Gff_Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  GffCompanyPkey = 'gff_company_pkey'
}

/** input type for inserting data into table "gff_company" */
export type Gff_Company_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gff_Company_Max_Fields = {
  __typename?: 'gff_company_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gff_Company_Min_Fields = {
  __typename?: 'gff_company_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gff_company" */
export type Gff_Company_Mutation_Response = {
  __typename?: 'gff_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gff_Company>;
};

/** input type for inserting object relation for remote table "gff_company" */
export type Gff_Company_Obj_Rel_Insert_Input = {
  data: Gff_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Gff_Company_On_Conflict>;
};

/** on_conflict condition type for table "gff_company" */
export type Gff_Company_On_Conflict = {
  constraint: Gff_Company_Constraint;
  update_columns?: Array<Gff_Company_Update_Column>;
  where?: Maybe<Gff_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "gff_company". */
export type Gff_Company_Order_By = {
  id?: Maybe<Order_By>;
  is_supported?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: gff_company */
export type Gff_Company_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "gff_company" */
export enum Gff_Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "gff_company" */
export type Gff_Company_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "gff_company" */
export type Gff_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gff_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gff_Company_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "gff_company" */
export enum Gff_Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

export type Gff_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Gff_Company_Set_Input>;
  where: Gff_Company_Bool_Exp;
};

/** columns and relationships of "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log = {
  __typename?: 'haveibeenpwned_api_log';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  request_params: Scalars['jsonb'];
  response_body: Scalars['jsonb'];
};


/** columns and relationships of "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_LogRequest_ParamsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_LogResponse_BodyArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_Aggregate = {
  __typename?: 'haveibeenpwned_api_log_aggregate';
  aggregate?: Maybe<Haveibeenpwned_Api_Log_Aggregate_Fields>;
  nodes: Array<Haveibeenpwned_Api_Log>;
};

/** aggregate fields of "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_Aggregate_Fields = {
  __typename?: 'haveibeenpwned_api_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Haveibeenpwned_Api_Log_Max_Fields>;
  min?: Maybe<Haveibeenpwned_Api_Log_Min_Fields>;
};


/** aggregate fields of "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Haveibeenpwned_Api_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Haveibeenpwned_Api_Log_Append_Input = {
  request_params?: Maybe<Scalars['jsonb']>;
  response_body?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "haveibeenpwned_api_log". All fields are combined with a logical 'AND'. */
export type Haveibeenpwned_Api_Log_Bool_Exp = {
  _and?: Maybe<Array<Haveibeenpwned_Api_Log_Bool_Exp>>;
  _not?: Maybe<Haveibeenpwned_Api_Log_Bool_Exp>;
  _or?: Maybe<Array<Haveibeenpwned_Api_Log_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  request_params?: Maybe<Jsonb_Comparison_Exp>;
  response_body?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "haveibeenpwned_api_log" */
export enum Haveibeenpwned_Api_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  HaveibeenpwnedApiLogPkey = 'haveibeenpwned_api_log_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Haveibeenpwned_Api_Log_Delete_At_Path_Input = {
  request_params?: Maybe<Array<Scalars['String']>>;
  response_body?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Haveibeenpwned_Api_Log_Delete_Elem_Input = {
  request_params?: Maybe<Scalars['Int']>;
  response_body?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Haveibeenpwned_Api_Log_Delete_Key_Input = {
  request_params?: Maybe<Scalars['String']>;
  response_body?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  request_params?: Maybe<Scalars['jsonb']>;
  response_body?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Haveibeenpwned_Api_Log_Max_Fields = {
  __typename?: 'haveibeenpwned_api_log_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Haveibeenpwned_Api_Log_Min_Fields = {
  __typename?: 'haveibeenpwned_api_log_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_Mutation_Response = {
  __typename?: 'haveibeenpwned_api_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Haveibeenpwned_Api_Log>;
};

/** on_conflict condition type for table "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_On_Conflict = {
  constraint: Haveibeenpwned_Api_Log_Constraint;
  update_columns?: Array<Haveibeenpwned_Api_Log_Update_Column>;
  where?: Maybe<Haveibeenpwned_Api_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "haveibeenpwned_api_log". */
export type Haveibeenpwned_Api_Log_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  request_params?: Maybe<Order_By>;
  response_body?: Maybe<Order_By>;
};

/** primary key columns input for table: haveibeenpwned_api_log */
export type Haveibeenpwned_Api_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Haveibeenpwned_Api_Log_Prepend_Input = {
  request_params?: Maybe<Scalars['jsonb']>;
  response_body?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "haveibeenpwned_api_log" */
export enum Haveibeenpwned_Api_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RequestParams = 'request_params',
  /** column name */
  ResponseBody = 'response_body'
}

/** input type for updating data in table "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  request_params?: Maybe<Scalars['jsonb']>;
  response_body?: Maybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "haveibeenpwned_api_log" */
export type Haveibeenpwned_Api_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Haveibeenpwned_Api_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Haveibeenpwned_Api_Log_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  request_params?: Maybe<Scalars['jsonb']>;
  response_body?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "haveibeenpwned_api_log" */
export enum Haveibeenpwned_Api_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RequestParams = 'request_params',
  /** column name */
  ResponseBody = 'response_body'
}

export type Haveibeenpwned_Api_Log_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Haveibeenpwned_Api_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Haveibeenpwned_Api_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Haveibeenpwned_Api_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Haveibeenpwned_Api_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Haveibeenpwned_Api_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Haveibeenpwned_Api_Log_Set_Input>;
  where: Haveibeenpwned_Api_Log_Bool_Exp;
};



export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "lost_assignment" */
export type Lost_Assignment = {
  __typename?: 'lost_assignment';
  created_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  received_at: Scalars['timestamp'];
  received_file: Scalars['String'];
  state: Lost_Assignment_State_Enum;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "lost_assignment" */
export type Lost_Assignment_Aggregate = {
  __typename?: 'lost_assignment_aggregate';
  aggregate?: Maybe<Lost_Assignment_Aggregate_Fields>;
  nodes: Array<Lost_Assignment>;
};

/** aggregate fields of "lost_assignment" */
export type Lost_Assignment_Aggregate_Fields = {
  __typename?: 'lost_assignment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lost_Assignment_Max_Fields>;
  min?: Maybe<Lost_Assignment_Min_Fields>;
};


/** aggregate fields of "lost_assignment" */
export type Lost_Assignment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lost_Assignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lost_assignment". All fields are combined with a logical 'AND'. */
export type Lost_Assignment_Bool_Exp = {
  _and?: Maybe<Array<Lost_Assignment_Bool_Exp>>;
  _not?: Maybe<Lost_Assignment_Bool_Exp>;
  _or?: Maybe<Array<Lost_Assignment_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  received_at?: Maybe<Timestamp_Comparison_Exp>;
  received_file?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Lost_Assignment_State_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "lost_assignment" */
export enum Lost_Assignment_Constraint {
  /** unique or primary key constraint on columns "id" */
  LostAssignmentPkey = 'lost_assignment_pkey'
}

/** input type for inserting data into table "lost_assignment" */
export type Lost_Assignment_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  state?: Maybe<Lost_Assignment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Lost_Assignment_Max_Fields = {
  __typename?: 'lost_assignment_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Lost_Assignment_Min_Fields = {
  __typename?: 'lost_assignment_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "lost_assignment" */
export type Lost_Assignment_Mutation_Response = {
  __typename?: 'lost_assignment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lost_Assignment>;
};

/** on_conflict condition type for table "lost_assignment" */
export type Lost_Assignment_On_Conflict = {
  constraint: Lost_Assignment_Constraint;
  update_columns?: Array<Lost_Assignment_Update_Column>;
  where?: Maybe<Lost_Assignment_Bool_Exp>;
};

/** Ordering options when selecting data from "lost_assignment". */
export type Lost_Assignment_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  received_at?: Maybe<Order_By>;
  received_file?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: lost_assignment */
export type Lost_Assignment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lost_assignment" */
export enum Lost_Assignment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  ReceivedFile = 'received_file',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "lost_assignment" */
export type Lost_Assignment_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  state?: Maybe<Lost_Assignment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "lost_assignment_state" */
export type Lost_Assignment_State = {
  __typename?: 'lost_assignment_state';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "lost_assignment_state" */
export type Lost_Assignment_State_Aggregate = {
  __typename?: 'lost_assignment_state_aggregate';
  aggregate?: Maybe<Lost_Assignment_State_Aggregate_Fields>;
  nodes: Array<Lost_Assignment_State>;
};

/** aggregate fields of "lost_assignment_state" */
export type Lost_Assignment_State_Aggregate_Fields = {
  __typename?: 'lost_assignment_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lost_Assignment_State_Max_Fields>;
  min?: Maybe<Lost_Assignment_State_Min_Fields>;
};


/** aggregate fields of "lost_assignment_state" */
export type Lost_Assignment_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Lost_Assignment_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lost_assignment_state". All fields are combined with a logical 'AND'. */
export type Lost_Assignment_State_Bool_Exp = {
  _and?: Maybe<Array<Lost_Assignment_State_Bool_Exp>>;
  _not?: Maybe<Lost_Assignment_State_Bool_Exp>;
  _or?: Maybe<Array<Lost_Assignment_State_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lost_assignment_state" */
export enum Lost_Assignment_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  LostAssignmentStatePkey = 'lost_assignment_state_pkey'
}

export enum Lost_Assignment_State_Enum {
  /** Completed manual checks by the ops */
  Completed = 'COMPLETED',
  /** Deleted after manual checks by the ops */
  Deleted = 'DELETED',
  /** Pending manual checks by the ops */
  Pending = 'PENDING'
}

/** Boolean expression to compare columns of type "lost_assignment_state_enum". All fields are combined with logical 'AND'. */
export type Lost_Assignment_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Lost_Assignment_State_Enum>;
  _in?: Maybe<Array<Lost_Assignment_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Lost_Assignment_State_Enum>;
  _nin?: Maybe<Array<Lost_Assignment_State_Enum>>;
};

/** input type for inserting data into table "lost_assignment_state" */
export type Lost_Assignment_State_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lost_Assignment_State_Max_Fields = {
  __typename?: 'lost_assignment_state_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lost_Assignment_State_Min_Fields = {
  __typename?: 'lost_assignment_state_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lost_assignment_state" */
export type Lost_Assignment_State_Mutation_Response = {
  __typename?: 'lost_assignment_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lost_Assignment_State>;
};

/** on_conflict condition type for table "lost_assignment_state" */
export type Lost_Assignment_State_On_Conflict = {
  constraint: Lost_Assignment_State_Constraint;
  update_columns?: Array<Lost_Assignment_State_Update_Column>;
  where?: Maybe<Lost_Assignment_State_Bool_Exp>;
};

/** Ordering options when selecting data from "lost_assignment_state". */
export type Lost_Assignment_State_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: lost_assignment_state */
export type Lost_Assignment_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "lost_assignment_state" */
export enum Lost_Assignment_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "lost_assignment_state" */
export type Lost_Assignment_State_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "lost_assignment_state" */
export type Lost_Assignment_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lost_Assignment_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lost_Assignment_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "lost_assignment_state" */
export enum Lost_Assignment_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Lost_Assignment_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Lost_Assignment_State_Set_Input>;
  where: Lost_Assignment_State_Bool_Exp;
};

/** Streaming cursor of the table "lost_assignment" */
export type Lost_Assignment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lost_Assignment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lost_Assignment_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamp']>;
  received_file?: Maybe<Scalars['String']>;
  state?: Maybe<Lost_Assignment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "lost_assignment" */
export enum Lost_Assignment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReceivedAt = 'received_at',
  /** column name */
  ReceivedFile = 'received_file',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Lost_Assignment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Lost_Assignment_Set_Input>;
  where: Lost_Assignment_Bool_Exp;
};

/** columns and relationships of "ls_case" */
export type Ls_Case = {
  __typename?: 'ls_case';
  alias_id: Scalars['String'];
  case_submitted_at: Scalars['timestamp'];
  claim: Scalars['jsonb'];
  claim_amount: Scalars['numeric'];
  company_id: Scalars['String'];
  currency: Scalars['String'];
  debtor_id: Scalars['uuid'];
  debtor_name?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['jsonb']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  legal_process_started_at: Scalars['timestamp'];
  /** An object relationship */
  ls_lawsuit?: Maybe<Ls_Lawsuit>;
  offer_accepted_at: Scalars['timestamp'];
  open_claim_amount: Scalars['numeric'];
  rejection_reason?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  vertical: Scalars['String'];
};


/** columns and relationships of "ls_case" */
export type Ls_CaseClaimArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "ls_case" */
export type Ls_CaseDocumentsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ls_case" */
export type Ls_Case_Aggregate = {
  __typename?: 'ls_case_aggregate';
  aggregate?: Maybe<Ls_Case_Aggregate_Fields>;
  nodes: Array<Ls_Case>;
};

/** aggregate fields of "ls_case" */
export type Ls_Case_Aggregate_Fields = {
  __typename?: 'ls_case_aggregate_fields';
  avg?: Maybe<Ls_Case_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ls_Case_Max_Fields>;
  min?: Maybe<Ls_Case_Min_Fields>;
  stddev?: Maybe<Ls_Case_Stddev_Fields>;
  stddev_pop?: Maybe<Ls_Case_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ls_Case_Stddev_Samp_Fields>;
  sum?: Maybe<Ls_Case_Sum_Fields>;
  var_pop?: Maybe<Ls_Case_Var_Pop_Fields>;
  var_samp?: Maybe<Ls_Case_Var_Samp_Fields>;
  variance?: Maybe<Ls_Case_Variance_Fields>;
};


/** aggregate fields of "ls_case" */
export type Ls_Case_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Case_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ls_Case_Append_Input = {
  claim?: Maybe<Scalars['jsonb']>;
  documents?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Ls_Case_Avg_Fields = {
  __typename?: 'ls_case_avg_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  open_claim_amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ls_case". All fields are combined with a logical 'AND'. */
export type Ls_Case_Bool_Exp = {
  _and?: Maybe<Array<Ls_Case_Bool_Exp>>;
  _not?: Maybe<Ls_Case_Bool_Exp>;
  _or?: Maybe<Array<Ls_Case_Bool_Exp>>;
  alias_id?: Maybe<String_Comparison_Exp>;
  case_submitted_at?: Maybe<Timestamp_Comparison_Exp>;
  claim?: Maybe<Jsonb_Comparison_Exp>;
  claim_amount?: Maybe<Numeric_Comparison_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  currency?: Maybe<String_Comparison_Exp>;
  debtor_id?: Maybe<Uuid_Comparison_Exp>;
  debtor_name?: Maybe<String_Comparison_Exp>;
  documents?: Maybe<Jsonb_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  lawsuit_id?: Maybe<Uuid_Comparison_Exp>;
  lawyer_fee?: Maybe<Numeric_Comparison_Exp>;
  legal_process_started_at?: Maybe<Timestamp_Comparison_Exp>;
  ls_lawsuit?: Maybe<Ls_Lawsuit_Bool_Exp>;
  offer_accepted_at?: Maybe<Timestamp_Comparison_Exp>;
  open_claim_amount?: Maybe<Numeric_Comparison_Exp>;
  rejection_reason?: Maybe<String_Comparison_Exp>;
  salutation?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  vertical?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ls_case" */
export enum Ls_Case_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_469785bbdd939f4c6b15a03244e = 'PK_469785bbdd939f4c6b15a03244e',
  /** unique or primary key constraint on columns "alias_id" */
  UqDcab9fdbcf1046233fda506752a = 'UQ_dcab9fdbcf1046233fda506752a'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ls_Case_Delete_At_Path_Input = {
  claim?: Maybe<Array<Scalars['String']>>;
  documents?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ls_Case_Delete_Elem_Input = {
  claim?: Maybe<Scalars['Int']>;
  documents?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ls_Case_Delete_Key_Input = {
  claim?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ls_case" */
export type Ls_Case_Inc_Input = {
  claim_amount?: Maybe<Scalars['numeric']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  open_claim_amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ls_case" */
export type Ls_Case_Insert_Input = {
  alias_id?: Maybe<Scalars['String']>;
  case_submitted_at?: Maybe<Scalars['timestamp']>;
  claim?: Maybe<Scalars['jsonb']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  debtor_id?: Maybe<Scalars['uuid']>;
  debtor_name?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['jsonb']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  legal_process_started_at?: Maybe<Scalars['timestamp']>;
  ls_lawsuit?: Maybe<Ls_Lawsuit_Obj_Rel_Insert_Input>;
  offer_accepted_at?: Maybe<Scalars['timestamp']>;
  open_claim_amount?: Maybe<Scalars['numeric']>;
  rejection_reason?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ls_Case_Max_Fields = {
  __typename?: 'ls_case_max_fields';
  alias_id?: Maybe<Scalars['String']>;
  case_submitted_at?: Maybe<Scalars['timestamp']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  debtor_id?: Maybe<Scalars['uuid']>;
  debtor_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  legal_process_started_at?: Maybe<Scalars['timestamp']>;
  offer_accepted_at?: Maybe<Scalars['timestamp']>;
  open_claim_amount?: Maybe<Scalars['numeric']>;
  rejection_reason?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ls_Case_Min_Fields = {
  __typename?: 'ls_case_min_fields';
  alias_id?: Maybe<Scalars['String']>;
  case_submitted_at?: Maybe<Scalars['timestamp']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  debtor_id?: Maybe<Scalars['uuid']>;
  debtor_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  legal_process_started_at?: Maybe<Scalars['timestamp']>;
  offer_accepted_at?: Maybe<Scalars['timestamp']>;
  open_claim_amount?: Maybe<Scalars['numeric']>;
  rejection_reason?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ls_case" */
export type Ls_Case_Mutation_Response = {
  __typename?: 'ls_case_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Case>;
};

/** on_conflict condition type for table "ls_case" */
export type Ls_Case_On_Conflict = {
  constraint: Ls_Case_Constraint;
  update_columns?: Array<Ls_Case_Update_Column>;
  where?: Maybe<Ls_Case_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_case". */
export type Ls_Case_Order_By = {
  alias_id?: Maybe<Order_By>;
  case_submitted_at?: Maybe<Order_By>;
  claim?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  debtor_id?: Maybe<Order_By>;
  debtor_name?: Maybe<Order_By>;
  documents?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  lawsuit_id?: Maybe<Order_By>;
  lawyer_fee?: Maybe<Order_By>;
  legal_process_started_at?: Maybe<Order_By>;
  ls_lawsuit?: Maybe<Ls_Lawsuit_Order_By>;
  offer_accepted_at?: Maybe<Order_By>;
  open_claim_amount?: Maybe<Order_By>;
  rejection_reason?: Maybe<Order_By>;
  salutation?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  vertical?: Maybe<Order_By>;
};

/** primary key columns input for table: ls_case */
export type Ls_Case_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ls_Case_Prepend_Input = {
  claim?: Maybe<Scalars['jsonb']>;
  documents?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "ls_case" */
export enum Ls_Case_Select_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  CaseSubmittedAt = 'case_submitted_at',
  /** column name */
  Claim = 'claim',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  DebtorId = 'debtor_id',
  /** column name */
  DebtorName = 'debtor_name',
  /** column name */
  Documents = 'documents',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  LawyerFee = 'lawyer_fee',
  /** column name */
  LegalProcessStartedAt = 'legal_process_started_at',
  /** column name */
  OfferAcceptedAt = 'offer_accepted_at',
  /** column name */
  OpenClaimAmount = 'open_claim_amount',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  Status = 'status',
  /** column name */
  Vertical = 'vertical'
}

/** input type for updating data in table "ls_case" */
export type Ls_Case_Set_Input = {
  alias_id?: Maybe<Scalars['String']>;
  case_submitted_at?: Maybe<Scalars['timestamp']>;
  claim?: Maybe<Scalars['jsonb']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  debtor_id?: Maybe<Scalars['uuid']>;
  debtor_name?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['jsonb']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  legal_process_started_at?: Maybe<Scalars['timestamp']>;
  offer_accepted_at?: Maybe<Scalars['timestamp']>;
  open_claim_amount?: Maybe<Scalars['numeric']>;
  rejection_reason?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ls_Case_Stddev_Fields = {
  __typename?: 'ls_case_stddev_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  open_claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ls_Case_Stddev_Pop_Fields = {
  __typename?: 'ls_case_stddev_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  open_claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ls_Case_Stddev_Samp_Fields = {
  __typename?: 'ls_case_stddev_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  open_claim_amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ls_case" */
export type Ls_Case_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Case_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Case_Stream_Cursor_Value_Input = {
  alias_id?: Maybe<Scalars['String']>;
  case_submitted_at?: Maybe<Scalars['timestamp']>;
  claim?: Maybe<Scalars['jsonb']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  debtor_id?: Maybe<Scalars['uuid']>;
  debtor_name?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['jsonb']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  legal_process_started_at?: Maybe<Scalars['timestamp']>;
  offer_accepted_at?: Maybe<Scalars['timestamp']>;
  open_claim_amount?: Maybe<Scalars['numeric']>;
  rejection_reason?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ls_Case_Sum_Fields = {
  __typename?: 'ls_case_sum_fields';
  claim_amount?: Maybe<Scalars['numeric']>;
  lawyer_fee?: Maybe<Scalars['numeric']>;
  open_claim_amount?: Maybe<Scalars['numeric']>;
};

/** update columns of table "ls_case" */
export enum Ls_Case_Update_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  CaseSubmittedAt = 'case_submitted_at',
  /** column name */
  Claim = 'claim',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  DebtorId = 'debtor_id',
  /** column name */
  DebtorName = 'debtor_name',
  /** column name */
  Documents = 'documents',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  LawyerFee = 'lawyer_fee',
  /** column name */
  LegalProcessStartedAt = 'legal_process_started_at',
  /** column name */
  OfferAcceptedAt = 'offer_accepted_at',
  /** column name */
  OpenClaimAmount = 'open_claim_amount',
  /** column name */
  RejectionReason = 'rejection_reason',
  /** column name */
  Salutation = 'salutation',
  /** column name */
  Status = 'status',
  /** column name */
  Vertical = 'vertical'
}

export type Ls_Case_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Ls_Case_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Ls_Case_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Ls_Case_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Ls_Case_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ls_Case_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Ls_Case_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Case_Set_Input>;
  where: Ls_Case_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ls_Case_Var_Pop_Fields = {
  __typename?: 'ls_case_var_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  open_claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ls_Case_Var_Samp_Fields = {
  __typename?: 'ls_case_var_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  open_claim_amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ls_Case_Variance_Fields = {
  __typename?: 'ls_case_variance_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  lawyer_fee?: Maybe<Scalars['Float']>;
  open_claim_amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ls_lawsuit" */
export type Ls_Lawsuit = {
  __typename?: 'ls_lawsuit';
  alias_id: Scalars['String'];
  claim_amount: Scalars['numeric'];
  /** An object relationship */
  court?: Maybe<Ls_Lawsuit_Court>;
  court_file_id?: Maybe<Scalars['String']>;
  court_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamp'];
  /** An object relationship */
  debtor?: Maybe<Ps_Debtor>;
  id: Scalars['uuid'];
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  interest_start_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  lawyer?: Maybe<Ls_Lawsuit_Lawyer>;
  lawyer_fees: Scalars['numeric'];
  lawyer_file_id?: Maybe<Scalars['String']>;
  lawyer_id: Scalars['uuid'];
  opponent_id: Scalars['uuid'];
  other_expenses: Scalars['numeric'];
  status: Scalars['String'];
  updated_at: Scalars['timestamp'];
  vertical: Scalars['String'];
};

/** aggregated selection of "ls_lawsuit" */
export type Ls_Lawsuit_Aggregate = {
  __typename?: 'ls_lawsuit_aggregate';
  aggregate?: Maybe<Ls_Lawsuit_Aggregate_Fields>;
  nodes: Array<Ls_Lawsuit>;
};

/** aggregate fields of "ls_lawsuit" */
export type Ls_Lawsuit_Aggregate_Fields = {
  __typename?: 'ls_lawsuit_aggregate_fields';
  avg?: Maybe<Ls_Lawsuit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ls_Lawsuit_Max_Fields>;
  min?: Maybe<Ls_Lawsuit_Min_Fields>;
  stddev?: Maybe<Ls_Lawsuit_Stddev_Fields>;
  stddev_pop?: Maybe<Ls_Lawsuit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ls_Lawsuit_Stddev_Samp_Fields>;
  sum?: Maybe<Ls_Lawsuit_Sum_Fields>;
  var_pop?: Maybe<Ls_Lawsuit_Var_Pop_Fields>;
  var_samp?: Maybe<Ls_Lawsuit_Var_Samp_Fields>;
  variance?: Maybe<Ls_Lawsuit_Variance_Fields>;
};


/** aggregate fields of "ls_lawsuit" */
export type Ls_Lawsuit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Lawsuit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ls_Lawsuit_Avg_Fields = {
  __typename?: 'ls_lawsuit_avg_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  interest_amount?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  lawyer_fees?: Maybe<Scalars['Float']>;
  other_expenses?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ls_lawsuit". All fields are combined with a logical 'AND'. */
export type Ls_Lawsuit_Bool_Exp = {
  _and?: Maybe<Array<Ls_Lawsuit_Bool_Exp>>;
  _not?: Maybe<Ls_Lawsuit_Bool_Exp>;
  _or?: Maybe<Array<Ls_Lawsuit_Bool_Exp>>;
  alias_id?: Maybe<String_Comparison_Exp>;
  claim_amount?: Maybe<Numeric_Comparison_Exp>;
  court?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
  court_file_id?: Maybe<String_Comparison_Exp>;
  court_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  debtor?: Maybe<Ps_Debtor_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  interest_amount?: Maybe<Numeric_Comparison_Exp>;
  interest_rate?: Maybe<Numeric_Comparison_Exp>;
  interest_start_date?: Maybe<Date_Comparison_Exp>;
  lawyer?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
  lawyer_fees?: Maybe<Numeric_Comparison_Exp>;
  lawyer_file_id?: Maybe<String_Comparison_Exp>;
  lawyer_id?: Maybe<Uuid_Comparison_Exp>;
  opponent_id?: Maybe<Uuid_Comparison_Exp>;
  other_expenses?: Maybe<Numeric_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  vertical?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ls_lawsuit" */
export enum Ls_Lawsuit_Constraint {
  /** unique or primary key constraint on columns "alias_id" */
  LsLawsuitAliasIdKey = 'ls_lawsuit_alias_id_key',
  /** unique or primary key constraint on columns "id" */
  LsLawsuitPkey = 'ls_lawsuit_pkey'
}

/** columns and relationships of "ls_lawsuit_court" */
export type Ls_Lawsuit_Court = {
  __typename?: 'ls_lawsuit_court';
  city: Scalars['String'];
  country_code: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Aggregate = {
  __typename?: 'ls_lawsuit_court_aggregate';
  aggregate?: Maybe<Ls_Lawsuit_Court_Aggregate_Fields>;
  nodes: Array<Ls_Lawsuit_Court>;
};

/** aggregate fields of "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Aggregate_Fields = {
  __typename?: 'ls_lawsuit_court_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ls_Lawsuit_Court_Max_Fields>;
  min?: Maybe<Ls_Lawsuit_Court_Min_Fields>;
};


/** aggregate fields of "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Lawsuit_Court_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ls_lawsuit_court". All fields are combined with a logical 'AND'. */
export type Ls_Lawsuit_Court_Bool_Exp = {
  _and?: Maybe<Array<Ls_Lawsuit_Court_Bool_Exp>>;
  _not?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
  _or?: Maybe<Array<Ls_Lawsuit_Court_Bool_Exp>>;
  city?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ls_lawsuit_court" */
export enum Ls_Lawsuit_Court_Constraint {
  /** unique or primary key constraint on columns "id" */
  LsLawsuitCourtPkey = 'ls_lawsuit_court_pkey'
}

/** input type for inserting data into table "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ls_Lawsuit_Court_Max_Fields = {
  __typename?: 'ls_lawsuit_court_max_fields';
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ls_Lawsuit_Court_Min_Fields = {
  __typename?: 'ls_lawsuit_court_min_fields';
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Mutation_Response = {
  __typename?: 'ls_lawsuit_court_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Lawsuit_Court>;
};

/** input type for inserting object relation for remote table "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Obj_Rel_Insert_Input = {
  data: Ls_Lawsuit_Court_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ls_Lawsuit_Court_On_Conflict>;
};

/** on_conflict condition type for table "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_On_Conflict = {
  constraint: Ls_Lawsuit_Court_Constraint;
  update_columns?: Array<Ls_Lawsuit_Court_Update_Column>;
  where?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_lawsuit_court". */
export type Ls_Lawsuit_Court_Order_By = {
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: ls_lawsuit_court */
export type Ls_Lawsuit_Court_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ls_lawsuit_court" */
export enum Ls_Lawsuit_Court_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Set_Input = {
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "ls_lawsuit_court" */
export type Ls_Lawsuit_Court_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Lawsuit_Court_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Lawsuit_Court_Stream_Cursor_Value_Input = {
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "ls_lawsuit_court" */
export enum Ls_Lawsuit_Court_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Ls_Lawsuit_Court_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Lawsuit_Court_Set_Input>;
  where: Ls_Lawsuit_Court_Bool_Exp;
};

/** columns and relationships of "ls_lawsuit_document" */
export type Ls_Lawsuit_Document = {
  __typename?: 'ls_lawsuit_document';
  /** An object relationship */
  category: Ls_Lawsuit_Document_Category;
  category_id: Scalars['String'];
  created_at: Scalars['timestamp'];
  file_content_type?: Maybe<Scalars['String']>;
  file_key: Scalars['String'];
  file_name: Scalars['String'];
  file_size: Scalars['numeric'];
  id: Scalars['uuid'];
  is_deleted: Scalars['Boolean'];
  /** An object relationship */
  lawsuit: Ls_Lawsuit;
  lawsuit_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamp']>;
  uploaded_at?: Maybe<Scalars['timestamp']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Aggregate = {
  __typename?: 'ls_lawsuit_document_aggregate';
  aggregate?: Maybe<Ls_Lawsuit_Document_Aggregate_Fields>;
  nodes: Array<Ls_Lawsuit_Document>;
};

/** aggregate fields of "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Aggregate_Fields = {
  __typename?: 'ls_lawsuit_document_aggregate_fields';
  avg?: Maybe<Ls_Lawsuit_Document_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ls_Lawsuit_Document_Max_Fields>;
  min?: Maybe<Ls_Lawsuit_Document_Min_Fields>;
  stddev?: Maybe<Ls_Lawsuit_Document_Stddev_Fields>;
  stddev_pop?: Maybe<Ls_Lawsuit_Document_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ls_Lawsuit_Document_Stddev_Samp_Fields>;
  sum?: Maybe<Ls_Lawsuit_Document_Sum_Fields>;
  var_pop?: Maybe<Ls_Lawsuit_Document_Var_Pop_Fields>;
  var_samp?: Maybe<Ls_Lawsuit_Document_Var_Samp_Fields>;
  variance?: Maybe<Ls_Lawsuit_Document_Variance_Fields>;
};


/** aggregate fields of "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Lawsuit_Document_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ls_Lawsuit_Document_Avg_Fields = {
  __typename?: 'ls_lawsuit_document_avg_fields';
  file_size?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ls_lawsuit_document". All fields are combined with a logical 'AND'. */
export type Ls_Lawsuit_Document_Bool_Exp = {
  _and?: Maybe<Array<Ls_Lawsuit_Document_Bool_Exp>>;
  _not?: Maybe<Ls_Lawsuit_Document_Bool_Exp>;
  _or?: Maybe<Array<Ls_Lawsuit_Document_Bool_Exp>>;
  category?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
  category_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  file_content_type?: Maybe<String_Comparison_Exp>;
  file_key?: Maybe<String_Comparison_Exp>;
  file_name?: Maybe<String_Comparison_Exp>;
  file_size?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_deleted?: Maybe<Boolean_Comparison_Exp>;
  lawsuit?: Maybe<Ls_Lawsuit_Bool_Exp>;
  lawsuit_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  uploaded_at?: Maybe<Timestamp_Comparison_Exp>;
  uploaded_by?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
};

/** columns and relationships of "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category = {
  __typename?: 'ls_lawsuit_document_category';
  id: Scalars['String'];
  label: Scalars['String'];
};

/** aggregated selection of "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Aggregate = {
  __typename?: 'ls_lawsuit_document_category_aggregate';
  aggregate?: Maybe<Ls_Lawsuit_Document_Category_Aggregate_Fields>;
  nodes: Array<Ls_Lawsuit_Document_Category>;
};

/** aggregate fields of "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Aggregate_Fields = {
  __typename?: 'ls_lawsuit_document_category_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ls_Lawsuit_Document_Category_Max_Fields>;
  min?: Maybe<Ls_Lawsuit_Document_Category_Min_Fields>;
};


/** aggregate fields of "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Lawsuit_Document_Category_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ls_lawsuit_document_category". All fields are combined with a logical 'AND'. */
export type Ls_Lawsuit_Document_Category_Bool_Exp = {
  _and?: Maybe<Array<Ls_Lawsuit_Document_Category_Bool_Exp>>;
  _not?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
  _or?: Maybe<Array<Ls_Lawsuit_Document_Category_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ls_lawsuit_document_category" */
export enum Ls_Lawsuit_Document_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  LsLawsuitDocumentCategoryPkey = 'ls_lawsuit_document_category_pkey'
}

/** input type for inserting data into table "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ls_Lawsuit_Document_Category_Max_Fields = {
  __typename?: 'ls_lawsuit_document_category_max_fields';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ls_Lawsuit_Document_Category_Min_Fields = {
  __typename?: 'ls_lawsuit_document_category_min_fields';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Mutation_Response = {
  __typename?: 'ls_lawsuit_document_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Lawsuit_Document_Category>;
};

/** input type for inserting object relation for remote table "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Obj_Rel_Insert_Input = {
  data: Ls_Lawsuit_Document_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ls_Lawsuit_Document_Category_On_Conflict>;
};

/** on_conflict condition type for table "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_On_Conflict = {
  constraint: Ls_Lawsuit_Document_Category_Constraint;
  update_columns?: Array<Ls_Lawsuit_Document_Category_Update_Column>;
  where?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_lawsuit_document_category". */
export type Ls_Lawsuit_Document_Category_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
};

/** primary key columns input for table: ls_lawsuit_document_category */
export type Ls_Lawsuit_Document_Category_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ls_lawsuit_document_category" */
export enum Ls_Lawsuit_Document_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label'
}

/** input type for updating data in table "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Set_Input = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "ls_lawsuit_document_category" */
export type Ls_Lawsuit_Document_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Lawsuit_Document_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Lawsuit_Document_Category_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

/** update columns of table "ls_lawsuit_document_category" */
export enum Ls_Lawsuit_Document_Category_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Label = 'label'
}

export type Ls_Lawsuit_Document_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Lawsuit_Document_Category_Set_Input>;
  where: Ls_Lawsuit_Document_Category_Bool_Exp;
};

/** unique or primary key constraints on table "ls_lawsuit_document" */
export enum Ls_Lawsuit_Document_Constraint {
  /** unique or primary key constraint on columns "file_key" */
  LsLawsuitDocumentFileKeyKey = 'ls_lawsuit_document_file_key_key',
  /** unique or primary key constraint on columns "id" */
  LsLawsuitDocumentPkey = 'ls_lawsuit_document_pkey'
}

/** input type for incrementing numeric columns in table "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Inc_Input = {
  file_size?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Insert_Input = {
  category?: Maybe<Ls_Lawsuit_Document_Category_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_content_type?: Maybe<Scalars['String']>;
  file_key?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  lawsuit?: Maybe<Ls_Lawsuit_Obj_Rel_Insert_Input>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uploaded_at?: Maybe<Scalars['timestamp']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ls_Lawsuit_Document_Max_Fields = {
  __typename?: 'ls_lawsuit_document_max_fields';
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_content_type?: Maybe<Scalars['String']>;
  file_key?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uploaded_at?: Maybe<Scalars['timestamp']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ls_Lawsuit_Document_Min_Fields = {
  __typename?: 'ls_lawsuit_document_min_fields';
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_content_type?: Maybe<Scalars['String']>;
  file_key?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uploaded_at?: Maybe<Scalars['timestamp']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Mutation_Response = {
  __typename?: 'ls_lawsuit_document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Lawsuit_Document>;
};

/** on_conflict condition type for table "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_On_Conflict = {
  constraint: Ls_Lawsuit_Document_Constraint;
  update_columns?: Array<Ls_Lawsuit_Document_Update_Column>;
  where?: Maybe<Ls_Lawsuit_Document_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_lawsuit_document". */
export type Ls_Lawsuit_Document_Order_By = {
  category?: Maybe<Ls_Lawsuit_Document_Category_Order_By>;
  category_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_content_type?: Maybe<Order_By>;
  file_key?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  file_size?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_deleted?: Maybe<Order_By>;
  lawsuit?: Maybe<Ls_Lawsuit_Order_By>;
  lawsuit_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uploaded_at?: Maybe<Order_By>;
  uploaded_by?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
};

/** primary key columns input for table: ls_lawsuit_document */
export type Ls_Lawsuit_Document_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ls_lawsuit_document" */
export enum Ls_Lawsuit_Document_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileContentType = 'file_content_type',
  /** column name */
  FileKey = 'file_key',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedAt = 'uploaded_at',
  /** column name */
  UploadedBy = 'uploaded_by'
}

/** input type for updating data in table "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Set_Input = {
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_content_type?: Maybe<Scalars['String']>;
  file_key?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uploaded_at?: Maybe<Scalars['timestamp']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Ls_Lawsuit_Document_Stddev_Fields = {
  __typename?: 'ls_lawsuit_document_stddev_fields';
  file_size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ls_Lawsuit_Document_Stddev_Pop_Fields = {
  __typename?: 'ls_lawsuit_document_stddev_pop_fields';
  file_size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ls_Lawsuit_Document_Stddev_Samp_Fields = {
  __typename?: 'ls_lawsuit_document_stddev_samp_fields';
  file_size?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ls_lawsuit_document" */
export type Ls_Lawsuit_Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Lawsuit_Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Lawsuit_Document_Stream_Cursor_Value_Input = {
  category_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  file_content_type?: Maybe<Scalars['String']>;
  file_key?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_size?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  uploaded_at?: Maybe<Scalars['timestamp']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Ls_Lawsuit_Document_Sum_Fields = {
  __typename?: 'ls_lawsuit_document_sum_fields';
  file_size?: Maybe<Scalars['numeric']>;
};

/** update columns of table "ls_lawsuit_document" */
export enum Ls_Lawsuit_Document_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileContentType = 'file_content_type',
  /** column name */
  FileKey = 'file_key',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadedAt = 'uploaded_at',
  /** column name */
  UploadedBy = 'uploaded_by'
}

export type Ls_Lawsuit_Document_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ls_Lawsuit_Document_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Lawsuit_Document_Set_Input>;
  where: Ls_Lawsuit_Document_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ls_Lawsuit_Document_Var_Pop_Fields = {
  __typename?: 'ls_lawsuit_document_var_pop_fields';
  file_size?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ls_Lawsuit_Document_Var_Samp_Fields = {
  __typename?: 'ls_lawsuit_document_var_samp_fields';
  file_size?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ls_Lawsuit_Document_Variance_Fields = {
  __typename?: 'ls_lawsuit_document_variance_fields';
  file_size?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense = {
  __typename?: 'ls_lawsuit_expense';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamp'];
  iban: Scalars['String'];
  id: Scalars['uuid'];
  lawsuit_id: Scalars['uuid'];
  recipient: Scalars['String'];
  reference: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Aggregate = {
  __typename?: 'ls_lawsuit_expense_aggregate';
  aggregate?: Maybe<Ls_Lawsuit_Expense_Aggregate_Fields>;
  nodes: Array<Ls_Lawsuit_Expense>;
};

/** aggregate fields of "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Aggregate_Fields = {
  __typename?: 'ls_lawsuit_expense_aggregate_fields';
  avg?: Maybe<Ls_Lawsuit_Expense_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ls_Lawsuit_Expense_Max_Fields>;
  min?: Maybe<Ls_Lawsuit_Expense_Min_Fields>;
  stddev?: Maybe<Ls_Lawsuit_Expense_Stddev_Fields>;
  stddev_pop?: Maybe<Ls_Lawsuit_Expense_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ls_Lawsuit_Expense_Stddev_Samp_Fields>;
  sum?: Maybe<Ls_Lawsuit_Expense_Sum_Fields>;
  var_pop?: Maybe<Ls_Lawsuit_Expense_Var_Pop_Fields>;
  var_samp?: Maybe<Ls_Lawsuit_Expense_Var_Samp_Fields>;
  variance?: Maybe<Ls_Lawsuit_Expense_Variance_Fields>;
};


/** aggregate fields of "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Lawsuit_Expense_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ls_Lawsuit_Expense_Avg_Fields = {
  __typename?: 'ls_lawsuit_expense_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ls_lawsuit_expense". All fields are combined with a logical 'AND'. */
export type Ls_Lawsuit_Expense_Bool_Exp = {
  _and?: Maybe<Array<Ls_Lawsuit_Expense_Bool_Exp>>;
  _not?: Maybe<Ls_Lawsuit_Expense_Bool_Exp>;
  _or?: Maybe<Array<Ls_Lawsuit_Expense_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  iban?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lawsuit_id?: Maybe<Uuid_Comparison_Exp>;
  recipient?: Maybe<String_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ls_lawsuit_expense" */
export enum Ls_Lawsuit_Expense_Constraint {
  /** unique or primary key constraint on columns "id" */
  LsLawsuitExpensePkey = 'ls_lawsuit_expense_pkey'
}

/** input type for incrementing numeric columns in table "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ls_Lawsuit_Expense_Max_Fields = {
  __typename?: 'ls_lawsuit_expense_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ls_Lawsuit_Expense_Min_Fields = {
  __typename?: 'ls_lawsuit_expense_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Mutation_Response = {
  __typename?: 'ls_lawsuit_expense_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Lawsuit_Expense>;
};

/** on_conflict condition type for table "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_On_Conflict = {
  constraint: Ls_Lawsuit_Expense_Constraint;
  update_columns?: Array<Ls_Lawsuit_Expense_Update_Column>;
  where?: Maybe<Ls_Lawsuit_Expense_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_lawsuit_expense". */
export type Ls_Lawsuit_Expense_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  iban?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lawsuit_id?: Maybe<Order_By>;
  recipient?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: ls_lawsuit_expense */
export type Ls_Lawsuit_Expense_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ls_lawsuit_expense" */
export enum Ls_Lawsuit_Expense_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  Reference = 'reference',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ls_Lawsuit_Expense_Stddev_Fields = {
  __typename?: 'ls_lawsuit_expense_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ls_Lawsuit_Expense_Stddev_Pop_Fields = {
  __typename?: 'ls_lawsuit_expense_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ls_Lawsuit_Expense_Stddev_Samp_Fields = {
  __typename?: 'ls_lawsuit_expense_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ls_lawsuit_expense" */
export type Ls_Lawsuit_Expense_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Lawsuit_Expense_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Lawsuit_Expense_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  iban?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  recipient?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ls_Lawsuit_Expense_Sum_Fields = {
  __typename?: 'ls_lawsuit_expense_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** update columns of table "ls_lawsuit_expense" */
export enum Ls_Lawsuit_Expense_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Iban = 'iban',
  /** column name */
  Id = 'id',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  Recipient = 'recipient',
  /** column name */
  Reference = 'reference',
  /** column name */
  Type = 'type'
}

export type Ls_Lawsuit_Expense_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ls_Lawsuit_Expense_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Lawsuit_Expense_Set_Input>;
  where: Ls_Lawsuit_Expense_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ls_Lawsuit_Expense_Var_Pop_Fields = {
  __typename?: 'ls_lawsuit_expense_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ls_Lawsuit_Expense_Var_Samp_Fields = {
  __typename?: 'ls_lawsuit_expense_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ls_Lawsuit_Expense_Variance_Fields = {
  __typename?: 'ls_lawsuit_expense_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ls_lawsuit_history" */
export type Ls_Lawsuit_History = {
  __typename?: 'ls_lawsuit_history';
  created_at: Scalars['timestamp'];
  data: Scalars['jsonb'];
  id: Scalars['uuid'];
  lawsuit_id: Scalars['uuid'];
  type: Scalars['String'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "ls_lawsuit_history" */
export type Ls_Lawsuit_HistoryDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ls_lawsuit_history" */
export type Ls_Lawsuit_History_Aggregate = {
  __typename?: 'ls_lawsuit_history_aggregate';
  aggregate?: Maybe<Ls_Lawsuit_History_Aggregate_Fields>;
  nodes: Array<Ls_Lawsuit_History>;
};

/** aggregate fields of "ls_lawsuit_history" */
export type Ls_Lawsuit_History_Aggregate_Fields = {
  __typename?: 'ls_lawsuit_history_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ls_Lawsuit_History_Max_Fields>;
  min?: Maybe<Ls_Lawsuit_History_Min_Fields>;
};


/** aggregate fields of "ls_lawsuit_history" */
export type Ls_Lawsuit_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Lawsuit_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ls_Lawsuit_History_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ls_lawsuit_history". All fields are combined with a logical 'AND'. */
export type Ls_Lawsuit_History_Bool_Exp = {
  _and?: Maybe<Array<Ls_Lawsuit_History_Bool_Exp>>;
  _not?: Maybe<Ls_Lawsuit_History_Bool_Exp>;
  _or?: Maybe<Array<Ls_Lawsuit_History_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lawsuit_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ls_lawsuit_history" */
export enum Ls_Lawsuit_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  LsLawsuitHistoryPkey = 'ls_lawsuit_history_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ls_Lawsuit_History_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ls_Lawsuit_History_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ls_Lawsuit_History_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "ls_lawsuit_history" */
export type Ls_Lawsuit_History_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ls_Lawsuit_History_Max_Fields = {
  __typename?: 'ls_lawsuit_history_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ls_Lawsuit_History_Min_Fields = {
  __typename?: 'ls_lawsuit_history_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ls_lawsuit_history" */
export type Ls_Lawsuit_History_Mutation_Response = {
  __typename?: 'ls_lawsuit_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Lawsuit_History>;
};

/** on_conflict condition type for table "ls_lawsuit_history" */
export type Ls_Lawsuit_History_On_Conflict = {
  constraint: Ls_Lawsuit_History_Constraint;
  update_columns?: Array<Ls_Lawsuit_History_Update_Column>;
  where?: Maybe<Ls_Lawsuit_History_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_lawsuit_history". */
export type Ls_Lawsuit_History_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lawsuit_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: ls_lawsuit_history */
export type Ls_Lawsuit_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ls_Lawsuit_History_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "ls_lawsuit_history" */
export enum Ls_Lawsuit_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "ls_lawsuit_history" */
export type Ls_Lawsuit_History_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ls_lawsuit_history" */
export type Ls_Lawsuit_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Lawsuit_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Lawsuit_History_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  lawsuit_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ls_lawsuit_history" */
export enum Ls_Lawsuit_History_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  LawsuitId = 'lawsuit_id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Ls_Lawsuit_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Ls_Lawsuit_History_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Ls_Lawsuit_History_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Ls_Lawsuit_History_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Ls_Lawsuit_History_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Ls_Lawsuit_History_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Lawsuit_History_Set_Input>;
  where: Ls_Lawsuit_History_Bool_Exp;
};

/** input type for incrementing numeric columns in table "ls_lawsuit" */
export type Ls_Lawsuit_Inc_Input = {
  claim_amount?: Maybe<Scalars['numeric']>;
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  lawyer_fees?: Maybe<Scalars['numeric']>;
  other_expenses?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ls_lawsuit" */
export type Ls_Lawsuit_Insert_Input = {
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  court?: Maybe<Ls_Lawsuit_Court_Obj_Rel_Insert_Input>;
  court_file_id?: Maybe<Scalars['String']>;
  court_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  debtor?: Maybe<Ps_Debtor_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  interest_start_date?: Maybe<Scalars['date']>;
  lawyer?: Maybe<Ls_Lawsuit_Lawyer_Obj_Rel_Insert_Input>;
  lawyer_fees?: Maybe<Scalars['numeric']>;
  lawyer_file_id?: Maybe<Scalars['String']>;
  lawyer_id?: Maybe<Scalars['uuid']>;
  opponent_id?: Maybe<Scalars['uuid']>;
  other_expenses?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer = {
  __typename?: 'ls_lawsuit_lawyer';
  address: Scalars['String'];
  company_name: Scalars['String'];
  emails: Scalars['jsonb'];
  id: Scalars['uuid'];
  meta_data: Scalars['jsonb'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_LawyerEmailsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_LawyerMeta_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Aggregate = {
  __typename?: 'ls_lawsuit_lawyer_aggregate';
  aggregate?: Maybe<Ls_Lawsuit_Lawyer_Aggregate_Fields>;
  nodes: Array<Ls_Lawsuit_Lawyer>;
};

/** aggregate fields of "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Aggregate_Fields = {
  __typename?: 'ls_lawsuit_lawyer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ls_Lawsuit_Lawyer_Max_Fields>;
  min?: Maybe<Ls_Lawsuit_Lawyer_Min_Fields>;
};


/** aggregate fields of "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ls_Lawsuit_Lawyer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ls_Lawsuit_Lawyer_Append_Input = {
  emails?: Maybe<Scalars['jsonb']>;
  meta_data?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ls_lawsuit_lawyer". All fields are combined with a logical 'AND'. */
export type Ls_Lawsuit_Lawyer_Bool_Exp = {
  _and?: Maybe<Array<Ls_Lawsuit_Lawyer_Bool_Exp>>;
  _not?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
  _or?: Maybe<Array<Ls_Lawsuit_Lawyer_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  company_name?: Maybe<String_Comparison_Exp>;
  emails?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meta_data?: Maybe<Jsonb_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ls_lawsuit_lawyer" */
export enum Ls_Lawsuit_Lawyer_Constraint {
  /** unique or primary key constraint on columns "id" */
  LsLawsuitLawyerPkey = 'ls_lawsuit_lawyer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ls_Lawsuit_Lawyer_Delete_At_Path_Input = {
  emails?: Maybe<Array<Scalars['String']>>;
  meta_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ls_Lawsuit_Lawyer_Delete_Elem_Input = {
  emails?: Maybe<Scalars['Int']>;
  meta_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ls_Lawsuit_Lawyer_Delete_Key_Input = {
  emails?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  emails?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  meta_data?: Maybe<Scalars['jsonb']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ls_Lawsuit_Lawyer_Max_Fields = {
  __typename?: 'ls_lawsuit_lawyer_max_fields';
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ls_Lawsuit_Lawyer_Min_Fields = {
  __typename?: 'ls_lawsuit_lawyer_min_fields';
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Mutation_Response = {
  __typename?: 'ls_lawsuit_lawyer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Lawsuit_Lawyer>;
};

/** input type for inserting object relation for remote table "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Obj_Rel_Insert_Input = {
  data: Ls_Lawsuit_Lawyer_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ls_Lawsuit_Lawyer_On_Conflict>;
};

/** on_conflict condition type for table "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_On_Conflict = {
  constraint: Ls_Lawsuit_Lawyer_Constraint;
  update_columns?: Array<Ls_Lawsuit_Lawyer_Update_Column>;
  where?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_lawsuit_lawyer". */
export type Ls_Lawsuit_Lawyer_Order_By = {
  address?: Maybe<Order_By>;
  company_name?: Maybe<Order_By>;
  emails?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meta_data?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: ls_lawsuit_lawyer */
export type Ls_Lawsuit_Lawyer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ls_Lawsuit_Lawyer_Prepend_Input = {
  emails?: Maybe<Scalars['jsonb']>;
  meta_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "ls_lawsuit_lawyer" */
export enum Ls_Lawsuit_Lawyer_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  Emails = 'emails',
  /** column name */
  Id = 'id',
  /** column name */
  MetaData = 'meta_data',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Set_Input = {
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  emails?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  meta_data?: Maybe<Scalars['jsonb']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ls_lawsuit_lawyer" */
export type Ls_Lawsuit_Lawyer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Lawsuit_Lawyer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Lawsuit_Lawyer_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  emails?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  meta_data?: Maybe<Scalars['jsonb']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "ls_lawsuit_lawyer" */
export enum Ls_Lawsuit_Lawyer_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CompanyName = 'company_name',
  /** column name */
  Emails = 'emails',
  /** column name */
  Id = 'id',
  /** column name */
  MetaData = 'meta_data',
  /** column name */
  UserId = 'user_id'
}

export type Ls_Lawsuit_Lawyer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Ls_Lawsuit_Lawyer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Ls_Lawsuit_Lawyer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Ls_Lawsuit_Lawyer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Ls_Lawsuit_Lawyer_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Ls_Lawsuit_Lawyer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Lawsuit_Lawyer_Set_Input>;
  where: Ls_Lawsuit_Lawyer_Bool_Exp;
};

/** aggregate max on columns */
export type Ls_Lawsuit_Max_Fields = {
  __typename?: 'ls_lawsuit_max_fields';
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  court_file_id?: Maybe<Scalars['String']>;
  court_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  interest_start_date?: Maybe<Scalars['date']>;
  lawyer_fees?: Maybe<Scalars['numeric']>;
  lawyer_file_id?: Maybe<Scalars['String']>;
  lawyer_id?: Maybe<Scalars['uuid']>;
  opponent_id?: Maybe<Scalars['uuid']>;
  other_expenses?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ls_Lawsuit_Min_Fields = {
  __typename?: 'ls_lawsuit_min_fields';
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  court_file_id?: Maybe<Scalars['String']>;
  court_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  interest_start_date?: Maybe<Scalars['date']>;
  lawyer_fees?: Maybe<Scalars['numeric']>;
  lawyer_file_id?: Maybe<Scalars['String']>;
  lawyer_id?: Maybe<Scalars['uuid']>;
  opponent_id?: Maybe<Scalars['uuid']>;
  other_expenses?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ls_lawsuit" */
export type Ls_Lawsuit_Mutation_Response = {
  __typename?: 'ls_lawsuit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ls_Lawsuit>;
};

/** input type for inserting object relation for remote table "ls_lawsuit" */
export type Ls_Lawsuit_Obj_Rel_Insert_Input = {
  data: Ls_Lawsuit_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ls_Lawsuit_On_Conflict>;
};

/** on_conflict condition type for table "ls_lawsuit" */
export type Ls_Lawsuit_On_Conflict = {
  constraint: Ls_Lawsuit_Constraint;
  update_columns?: Array<Ls_Lawsuit_Update_Column>;
  where?: Maybe<Ls_Lawsuit_Bool_Exp>;
};

/** Ordering options when selecting data from "ls_lawsuit". */
export type Ls_Lawsuit_Order_By = {
  alias_id?: Maybe<Order_By>;
  claim_amount?: Maybe<Order_By>;
  court?: Maybe<Ls_Lawsuit_Court_Order_By>;
  court_file_id?: Maybe<Order_By>;
  court_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  debtor?: Maybe<Ps_Debtor_Order_By>;
  id?: Maybe<Order_By>;
  interest_amount?: Maybe<Order_By>;
  interest_rate?: Maybe<Order_By>;
  interest_start_date?: Maybe<Order_By>;
  lawyer?: Maybe<Ls_Lawsuit_Lawyer_Order_By>;
  lawyer_fees?: Maybe<Order_By>;
  lawyer_file_id?: Maybe<Order_By>;
  lawyer_id?: Maybe<Order_By>;
  opponent_id?: Maybe<Order_By>;
  other_expenses?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vertical?: Maybe<Order_By>;
};

/** primary key columns input for table: ls_lawsuit */
export type Ls_Lawsuit_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ls_lawsuit" */
export enum Ls_Lawsuit_Select_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CourtFileId = 'court_file_id',
  /** column name */
  CourtId = 'court_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  InterestStartDate = 'interest_start_date',
  /** column name */
  LawyerFees = 'lawyer_fees',
  /** column name */
  LawyerFileId = 'lawyer_file_id',
  /** column name */
  LawyerId = 'lawyer_id',
  /** column name */
  OpponentId = 'opponent_id',
  /** column name */
  OtherExpenses = 'other_expenses',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vertical = 'vertical'
}

/** input type for updating data in table "ls_lawsuit" */
export type Ls_Lawsuit_Set_Input = {
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  court_file_id?: Maybe<Scalars['String']>;
  court_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  interest_start_date?: Maybe<Scalars['date']>;
  lawyer_fees?: Maybe<Scalars['numeric']>;
  lawyer_file_id?: Maybe<Scalars['String']>;
  lawyer_id?: Maybe<Scalars['uuid']>;
  opponent_id?: Maybe<Scalars['uuid']>;
  other_expenses?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ls_Lawsuit_Stddev_Fields = {
  __typename?: 'ls_lawsuit_stddev_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  interest_amount?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  lawyer_fees?: Maybe<Scalars['Float']>;
  other_expenses?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ls_Lawsuit_Stddev_Pop_Fields = {
  __typename?: 'ls_lawsuit_stddev_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  interest_amount?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  lawyer_fees?: Maybe<Scalars['Float']>;
  other_expenses?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ls_Lawsuit_Stddev_Samp_Fields = {
  __typename?: 'ls_lawsuit_stddev_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  interest_amount?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  lawyer_fees?: Maybe<Scalars['Float']>;
  other_expenses?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ls_lawsuit" */
export type Ls_Lawsuit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ls_Lawsuit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ls_Lawsuit_Stream_Cursor_Value_Input = {
  alias_id?: Maybe<Scalars['String']>;
  claim_amount?: Maybe<Scalars['numeric']>;
  court_file_id?: Maybe<Scalars['String']>;
  court_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  interest_start_date?: Maybe<Scalars['date']>;
  lawyer_fees?: Maybe<Scalars['numeric']>;
  lawyer_file_id?: Maybe<Scalars['String']>;
  lawyer_id?: Maybe<Scalars['uuid']>;
  opponent_id?: Maybe<Scalars['uuid']>;
  other_expenses?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ls_Lawsuit_Sum_Fields = {
  __typename?: 'ls_lawsuit_sum_fields';
  claim_amount?: Maybe<Scalars['numeric']>;
  interest_amount?: Maybe<Scalars['numeric']>;
  interest_rate?: Maybe<Scalars['numeric']>;
  lawyer_fees?: Maybe<Scalars['numeric']>;
  other_expenses?: Maybe<Scalars['numeric']>;
};

/** update columns of table "ls_lawsuit" */
export enum Ls_Lawsuit_Update_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  ClaimAmount = 'claim_amount',
  /** column name */
  CourtFileId = 'court_file_id',
  /** column name */
  CourtId = 'court_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InterestAmount = 'interest_amount',
  /** column name */
  InterestRate = 'interest_rate',
  /** column name */
  InterestStartDate = 'interest_start_date',
  /** column name */
  LawyerFees = 'lawyer_fees',
  /** column name */
  LawyerFileId = 'lawyer_file_id',
  /** column name */
  LawyerId = 'lawyer_id',
  /** column name */
  OpponentId = 'opponent_id',
  /** column name */
  OtherExpenses = 'other_expenses',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Vertical = 'vertical'
}

export type Ls_Lawsuit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ls_Lawsuit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ls_Lawsuit_Set_Input>;
  where: Ls_Lawsuit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ls_Lawsuit_Var_Pop_Fields = {
  __typename?: 'ls_lawsuit_var_pop_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  interest_amount?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  lawyer_fees?: Maybe<Scalars['Float']>;
  other_expenses?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ls_Lawsuit_Var_Samp_Fields = {
  __typename?: 'ls_lawsuit_var_samp_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  interest_amount?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  lawyer_fees?: Maybe<Scalars['Float']>;
  other_expenses?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ls_Lawsuit_Variance_Fields = {
  __typename?: 'ls_lawsuit_variance_fields';
  claim_amount?: Maybe<Scalars['Float']>;
  interest_amount?: Maybe<Scalars['Float']>;
  interest_rate?: Maybe<Scalars['Float']>;
  lawyer_fees?: Maybe<Scalars['Float']>;
  other_expenses?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  admin_add_documents?: Maybe<AdminAddDocumentsOutput>;
  admin_approve_offer?: Maybe<AdminApproveOfferOutput>;
  admin_assignment_resend?: Maybe<AdminAssignmentResendOutput>;
  admin_cancel_offer?: Maybe<AdminCancelOfferOutput>;
  admin_case_comment_add?: Maybe<AdminCaseCommentAddOutput>;
  admin_case_delete?: Maybe<AdminCaseDeleteOutput>;
  admin_case_documents_complete?: Maybe<AdminCaseDocumentsCompleteOutput>;
  admin_case_rejection_revoke?: Maybe<AdminCaseRejectionRevokeOutput>;
  admin_case_revoke?: Maybe<AdminCaseRevokeOutput>;
  admin_confirm_rejection?: Maybe<AdminConfirmRejectionOutput>;
  admin_create_offer?: Maybe<AdminCreateOfferOutput>;
  admin_delete_documents?: Maybe<AdminDeleteDocumentsOutput>;
  admin_download_documents?: Maybe<AdminDownloadDocumentsOutput>;
  admin_fit_claim_update?: Maybe<AdminFitClaimUpdateOutput>;
  admin_gff_claim_update?: Maybe<AdminGffClaimUpdateOutput>;
  admin_handle_gdpr_deletion_request?: Maybe<AdminHandleGdprDeletionRequestOutput>;
  admin_offer_address_change?: Maybe<AdminOfferAddressChangeOutput>;
  admin_offer_delete?: Maybe<AdminOfferDeleteOutput>;
  admin_offer_resend?: Maybe<AdminOfferResendOutput>;
  admin_offer_send?: Maybe<AdminOfferSendOutput>;
  admin_pkv_claim_update?: Maybe<AdminPkvClaimUpdateOutput>;
  admin_refuse_offer?: Maybe<AdminRefuseOfferOutput>;
  admin_reject_case?: Maybe<AdminRejectCaseOutput>;
  admin_remove_cases_from_lawsuit?: Maybe<AdminRemoveCasesFromLawsuitOutput>;
  admin_request_documents?: Maybe<AdminRequestDocumentsOutput>;
  admin_resend_offer?: Maybe<AdminResendOfferOutput>;
  admin_send_offer?: Maybe<AdminSendOfferOutput>;
  admin_update_claim?: Maybe<AdminUpdateClaimOutput>;
  admin_update_comment?: Maybe<AdminUpdateCommentOutput>;
  admin_update_customer?: Maybe<AdminUpdateCustomerOutput>;
  admin_update_document?: Maybe<AdminUpdateDocumentOutput>;
  admin_update_document_order?: Maybe<AdminUpdateDocumentOrderOutput>;
  case_add_documents?: Maybe<CaseAddDocumentsOutput>;
  /** customer_accept_external_offer */
  customer_accept_external_offer?: Maybe<CustomerAcceptExternalOfferOutput>;
  customer_accept_offer?: Maybe<CustomerAcceptOfferOutput>;
  customer_add_documents?: Maybe<CustomerAddDocumentsOutput>;
  customer_submit_case?: Maybe<CustomerSubmitCaseOutput>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "api_keys" */
  delete_api_keys?: Maybe<Api_Keys_Mutation_Response>;
  /** delete single row from the table: "api_keys" */
  delete_api_keys_by_pk?: Maybe<Api_Keys>;
  /** delete data from the table: "assignment" */
  delete_assignment?: Maybe<Assignment_Mutation_Response>;
  /** delete single row from the table: "assignment" */
  delete_assignment_by_pk?: Maybe<Assignment>;
  /** delete data from the table: "assignment_state" */
  delete_assignment_state?: Maybe<Assignment_State_Mutation_Response>;
  /** delete single row from the table: "assignment_state" */
  delete_assignment_state_by_pk?: Maybe<Assignment_State>;
  /** delete data from the table: "bank_account" */
  delete_bank_account?: Maybe<Bank_Account_Mutation_Response>;
  /** delete single row from the table: "bank_account" */
  delete_bank_account_by_pk?: Maybe<Bank_Account>;
  /** delete data from the table: "base_case" */
  delete_base_case?: Maybe<Base_Case_Mutation_Response>;
  /** delete single row from the table: "base_case" */
  delete_base_case_by_pk?: Maybe<Base_Case>;
  /** delete data from the table: "base_case_document" */
  delete_base_case_document?: Maybe<Base_Case_Document_Mutation_Response>;
  /** delete single row from the table: "base_case_document" */
  delete_base_case_document_by_pk?: Maybe<Base_Case_Document>;
  /** delete data from the table: "base_case_document_category" */
  delete_base_case_document_category?: Maybe<Base_Case_Document_Category_Mutation_Response>;
  /** delete single row from the table: "base_case_document_category" */
  delete_base_case_document_category_by_pk?: Maybe<Base_Case_Document_Category>;
  /** delete data from the table: "base_offer" */
  delete_base_offer?: Maybe<Base_Offer_Mutation_Response>;
  /** delete single row from the table: "base_offer" */
  delete_base_offer_by_pk?: Maybe<Base_Offer>;
  /** delete data from the table: "case_comment" */
  delete_case_comment?: Maybe<Case_Comment_Mutation_Response>;
  /** delete single row from the table: "case_comment" */
  delete_case_comment_by_pk?: Maybe<Case_Comment>;
  /** delete data from the table: "customer" */
  delete_customer?: Maybe<Customer_Mutation_Response>;
  /** delete single row from the table: "customer" */
  delete_customer_by_pk?: Maybe<Customer>;
  /** delete data from the table: "email_queue" */
  delete_email_queue?: Maybe<Email_Queue_Mutation_Response>;
  /** delete single row from the table: "email_queue" */
  delete_email_queue_by_pk?: Maybe<Email_Queue>;
  /** delete data from the table: "fit_claim" */
  delete_fit_claim?: Maybe<Fit_Claim_Mutation_Response>;
  /** delete single row from the table: "fit_claim" */
  delete_fit_claim_by_pk?: Maybe<Fit_Claim>;
  /** delete data from the table: "fit_company" */
  delete_fit_company?: Maybe<Fit_Company_Mutation_Response>;
  /** delete single row from the table: "fit_company" */
  delete_fit_company_by_pk?: Maybe<Fit_Company>;
  /** delete data from the table: "fit_fts_transaction" */
  delete_fit_fts_transaction?: Maybe<Fit_Fts_Transaction_Mutation_Response>;
  /** delete single row from the table: "fit_fts_transaction" */
  delete_fit_fts_transaction_by_pk?: Maybe<Fit_Fts_Transaction>;
  /** delete data from the table: "fs_bank_transaction" */
  delete_fs_bank_transaction?: Maybe<Fs_Bank_Transaction_Mutation_Response>;
  /** delete single row from the table: "fs_bank_transaction" */
  delete_fs_bank_transaction_by_pk?: Maybe<Fs_Bank_Transaction>;
  /** delete data from the table: "fs_customer_payout" */
  delete_fs_customer_payout?: Maybe<Fs_Customer_Payout_Mutation_Response>;
  /** delete single row from the table: "fs_customer_payout" */
  delete_fs_customer_payout_by_pk?: Maybe<Fs_Customer_Payout>;
  /** delete data from the table: "fs_customer_payout_queue" */
  delete_fs_customer_payout_queue?: Maybe<Fs_Customer_Payout_Queue_Mutation_Response>;
  /** delete single row from the table: "fs_customer_payout_queue" */
  delete_fs_customer_payout_queue_by_pk?: Maybe<Fs_Customer_Payout_Queue>;
  /** delete data from the table: "gdpr_case" */
  delete_gdpr_case?: Maybe<Gdpr_Case_Mutation_Response>;
  /** delete single row from the table: "gdpr_case" */
  delete_gdpr_case_by_pk?: Maybe<Gdpr_Case>;
  /** delete data from the table: "gdpr_case_rejection_reason" */
  delete_gdpr_case_rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Mutation_Response>;
  /** delete single row from the table: "gdpr_case_rejection_reason" */
  delete_gdpr_case_rejection_reason_by_pk?: Maybe<Gdpr_Case_Rejection_Reason>;
  /** delete data from the table: "gdpr_case_state" */
  delete_gdpr_case_state?: Maybe<Gdpr_Case_State_Mutation_Response>;
  /** delete single row from the table: "gdpr_case_state" */
  delete_gdpr_case_state_by_pk?: Maybe<Gdpr_Case_State>;
  /** delete data from the table: "gdpr_claim" */
  delete_gdpr_claim?: Maybe<Gdpr_Claim_Mutation_Response>;
  /** delete single row from the table: "gdpr_claim" */
  delete_gdpr_claim_by_pk?: Maybe<Gdpr_Claim>;
  /** delete data from the table: "gdpr_claim_company" */
  delete_gdpr_claim_company?: Maybe<Gdpr_Claim_Company_Mutation_Response>;
  /** delete single row from the table: "gdpr_claim_company" */
  delete_gdpr_claim_company_by_pk?: Maybe<Gdpr_Claim_Company>;
  /** delete data from the table: "gdpr_company" */
  delete_gdpr_company?: Maybe<Gdpr_Company_Mutation_Response>;
  /** delete single row from the table: "gdpr_company" */
  delete_gdpr_company_by_pk?: Maybe<Gdpr_Company>;
  /** delete data from the table: "gdpr_offer" */
  delete_gdpr_offer?: Maybe<Gdpr_Offer_Mutation_Response>;
  /** delete data from the table: "gdpr_offer_assignment" */
  delete_gdpr_offer_assignment?: Maybe<Gdpr_Offer_Assignment_Mutation_Response>;
  /** delete single row from the table: "gdpr_offer_assignment" */
  delete_gdpr_offer_assignment_by_pk?: Maybe<Gdpr_Offer_Assignment>;
  /** delete single row from the table: "gdpr_offer" */
  delete_gdpr_offer_by_pk?: Maybe<Gdpr_Offer>;
  /** delete data from the table: "gdpr_offer_payment" */
  delete_gdpr_offer_payment?: Maybe<Gdpr_Offer_Payment_Mutation_Response>;
  /** delete single row from the table: "gdpr_offer_payment" */
  delete_gdpr_offer_payment_by_pk?: Maybe<Gdpr_Offer_Payment>;
  /** delete data from the table: "gdpr_offer_state" */
  delete_gdpr_offer_state?: Maybe<Gdpr_Offer_State_Mutation_Response>;
  /** delete single row from the table: "gdpr_offer_state" */
  delete_gdpr_offer_state_by_pk?: Maybe<Gdpr_Offer_State>;
  /** delete data from the table: "generated_report" */
  delete_generated_report?: Maybe<Generated_Report_Mutation_Response>;
  /** delete single row from the table: "generated_report" */
  delete_generated_report_by_pk?: Maybe<Generated_Report>;
  /** delete data from the table: "gff_airport" */
  delete_gff_airport?: Maybe<Gff_Airport_Mutation_Response>;
  /** delete single row from the table: "gff_airport" */
  delete_gff_airport_by_pk?: Maybe<Gff_Airport>;
  /** delete data from the table: "gff_claim" */
  delete_gff_claim?: Maybe<Gff_Claim_Mutation_Response>;
  /** delete single row from the table: "gff_claim" */
  delete_gff_claim_by_pk?: Maybe<Gff_Claim>;
  /** delete data from the table: "gff_company" */
  delete_gff_company?: Maybe<Gff_Company_Mutation_Response>;
  /** delete single row from the table: "gff_company" */
  delete_gff_company_by_pk?: Maybe<Gff_Company>;
  /** delete data from the table: "haveibeenpwned_api_log" */
  delete_haveibeenpwned_api_log?: Maybe<Haveibeenpwned_Api_Log_Mutation_Response>;
  /** delete single row from the table: "haveibeenpwned_api_log" */
  delete_haveibeenpwned_api_log_by_pk?: Maybe<Haveibeenpwned_Api_Log>;
  /** delete data from the table: "lost_assignment" */
  delete_lost_assignment?: Maybe<Lost_Assignment_Mutation_Response>;
  /** delete single row from the table: "lost_assignment" */
  delete_lost_assignment_by_pk?: Maybe<Lost_Assignment>;
  /** delete data from the table: "lost_assignment_state" */
  delete_lost_assignment_state?: Maybe<Lost_Assignment_State_Mutation_Response>;
  /** delete single row from the table: "lost_assignment_state" */
  delete_lost_assignment_state_by_pk?: Maybe<Lost_Assignment_State>;
  /** delete data from the table: "ls_case" */
  delete_ls_case?: Maybe<Ls_Case_Mutation_Response>;
  /** delete single row from the table: "ls_case" */
  delete_ls_case_by_pk?: Maybe<Ls_Case>;
  /** delete data from the table: "ls_lawsuit" */
  delete_ls_lawsuit?: Maybe<Ls_Lawsuit_Mutation_Response>;
  /** delete single row from the table: "ls_lawsuit" */
  delete_ls_lawsuit_by_pk?: Maybe<Ls_Lawsuit>;
  /** delete data from the table: "ls_lawsuit_court" */
  delete_ls_lawsuit_court?: Maybe<Ls_Lawsuit_Court_Mutation_Response>;
  /** delete single row from the table: "ls_lawsuit_court" */
  delete_ls_lawsuit_court_by_pk?: Maybe<Ls_Lawsuit_Court>;
  /** delete data from the table: "ls_lawsuit_document" */
  delete_ls_lawsuit_document?: Maybe<Ls_Lawsuit_Document_Mutation_Response>;
  /** delete single row from the table: "ls_lawsuit_document" */
  delete_ls_lawsuit_document_by_pk?: Maybe<Ls_Lawsuit_Document>;
  /** delete data from the table: "ls_lawsuit_document_category" */
  delete_ls_lawsuit_document_category?: Maybe<Ls_Lawsuit_Document_Category_Mutation_Response>;
  /** delete single row from the table: "ls_lawsuit_document_category" */
  delete_ls_lawsuit_document_category_by_pk?: Maybe<Ls_Lawsuit_Document_Category>;
  /** delete data from the table: "ls_lawsuit_expense" */
  delete_ls_lawsuit_expense?: Maybe<Ls_Lawsuit_Expense_Mutation_Response>;
  /** delete single row from the table: "ls_lawsuit_expense" */
  delete_ls_lawsuit_expense_by_pk?: Maybe<Ls_Lawsuit_Expense>;
  /** delete data from the table: "ls_lawsuit_history" */
  delete_ls_lawsuit_history?: Maybe<Ls_Lawsuit_History_Mutation_Response>;
  /** delete single row from the table: "ls_lawsuit_history" */
  delete_ls_lawsuit_history_by_pk?: Maybe<Ls_Lawsuit_History>;
  /** delete data from the table: "ls_lawsuit_lawyer" */
  delete_ls_lawsuit_lawyer?: Maybe<Ls_Lawsuit_Lawyer_Mutation_Response>;
  /** delete single row from the table: "ls_lawsuit_lawyer" */
  delete_ls_lawsuit_lawyer_by_pk?: Maybe<Ls_Lawsuit_Lawyer>;
  /** delete data from the table: "nflx_claim" */
  delete_nflx_claim?: Maybe<Nflx_Claim_Mutation_Response>;
  /** delete single row from the table: "nflx_claim" */
  delete_nflx_claim_by_pk?: Maybe<Nflx_Claim>;
  /** delete data from the table: "nflx_company" */
  delete_nflx_company?: Maybe<Nflx_Company_Mutation_Response>;
  /** delete single row from the table: "nflx_company" */
  delete_nflx_company_by_pk?: Maybe<Nflx_Company>;
  /** delete data from the table: "nrg_claim" */
  delete_nrg_claim?: Maybe<Nrg_Claim_Mutation_Response>;
  /** delete single row from the table: "nrg_claim" */
  delete_nrg_claim_by_pk?: Maybe<Nrg_Claim>;
  /** delete data from the table: "nrg_company" */
  delete_nrg_company?: Maybe<Nrg_Company_Mutation_Response>;
  /** delete single row from the table: "nrg_company" */
  delete_nrg_company_by_pk?: Maybe<Nrg_Company>;
  /** delete data from the table: "ocg_claim" */
  delete_ocg_claim?: Maybe<Ocg_Claim_Mutation_Response>;
  /** delete single row from the table: "ocg_claim" */
  delete_ocg_claim_by_pk?: Maybe<Ocg_Claim>;
  /** delete data from the table: "ocg_company" */
  delete_ocg_company?: Maybe<Ocg_Company_Mutation_Response>;
  /** delete single row from the table: "ocg_company" */
  delete_ocg_company_by_pk?: Maybe<Ocg_Company>;
  /** delete data from the table: "payment" */
  delete_payment?: Maybe<Payment_Mutation_Response>;
  /** delete single row from the table: "payment" */
  delete_payment_by_pk?: Maybe<Payment>;
  /** delete data from the table: "payment_state" */
  delete_payment_state?: Maybe<Payment_State_Mutation_Response>;
  /** delete single row from the table: "payment_state" */
  delete_payment_state_by_pk?: Maybe<Payment_State>;
  /** delete data from the table: "pkv_claim" */
  delete_pkv_claim?: Maybe<Pkv_Claim_Mutation_Response>;
  /** delete single row from the table: "pkv_claim" */
  delete_pkv_claim_by_pk?: Maybe<Pkv_Claim>;
  /** delete data from the table: "pkv_company" */
  delete_pkv_company?: Maybe<Pkv_Company_Mutation_Response>;
  /** delete single row from the table: "pkv_company" */
  delete_pkv_company_by_pk?: Maybe<Pkv_Company>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "ps_debtor" */
  delete_ps_debtor?: Maybe<Ps_Debtor_Mutation_Response>;
  /** delete single row from the table: "ps_debtor" */
  delete_ps_debtor_by_pk?: Maybe<Ps_Debtor>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "third_party_raw_data" */
  delete_third_party_raw_data?: Maybe<Third_Party_Raw_Data_Mutation_Response>;
  /** delete single row from the table: "third_party_raw_data" */
  delete_third_party_raw_data_by_pk?: Maybe<Third_Party_Raw_Data>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>;
  docs_sign_url?: Maybe<DocsSignUrlOutput>;
  gff_case_create?: Maybe<GffCaseCreateOutput>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "api_keys" */
  insert_api_keys?: Maybe<Api_Keys_Mutation_Response>;
  /** insert a single row into the table: "api_keys" */
  insert_api_keys_one?: Maybe<Api_Keys>;
  /** insert data into the table: "assignment" */
  insert_assignment?: Maybe<Assignment_Mutation_Response>;
  /** insert a single row into the table: "assignment" */
  insert_assignment_one?: Maybe<Assignment>;
  /** insert data into the table: "assignment_state" */
  insert_assignment_state?: Maybe<Assignment_State_Mutation_Response>;
  /** insert a single row into the table: "assignment_state" */
  insert_assignment_state_one?: Maybe<Assignment_State>;
  /** insert data into the table: "bank_account" */
  insert_bank_account?: Maybe<Bank_Account_Mutation_Response>;
  /** insert a single row into the table: "bank_account" */
  insert_bank_account_one?: Maybe<Bank_Account>;
  /** insert data into the table: "base_case" */
  insert_base_case?: Maybe<Base_Case_Mutation_Response>;
  /** insert data into the table: "base_case_document" */
  insert_base_case_document?: Maybe<Base_Case_Document_Mutation_Response>;
  /** insert data into the table: "base_case_document_category" */
  insert_base_case_document_category?: Maybe<Base_Case_Document_Category_Mutation_Response>;
  /** insert a single row into the table: "base_case_document_category" */
  insert_base_case_document_category_one?: Maybe<Base_Case_Document_Category>;
  /** insert a single row into the table: "base_case_document" */
  insert_base_case_document_one?: Maybe<Base_Case_Document>;
  /** insert a single row into the table: "base_case" */
  insert_base_case_one?: Maybe<Base_Case>;
  /** insert data into the table: "base_offer" */
  insert_base_offer?: Maybe<Base_Offer_Mutation_Response>;
  /** insert a single row into the table: "base_offer" */
  insert_base_offer_one?: Maybe<Base_Offer>;
  /** insert data into the table: "case_comment" */
  insert_case_comment?: Maybe<Case_Comment_Mutation_Response>;
  /** insert a single row into the table: "case_comment" */
  insert_case_comment_one?: Maybe<Case_Comment>;
  /** insert data into the table: "customer" */
  insert_customer?: Maybe<Customer_Mutation_Response>;
  /** insert a single row into the table: "customer" */
  insert_customer_one?: Maybe<Customer>;
  /** insert data into the table: "email_queue" */
  insert_email_queue?: Maybe<Email_Queue_Mutation_Response>;
  /** insert a single row into the table: "email_queue" */
  insert_email_queue_one?: Maybe<Email_Queue>;
  /** insert data into the table: "fit_claim" */
  insert_fit_claim?: Maybe<Fit_Claim_Mutation_Response>;
  /** insert a single row into the table: "fit_claim" */
  insert_fit_claim_one?: Maybe<Fit_Claim>;
  /** insert data into the table: "fit_company" */
  insert_fit_company?: Maybe<Fit_Company_Mutation_Response>;
  /** insert a single row into the table: "fit_company" */
  insert_fit_company_one?: Maybe<Fit_Company>;
  /** insert data into the table: "fit_fts_transaction" */
  insert_fit_fts_transaction?: Maybe<Fit_Fts_Transaction_Mutation_Response>;
  /** insert a single row into the table: "fit_fts_transaction" */
  insert_fit_fts_transaction_one?: Maybe<Fit_Fts_Transaction>;
  /** insert data into the table: "fs_bank_transaction" */
  insert_fs_bank_transaction?: Maybe<Fs_Bank_Transaction_Mutation_Response>;
  /** insert a single row into the table: "fs_bank_transaction" */
  insert_fs_bank_transaction_one?: Maybe<Fs_Bank_Transaction>;
  /** insert data into the table: "fs_customer_payout" */
  insert_fs_customer_payout?: Maybe<Fs_Customer_Payout_Mutation_Response>;
  /** insert a single row into the table: "fs_customer_payout" */
  insert_fs_customer_payout_one?: Maybe<Fs_Customer_Payout>;
  /** insert data into the table: "fs_customer_payout_queue" */
  insert_fs_customer_payout_queue?: Maybe<Fs_Customer_Payout_Queue_Mutation_Response>;
  /** insert a single row into the table: "fs_customer_payout_queue" */
  insert_fs_customer_payout_queue_one?: Maybe<Fs_Customer_Payout_Queue>;
  /** insert data into the table: "gdpr_case" */
  insert_gdpr_case?: Maybe<Gdpr_Case_Mutation_Response>;
  /** insert a single row into the table: "gdpr_case" */
  insert_gdpr_case_one?: Maybe<Gdpr_Case>;
  /** insert data into the table: "gdpr_case_rejection_reason" */
  insert_gdpr_case_rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Mutation_Response>;
  /** insert a single row into the table: "gdpr_case_rejection_reason" */
  insert_gdpr_case_rejection_reason_one?: Maybe<Gdpr_Case_Rejection_Reason>;
  /** insert data into the table: "gdpr_case_state" */
  insert_gdpr_case_state?: Maybe<Gdpr_Case_State_Mutation_Response>;
  /** insert a single row into the table: "gdpr_case_state" */
  insert_gdpr_case_state_one?: Maybe<Gdpr_Case_State>;
  /** insert data into the table: "gdpr_claim" */
  insert_gdpr_claim?: Maybe<Gdpr_Claim_Mutation_Response>;
  /** insert data into the table: "gdpr_claim_company" */
  insert_gdpr_claim_company?: Maybe<Gdpr_Claim_Company_Mutation_Response>;
  /** insert a single row into the table: "gdpr_claim_company" */
  insert_gdpr_claim_company_one?: Maybe<Gdpr_Claim_Company>;
  /** insert a single row into the table: "gdpr_claim" */
  insert_gdpr_claim_one?: Maybe<Gdpr_Claim>;
  /** insert data into the table: "gdpr_company" */
  insert_gdpr_company?: Maybe<Gdpr_Company_Mutation_Response>;
  /** insert a single row into the table: "gdpr_company" */
  insert_gdpr_company_one?: Maybe<Gdpr_Company>;
  /** insert data into the table: "gdpr_offer" */
  insert_gdpr_offer?: Maybe<Gdpr_Offer_Mutation_Response>;
  /** insert data into the table: "gdpr_offer_assignment" */
  insert_gdpr_offer_assignment?: Maybe<Gdpr_Offer_Assignment_Mutation_Response>;
  /** insert a single row into the table: "gdpr_offer_assignment" */
  insert_gdpr_offer_assignment_one?: Maybe<Gdpr_Offer_Assignment>;
  /** insert a single row into the table: "gdpr_offer" */
  insert_gdpr_offer_one?: Maybe<Gdpr_Offer>;
  /** insert data into the table: "gdpr_offer_payment" */
  insert_gdpr_offer_payment?: Maybe<Gdpr_Offer_Payment_Mutation_Response>;
  /** insert a single row into the table: "gdpr_offer_payment" */
  insert_gdpr_offer_payment_one?: Maybe<Gdpr_Offer_Payment>;
  /** insert data into the table: "gdpr_offer_state" */
  insert_gdpr_offer_state?: Maybe<Gdpr_Offer_State_Mutation_Response>;
  /** insert a single row into the table: "gdpr_offer_state" */
  insert_gdpr_offer_state_one?: Maybe<Gdpr_Offer_State>;
  /** insert data into the table: "generated_report" */
  insert_generated_report?: Maybe<Generated_Report_Mutation_Response>;
  /** insert a single row into the table: "generated_report" */
  insert_generated_report_one?: Maybe<Generated_Report>;
  /** insert data into the table: "gff_airport" */
  insert_gff_airport?: Maybe<Gff_Airport_Mutation_Response>;
  /** insert a single row into the table: "gff_airport" */
  insert_gff_airport_one?: Maybe<Gff_Airport>;
  /** insert data into the table: "gff_claim" */
  insert_gff_claim?: Maybe<Gff_Claim_Mutation_Response>;
  /** insert a single row into the table: "gff_claim" */
  insert_gff_claim_one?: Maybe<Gff_Claim>;
  /** insert data into the table: "gff_company" */
  insert_gff_company?: Maybe<Gff_Company_Mutation_Response>;
  /** insert a single row into the table: "gff_company" */
  insert_gff_company_one?: Maybe<Gff_Company>;
  /** insert data into the table: "haveibeenpwned_api_log" */
  insert_haveibeenpwned_api_log?: Maybe<Haveibeenpwned_Api_Log_Mutation_Response>;
  /** insert a single row into the table: "haveibeenpwned_api_log" */
  insert_haveibeenpwned_api_log_one?: Maybe<Haveibeenpwned_Api_Log>;
  /** insert data into the table: "lost_assignment" */
  insert_lost_assignment?: Maybe<Lost_Assignment_Mutation_Response>;
  /** insert a single row into the table: "lost_assignment" */
  insert_lost_assignment_one?: Maybe<Lost_Assignment>;
  /** insert data into the table: "lost_assignment_state" */
  insert_lost_assignment_state?: Maybe<Lost_Assignment_State_Mutation_Response>;
  /** insert a single row into the table: "lost_assignment_state" */
  insert_lost_assignment_state_one?: Maybe<Lost_Assignment_State>;
  /** insert data into the table: "ls_case" */
  insert_ls_case?: Maybe<Ls_Case_Mutation_Response>;
  /** insert a single row into the table: "ls_case" */
  insert_ls_case_one?: Maybe<Ls_Case>;
  /** insert data into the table: "ls_lawsuit" */
  insert_ls_lawsuit?: Maybe<Ls_Lawsuit_Mutation_Response>;
  /** insert data into the table: "ls_lawsuit_court" */
  insert_ls_lawsuit_court?: Maybe<Ls_Lawsuit_Court_Mutation_Response>;
  /** insert a single row into the table: "ls_lawsuit_court" */
  insert_ls_lawsuit_court_one?: Maybe<Ls_Lawsuit_Court>;
  /** insert data into the table: "ls_lawsuit_document" */
  insert_ls_lawsuit_document?: Maybe<Ls_Lawsuit_Document_Mutation_Response>;
  /** insert data into the table: "ls_lawsuit_document_category" */
  insert_ls_lawsuit_document_category?: Maybe<Ls_Lawsuit_Document_Category_Mutation_Response>;
  /** insert a single row into the table: "ls_lawsuit_document_category" */
  insert_ls_lawsuit_document_category_one?: Maybe<Ls_Lawsuit_Document_Category>;
  /** insert a single row into the table: "ls_lawsuit_document" */
  insert_ls_lawsuit_document_one?: Maybe<Ls_Lawsuit_Document>;
  /** insert data into the table: "ls_lawsuit_expense" */
  insert_ls_lawsuit_expense?: Maybe<Ls_Lawsuit_Expense_Mutation_Response>;
  /** insert a single row into the table: "ls_lawsuit_expense" */
  insert_ls_lawsuit_expense_one?: Maybe<Ls_Lawsuit_Expense>;
  /** insert data into the table: "ls_lawsuit_history" */
  insert_ls_lawsuit_history?: Maybe<Ls_Lawsuit_History_Mutation_Response>;
  /** insert a single row into the table: "ls_lawsuit_history" */
  insert_ls_lawsuit_history_one?: Maybe<Ls_Lawsuit_History>;
  /** insert data into the table: "ls_lawsuit_lawyer" */
  insert_ls_lawsuit_lawyer?: Maybe<Ls_Lawsuit_Lawyer_Mutation_Response>;
  /** insert a single row into the table: "ls_lawsuit_lawyer" */
  insert_ls_lawsuit_lawyer_one?: Maybe<Ls_Lawsuit_Lawyer>;
  /** insert a single row into the table: "ls_lawsuit" */
  insert_ls_lawsuit_one?: Maybe<Ls_Lawsuit>;
  /** insert data into the table: "nflx_claim" */
  insert_nflx_claim?: Maybe<Nflx_Claim_Mutation_Response>;
  /** insert a single row into the table: "nflx_claim" */
  insert_nflx_claim_one?: Maybe<Nflx_Claim>;
  /** insert data into the table: "nflx_company" */
  insert_nflx_company?: Maybe<Nflx_Company_Mutation_Response>;
  /** insert a single row into the table: "nflx_company" */
  insert_nflx_company_one?: Maybe<Nflx_Company>;
  /** insert data into the table: "nrg_claim" */
  insert_nrg_claim?: Maybe<Nrg_Claim_Mutation_Response>;
  /** insert a single row into the table: "nrg_claim" */
  insert_nrg_claim_one?: Maybe<Nrg_Claim>;
  /** insert data into the table: "nrg_company" */
  insert_nrg_company?: Maybe<Nrg_Company_Mutation_Response>;
  /** insert a single row into the table: "nrg_company" */
  insert_nrg_company_one?: Maybe<Nrg_Company>;
  /** insert data into the table: "ocg_claim" */
  insert_ocg_claim?: Maybe<Ocg_Claim_Mutation_Response>;
  /** insert a single row into the table: "ocg_claim" */
  insert_ocg_claim_one?: Maybe<Ocg_Claim>;
  /** insert data into the table: "ocg_company" */
  insert_ocg_company?: Maybe<Ocg_Company_Mutation_Response>;
  /** insert a single row into the table: "ocg_company" */
  insert_ocg_company_one?: Maybe<Ocg_Company>;
  /** insert data into the table: "payment" */
  insert_payment?: Maybe<Payment_Mutation_Response>;
  /** insert a single row into the table: "payment" */
  insert_payment_one?: Maybe<Payment>;
  /** insert data into the table: "payment_state" */
  insert_payment_state?: Maybe<Payment_State_Mutation_Response>;
  /** insert a single row into the table: "payment_state" */
  insert_payment_state_one?: Maybe<Payment_State>;
  /** insert data into the table: "pkv_claim" */
  insert_pkv_claim?: Maybe<Pkv_Claim_Mutation_Response>;
  /** insert a single row into the table: "pkv_claim" */
  insert_pkv_claim_one?: Maybe<Pkv_Claim>;
  /** insert data into the table: "pkv_company" */
  insert_pkv_company?: Maybe<Pkv_Company_Mutation_Response>;
  /** insert a single row into the table: "pkv_company" */
  insert_pkv_company_one?: Maybe<Pkv_Company>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "ps_debtor" */
  insert_ps_debtor?: Maybe<Ps_Debtor_Mutation_Response>;
  /** insert a single row into the table: "ps_debtor" */
  insert_ps_debtor_one?: Maybe<Ps_Debtor>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "third_party_raw_data" */
  insert_third_party_raw_data?: Maybe<Third_Party_Raw_Data_Mutation_Response>;
  /** insert a single row into the table: "third_party_raw_data" */
  insert_third_party_raw_data_one?: Maybe<Third_Party_Raw_Data>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>;
  lawsuitnow_add_documents?: Maybe<LawsuitnowAddDocumentsOutput>;
  lawsuitnow_add_event?: Maybe<LawsuitnowAddEventOutput>;
  /** Soft deletes documents belonging to a lawsuit */
  lawsuitnow_delete_documents?: Maybe<LawsuitnowDeleteDocumentsOutput>;
  /** Downloads all the lawsuit now documents as a zip */
  lawsuitnow_download_documents?: Maybe<LawsuitnowDownloadDocumentsOutput>;
  /** Get signed URL for documents and inserts a new for each document in ls_lawsuit_document */
  lawsuitnow_sign_document_urls?: Maybe<LawsuitnowSignDocumentUrlsOutput>;
  lawsuitnow_update_cases?: Maybe<LawsuitnowUpdateCasesOutput>;
  /** Updates the names and categories of lawsuit documents */
  lawsuitnow_update_documents?: Maybe<LawsuitnowUpdateDocumentsOutput>;
  lawsuitnow_update_lawsuit?: Maybe<LawsuitnowUpdateLawsuitOutput>;
  login?: Maybe<LoginOutput>;
  ocg_case_create?: Maybe<OcgCaseCreateOutput>;
  offer_accept?: Maybe<OfferAcceptOutput>;
  pkv_case_create?: Maybe<PkvCaseCreateOutput>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "api_keys" */
  update_api_keys?: Maybe<Api_Keys_Mutation_Response>;
  /** update single row of the table: "api_keys" */
  update_api_keys_by_pk?: Maybe<Api_Keys>;
  /** update multiples rows of table: "api_keys" */
  update_api_keys_many?: Maybe<Array<Maybe<Api_Keys_Mutation_Response>>>;
  /** update data of the table: "assignment" */
  update_assignment?: Maybe<Assignment_Mutation_Response>;
  /** update single row of the table: "assignment" */
  update_assignment_by_pk?: Maybe<Assignment>;
  /** update multiples rows of table: "assignment" */
  update_assignment_many?: Maybe<Array<Maybe<Assignment_Mutation_Response>>>;
  /** update data of the table: "assignment_state" */
  update_assignment_state?: Maybe<Assignment_State_Mutation_Response>;
  /** update single row of the table: "assignment_state" */
  update_assignment_state_by_pk?: Maybe<Assignment_State>;
  /** update multiples rows of table: "assignment_state" */
  update_assignment_state_many?: Maybe<Array<Maybe<Assignment_State_Mutation_Response>>>;
  /** update data of the table: "bank_account" */
  update_bank_account?: Maybe<Bank_Account_Mutation_Response>;
  /** update single row of the table: "bank_account" */
  update_bank_account_by_pk?: Maybe<Bank_Account>;
  /** update multiples rows of table: "bank_account" */
  update_bank_account_many?: Maybe<Array<Maybe<Bank_Account_Mutation_Response>>>;
  /** update data of the table: "base_case" */
  update_base_case?: Maybe<Base_Case_Mutation_Response>;
  /** update single row of the table: "base_case" */
  update_base_case_by_pk?: Maybe<Base_Case>;
  /** update data of the table: "base_case_document" */
  update_base_case_document?: Maybe<Base_Case_Document_Mutation_Response>;
  /** update single row of the table: "base_case_document" */
  update_base_case_document_by_pk?: Maybe<Base_Case_Document>;
  /** update data of the table: "base_case_document_category" */
  update_base_case_document_category?: Maybe<Base_Case_Document_Category_Mutation_Response>;
  /** update single row of the table: "base_case_document_category" */
  update_base_case_document_category_by_pk?: Maybe<Base_Case_Document_Category>;
  /** update multiples rows of table: "base_case_document_category" */
  update_base_case_document_category_many?: Maybe<Array<Maybe<Base_Case_Document_Category_Mutation_Response>>>;
  /** update multiples rows of table: "base_case_document" */
  update_base_case_document_many?: Maybe<Array<Maybe<Base_Case_Document_Mutation_Response>>>;
  /** update multiples rows of table: "base_case" */
  update_base_case_many?: Maybe<Array<Maybe<Base_Case_Mutation_Response>>>;
  /** update data of the table: "base_offer" */
  update_base_offer?: Maybe<Base_Offer_Mutation_Response>;
  /** update single row of the table: "base_offer" */
  update_base_offer_by_pk?: Maybe<Base_Offer>;
  /** update multiples rows of table: "base_offer" */
  update_base_offer_many?: Maybe<Array<Maybe<Base_Offer_Mutation_Response>>>;
  /** update data of the table: "case_comment" */
  update_case_comment?: Maybe<Case_Comment_Mutation_Response>;
  /** update single row of the table: "case_comment" */
  update_case_comment_by_pk?: Maybe<Case_Comment>;
  /** update multiples rows of table: "case_comment" */
  update_case_comment_many?: Maybe<Array<Maybe<Case_Comment_Mutation_Response>>>;
  /** update data of the table: "customer" */
  update_customer?: Maybe<Customer_Mutation_Response>;
  /** update single row of the table: "customer" */
  update_customer_by_pk?: Maybe<Customer>;
  /** update multiples rows of table: "customer" */
  update_customer_many?: Maybe<Array<Maybe<Customer_Mutation_Response>>>;
  /** update data of the table: "email_queue" */
  update_email_queue?: Maybe<Email_Queue_Mutation_Response>;
  /** update single row of the table: "email_queue" */
  update_email_queue_by_pk?: Maybe<Email_Queue>;
  /** update multiples rows of table: "email_queue" */
  update_email_queue_many?: Maybe<Array<Maybe<Email_Queue_Mutation_Response>>>;
  /** update data of the table: "fit_claim" */
  update_fit_claim?: Maybe<Fit_Claim_Mutation_Response>;
  /** update single row of the table: "fit_claim" */
  update_fit_claim_by_pk?: Maybe<Fit_Claim>;
  /** update multiples rows of table: "fit_claim" */
  update_fit_claim_many?: Maybe<Array<Maybe<Fit_Claim_Mutation_Response>>>;
  /** update data of the table: "fit_company" */
  update_fit_company?: Maybe<Fit_Company_Mutation_Response>;
  /** update single row of the table: "fit_company" */
  update_fit_company_by_pk?: Maybe<Fit_Company>;
  /** update multiples rows of table: "fit_company" */
  update_fit_company_many?: Maybe<Array<Maybe<Fit_Company_Mutation_Response>>>;
  /** update data of the table: "fit_fts_transaction" */
  update_fit_fts_transaction?: Maybe<Fit_Fts_Transaction_Mutation_Response>;
  /** update single row of the table: "fit_fts_transaction" */
  update_fit_fts_transaction_by_pk?: Maybe<Fit_Fts_Transaction>;
  /** update multiples rows of table: "fit_fts_transaction" */
  update_fit_fts_transaction_many?: Maybe<Array<Maybe<Fit_Fts_Transaction_Mutation_Response>>>;
  /** update data of the table: "fs_bank_transaction" */
  update_fs_bank_transaction?: Maybe<Fs_Bank_Transaction_Mutation_Response>;
  /** update single row of the table: "fs_bank_transaction" */
  update_fs_bank_transaction_by_pk?: Maybe<Fs_Bank_Transaction>;
  /** update multiples rows of table: "fs_bank_transaction" */
  update_fs_bank_transaction_many?: Maybe<Array<Maybe<Fs_Bank_Transaction_Mutation_Response>>>;
  /** update data of the table: "fs_customer_payout" */
  update_fs_customer_payout?: Maybe<Fs_Customer_Payout_Mutation_Response>;
  /** update single row of the table: "fs_customer_payout" */
  update_fs_customer_payout_by_pk?: Maybe<Fs_Customer_Payout>;
  /** update multiples rows of table: "fs_customer_payout" */
  update_fs_customer_payout_many?: Maybe<Array<Maybe<Fs_Customer_Payout_Mutation_Response>>>;
  /** update data of the table: "fs_customer_payout_queue" */
  update_fs_customer_payout_queue?: Maybe<Fs_Customer_Payout_Queue_Mutation_Response>;
  /** update single row of the table: "fs_customer_payout_queue" */
  update_fs_customer_payout_queue_by_pk?: Maybe<Fs_Customer_Payout_Queue>;
  /** update multiples rows of table: "fs_customer_payout_queue" */
  update_fs_customer_payout_queue_many?: Maybe<Array<Maybe<Fs_Customer_Payout_Queue_Mutation_Response>>>;
  /** update data of the table: "gdpr_case" */
  update_gdpr_case?: Maybe<Gdpr_Case_Mutation_Response>;
  /** update single row of the table: "gdpr_case" */
  update_gdpr_case_by_pk?: Maybe<Gdpr_Case>;
  /** update multiples rows of table: "gdpr_case" */
  update_gdpr_case_many?: Maybe<Array<Maybe<Gdpr_Case_Mutation_Response>>>;
  /** update data of the table: "gdpr_case_rejection_reason" */
  update_gdpr_case_rejection_reason?: Maybe<Gdpr_Case_Rejection_Reason_Mutation_Response>;
  /** update single row of the table: "gdpr_case_rejection_reason" */
  update_gdpr_case_rejection_reason_by_pk?: Maybe<Gdpr_Case_Rejection_Reason>;
  /** update multiples rows of table: "gdpr_case_rejection_reason" */
  update_gdpr_case_rejection_reason_many?: Maybe<Array<Maybe<Gdpr_Case_Rejection_Reason_Mutation_Response>>>;
  /** update data of the table: "gdpr_case_state" */
  update_gdpr_case_state?: Maybe<Gdpr_Case_State_Mutation_Response>;
  /** update single row of the table: "gdpr_case_state" */
  update_gdpr_case_state_by_pk?: Maybe<Gdpr_Case_State>;
  /** update multiples rows of table: "gdpr_case_state" */
  update_gdpr_case_state_many?: Maybe<Array<Maybe<Gdpr_Case_State_Mutation_Response>>>;
  /** update data of the table: "gdpr_claim" */
  update_gdpr_claim?: Maybe<Gdpr_Claim_Mutation_Response>;
  /** update single row of the table: "gdpr_claim" */
  update_gdpr_claim_by_pk?: Maybe<Gdpr_Claim>;
  /** update data of the table: "gdpr_claim_company" */
  update_gdpr_claim_company?: Maybe<Gdpr_Claim_Company_Mutation_Response>;
  /** update single row of the table: "gdpr_claim_company" */
  update_gdpr_claim_company_by_pk?: Maybe<Gdpr_Claim_Company>;
  /** update multiples rows of table: "gdpr_claim_company" */
  update_gdpr_claim_company_many?: Maybe<Array<Maybe<Gdpr_Claim_Company_Mutation_Response>>>;
  /** update multiples rows of table: "gdpr_claim" */
  update_gdpr_claim_many?: Maybe<Array<Maybe<Gdpr_Claim_Mutation_Response>>>;
  /** update data of the table: "gdpr_company" */
  update_gdpr_company?: Maybe<Gdpr_Company_Mutation_Response>;
  /** update single row of the table: "gdpr_company" */
  update_gdpr_company_by_pk?: Maybe<Gdpr_Company>;
  /** update multiples rows of table: "gdpr_company" */
  update_gdpr_company_many?: Maybe<Array<Maybe<Gdpr_Company_Mutation_Response>>>;
  /** update data of the table: "gdpr_offer" */
  update_gdpr_offer?: Maybe<Gdpr_Offer_Mutation_Response>;
  /** update data of the table: "gdpr_offer_assignment" */
  update_gdpr_offer_assignment?: Maybe<Gdpr_Offer_Assignment_Mutation_Response>;
  /** update single row of the table: "gdpr_offer_assignment" */
  update_gdpr_offer_assignment_by_pk?: Maybe<Gdpr_Offer_Assignment>;
  /** update multiples rows of table: "gdpr_offer_assignment" */
  update_gdpr_offer_assignment_many?: Maybe<Array<Maybe<Gdpr_Offer_Assignment_Mutation_Response>>>;
  /** update single row of the table: "gdpr_offer" */
  update_gdpr_offer_by_pk?: Maybe<Gdpr_Offer>;
  /** update multiples rows of table: "gdpr_offer" */
  update_gdpr_offer_many?: Maybe<Array<Maybe<Gdpr_Offer_Mutation_Response>>>;
  /** update data of the table: "gdpr_offer_payment" */
  update_gdpr_offer_payment?: Maybe<Gdpr_Offer_Payment_Mutation_Response>;
  /** update single row of the table: "gdpr_offer_payment" */
  update_gdpr_offer_payment_by_pk?: Maybe<Gdpr_Offer_Payment>;
  /** update multiples rows of table: "gdpr_offer_payment" */
  update_gdpr_offer_payment_many?: Maybe<Array<Maybe<Gdpr_Offer_Payment_Mutation_Response>>>;
  /** update data of the table: "gdpr_offer_state" */
  update_gdpr_offer_state?: Maybe<Gdpr_Offer_State_Mutation_Response>;
  /** update single row of the table: "gdpr_offer_state" */
  update_gdpr_offer_state_by_pk?: Maybe<Gdpr_Offer_State>;
  /** update multiples rows of table: "gdpr_offer_state" */
  update_gdpr_offer_state_many?: Maybe<Array<Maybe<Gdpr_Offer_State_Mutation_Response>>>;
  /** update data of the table: "generated_report" */
  update_generated_report?: Maybe<Generated_Report_Mutation_Response>;
  /** update single row of the table: "generated_report" */
  update_generated_report_by_pk?: Maybe<Generated_Report>;
  /** update multiples rows of table: "generated_report" */
  update_generated_report_many?: Maybe<Array<Maybe<Generated_Report_Mutation_Response>>>;
  /** update data of the table: "gff_airport" */
  update_gff_airport?: Maybe<Gff_Airport_Mutation_Response>;
  /** update single row of the table: "gff_airport" */
  update_gff_airport_by_pk?: Maybe<Gff_Airport>;
  /** update multiples rows of table: "gff_airport" */
  update_gff_airport_many?: Maybe<Array<Maybe<Gff_Airport_Mutation_Response>>>;
  /** update data of the table: "gff_claim" */
  update_gff_claim?: Maybe<Gff_Claim_Mutation_Response>;
  /** update single row of the table: "gff_claim" */
  update_gff_claim_by_pk?: Maybe<Gff_Claim>;
  /** update multiples rows of table: "gff_claim" */
  update_gff_claim_many?: Maybe<Array<Maybe<Gff_Claim_Mutation_Response>>>;
  /** update data of the table: "gff_company" */
  update_gff_company?: Maybe<Gff_Company_Mutation_Response>;
  /** update single row of the table: "gff_company" */
  update_gff_company_by_pk?: Maybe<Gff_Company>;
  /** update multiples rows of table: "gff_company" */
  update_gff_company_many?: Maybe<Array<Maybe<Gff_Company_Mutation_Response>>>;
  /** update data of the table: "haveibeenpwned_api_log" */
  update_haveibeenpwned_api_log?: Maybe<Haveibeenpwned_Api_Log_Mutation_Response>;
  /** update single row of the table: "haveibeenpwned_api_log" */
  update_haveibeenpwned_api_log_by_pk?: Maybe<Haveibeenpwned_Api_Log>;
  /** update multiples rows of table: "haveibeenpwned_api_log" */
  update_haveibeenpwned_api_log_many?: Maybe<Array<Maybe<Haveibeenpwned_Api_Log_Mutation_Response>>>;
  /** update data of the table: "lost_assignment" */
  update_lost_assignment?: Maybe<Lost_Assignment_Mutation_Response>;
  /** update single row of the table: "lost_assignment" */
  update_lost_assignment_by_pk?: Maybe<Lost_Assignment>;
  /** update multiples rows of table: "lost_assignment" */
  update_lost_assignment_many?: Maybe<Array<Maybe<Lost_Assignment_Mutation_Response>>>;
  /** update data of the table: "lost_assignment_state" */
  update_lost_assignment_state?: Maybe<Lost_Assignment_State_Mutation_Response>;
  /** update single row of the table: "lost_assignment_state" */
  update_lost_assignment_state_by_pk?: Maybe<Lost_Assignment_State>;
  /** update multiples rows of table: "lost_assignment_state" */
  update_lost_assignment_state_many?: Maybe<Array<Maybe<Lost_Assignment_State_Mutation_Response>>>;
  /** update data of the table: "ls_case" */
  update_ls_case?: Maybe<Ls_Case_Mutation_Response>;
  /** update single row of the table: "ls_case" */
  update_ls_case_by_pk?: Maybe<Ls_Case>;
  /** update multiples rows of table: "ls_case" */
  update_ls_case_many?: Maybe<Array<Maybe<Ls_Case_Mutation_Response>>>;
  /** update data of the table: "ls_lawsuit" */
  update_ls_lawsuit?: Maybe<Ls_Lawsuit_Mutation_Response>;
  /** update single row of the table: "ls_lawsuit" */
  update_ls_lawsuit_by_pk?: Maybe<Ls_Lawsuit>;
  /** update data of the table: "ls_lawsuit_court" */
  update_ls_lawsuit_court?: Maybe<Ls_Lawsuit_Court_Mutation_Response>;
  /** update single row of the table: "ls_lawsuit_court" */
  update_ls_lawsuit_court_by_pk?: Maybe<Ls_Lawsuit_Court>;
  /** update multiples rows of table: "ls_lawsuit_court" */
  update_ls_lawsuit_court_many?: Maybe<Array<Maybe<Ls_Lawsuit_Court_Mutation_Response>>>;
  /** update data of the table: "ls_lawsuit_document" */
  update_ls_lawsuit_document?: Maybe<Ls_Lawsuit_Document_Mutation_Response>;
  /** update single row of the table: "ls_lawsuit_document" */
  update_ls_lawsuit_document_by_pk?: Maybe<Ls_Lawsuit_Document>;
  /** update data of the table: "ls_lawsuit_document_category" */
  update_ls_lawsuit_document_category?: Maybe<Ls_Lawsuit_Document_Category_Mutation_Response>;
  /** update single row of the table: "ls_lawsuit_document_category" */
  update_ls_lawsuit_document_category_by_pk?: Maybe<Ls_Lawsuit_Document_Category>;
  /** update multiples rows of table: "ls_lawsuit_document_category" */
  update_ls_lawsuit_document_category_many?: Maybe<Array<Maybe<Ls_Lawsuit_Document_Category_Mutation_Response>>>;
  /** update multiples rows of table: "ls_lawsuit_document" */
  update_ls_lawsuit_document_many?: Maybe<Array<Maybe<Ls_Lawsuit_Document_Mutation_Response>>>;
  /** update data of the table: "ls_lawsuit_expense" */
  update_ls_lawsuit_expense?: Maybe<Ls_Lawsuit_Expense_Mutation_Response>;
  /** update single row of the table: "ls_lawsuit_expense" */
  update_ls_lawsuit_expense_by_pk?: Maybe<Ls_Lawsuit_Expense>;
  /** update multiples rows of table: "ls_lawsuit_expense" */
  update_ls_lawsuit_expense_many?: Maybe<Array<Maybe<Ls_Lawsuit_Expense_Mutation_Response>>>;
  /** update data of the table: "ls_lawsuit_history" */
  update_ls_lawsuit_history?: Maybe<Ls_Lawsuit_History_Mutation_Response>;
  /** update single row of the table: "ls_lawsuit_history" */
  update_ls_lawsuit_history_by_pk?: Maybe<Ls_Lawsuit_History>;
  /** update multiples rows of table: "ls_lawsuit_history" */
  update_ls_lawsuit_history_many?: Maybe<Array<Maybe<Ls_Lawsuit_History_Mutation_Response>>>;
  /** update data of the table: "ls_lawsuit_lawyer" */
  update_ls_lawsuit_lawyer?: Maybe<Ls_Lawsuit_Lawyer_Mutation_Response>;
  /** update single row of the table: "ls_lawsuit_lawyer" */
  update_ls_lawsuit_lawyer_by_pk?: Maybe<Ls_Lawsuit_Lawyer>;
  /** update multiples rows of table: "ls_lawsuit_lawyer" */
  update_ls_lawsuit_lawyer_many?: Maybe<Array<Maybe<Ls_Lawsuit_Lawyer_Mutation_Response>>>;
  /** update multiples rows of table: "ls_lawsuit" */
  update_ls_lawsuit_many?: Maybe<Array<Maybe<Ls_Lawsuit_Mutation_Response>>>;
  /** update data of the table: "nflx_claim" */
  update_nflx_claim?: Maybe<Nflx_Claim_Mutation_Response>;
  /** update single row of the table: "nflx_claim" */
  update_nflx_claim_by_pk?: Maybe<Nflx_Claim>;
  /** update multiples rows of table: "nflx_claim" */
  update_nflx_claim_many?: Maybe<Array<Maybe<Nflx_Claim_Mutation_Response>>>;
  /** update data of the table: "nflx_company" */
  update_nflx_company?: Maybe<Nflx_Company_Mutation_Response>;
  /** update single row of the table: "nflx_company" */
  update_nflx_company_by_pk?: Maybe<Nflx_Company>;
  /** update multiples rows of table: "nflx_company" */
  update_nflx_company_many?: Maybe<Array<Maybe<Nflx_Company_Mutation_Response>>>;
  /** update data of the table: "nrg_claim" */
  update_nrg_claim?: Maybe<Nrg_Claim_Mutation_Response>;
  /** update single row of the table: "nrg_claim" */
  update_nrg_claim_by_pk?: Maybe<Nrg_Claim>;
  /** update multiples rows of table: "nrg_claim" */
  update_nrg_claim_many?: Maybe<Array<Maybe<Nrg_Claim_Mutation_Response>>>;
  /** update data of the table: "nrg_company" */
  update_nrg_company?: Maybe<Nrg_Company_Mutation_Response>;
  /** update single row of the table: "nrg_company" */
  update_nrg_company_by_pk?: Maybe<Nrg_Company>;
  /** update multiples rows of table: "nrg_company" */
  update_nrg_company_many?: Maybe<Array<Maybe<Nrg_Company_Mutation_Response>>>;
  /** update data of the table: "ocg_claim" */
  update_ocg_claim?: Maybe<Ocg_Claim_Mutation_Response>;
  /** update single row of the table: "ocg_claim" */
  update_ocg_claim_by_pk?: Maybe<Ocg_Claim>;
  /** update multiples rows of table: "ocg_claim" */
  update_ocg_claim_many?: Maybe<Array<Maybe<Ocg_Claim_Mutation_Response>>>;
  /** update data of the table: "ocg_company" */
  update_ocg_company?: Maybe<Ocg_Company_Mutation_Response>;
  /** update single row of the table: "ocg_company" */
  update_ocg_company_by_pk?: Maybe<Ocg_Company>;
  /** update multiples rows of table: "ocg_company" */
  update_ocg_company_many?: Maybe<Array<Maybe<Ocg_Company_Mutation_Response>>>;
  /** update data of the table: "payment" */
  update_payment?: Maybe<Payment_Mutation_Response>;
  /** update single row of the table: "payment" */
  update_payment_by_pk?: Maybe<Payment>;
  /** update multiples rows of table: "payment" */
  update_payment_many?: Maybe<Array<Maybe<Payment_Mutation_Response>>>;
  /** update data of the table: "payment_state" */
  update_payment_state?: Maybe<Payment_State_Mutation_Response>;
  /** update single row of the table: "payment_state" */
  update_payment_state_by_pk?: Maybe<Payment_State>;
  /** update multiples rows of table: "payment_state" */
  update_payment_state_many?: Maybe<Array<Maybe<Payment_State_Mutation_Response>>>;
  /** update data of the table: "pkv_claim" */
  update_pkv_claim?: Maybe<Pkv_Claim_Mutation_Response>;
  /** update single row of the table: "pkv_claim" */
  update_pkv_claim_by_pk?: Maybe<Pkv_Claim>;
  /** update multiples rows of table: "pkv_claim" */
  update_pkv_claim_many?: Maybe<Array<Maybe<Pkv_Claim_Mutation_Response>>>;
  /** update data of the table: "pkv_company" */
  update_pkv_company?: Maybe<Pkv_Company_Mutation_Response>;
  /** update single row of the table: "pkv_company" */
  update_pkv_company_by_pk?: Maybe<Pkv_Company>;
  /** update multiples rows of table: "pkv_company" */
  update_pkv_company_many?: Maybe<Array<Maybe<Pkv_Company_Mutation_Response>>>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "ps_debtor" */
  update_ps_debtor?: Maybe<Ps_Debtor_Mutation_Response>;
  /** update single row of the table: "ps_debtor" */
  update_ps_debtor_by_pk?: Maybe<Ps_Debtor>;
  /** update multiples rows of table: "ps_debtor" */
  update_ps_debtor_many?: Maybe<Array<Maybe<Ps_Debtor_Mutation_Response>>>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update multiples rows of table: "role" */
  update_role_many?: Maybe<Array<Maybe<Role_Mutation_Response>>>;
  /** update data of the table: "third_party_raw_data" */
  update_third_party_raw_data?: Maybe<Third_Party_Raw_Data_Mutation_Response>;
  /** update single row of the table: "third_party_raw_data" */
  update_third_party_raw_data_by_pk?: Maybe<Third_Party_Raw_Data>;
  /** update multiples rows of table: "third_party_raw_data" */
  update_third_party_raw_data_many?: Maybe<Array<Maybe<Third_Party_Raw_Data_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>;
  /** update multiples rows of table: "user_role" */
  update_user_role_many?: Maybe<Array<Maybe<User_Role_Mutation_Response>>>;
  user_create?: Maybe<UserCreateOutput>;
};


/** mutation root */
export type Mutation_RootAdmin_Add_DocumentsArgs = {
  input: AdminAddDocumentsInput;
};


/** mutation root */
export type Mutation_RootAdmin_Approve_OfferArgs = {
  input: AdminApproveOfferInput;
};


/** mutation root */
export type Mutation_RootAdmin_Assignment_ResendArgs = {
  input: AdminAssignmentResendInput;
};


/** mutation root */
export type Mutation_RootAdmin_Cancel_OfferArgs = {
  input: AdminCancelOfferInput;
};


/** mutation root */
export type Mutation_RootAdmin_Case_Comment_AddArgs = {
  input: AdminCaseCommentAddInput;
};


/** mutation root */
export type Mutation_RootAdmin_Case_DeleteArgs = {
  input: AdminCaseDeleteInput;
};


/** mutation root */
export type Mutation_RootAdmin_Case_Documents_CompleteArgs = {
  input: AdminCaseDocumentsCompleteInput;
};


/** mutation root */
export type Mutation_RootAdmin_Case_Rejection_RevokeArgs = {
  input: AdminCaseRejectionRevokeInput;
};


/** mutation root */
export type Mutation_RootAdmin_Case_RevokeArgs = {
  input: AdminCaseRevokeInput;
};


/** mutation root */
export type Mutation_RootAdmin_Confirm_RejectionArgs = {
  input: AdminConfirmRejectionInput;
};


/** mutation root */
export type Mutation_RootAdmin_Create_OfferArgs = {
  input: AdminCreateOfferInput;
};


/** mutation root */
export type Mutation_RootAdmin_Delete_DocumentsArgs = {
  input: AdminDeleteDocumentsInput;
};


/** mutation root */
export type Mutation_RootAdmin_Download_DocumentsArgs = {
  input: AdminDownloadDocumentsInput;
};


/** mutation root */
export type Mutation_RootAdmin_Fit_Claim_UpdateArgs = {
  input: AdminFitClaimUpdateInput;
};


/** mutation root */
export type Mutation_RootAdmin_Gff_Claim_UpdateArgs = {
  input: AdminGffClaimUpdateInput;
};


/** mutation root */
export type Mutation_RootAdmin_Handle_Gdpr_Deletion_RequestArgs = {
  input: AdminHandleGdprDeletionRequestInput;
};


/** mutation root */
export type Mutation_RootAdmin_Offer_Address_ChangeArgs = {
  input: AdminOfferAddressChangeInput;
};


/** mutation root */
export type Mutation_RootAdmin_Offer_DeleteArgs = {
  input: AdminOfferDeleteInput;
};


/** mutation root */
export type Mutation_RootAdmin_Offer_ResendArgs = {
  input: AdminOfferResendInput;
};


/** mutation root */
export type Mutation_RootAdmin_Offer_SendArgs = {
  input: AdminOfferSendInput;
};


/** mutation root */
export type Mutation_RootAdmin_Pkv_Claim_UpdateArgs = {
  input: AdminPkvClaimUpdateInput;
};


/** mutation root */
export type Mutation_RootAdmin_Refuse_OfferArgs = {
  input: AdminRefuseOfferInput;
};


/** mutation root */
export type Mutation_RootAdmin_Reject_CaseArgs = {
  input: AdminRejectCaseInput;
};


/** mutation root */
export type Mutation_RootAdmin_Remove_Cases_From_LawsuitArgs = {
  input: AdminRemoveCasesFromLawsuitInput;
};


/** mutation root */
export type Mutation_RootAdmin_Request_DocumentsArgs = {
  input: AdminRequestDocumentsInput;
};


/** mutation root */
export type Mutation_RootAdmin_Resend_OfferArgs = {
  input: AdminResendOfferInput;
};


/** mutation root */
export type Mutation_RootAdmin_Send_OfferArgs = {
  input: AdminSendOfferInput;
};


/** mutation root */
export type Mutation_RootAdmin_Update_ClaimArgs = {
  input: AdminUpdateClaimInput;
};


/** mutation root */
export type Mutation_RootAdmin_Update_CommentArgs = {
  input: AdminUpdateCommentInput;
};


/** mutation root */
export type Mutation_RootAdmin_Update_CustomerArgs = {
  input: AdminUpdateCustomerInput;
};


/** mutation root */
export type Mutation_RootAdmin_Update_DocumentArgs = {
  input: AdminUpdateDocumentInput;
};


/** mutation root */
export type Mutation_RootAdmin_Update_Document_OrderArgs = {
  input: AdminUpdateDocumentOrderInput;
};


/** mutation root */
export type Mutation_RootCase_Add_DocumentsArgs = {
  input: CaseAddDocumentsInput;
};


/** mutation root */
export type Mutation_RootCustomer_Accept_External_OfferArgs = {
  input: CustomerAcceptExternalOfferInput;
};


/** mutation root */
export type Mutation_RootCustomer_Accept_OfferArgs = {
  input: CustomerAcceptOfferInput;
};


/** mutation root */
export type Mutation_RootCustomer_Add_DocumentsArgs = {
  input: CustomerAddDocumentsInput;
};


/** mutation root */
export type Mutation_RootCustomer_Submit_CaseArgs = {
  input: CustomerSubmitCaseInput;
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Api_KeysArgs = {
  where: Api_Keys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Api_Keys_By_PkArgs = {
  name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AssignmentArgs = {
  where: Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Assignment_StateArgs = {
  where: Assignment_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Assignment_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Bank_AccountArgs = {
  where: Bank_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bank_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Base_CaseArgs = {
  where: Base_Case_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Base_Case_DocumentArgs = {
  where: Base_Case_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Case_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Base_Case_Document_CategoryArgs = {
  where: Base_Case_Document_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Case_Document_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Base_OfferArgs = {
  where: Base_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Base_Offer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Case_CommentArgs = {
  where: Case_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Case_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CustomerArgs = {
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Customer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Email_QueueArgs = {
  where: Email_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fit_ClaimArgs = {
  where: Fit_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fit_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fit_CompanyArgs = {
  where: Fit_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fit_Company_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Fit_Fts_TransactionArgs = {
  where: Fit_Fts_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fit_Fts_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fs_Bank_TransactionArgs = {
  where: Fs_Bank_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fs_Bank_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fs_Customer_PayoutArgs = {
  where: Fs_Customer_Payout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fs_Customer_Payout_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Fs_Customer_Payout_QueueArgs = {
  where: Fs_Customer_Payout_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Fs_Customer_Payout_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_CaseArgs = {
  where: Gdpr_Case_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Case_Rejection_ReasonArgs = {
  where: Gdpr_Case_Rejection_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Case_Rejection_Reason_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Case_StateArgs = {
  where: Gdpr_Case_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Case_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_ClaimArgs = {
  where: Gdpr_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Claim_CompanyArgs = {
  where: Gdpr_Claim_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Claim_Company_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_CompanyArgs = {
  where: Gdpr_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Company_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_OfferArgs = {
  where: Gdpr_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Offer_AssignmentArgs = {
  where: Gdpr_Offer_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Offer_Assignment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Offer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Offer_PaymentArgs = {
  where: Gdpr_Offer_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Offer_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Offer_StateArgs = {
  where: Gdpr_Offer_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gdpr_Offer_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Generated_ReportArgs = {
  where: Generated_Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Generated_Report_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gff_AirportArgs = {
  where: Gff_Airport_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gff_Airport_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Gff_ClaimArgs = {
  where: Gff_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gff_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Gff_CompanyArgs = {
  where: Gff_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Gff_Company_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Haveibeenpwned_Api_LogArgs = {
  where: Haveibeenpwned_Api_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Haveibeenpwned_Api_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lost_AssignmentArgs = {
  where: Lost_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lost_Assignment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Lost_Assignment_StateArgs = {
  where: Lost_Assignment_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lost_Assignment_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_CaseArgs = {
  where: Ls_Case_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_LawsuitArgs = {
  where: Ls_Lawsuit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_CourtArgs = {
  where: Ls_Lawsuit_Court_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_Court_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_DocumentArgs = {
  where: Ls_Lawsuit_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_Document_CategoryArgs = {
  where: Ls_Lawsuit_Document_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_Document_Category_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_ExpenseArgs = {
  where: Ls_Lawsuit_Expense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_Expense_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_HistoryArgs = {
  where: Ls_Lawsuit_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_History_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_LawyerArgs = {
  where: Ls_Lawsuit_Lawyer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ls_Lawsuit_Lawyer_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nflx_ClaimArgs = {
  where: Nflx_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nflx_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nflx_CompanyArgs = {
  where: Nflx_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nflx_Company_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Nrg_ClaimArgs = {
  where: Nrg_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nrg_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Nrg_CompanyArgs = {
  where: Nrg_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nrg_Company_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ocg_ClaimArgs = {
  where: Ocg_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ocg_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ocg_CompanyArgs = {
  where: Ocg_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ocg_Company_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_StateArgs = {
  where: Payment_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_State_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Pkv_ClaimArgs = {
  where: Pkv_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pkv_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Pkv_CompanyArgs = {
  where: Pkv_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pkv_Company_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ps_DebtorArgs = {
  where: Ps_Debtor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ps_Debtor_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Third_Party_Raw_DataArgs = {
  where: Third_Party_Raw_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Third_Party_Raw_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDocs_Sign_UrlArgs = {
  input: DocsSignUrlInput;
};


/** mutation root */
export type Mutation_RootGff_Case_CreateArgs = {
  input: GffCaseCreateInput;
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_KeysArgs = {
  objects: Array<Api_Keys_Insert_Input>;
  on_conflict?: Maybe<Api_Keys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Api_Keys_OneArgs = {
  object: Api_Keys_Insert_Input;
  on_conflict?: Maybe<Api_Keys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AssignmentArgs = {
  objects: Array<Assignment_Insert_Input>;
  on_conflict?: Maybe<Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_OneArgs = {
  object: Assignment_Insert_Input;
  on_conflict?: Maybe<Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_StateArgs = {
  objects: Array<Assignment_State_Insert_Input>;
  on_conflict?: Maybe<Assignment_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Assignment_State_OneArgs = {
  object: Assignment_State_Insert_Input;
  on_conflict?: Maybe<Assignment_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bank_AccountArgs = {
  objects: Array<Bank_Account_Insert_Input>;
  on_conflict?: Maybe<Bank_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bank_Account_OneArgs = {
  object: Bank_Account_Insert_Input;
  on_conflict?: Maybe<Bank_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_CaseArgs = {
  objects: Array<Base_Case_Insert_Input>;
  on_conflict?: Maybe<Base_Case_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Case_DocumentArgs = {
  objects: Array<Base_Case_Document_Insert_Input>;
  on_conflict?: Maybe<Base_Case_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Case_Document_CategoryArgs = {
  objects: Array<Base_Case_Document_Category_Insert_Input>;
  on_conflict?: Maybe<Base_Case_Document_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Case_Document_Category_OneArgs = {
  object: Base_Case_Document_Category_Insert_Input;
  on_conflict?: Maybe<Base_Case_Document_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Case_Document_OneArgs = {
  object: Base_Case_Document_Insert_Input;
  on_conflict?: Maybe<Base_Case_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Case_OneArgs = {
  object: Base_Case_Insert_Input;
  on_conflict?: Maybe<Base_Case_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_OfferArgs = {
  objects: Array<Base_Offer_Insert_Input>;
  on_conflict?: Maybe<Base_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Base_Offer_OneArgs = {
  object: Base_Offer_Insert_Input;
  on_conflict?: Maybe<Base_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_CommentArgs = {
  objects: Array<Case_Comment_Insert_Input>;
  on_conflict?: Maybe<Case_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Case_Comment_OneArgs = {
  object: Case_Comment_Insert_Input;
  on_conflict?: Maybe<Case_Comment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CustomerArgs = {
  objects: Array<Customer_Insert_Input>;
  on_conflict?: Maybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Customer_OneArgs = {
  object: Customer_Insert_Input;
  on_conflict?: Maybe<Customer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_QueueArgs = {
  objects: Array<Email_Queue_Insert_Input>;
  on_conflict?: Maybe<Email_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Queue_OneArgs = {
  object: Email_Queue_Insert_Input;
  on_conflict?: Maybe<Email_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_ClaimArgs = {
  objects: Array<Fit_Claim_Insert_Input>;
  on_conflict?: Maybe<Fit_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_Claim_OneArgs = {
  object: Fit_Claim_Insert_Input;
  on_conflict?: Maybe<Fit_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_CompanyArgs = {
  objects: Array<Fit_Company_Insert_Input>;
  on_conflict?: Maybe<Fit_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_Company_OneArgs = {
  object: Fit_Company_Insert_Input;
  on_conflict?: Maybe<Fit_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_Fts_TransactionArgs = {
  objects: Array<Fit_Fts_Transaction_Insert_Input>;
  on_conflict?: Maybe<Fit_Fts_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fit_Fts_Transaction_OneArgs = {
  object: Fit_Fts_Transaction_Insert_Input;
  on_conflict?: Maybe<Fit_Fts_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fs_Bank_TransactionArgs = {
  objects: Array<Fs_Bank_Transaction_Insert_Input>;
  on_conflict?: Maybe<Fs_Bank_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fs_Bank_Transaction_OneArgs = {
  object: Fs_Bank_Transaction_Insert_Input;
  on_conflict?: Maybe<Fs_Bank_Transaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fs_Customer_PayoutArgs = {
  objects: Array<Fs_Customer_Payout_Insert_Input>;
  on_conflict?: Maybe<Fs_Customer_Payout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fs_Customer_Payout_OneArgs = {
  object: Fs_Customer_Payout_Insert_Input;
  on_conflict?: Maybe<Fs_Customer_Payout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fs_Customer_Payout_QueueArgs = {
  objects: Array<Fs_Customer_Payout_Queue_Insert_Input>;
  on_conflict?: Maybe<Fs_Customer_Payout_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Fs_Customer_Payout_Queue_OneArgs = {
  object: Fs_Customer_Payout_Queue_Insert_Input;
  on_conflict?: Maybe<Fs_Customer_Payout_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_CaseArgs = {
  objects: Array<Gdpr_Case_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Case_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Case_OneArgs = {
  object: Gdpr_Case_Insert_Input;
  on_conflict?: Maybe<Gdpr_Case_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Case_Rejection_ReasonArgs = {
  objects: Array<Gdpr_Case_Rejection_Reason_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Case_Rejection_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Case_Rejection_Reason_OneArgs = {
  object: Gdpr_Case_Rejection_Reason_Insert_Input;
  on_conflict?: Maybe<Gdpr_Case_Rejection_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Case_StateArgs = {
  objects: Array<Gdpr_Case_State_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Case_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Case_State_OneArgs = {
  object: Gdpr_Case_State_Insert_Input;
  on_conflict?: Maybe<Gdpr_Case_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_ClaimArgs = {
  objects: Array<Gdpr_Claim_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Claim_CompanyArgs = {
  objects: Array<Gdpr_Claim_Company_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Claim_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Claim_Company_OneArgs = {
  object: Gdpr_Claim_Company_Insert_Input;
  on_conflict?: Maybe<Gdpr_Claim_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Claim_OneArgs = {
  object: Gdpr_Claim_Insert_Input;
  on_conflict?: Maybe<Gdpr_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_CompanyArgs = {
  objects: Array<Gdpr_Company_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Company_OneArgs = {
  object: Gdpr_Company_Insert_Input;
  on_conflict?: Maybe<Gdpr_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_OfferArgs = {
  objects: Array<Gdpr_Offer_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Offer_AssignmentArgs = {
  objects: Array<Gdpr_Offer_Assignment_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Offer_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Offer_Assignment_OneArgs = {
  object: Gdpr_Offer_Assignment_Insert_Input;
  on_conflict?: Maybe<Gdpr_Offer_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Offer_OneArgs = {
  object: Gdpr_Offer_Insert_Input;
  on_conflict?: Maybe<Gdpr_Offer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Offer_PaymentArgs = {
  objects: Array<Gdpr_Offer_Payment_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Offer_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Offer_Payment_OneArgs = {
  object: Gdpr_Offer_Payment_Insert_Input;
  on_conflict?: Maybe<Gdpr_Offer_Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Offer_StateArgs = {
  objects: Array<Gdpr_Offer_State_Insert_Input>;
  on_conflict?: Maybe<Gdpr_Offer_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gdpr_Offer_State_OneArgs = {
  object: Gdpr_Offer_State_Insert_Input;
  on_conflict?: Maybe<Gdpr_Offer_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Generated_ReportArgs = {
  objects: Array<Generated_Report_Insert_Input>;
  on_conflict?: Maybe<Generated_Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Generated_Report_OneArgs = {
  object: Generated_Report_Insert_Input;
  on_conflict?: Maybe<Generated_Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gff_AirportArgs = {
  objects: Array<Gff_Airport_Insert_Input>;
  on_conflict?: Maybe<Gff_Airport_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gff_Airport_OneArgs = {
  object: Gff_Airport_Insert_Input;
  on_conflict?: Maybe<Gff_Airport_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gff_ClaimArgs = {
  objects: Array<Gff_Claim_Insert_Input>;
  on_conflict?: Maybe<Gff_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gff_Claim_OneArgs = {
  object: Gff_Claim_Insert_Input;
  on_conflict?: Maybe<Gff_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gff_CompanyArgs = {
  objects: Array<Gff_Company_Insert_Input>;
  on_conflict?: Maybe<Gff_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Gff_Company_OneArgs = {
  object: Gff_Company_Insert_Input;
  on_conflict?: Maybe<Gff_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Haveibeenpwned_Api_LogArgs = {
  objects: Array<Haveibeenpwned_Api_Log_Insert_Input>;
  on_conflict?: Maybe<Haveibeenpwned_Api_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Haveibeenpwned_Api_Log_OneArgs = {
  object: Haveibeenpwned_Api_Log_Insert_Input;
  on_conflict?: Maybe<Haveibeenpwned_Api_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lost_AssignmentArgs = {
  objects: Array<Lost_Assignment_Insert_Input>;
  on_conflict?: Maybe<Lost_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lost_Assignment_OneArgs = {
  object: Lost_Assignment_Insert_Input;
  on_conflict?: Maybe<Lost_Assignment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lost_Assignment_StateArgs = {
  objects: Array<Lost_Assignment_State_Insert_Input>;
  on_conflict?: Maybe<Lost_Assignment_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lost_Assignment_State_OneArgs = {
  object: Lost_Assignment_State_Insert_Input;
  on_conflict?: Maybe<Lost_Assignment_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_CaseArgs = {
  objects: Array<Ls_Case_Insert_Input>;
  on_conflict?: Maybe<Ls_Case_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Case_OneArgs = {
  object: Ls_Case_Insert_Input;
  on_conflict?: Maybe<Ls_Case_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_LawsuitArgs = {
  objects: Array<Ls_Lawsuit_Insert_Input>;
  on_conflict?: Maybe<Ls_Lawsuit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_CourtArgs = {
  objects: Array<Ls_Lawsuit_Court_Insert_Input>;
  on_conflict?: Maybe<Ls_Lawsuit_Court_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_Court_OneArgs = {
  object: Ls_Lawsuit_Court_Insert_Input;
  on_conflict?: Maybe<Ls_Lawsuit_Court_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_DocumentArgs = {
  objects: Array<Ls_Lawsuit_Document_Insert_Input>;
  on_conflict?: Maybe<Ls_Lawsuit_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_Document_CategoryArgs = {
  objects: Array<Ls_Lawsuit_Document_Category_Insert_Input>;
  on_conflict?: Maybe<Ls_Lawsuit_Document_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_Document_Category_OneArgs = {
  object: Ls_Lawsuit_Document_Category_Insert_Input;
  on_conflict?: Maybe<Ls_Lawsuit_Document_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_Document_OneArgs = {
  object: Ls_Lawsuit_Document_Insert_Input;
  on_conflict?: Maybe<Ls_Lawsuit_Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_ExpenseArgs = {
  objects: Array<Ls_Lawsuit_Expense_Insert_Input>;
  on_conflict?: Maybe<Ls_Lawsuit_Expense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_Expense_OneArgs = {
  object: Ls_Lawsuit_Expense_Insert_Input;
  on_conflict?: Maybe<Ls_Lawsuit_Expense_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_HistoryArgs = {
  objects: Array<Ls_Lawsuit_History_Insert_Input>;
  on_conflict?: Maybe<Ls_Lawsuit_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_History_OneArgs = {
  object: Ls_Lawsuit_History_Insert_Input;
  on_conflict?: Maybe<Ls_Lawsuit_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_LawyerArgs = {
  objects: Array<Ls_Lawsuit_Lawyer_Insert_Input>;
  on_conflict?: Maybe<Ls_Lawsuit_Lawyer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_Lawyer_OneArgs = {
  object: Ls_Lawsuit_Lawyer_Insert_Input;
  on_conflict?: Maybe<Ls_Lawsuit_Lawyer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ls_Lawsuit_OneArgs = {
  object: Ls_Lawsuit_Insert_Input;
  on_conflict?: Maybe<Ls_Lawsuit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nflx_ClaimArgs = {
  objects: Array<Nflx_Claim_Insert_Input>;
  on_conflict?: Maybe<Nflx_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nflx_Claim_OneArgs = {
  object: Nflx_Claim_Insert_Input;
  on_conflict?: Maybe<Nflx_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nflx_CompanyArgs = {
  objects: Array<Nflx_Company_Insert_Input>;
  on_conflict?: Maybe<Nflx_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nflx_Company_OneArgs = {
  object: Nflx_Company_Insert_Input;
  on_conflict?: Maybe<Nflx_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nrg_ClaimArgs = {
  objects: Array<Nrg_Claim_Insert_Input>;
  on_conflict?: Maybe<Nrg_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nrg_Claim_OneArgs = {
  object: Nrg_Claim_Insert_Input;
  on_conflict?: Maybe<Nrg_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nrg_CompanyArgs = {
  objects: Array<Nrg_Company_Insert_Input>;
  on_conflict?: Maybe<Nrg_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nrg_Company_OneArgs = {
  object: Nrg_Company_Insert_Input;
  on_conflict?: Maybe<Nrg_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ocg_ClaimArgs = {
  objects: Array<Ocg_Claim_Insert_Input>;
  on_conflict?: Maybe<Ocg_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ocg_Claim_OneArgs = {
  object: Ocg_Claim_Insert_Input;
  on_conflict?: Maybe<Ocg_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ocg_CompanyArgs = {
  objects: Array<Ocg_Company_Insert_Input>;
  on_conflict?: Maybe<Ocg_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ocg_Company_OneArgs = {
  object: Ocg_Company_Insert_Input;
  on_conflict?: Maybe<Ocg_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_StateArgs = {
  objects: Array<Payment_State_Insert_Input>;
  on_conflict?: Maybe<Payment_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_State_OneArgs = {
  object: Payment_State_Insert_Input;
  on_conflict?: Maybe<Payment_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pkv_ClaimArgs = {
  objects: Array<Pkv_Claim_Insert_Input>;
  on_conflict?: Maybe<Pkv_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pkv_Claim_OneArgs = {
  object: Pkv_Claim_Insert_Input;
  on_conflict?: Maybe<Pkv_Claim_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pkv_CompanyArgs = {
  objects: Array<Pkv_Company_Insert_Input>;
  on_conflict?: Maybe<Pkv_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pkv_Company_OneArgs = {
  object: Pkv_Company_Insert_Input;
  on_conflict?: Maybe<Pkv_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ps_DebtorArgs = {
  objects: Array<Ps_Debtor_Insert_Input>;
  on_conflict?: Maybe<Ps_Debtor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ps_Debtor_OneArgs = {
  object: Ps_Debtor_Insert_Input;
  on_conflict?: Maybe<Ps_Debtor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_Party_Raw_DataArgs = {
  objects: Array<Third_Party_Raw_Data_Insert_Input>;
  on_conflict?: Maybe<Third_Party_Raw_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_Party_Raw_Data_OneArgs = {
  object: Third_Party_Raw_Data_Insert_Input;
  on_conflict?: Maybe<Third_Party_Raw_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Add_DocumentsArgs = {
  input: LawsuitnowAddDocumentsInput;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Add_EventArgs = {
  input: LawsuitnowAddEventInput;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Delete_DocumentsArgs = {
  input: LawsuitnowDeleteDocumentsInput;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Download_DocumentsArgs = {
  input: LawsuitnowDownloadDocumentsInput;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Sign_Document_UrlsArgs = {
  input: LawsuitnowSignDocumentUrlsInput;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Update_CasesArgs = {
  input: LawsuitnowUpdateCasesInput;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Update_DocumentsArgs = {
  input: LawsuitnowUpdateDocumentsInput;
};


/** mutation root */
export type Mutation_RootLawsuitnow_Update_LawsuitArgs = {
  input: LawsuitnowUpdateLawsuitInput;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  input: LoginInput;
};


/** mutation root */
export type Mutation_RootOcg_Case_CreateArgs = {
  input: OcgCaseCreateInput;
};


/** mutation root */
export type Mutation_RootOffer_AcceptArgs = {
  input: OfferAcceptInput;
};


/** mutation root */
export type Mutation_RootPkv_Case_CreateArgs = {
  input: PkvCaseCreateInput;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _set?: Maybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Api_KeysArgs = {
  _set?: Maybe<Api_Keys_Set_Input>;
  where: Api_Keys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Api_Keys_By_PkArgs = {
  _set?: Maybe<Api_Keys_Set_Input>;
  pk_columns: Api_Keys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Api_Keys_ManyArgs = {
  updates: Array<Api_Keys_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AssignmentArgs = {
  _set?: Maybe<Assignment_Set_Input>;
  where: Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_By_PkArgs = {
  _set?: Maybe<Assignment_Set_Input>;
  pk_columns: Assignment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_ManyArgs = {
  updates: Array<Assignment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_StateArgs = {
  _set?: Maybe<Assignment_State_Set_Input>;
  where: Assignment_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_State_By_PkArgs = {
  _set?: Maybe<Assignment_State_Set_Input>;
  pk_columns: Assignment_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Assignment_State_ManyArgs = {
  updates: Array<Assignment_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Bank_AccountArgs = {
  _set?: Maybe<Bank_Account_Set_Input>;
  where: Bank_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bank_Account_By_PkArgs = {
  _set?: Maybe<Bank_Account_Set_Input>;
  pk_columns: Bank_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bank_Account_ManyArgs = {
  updates: Array<Bank_Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_CaseArgs = {
  _append?: Maybe<Base_Case_Append_Input>;
  _delete_at_path?: Maybe<Base_Case_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Base_Case_Delete_Elem_Input>;
  _delete_key?: Maybe<Base_Case_Delete_Key_Input>;
  _prepend?: Maybe<Base_Case_Prepend_Input>;
  _set?: Maybe<Base_Case_Set_Input>;
  where: Base_Case_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_By_PkArgs = {
  _append?: Maybe<Base_Case_Append_Input>;
  _delete_at_path?: Maybe<Base_Case_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Base_Case_Delete_Elem_Input>;
  _delete_key?: Maybe<Base_Case_Delete_Key_Input>;
  _prepend?: Maybe<Base_Case_Prepend_Input>;
  _set?: Maybe<Base_Case_Set_Input>;
  pk_columns: Base_Case_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_DocumentArgs = {
  _inc?: Maybe<Base_Case_Document_Inc_Input>;
  _set?: Maybe<Base_Case_Document_Set_Input>;
  where: Base_Case_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_Document_By_PkArgs = {
  _inc?: Maybe<Base_Case_Document_Inc_Input>;
  _set?: Maybe<Base_Case_Document_Set_Input>;
  pk_columns: Base_Case_Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_Document_CategoryArgs = {
  _set?: Maybe<Base_Case_Document_Category_Set_Input>;
  where: Base_Case_Document_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_Document_Category_By_PkArgs = {
  _set?: Maybe<Base_Case_Document_Category_Set_Input>;
  pk_columns: Base_Case_Document_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_Document_Category_ManyArgs = {
  updates: Array<Base_Case_Document_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_Document_ManyArgs = {
  updates: Array<Base_Case_Document_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Case_ManyArgs = {
  updates: Array<Base_Case_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Base_OfferArgs = {
  _append?: Maybe<Base_Offer_Append_Input>;
  _delete_at_path?: Maybe<Base_Offer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Base_Offer_Delete_Elem_Input>;
  _delete_key?: Maybe<Base_Offer_Delete_Key_Input>;
  _inc?: Maybe<Base_Offer_Inc_Input>;
  _prepend?: Maybe<Base_Offer_Prepend_Input>;
  _set?: Maybe<Base_Offer_Set_Input>;
  where: Base_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Offer_By_PkArgs = {
  _append?: Maybe<Base_Offer_Append_Input>;
  _delete_at_path?: Maybe<Base_Offer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Base_Offer_Delete_Elem_Input>;
  _delete_key?: Maybe<Base_Offer_Delete_Key_Input>;
  _inc?: Maybe<Base_Offer_Inc_Input>;
  _prepend?: Maybe<Base_Offer_Prepend_Input>;
  _set?: Maybe<Base_Offer_Set_Input>;
  pk_columns: Base_Offer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Base_Offer_ManyArgs = {
  updates: Array<Base_Offer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Case_CommentArgs = {
  _set?: Maybe<Case_Comment_Set_Input>;
  where: Case_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Comment_By_PkArgs = {
  _set?: Maybe<Case_Comment_Set_Input>;
  pk_columns: Case_Comment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Case_Comment_ManyArgs = {
  updates: Array<Case_Comment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CustomerArgs = {
  _set?: Maybe<Customer_Set_Input>;
  where: Customer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_By_PkArgs = {
  _set?: Maybe<Customer_Set_Input>;
  pk_columns: Customer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Customer_ManyArgs = {
  updates: Array<Customer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Email_QueueArgs = {
  _append?: Maybe<Email_Queue_Append_Input>;
  _delete_at_path?: Maybe<Email_Queue_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Queue_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Queue_Delete_Key_Input>;
  _prepend?: Maybe<Email_Queue_Prepend_Input>;
  _set?: Maybe<Email_Queue_Set_Input>;
  where: Email_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Queue_By_PkArgs = {
  _append?: Maybe<Email_Queue_Append_Input>;
  _delete_at_path?: Maybe<Email_Queue_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Queue_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Queue_Delete_Key_Input>;
  _prepend?: Maybe<Email_Queue_Prepend_Input>;
  _set?: Maybe<Email_Queue_Set_Input>;
  pk_columns: Email_Queue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Queue_ManyArgs = {
  updates: Array<Email_Queue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_ClaimArgs = {
  _append?: Maybe<Fit_Claim_Append_Input>;
  _delete_at_path?: Maybe<Fit_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Fit_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Fit_Claim_Delete_Key_Input>;
  _inc?: Maybe<Fit_Claim_Inc_Input>;
  _prepend?: Maybe<Fit_Claim_Prepend_Input>;
  _set?: Maybe<Fit_Claim_Set_Input>;
  where: Fit_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Claim_By_PkArgs = {
  _append?: Maybe<Fit_Claim_Append_Input>;
  _delete_at_path?: Maybe<Fit_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Fit_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Fit_Claim_Delete_Key_Input>;
  _inc?: Maybe<Fit_Claim_Inc_Input>;
  _prepend?: Maybe<Fit_Claim_Prepend_Input>;
  _set?: Maybe<Fit_Claim_Set_Input>;
  pk_columns: Fit_Claim_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Claim_ManyArgs = {
  updates: Array<Fit_Claim_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_CompanyArgs = {
  _set?: Maybe<Fit_Company_Set_Input>;
  where: Fit_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Company_By_PkArgs = {
  _set?: Maybe<Fit_Company_Set_Input>;
  pk_columns: Fit_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Company_ManyArgs = {
  updates: Array<Fit_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Fts_TransactionArgs = {
  _set?: Maybe<Fit_Fts_Transaction_Set_Input>;
  where: Fit_Fts_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Fts_Transaction_By_PkArgs = {
  _set?: Maybe<Fit_Fts_Transaction_Set_Input>;
  pk_columns: Fit_Fts_Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fit_Fts_Transaction_ManyArgs = {
  updates: Array<Fit_Fts_Transaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Bank_TransactionArgs = {
  _inc?: Maybe<Fs_Bank_Transaction_Inc_Input>;
  _set?: Maybe<Fs_Bank_Transaction_Set_Input>;
  where: Fs_Bank_Transaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Bank_Transaction_By_PkArgs = {
  _inc?: Maybe<Fs_Bank_Transaction_Inc_Input>;
  _set?: Maybe<Fs_Bank_Transaction_Set_Input>;
  pk_columns: Fs_Bank_Transaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Bank_Transaction_ManyArgs = {
  updates: Array<Fs_Bank_Transaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Customer_PayoutArgs = {
  _inc?: Maybe<Fs_Customer_Payout_Inc_Input>;
  _set?: Maybe<Fs_Customer_Payout_Set_Input>;
  where: Fs_Customer_Payout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Customer_Payout_By_PkArgs = {
  _inc?: Maybe<Fs_Customer_Payout_Inc_Input>;
  _set?: Maybe<Fs_Customer_Payout_Set_Input>;
  pk_columns: Fs_Customer_Payout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Customer_Payout_ManyArgs = {
  updates: Array<Fs_Customer_Payout_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Customer_Payout_QueueArgs = {
  _set?: Maybe<Fs_Customer_Payout_Queue_Set_Input>;
  where: Fs_Customer_Payout_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Customer_Payout_Queue_By_PkArgs = {
  _set?: Maybe<Fs_Customer_Payout_Queue_Set_Input>;
  pk_columns: Fs_Customer_Payout_Queue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Fs_Customer_Payout_Queue_ManyArgs = {
  updates: Array<Fs_Customer_Payout_Queue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_CaseArgs = {
  _append?: Maybe<Gdpr_Case_Append_Input>;
  _delete_at_path?: Maybe<Gdpr_Case_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gdpr_Case_Delete_Elem_Input>;
  _delete_key?: Maybe<Gdpr_Case_Delete_Key_Input>;
  _prepend?: Maybe<Gdpr_Case_Prepend_Input>;
  _set?: Maybe<Gdpr_Case_Set_Input>;
  where: Gdpr_Case_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_By_PkArgs = {
  _append?: Maybe<Gdpr_Case_Append_Input>;
  _delete_at_path?: Maybe<Gdpr_Case_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gdpr_Case_Delete_Elem_Input>;
  _delete_key?: Maybe<Gdpr_Case_Delete_Key_Input>;
  _prepend?: Maybe<Gdpr_Case_Prepend_Input>;
  _set?: Maybe<Gdpr_Case_Set_Input>;
  pk_columns: Gdpr_Case_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_ManyArgs = {
  updates: Array<Gdpr_Case_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_Rejection_ReasonArgs = {
  _set?: Maybe<Gdpr_Case_Rejection_Reason_Set_Input>;
  where: Gdpr_Case_Rejection_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_Rejection_Reason_By_PkArgs = {
  _set?: Maybe<Gdpr_Case_Rejection_Reason_Set_Input>;
  pk_columns: Gdpr_Case_Rejection_Reason_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_Rejection_Reason_ManyArgs = {
  updates: Array<Gdpr_Case_Rejection_Reason_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_StateArgs = {
  _set?: Maybe<Gdpr_Case_State_Set_Input>;
  where: Gdpr_Case_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_State_By_PkArgs = {
  _set?: Maybe<Gdpr_Case_State_Set_Input>;
  pk_columns: Gdpr_Case_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Case_State_ManyArgs = {
  updates: Array<Gdpr_Case_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_ClaimArgs = {
  _append?: Maybe<Gdpr_Claim_Append_Input>;
  _delete_at_path?: Maybe<Gdpr_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gdpr_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Gdpr_Claim_Delete_Key_Input>;
  _prepend?: Maybe<Gdpr_Claim_Prepend_Input>;
  _set?: Maybe<Gdpr_Claim_Set_Input>;
  where: Gdpr_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Claim_By_PkArgs = {
  _append?: Maybe<Gdpr_Claim_Append_Input>;
  _delete_at_path?: Maybe<Gdpr_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gdpr_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Gdpr_Claim_Delete_Key_Input>;
  _prepend?: Maybe<Gdpr_Claim_Prepend_Input>;
  _set?: Maybe<Gdpr_Claim_Set_Input>;
  pk_columns: Gdpr_Claim_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Claim_CompanyArgs = {
  _set?: Maybe<Gdpr_Claim_Company_Set_Input>;
  where: Gdpr_Claim_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Claim_Company_By_PkArgs = {
  _set?: Maybe<Gdpr_Claim_Company_Set_Input>;
  pk_columns: Gdpr_Claim_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Claim_Company_ManyArgs = {
  updates: Array<Gdpr_Claim_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Claim_ManyArgs = {
  updates: Array<Gdpr_Claim_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_CompanyArgs = {
  _set?: Maybe<Gdpr_Company_Set_Input>;
  where: Gdpr_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Company_By_PkArgs = {
  _set?: Maybe<Gdpr_Company_Set_Input>;
  pk_columns: Gdpr_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Company_ManyArgs = {
  updates: Array<Gdpr_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_OfferArgs = {
  _inc?: Maybe<Gdpr_Offer_Inc_Input>;
  _set?: Maybe<Gdpr_Offer_Set_Input>;
  where: Gdpr_Offer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_AssignmentArgs = {
  _set?: Maybe<Gdpr_Offer_Assignment_Set_Input>;
  where: Gdpr_Offer_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_Assignment_By_PkArgs = {
  _set?: Maybe<Gdpr_Offer_Assignment_Set_Input>;
  pk_columns: Gdpr_Offer_Assignment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_Assignment_ManyArgs = {
  updates: Array<Gdpr_Offer_Assignment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_By_PkArgs = {
  _inc?: Maybe<Gdpr_Offer_Inc_Input>;
  _set?: Maybe<Gdpr_Offer_Set_Input>;
  pk_columns: Gdpr_Offer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_ManyArgs = {
  updates: Array<Gdpr_Offer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_PaymentArgs = {
  _set?: Maybe<Gdpr_Offer_Payment_Set_Input>;
  where: Gdpr_Offer_Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_Payment_By_PkArgs = {
  _set?: Maybe<Gdpr_Offer_Payment_Set_Input>;
  pk_columns: Gdpr_Offer_Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_Payment_ManyArgs = {
  updates: Array<Gdpr_Offer_Payment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_StateArgs = {
  _set?: Maybe<Gdpr_Offer_State_Set_Input>;
  where: Gdpr_Offer_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_State_By_PkArgs = {
  _set?: Maybe<Gdpr_Offer_State_Set_Input>;
  pk_columns: Gdpr_Offer_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gdpr_Offer_State_ManyArgs = {
  updates: Array<Gdpr_Offer_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Generated_ReportArgs = {
  _append?: Maybe<Generated_Report_Append_Input>;
  _delete_at_path?: Maybe<Generated_Report_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Generated_Report_Delete_Elem_Input>;
  _delete_key?: Maybe<Generated_Report_Delete_Key_Input>;
  _prepend?: Maybe<Generated_Report_Prepend_Input>;
  _set?: Maybe<Generated_Report_Set_Input>;
  where: Generated_Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Generated_Report_By_PkArgs = {
  _append?: Maybe<Generated_Report_Append_Input>;
  _delete_at_path?: Maybe<Generated_Report_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Generated_Report_Delete_Elem_Input>;
  _delete_key?: Maybe<Generated_Report_Delete_Key_Input>;
  _prepend?: Maybe<Generated_Report_Prepend_Input>;
  _set?: Maybe<Generated_Report_Set_Input>;
  pk_columns: Generated_Report_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Generated_Report_ManyArgs = {
  updates: Array<Generated_Report_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_AirportArgs = {
  _set?: Maybe<Gff_Airport_Set_Input>;
  where: Gff_Airport_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_Airport_By_PkArgs = {
  _set?: Maybe<Gff_Airport_Set_Input>;
  pk_columns: Gff_Airport_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_Airport_ManyArgs = {
  updates: Array<Gff_Airport_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_ClaimArgs = {
  _append?: Maybe<Gff_Claim_Append_Input>;
  _delete_at_path?: Maybe<Gff_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gff_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Gff_Claim_Delete_Key_Input>;
  _inc?: Maybe<Gff_Claim_Inc_Input>;
  _prepend?: Maybe<Gff_Claim_Prepend_Input>;
  _set?: Maybe<Gff_Claim_Set_Input>;
  where: Gff_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_Claim_By_PkArgs = {
  _append?: Maybe<Gff_Claim_Append_Input>;
  _delete_at_path?: Maybe<Gff_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Gff_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Gff_Claim_Delete_Key_Input>;
  _inc?: Maybe<Gff_Claim_Inc_Input>;
  _prepend?: Maybe<Gff_Claim_Prepend_Input>;
  _set?: Maybe<Gff_Claim_Set_Input>;
  pk_columns: Gff_Claim_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_Claim_ManyArgs = {
  updates: Array<Gff_Claim_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_CompanyArgs = {
  _set?: Maybe<Gff_Company_Set_Input>;
  where: Gff_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_Company_By_PkArgs = {
  _set?: Maybe<Gff_Company_Set_Input>;
  pk_columns: Gff_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Gff_Company_ManyArgs = {
  updates: Array<Gff_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Haveibeenpwned_Api_LogArgs = {
  _append?: Maybe<Haveibeenpwned_Api_Log_Append_Input>;
  _delete_at_path?: Maybe<Haveibeenpwned_Api_Log_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Haveibeenpwned_Api_Log_Delete_Elem_Input>;
  _delete_key?: Maybe<Haveibeenpwned_Api_Log_Delete_Key_Input>;
  _prepend?: Maybe<Haveibeenpwned_Api_Log_Prepend_Input>;
  _set?: Maybe<Haveibeenpwned_Api_Log_Set_Input>;
  where: Haveibeenpwned_Api_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Haveibeenpwned_Api_Log_By_PkArgs = {
  _append?: Maybe<Haveibeenpwned_Api_Log_Append_Input>;
  _delete_at_path?: Maybe<Haveibeenpwned_Api_Log_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Haveibeenpwned_Api_Log_Delete_Elem_Input>;
  _delete_key?: Maybe<Haveibeenpwned_Api_Log_Delete_Key_Input>;
  _prepend?: Maybe<Haveibeenpwned_Api_Log_Prepend_Input>;
  _set?: Maybe<Haveibeenpwned_Api_Log_Set_Input>;
  pk_columns: Haveibeenpwned_Api_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Haveibeenpwned_Api_Log_ManyArgs = {
  updates: Array<Haveibeenpwned_Api_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lost_AssignmentArgs = {
  _set?: Maybe<Lost_Assignment_Set_Input>;
  where: Lost_Assignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lost_Assignment_By_PkArgs = {
  _set?: Maybe<Lost_Assignment_Set_Input>;
  pk_columns: Lost_Assignment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lost_Assignment_ManyArgs = {
  updates: Array<Lost_Assignment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Lost_Assignment_StateArgs = {
  _set?: Maybe<Lost_Assignment_State_Set_Input>;
  where: Lost_Assignment_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lost_Assignment_State_By_PkArgs = {
  _set?: Maybe<Lost_Assignment_State_Set_Input>;
  pk_columns: Lost_Assignment_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lost_Assignment_State_ManyArgs = {
  updates: Array<Lost_Assignment_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_CaseArgs = {
  _append?: Maybe<Ls_Case_Append_Input>;
  _delete_at_path?: Maybe<Ls_Case_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ls_Case_Delete_Elem_Input>;
  _delete_key?: Maybe<Ls_Case_Delete_Key_Input>;
  _inc?: Maybe<Ls_Case_Inc_Input>;
  _prepend?: Maybe<Ls_Case_Prepend_Input>;
  _set?: Maybe<Ls_Case_Set_Input>;
  where: Ls_Case_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Case_By_PkArgs = {
  _append?: Maybe<Ls_Case_Append_Input>;
  _delete_at_path?: Maybe<Ls_Case_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ls_Case_Delete_Elem_Input>;
  _delete_key?: Maybe<Ls_Case_Delete_Key_Input>;
  _inc?: Maybe<Ls_Case_Inc_Input>;
  _prepend?: Maybe<Ls_Case_Prepend_Input>;
  _set?: Maybe<Ls_Case_Set_Input>;
  pk_columns: Ls_Case_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Case_ManyArgs = {
  updates: Array<Ls_Case_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_LawsuitArgs = {
  _inc?: Maybe<Ls_Lawsuit_Inc_Input>;
  _set?: Maybe<Ls_Lawsuit_Set_Input>;
  where: Ls_Lawsuit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_By_PkArgs = {
  _inc?: Maybe<Ls_Lawsuit_Inc_Input>;
  _set?: Maybe<Ls_Lawsuit_Set_Input>;
  pk_columns: Ls_Lawsuit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_CourtArgs = {
  _set?: Maybe<Ls_Lawsuit_Court_Set_Input>;
  where: Ls_Lawsuit_Court_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Court_By_PkArgs = {
  _set?: Maybe<Ls_Lawsuit_Court_Set_Input>;
  pk_columns: Ls_Lawsuit_Court_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Court_ManyArgs = {
  updates: Array<Ls_Lawsuit_Court_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_DocumentArgs = {
  _inc?: Maybe<Ls_Lawsuit_Document_Inc_Input>;
  _set?: Maybe<Ls_Lawsuit_Document_Set_Input>;
  where: Ls_Lawsuit_Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Document_By_PkArgs = {
  _inc?: Maybe<Ls_Lawsuit_Document_Inc_Input>;
  _set?: Maybe<Ls_Lawsuit_Document_Set_Input>;
  pk_columns: Ls_Lawsuit_Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Document_CategoryArgs = {
  _set?: Maybe<Ls_Lawsuit_Document_Category_Set_Input>;
  where: Ls_Lawsuit_Document_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Document_Category_By_PkArgs = {
  _set?: Maybe<Ls_Lawsuit_Document_Category_Set_Input>;
  pk_columns: Ls_Lawsuit_Document_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Document_Category_ManyArgs = {
  updates: Array<Ls_Lawsuit_Document_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Document_ManyArgs = {
  updates: Array<Ls_Lawsuit_Document_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_ExpenseArgs = {
  _inc?: Maybe<Ls_Lawsuit_Expense_Inc_Input>;
  _set?: Maybe<Ls_Lawsuit_Expense_Set_Input>;
  where: Ls_Lawsuit_Expense_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Expense_By_PkArgs = {
  _inc?: Maybe<Ls_Lawsuit_Expense_Inc_Input>;
  _set?: Maybe<Ls_Lawsuit_Expense_Set_Input>;
  pk_columns: Ls_Lawsuit_Expense_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Expense_ManyArgs = {
  updates: Array<Ls_Lawsuit_Expense_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_HistoryArgs = {
  _append?: Maybe<Ls_Lawsuit_History_Append_Input>;
  _delete_at_path?: Maybe<Ls_Lawsuit_History_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ls_Lawsuit_History_Delete_Elem_Input>;
  _delete_key?: Maybe<Ls_Lawsuit_History_Delete_Key_Input>;
  _prepend?: Maybe<Ls_Lawsuit_History_Prepend_Input>;
  _set?: Maybe<Ls_Lawsuit_History_Set_Input>;
  where: Ls_Lawsuit_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_History_By_PkArgs = {
  _append?: Maybe<Ls_Lawsuit_History_Append_Input>;
  _delete_at_path?: Maybe<Ls_Lawsuit_History_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ls_Lawsuit_History_Delete_Elem_Input>;
  _delete_key?: Maybe<Ls_Lawsuit_History_Delete_Key_Input>;
  _prepend?: Maybe<Ls_Lawsuit_History_Prepend_Input>;
  _set?: Maybe<Ls_Lawsuit_History_Set_Input>;
  pk_columns: Ls_Lawsuit_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_History_ManyArgs = {
  updates: Array<Ls_Lawsuit_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_LawyerArgs = {
  _append?: Maybe<Ls_Lawsuit_Lawyer_Append_Input>;
  _delete_at_path?: Maybe<Ls_Lawsuit_Lawyer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ls_Lawsuit_Lawyer_Delete_Elem_Input>;
  _delete_key?: Maybe<Ls_Lawsuit_Lawyer_Delete_Key_Input>;
  _prepend?: Maybe<Ls_Lawsuit_Lawyer_Prepend_Input>;
  _set?: Maybe<Ls_Lawsuit_Lawyer_Set_Input>;
  where: Ls_Lawsuit_Lawyer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Lawyer_By_PkArgs = {
  _append?: Maybe<Ls_Lawsuit_Lawyer_Append_Input>;
  _delete_at_path?: Maybe<Ls_Lawsuit_Lawyer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ls_Lawsuit_Lawyer_Delete_Elem_Input>;
  _delete_key?: Maybe<Ls_Lawsuit_Lawyer_Delete_Key_Input>;
  _prepend?: Maybe<Ls_Lawsuit_Lawyer_Prepend_Input>;
  _set?: Maybe<Ls_Lawsuit_Lawyer_Set_Input>;
  pk_columns: Ls_Lawsuit_Lawyer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_Lawyer_ManyArgs = {
  updates: Array<Ls_Lawsuit_Lawyer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ls_Lawsuit_ManyArgs = {
  updates: Array<Ls_Lawsuit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nflx_ClaimArgs = {
  _append?: Maybe<Nflx_Claim_Append_Input>;
  _delete_at_path?: Maybe<Nflx_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Nflx_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Nflx_Claim_Delete_Key_Input>;
  _prepend?: Maybe<Nflx_Claim_Prepend_Input>;
  _set?: Maybe<Nflx_Claim_Set_Input>;
  where: Nflx_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nflx_Claim_By_PkArgs = {
  _append?: Maybe<Nflx_Claim_Append_Input>;
  _delete_at_path?: Maybe<Nflx_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Nflx_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Nflx_Claim_Delete_Key_Input>;
  _prepend?: Maybe<Nflx_Claim_Prepend_Input>;
  _set?: Maybe<Nflx_Claim_Set_Input>;
  pk_columns: Nflx_Claim_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nflx_Claim_ManyArgs = {
  updates: Array<Nflx_Claim_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nflx_CompanyArgs = {
  _set?: Maybe<Nflx_Company_Set_Input>;
  where: Nflx_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nflx_Company_By_PkArgs = {
  _set?: Maybe<Nflx_Company_Set_Input>;
  pk_columns: Nflx_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nflx_Company_ManyArgs = {
  updates: Array<Nflx_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nrg_ClaimArgs = {
  _set?: Maybe<Nrg_Claim_Set_Input>;
  where: Nrg_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nrg_Claim_By_PkArgs = {
  _set?: Maybe<Nrg_Claim_Set_Input>;
  pk_columns: Nrg_Claim_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nrg_Claim_ManyArgs = {
  updates: Array<Nrg_Claim_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nrg_CompanyArgs = {
  _set?: Maybe<Nrg_Company_Set_Input>;
  where: Nrg_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nrg_Company_By_PkArgs = {
  _set?: Maybe<Nrg_Company_Set_Input>;
  pk_columns: Nrg_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nrg_Company_ManyArgs = {
  updates: Array<Nrg_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ocg_ClaimArgs = {
  _inc?: Maybe<Ocg_Claim_Inc_Input>;
  _set?: Maybe<Ocg_Claim_Set_Input>;
  where: Ocg_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ocg_Claim_By_PkArgs = {
  _inc?: Maybe<Ocg_Claim_Inc_Input>;
  _set?: Maybe<Ocg_Claim_Set_Input>;
  pk_columns: Ocg_Claim_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ocg_Claim_ManyArgs = {
  updates: Array<Ocg_Claim_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ocg_CompanyArgs = {
  _set?: Maybe<Ocg_Company_Set_Input>;
  where: Ocg_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ocg_Company_By_PkArgs = {
  _set?: Maybe<Ocg_Company_Set_Input>;
  pk_columns: Ocg_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ocg_Company_ManyArgs = {
  updates: Array<Ocg_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _inc?: Maybe<Payment_Inc_Input>;
  _set?: Maybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _inc?: Maybe<Payment_Inc_Input>;
  _set?: Maybe<Payment_Set_Input>;
  pk_columns: Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_ManyArgs = {
  updates: Array<Payment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_StateArgs = {
  _set?: Maybe<Payment_State_Set_Input>;
  where: Payment_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_State_By_PkArgs = {
  _set?: Maybe<Payment_State_Set_Input>;
  pk_columns: Payment_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_State_ManyArgs = {
  updates: Array<Payment_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pkv_ClaimArgs = {
  _append?: Maybe<Pkv_Claim_Append_Input>;
  _delete_at_path?: Maybe<Pkv_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pkv_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Pkv_Claim_Delete_Key_Input>;
  _prepend?: Maybe<Pkv_Claim_Prepend_Input>;
  _set?: Maybe<Pkv_Claim_Set_Input>;
  where: Pkv_Claim_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pkv_Claim_By_PkArgs = {
  _append?: Maybe<Pkv_Claim_Append_Input>;
  _delete_at_path?: Maybe<Pkv_Claim_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pkv_Claim_Delete_Elem_Input>;
  _delete_key?: Maybe<Pkv_Claim_Delete_Key_Input>;
  _prepend?: Maybe<Pkv_Claim_Prepend_Input>;
  _set?: Maybe<Pkv_Claim_Set_Input>;
  pk_columns: Pkv_Claim_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pkv_Claim_ManyArgs = {
  updates: Array<Pkv_Claim_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pkv_CompanyArgs = {
  _set?: Maybe<Pkv_Company_Set_Input>;
  where: Pkv_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pkv_Company_By_PkArgs = {
  _set?: Maybe<Pkv_Company_Set_Input>;
  pk_columns: Pkv_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pkv_Company_ManyArgs = {
  updates: Array<Pkv_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _set?: Maybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Ps_DebtorArgs = {
  _append?: Maybe<Ps_Debtor_Append_Input>;
  _delete_at_path?: Maybe<Ps_Debtor_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ps_Debtor_Delete_Elem_Input>;
  _delete_key?: Maybe<Ps_Debtor_Delete_Key_Input>;
  _inc?: Maybe<Ps_Debtor_Inc_Input>;
  _prepend?: Maybe<Ps_Debtor_Prepend_Input>;
  _set?: Maybe<Ps_Debtor_Set_Input>;
  where: Ps_Debtor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ps_Debtor_By_PkArgs = {
  _append?: Maybe<Ps_Debtor_Append_Input>;
  _delete_at_path?: Maybe<Ps_Debtor_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Ps_Debtor_Delete_Elem_Input>;
  _delete_key?: Maybe<Ps_Debtor_Delete_Key_Input>;
  _inc?: Maybe<Ps_Debtor_Inc_Input>;
  _prepend?: Maybe<Ps_Debtor_Prepend_Input>;
  _set?: Maybe<Ps_Debtor_Set_Input>;
  pk_columns: Ps_Debtor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ps_Debtor_ManyArgs = {
  updates: Array<Ps_Debtor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _set?: Maybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_ManyArgs = {
  updates: Array<Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_Raw_DataArgs = {
  _append?: Maybe<Third_Party_Raw_Data_Append_Input>;
  _delete_at_path?: Maybe<Third_Party_Raw_Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Third_Party_Raw_Data_Delete_Elem_Input>;
  _delete_key?: Maybe<Third_Party_Raw_Data_Delete_Key_Input>;
  _prepend?: Maybe<Third_Party_Raw_Data_Prepend_Input>;
  _set?: Maybe<Third_Party_Raw_Data_Set_Input>;
  where: Third_Party_Raw_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_Raw_Data_By_PkArgs = {
  _append?: Maybe<Third_Party_Raw_Data_Append_Input>;
  _delete_at_path?: Maybe<Third_Party_Raw_Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Third_Party_Raw_Data_Delete_Elem_Input>;
  _delete_key?: Maybe<Third_Party_Raw_Data_Delete_Key_Input>;
  _prepend?: Maybe<Third_Party_Raw_Data_Prepend_Input>;
  _set?: Maybe<Third_Party_Raw_Data_Set_Input>;
  pk_columns: Third_Party_Raw_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_Raw_Data_ManyArgs = {
  updates: Array<Third_Party_Raw_Data_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _set?: Maybe<User_Role_Set_Input>;
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _set?: Maybe<User_Role_Set_Input>;
  pk_columns: User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Role_ManyArgs = {
  updates: Array<User_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUser_CreateArgs = {
  input: UserCreateInput;
};

/** columns and relationships of "nflx_claim" */
export type Nflx_Claim = {
  __typename?: 'nflx_claim';
  account_details?: Maybe<Scalars['jsonb']>;
  account_email?: Maybe<Scalars['String']>;
  billing_history?: Maybe<Scalars['jsonb']>;
  calculation?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  /** An object relationship */
  company: Nflx_Company;
  company_id: Scalars['String'];
  current_tariff?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_name?: Maybe<Scalars['String']>;
  messages?: Maybe<Scalars['jsonb']>;
  start_of_membership?: Maybe<Scalars['date']>;
};


/** columns and relationships of "nflx_claim" */
export type Nflx_ClaimAccount_DetailsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "nflx_claim" */
export type Nflx_ClaimBilling_HistoryArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "nflx_claim" */
export type Nflx_ClaimCalculationArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "nflx_claim" */
export type Nflx_ClaimMessagesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "nflx_claim" */
export type Nflx_Claim_Aggregate = {
  __typename?: 'nflx_claim_aggregate';
  aggregate?: Maybe<Nflx_Claim_Aggregate_Fields>;
  nodes: Array<Nflx_Claim>;
};

/** aggregate fields of "nflx_claim" */
export type Nflx_Claim_Aggregate_Fields = {
  __typename?: 'nflx_claim_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nflx_Claim_Max_Fields>;
  min?: Maybe<Nflx_Claim_Min_Fields>;
};


/** aggregate fields of "nflx_claim" */
export type Nflx_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Nflx_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Nflx_Claim_Append_Input = {
  account_details?: Maybe<Scalars['jsonb']>;
  billing_history?: Maybe<Scalars['jsonb']>;
  calculation?: Maybe<Scalars['jsonb']>;
  messages?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "nflx_claim". All fields are combined with a logical 'AND'. */
export type Nflx_Claim_Bool_Exp = {
  _and?: Maybe<Array<Nflx_Claim_Bool_Exp>>;
  _not?: Maybe<Nflx_Claim_Bool_Exp>;
  _or?: Maybe<Array<Nflx_Claim_Bool_Exp>>;
  account_details?: Maybe<Jsonb_Comparison_Exp>;
  account_email?: Maybe<String_Comparison_Exp>;
  billing_history?: Maybe<Jsonb_Comparison_Exp>;
  calculation?: Maybe<Jsonb_Comparison_Exp>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  company?: Maybe<Nflx_Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  current_tariff?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  messages?: Maybe<Jsonb_Comparison_Exp>;
  start_of_membership?: Maybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "nflx_claim" */
export enum Nflx_Claim_Constraint {
  /** unique or primary key constraint on columns "case_id" */
  NflxClaimCaseIdKey = 'nflx_claim_case_id_key',
  /** unique or primary key constraint on columns "id" */
  NflxClaimPkey = 'nflx_claim_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Nflx_Claim_Delete_At_Path_Input = {
  account_details?: Maybe<Array<Scalars['String']>>;
  billing_history?: Maybe<Array<Scalars['String']>>;
  calculation?: Maybe<Array<Scalars['String']>>;
  messages?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Nflx_Claim_Delete_Elem_Input = {
  account_details?: Maybe<Scalars['Int']>;
  billing_history?: Maybe<Scalars['Int']>;
  calculation?: Maybe<Scalars['Int']>;
  messages?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Nflx_Claim_Delete_Key_Input = {
  account_details?: Maybe<Scalars['String']>;
  billing_history?: Maybe<Scalars['String']>;
  calculation?: Maybe<Scalars['String']>;
  messages?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "nflx_claim" */
export type Nflx_Claim_Insert_Input = {
  account_details?: Maybe<Scalars['jsonb']>;
  account_email?: Maybe<Scalars['String']>;
  billing_history?: Maybe<Scalars['jsonb']>;
  calculation?: Maybe<Scalars['jsonb']>;
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  company?: Maybe<Nflx_Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  current_tariff?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  messages?: Maybe<Scalars['jsonb']>;
  start_of_membership?: Maybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Nflx_Claim_Max_Fields = {
  __typename?: 'nflx_claim_max_fields';
  account_email?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  current_tariff?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  start_of_membership?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Nflx_Claim_Min_Fields = {
  __typename?: 'nflx_claim_min_fields';
  account_email?: Maybe<Scalars['String']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  current_tariff?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  start_of_membership?: Maybe<Scalars['date']>;
};

/** response of any mutation on the table "nflx_claim" */
export type Nflx_Claim_Mutation_Response = {
  __typename?: 'nflx_claim_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nflx_Claim>;
};

/** input type for inserting object relation for remote table "nflx_claim" */
export type Nflx_Claim_Obj_Rel_Insert_Input = {
  data: Nflx_Claim_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Nflx_Claim_On_Conflict>;
};

/** on_conflict condition type for table "nflx_claim" */
export type Nflx_Claim_On_Conflict = {
  constraint: Nflx_Claim_Constraint;
  update_columns?: Array<Nflx_Claim_Update_Column>;
  where?: Maybe<Nflx_Claim_Bool_Exp>;
};

/** Ordering options when selecting data from "nflx_claim". */
export type Nflx_Claim_Order_By = {
  account_details?: Maybe<Order_By>;
  account_email?: Maybe<Order_By>;
  billing_history?: Maybe<Order_By>;
  calculation?: Maybe<Order_By>;
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  company?: Maybe<Nflx_Company_Order_By>;
  company_id?: Maybe<Order_By>;
  current_tariff?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  messages?: Maybe<Order_By>;
  start_of_membership?: Maybe<Order_By>;
};

/** primary key columns input for table: nflx_claim */
export type Nflx_Claim_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Nflx_Claim_Prepend_Input = {
  account_details?: Maybe<Scalars['jsonb']>;
  billing_history?: Maybe<Scalars['jsonb']>;
  calculation?: Maybe<Scalars['jsonb']>;
  messages?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "nflx_claim" */
export enum Nflx_Claim_Select_Column {
  /** column name */
  AccountDetails = 'account_details',
  /** column name */
  AccountEmail = 'account_email',
  /** column name */
  BillingHistory = 'billing_history',
  /** column name */
  Calculation = 'calculation',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrentTariff = 'current_tariff',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Messages = 'messages',
  /** column name */
  StartOfMembership = 'start_of_membership'
}

/** input type for updating data in table "nflx_claim" */
export type Nflx_Claim_Set_Input = {
  account_details?: Maybe<Scalars['jsonb']>;
  account_email?: Maybe<Scalars['String']>;
  billing_history?: Maybe<Scalars['jsonb']>;
  calculation?: Maybe<Scalars['jsonb']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  current_tariff?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  messages?: Maybe<Scalars['jsonb']>;
  start_of_membership?: Maybe<Scalars['date']>;
};

/** Streaming cursor of the table "nflx_claim" */
export type Nflx_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nflx_Claim_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nflx_Claim_Stream_Cursor_Value_Input = {
  account_details?: Maybe<Scalars['jsonb']>;
  account_email?: Maybe<Scalars['String']>;
  billing_history?: Maybe<Scalars['jsonb']>;
  calculation?: Maybe<Scalars['jsonb']>;
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  current_tariff?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  messages?: Maybe<Scalars['jsonb']>;
  start_of_membership?: Maybe<Scalars['date']>;
};

/** update columns of table "nflx_claim" */
export enum Nflx_Claim_Update_Column {
  /** column name */
  AccountDetails = 'account_details',
  /** column name */
  AccountEmail = 'account_email',
  /** column name */
  BillingHistory = 'billing_history',
  /** column name */
  Calculation = 'calculation',
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrentTariff = 'current_tariff',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Messages = 'messages',
  /** column name */
  StartOfMembership = 'start_of_membership'
}

export type Nflx_Claim_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Nflx_Claim_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Nflx_Claim_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Nflx_Claim_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Nflx_Claim_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Nflx_Claim_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Nflx_Claim_Set_Input>;
  where: Nflx_Claim_Bool_Exp;
};

/** columns and relationships of "nflx_company" */
export type Nflx_Company = {
  __typename?: 'nflx_company';
  id: Scalars['String'];
  is_supported: Scalars['Boolean'];
  name: Scalars['String'];
};

/** aggregated selection of "nflx_company" */
export type Nflx_Company_Aggregate = {
  __typename?: 'nflx_company_aggregate';
  aggregate?: Maybe<Nflx_Company_Aggregate_Fields>;
  nodes: Array<Nflx_Company>;
};

/** aggregate fields of "nflx_company" */
export type Nflx_Company_Aggregate_Fields = {
  __typename?: 'nflx_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nflx_Company_Max_Fields>;
  min?: Maybe<Nflx_Company_Min_Fields>;
};


/** aggregate fields of "nflx_company" */
export type Nflx_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Nflx_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nflx_company". All fields are combined with a logical 'AND'. */
export type Nflx_Company_Bool_Exp = {
  _and?: Maybe<Array<Nflx_Company_Bool_Exp>>;
  _not?: Maybe<Nflx_Company_Bool_Exp>;
  _or?: Maybe<Array<Nflx_Company_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_supported?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nflx_company" */
export enum Nflx_Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  NflxCompanyPkey = 'nflx_company_pkey'
}

/** input type for inserting data into table "nflx_company" */
export type Nflx_Company_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nflx_Company_Max_Fields = {
  __typename?: 'nflx_company_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nflx_Company_Min_Fields = {
  __typename?: 'nflx_company_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nflx_company" */
export type Nflx_Company_Mutation_Response = {
  __typename?: 'nflx_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nflx_Company>;
};

/** input type for inserting object relation for remote table "nflx_company" */
export type Nflx_Company_Obj_Rel_Insert_Input = {
  data: Nflx_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Nflx_Company_On_Conflict>;
};

/** on_conflict condition type for table "nflx_company" */
export type Nflx_Company_On_Conflict = {
  constraint: Nflx_Company_Constraint;
  update_columns?: Array<Nflx_Company_Update_Column>;
  where?: Maybe<Nflx_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "nflx_company". */
export type Nflx_Company_Order_By = {
  id?: Maybe<Order_By>;
  is_supported?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: nflx_company */
export type Nflx_Company_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "nflx_company" */
export enum Nflx_Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "nflx_company" */
export type Nflx_Company_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "nflx_company" */
export type Nflx_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nflx_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nflx_Company_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "nflx_company" */
export enum Nflx_Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

export type Nflx_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Nflx_Company_Set_Input>;
  where: Nflx_Company_Bool_Exp;
};

/** columns and relationships of "nrg_claim" */
export type Nrg_Claim = {
  __typename?: 'nrg_claim';
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  /** An object relationship */
  company: Nrg_Company;
  company_id: Scalars['String'];
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  contract_type_after_suspension?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  date_of_suspension?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  meter_number?: Maybe<Scalars['String']>;
};

/** aggregated selection of "nrg_claim" */
export type Nrg_Claim_Aggregate = {
  __typename?: 'nrg_claim_aggregate';
  aggregate?: Maybe<Nrg_Claim_Aggregate_Fields>;
  nodes: Array<Nrg_Claim>;
};

/** aggregate fields of "nrg_claim" */
export type Nrg_Claim_Aggregate_Fields = {
  __typename?: 'nrg_claim_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nrg_Claim_Max_Fields>;
  min?: Maybe<Nrg_Claim_Min_Fields>;
};


/** aggregate fields of "nrg_claim" */
export type Nrg_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Nrg_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nrg_claim". All fields are combined with a logical 'AND'. */
export type Nrg_Claim_Bool_Exp = {
  _and?: Maybe<Array<Nrg_Claim_Bool_Exp>>;
  _not?: Maybe<Nrg_Claim_Bool_Exp>;
  _or?: Maybe<Array<Nrg_Claim_Bool_Exp>>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  company?: Maybe<Nrg_Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  contract_end_date?: Maybe<Date_Comparison_Exp>;
  contract_number?: Maybe<String_Comparison_Exp>;
  contract_type_after_suspension?: Maybe<String_Comparison_Exp>;
  customer_type?: Maybe<String_Comparison_Exp>;
  date_of_suspension?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  meter_number?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nrg_claim" */
export enum Nrg_Claim_Constraint {
  /** unique or primary key constraint on columns "case_id" */
  NrgClaimCaseIdKey = 'nrg_claim_case_id_key',
  /** unique or primary key constraint on columns "id" */
  NrgClaimPkey = 'nrg_claim_pkey'
}

/** input type for inserting data into table "nrg_claim" */
export type Nrg_Claim_Insert_Input = {
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  company?: Maybe<Nrg_Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  contract_type_after_suspension?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  date_of_suspension?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meter_number?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nrg_Claim_Max_Fields = {
  __typename?: 'nrg_claim_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  contract_type_after_suspension?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  date_of_suspension?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meter_number?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nrg_Claim_Min_Fields = {
  __typename?: 'nrg_claim_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  contract_type_after_suspension?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  date_of_suspension?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meter_number?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nrg_claim" */
export type Nrg_Claim_Mutation_Response = {
  __typename?: 'nrg_claim_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nrg_Claim>;
};

/** input type for inserting object relation for remote table "nrg_claim" */
export type Nrg_Claim_Obj_Rel_Insert_Input = {
  data: Nrg_Claim_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Nrg_Claim_On_Conflict>;
};

/** on_conflict condition type for table "nrg_claim" */
export type Nrg_Claim_On_Conflict = {
  constraint: Nrg_Claim_Constraint;
  update_columns?: Array<Nrg_Claim_Update_Column>;
  where?: Maybe<Nrg_Claim_Bool_Exp>;
};

/** Ordering options when selecting data from "nrg_claim". */
export type Nrg_Claim_Order_By = {
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  company?: Maybe<Nrg_Company_Order_By>;
  company_id?: Maybe<Order_By>;
  contract_end_date?: Maybe<Order_By>;
  contract_number?: Maybe<Order_By>;
  contract_type_after_suspension?: Maybe<Order_By>;
  customer_type?: Maybe<Order_By>;
  date_of_suspension?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meter_number?: Maybe<Order_By>;
};

/** primary key columns input for table: nrg_claim */
export type Nrg_Claim_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "nrg_claim" */
export enum Nrg_Claim_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractEndDate = 'contract_end_date',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  ContractTypeAfterSuspension = 'contract_type_after_suspension',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  DateOfSuspension = 'date_of_suspension',
  /** column name */
  Id = 'id',
  /** column name */
  MeterNumber = 'meter_number'
}

/** input type for updating data in table "nrg_claim" */
export type Nrg_Claim_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  contract_type_after_suspension?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  date_of_suspension?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meter_number?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "nrg_claim" */
export type Nrg_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nrg_Claim_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nrg_Claim_Stream_Cursor_Value_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  contract_end_date?: Maybe<Scalars['date']>;
  contract_number?: Maybe<Scalars['String']>;
  contract_type_after_suspension?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  date_of_suspension?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  meter_number?: Maybe<Scalars['String']>;
};

/** update columns of table "nrg_claim" */
export enum Nrg_Claim_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  ContractEndDate = 'contract_end_date',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  ContractTypeAfterSuspension = 'contract_type_after_suspension',
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  DateOfSuspension = 'date_of_suspension',
  /** column name */
  Id = 'id',
  /** column name */
  MeterNumber = 'meter_number'
}

export type Nrg_Claim_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Nrg_Claim_Set_Input>;
  where: Nrg_Claim_Bool_Exp;
};

/** columns and relationships of "nrg_company" */
export type Nrg_Company = {
  __typename?: 'nrg_company';
  id: Scalars['String'];
  is_supported: Scalars['Boolean'];
  name: Scalars['String'];
};

/** aggregated selection of "nrg_company" */
export type Nrg_Company_Aggregate = {
  __typename?: 'nrg_company_aggregate';
  aggregate?: Maybe<Nrg_Company_Aggregate_Fields>;
  nodes: Array<Nrg_Company>;
};

/** aggregate fields of "nrg_company" */
export type Nrg_Company_Aggregate_Fields = {
  __typename?: 'nrg_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Nrg_Company_Max_Fields>;
  min?: Maybe<Nrg_Company_Min_Fields>;
};


/** aggregate fields of "nrg_company" */
export type Nrg_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Nrg_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "nrg_company". All fields are combined with a logical 'AND'. */
export type Nrg_Company_Bool_Exp = {
  _and?: Maybe<Array<Nrg_Company_Bool_Exp>>;
  _not?: Maybe<Nrg_Company_Bool_Exp>;
  _or?: Maybe<Array<Nrg_Company_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_supported?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nrg_company" */
export enum Nrg_Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  NrgCompanyPkey = 'nrg_company_pkey'
}

/** input type for inserting data into table "nrg_company" */
export type Nrg_Company_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Nrg_Company_Max_Fields = {
  __typename?: 'nrg_company_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Nrg_Company_Min_Fields = {
  __typename?: 'nrg_company_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "nrg_company" */
export type Nrg_Company_Mutation_Response = {
  __typename?: 'nrg_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Nrg_Company>;
};

/** input type for inserting object relation for remote table "nrg_company" */
export type Nrg_Company_Obj_Rel_Insert_Input = {
  data: Nrg_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Nrg_Company_On_Conflict>;
};

/** on_conflict condition type for table "nrg_company" */
export type Nrg_Company_On_Conflict = {
  constraint: Nrg_Company_Constraint;
  update_columns?: Array<Nrg_Company_Update_Column>;
  where?: Maybe<Nrg_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "nrg_company". */
export type Nrg_Company_Order_By = {
  id?: Maybe<Order_By>;
  is_supported?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: nrg_company */
export type Nrg_Company_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "nrg_company" */
export enum Nrg_Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "nrg_company" */
export type Nrg_Company_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "nrg_company" */
export type Nrg_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nrg_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nrg_Company_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "nrg_company" */
export enum Nrg_Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

export type Nrg_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Nrg_Company_Set_Input>;
  where: Nrg_Company_Bool_Exp;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "ocg_claim" */
export type Ocg_Claim = {
  __typename?: 'ocg_claim';
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  /** An object relationship */
  company: Ocg_Company;
  company_id: Scalars['String'];
  id: Scalars['uuid'];
  is_sports_betting: Scalars['Boolean'];
  money_spent: Scalars['numeric'];
};

/** aggregated selection of "ocg_claim" */
export type Ocg_Claim_Aggregate = {
  __typename?: 'ocg_claim_aggregate';
  aggregate?: Maybe<Ocg_Claim_Aggregate_Fields>;
  nodes: Array<Ocg_Claim>;
};

/** aggregate fields of "ocg_claim" */
export type Ocg_Claim_Aggregate_Fields = {
  __typename?: 'ocg_claim_aggregate_fields';
  avg?: Maybe<Ocg_Claim_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ocg_Claim_Max_Fields>;
  min?: Maybe<Ocg_Claim_Min_Fields>;
  stddev?: Maybe<Ocg_Claim_Stddev_Fields>;
  stddev_pop?: Maybe<Ocg_Claim_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ocg_Claim_Stddev_Samp_Fields>;
  sum?: Maybe<Ocg_Claim_Sum_Fields>;
  var_pop?: Maybe<Ocg_Claim_Var_Pop_Fields>;
  var_samp?: Maybe<Ocg_Claim_Var_Samp_Fields>;
  variance?: Maybe<Ocg_Claim_Variance_Fields>;
};


/** aggregate fields of "ocg_claim" */
export type Ocg_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ocg_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ocg_Claim_Avg_Fields = {
  __typename?: 'ocg_claim_avg_fields';
  money_spent?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ocg_claim". All fields are combined with a logical 'AND'. */
export type Ocg_Claim_Bool_Exp = {
  _and?: Maybe<Array<Ocg_Claim_Bool_Exp>>;
  _not?: Maybe<Ocg_Claim_Bool_Exp>;
  _or?: Maybe<Array<Ocg_Claim_Bool_Exp>>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  company?: Maybe<Ocg_Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_sports_betting?: Maybe<Boolean_Comparison_Exp>;
  money_spent?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "ocg_claim" */
export enum Ocg_Claim_Constraint {
  /** unique or primary key constraint on columns "case_id" */
  OcgClaimCaseIdKey = 'ocg_claim_case_id_key',
  /** unique or primary key constraint on columns "id" */
  OcgClaimPkey = 'ocg_claim_pkey'
}

/** input type for incrementing numeric columns in table "ocg_claim" */
export type Ocg_Claim_Inc_Input = {
  money_spent?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ocg_claim" */
export type Ocg_Claim_Insert_Input = {
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  company?: Maybe<Ocg_Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_sports_betting?: Maybe<Scalars['Boolean']>;
  money_spent?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Ocg_Claim_Max_Fields = {
  __typename?: 'ocg_claim_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  money_spent?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Ocg_Claim_Min_Fields = {
  __typename?: 'ocg_claim_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  money_spent?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "ocg_claim" */
export type Ocg_Claim_Mutation_Response = {
  __typename?: 'ocg_claim_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ocg_Claim>;
};

/** input type for inserting object relation for remote table "ocg_claim" */
export type Ocg_Claim_Obj_Rel_Insert_Input = {
  data: Ocg_Claim_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ocg_Claim_On_Conflict>;
};

/** on_conflict condition type for table "ocg_claim" */
export type Ocg_Claim_On_Conflict = {
  constraint: Ocg_Claim_Constraint;
  update_columns?: Array<Ocg_Claim_Update_Column>;
  where?: Maybe<Ocg_Claim_Bool_Exp>;
};

/** Ordering options when selecting data from "ocg_claim". */
export type Ocg_Claim_Order_By = {
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  company?: Maybe<Ocg_Company_Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_sports_betting?: Maybe<Order_By>;
  money_spent?: Maybe<Order_By>;
};

/** primary key columns input for table: ocg_claim */
export type Ocg_Claim_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ocg_claim" */
export enum Ocg_Claim_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSportsBetting = 'is_sports_betting',
  /** column name */
  MoneySpent = 'money_spent'
}

/** input type for updating data in table "ocg_claim" */
export type Ocg_Claim_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_sports_betting?: Maybe<Scalars['Boolean']>;
  money_spent?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Ocg_Claim_Stddev_Fields = {
  __typename?: 'ocg_claim_stddev_fields';
  money_spent?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ocg_Claim_Stddev_Pop_Fields = {
  __typename?: 'ocg_claim_stddev_pop_fields';
  money_spent?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ocg_Claim_Stddev_Samp_Fields = {
  __typename?: 'ocg_claim_stddev_samp_fields';
  money_spent?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ocg_claim" */
export type Ocg_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ocg_Claim_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ocg_Claim_Stream_Cursor_Value_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_sports_betting?: Maybe<Scalars['Boolean']>;
  money_spent?: Maybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Ocg_Claim_Sum_Fields = {
  __typename?: 'ocg_claim_sum_fields';
  money_spent?: Maybe<Scalars['numeric']>;
};

/** update columns of table "ocg_claim" */
export enum Ocg_Claim_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSportsBetting = 'is_sports_betting',
  /** column name */
  MoneySpent = 'money_spent'
}

export type Ocg_Claim_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ocg_Claim_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ocg_Claim_Set_Input>;
  where: Ocg_Claim_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ocg_Claim_Var_Pop_Fields = {
  __typename?: 'ocg_claim_var_pop_fields';
  money_spent?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ocg_Claim_Var_Samp_Fields = {
  __typename?: 'ocg_claim_var_samp_fields';
  money_spent?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ocg_Claim_Variance_Fields = {
  __typename?: 'ocg_claim_variance_fields';
  money_spent?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "ocg_company" */
export type Ocg_Company = {
  __typename?: 'ocg_company';
  id: Scalars['String'];
  is_supported?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/** aggregated selection of "ocg_company" */
export type Ocg_Company_Aggregate = {
  __typename?: 'ocg_company_aggregate';
  aggregate?: Maybe<Ocg_Company_Aggregate_Fields>;
  nodes: Array<Ocg_Company>;
};

/** aggregate fields of "ocg_company" */
export type Ocg_Company_Aggregate_Fields = {
  __typename?: 'ocg_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ocg_Company_Max_Fields>;
  min?: Maybe<Ocg_Company_Min_Fields>;
};


/** aggregate fields of "ocg_company" */
export type Ocg_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ocg_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ocg_company". All fields are combined with a logical 'AND'. */
export type Ocg_Company_Bool_Exp = {
  _and?: Maybe<Array<Ocg_Company_Bool_Exp>>;
  _not?: Maybe<Ocg_Company_Bool_Exp>;
  _or?: Maybe<Array<Ocg_Company_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_supported?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ocg_company" */
export enum Ocg_Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  OcgCompanyPkey = 'ocg_company_pkey'
}

/** input type for inserting data into table "ocg_company" */
export type Ocg_Company_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ocg_Company_Max_Fields = {
  __typename?: 'ocg_company_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ocg_Company_Min_Fields = {
  __typename?: 'ocg_company_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ocg_company" */
export type Ocg_Company_Mutation_Response = {
  __typename?: 'ocg_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ocg_Company>;
};

/** input type for inserting object relation for remote table "ocg_company" */
export type Ocg_Company_Obj_Rel_Insert_Input = {
  data: Ocg_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ocg_Company_On_Conflict>;
};

/** on_conflict condition type for table "ocg_company" */
export type Ocg_Company_On_Conflict = {
  constraint: Ocg_Company_Constraint;
  update_columns?: Array<Ocg_Company_Update_Column>;
  where?: Maybe<Ocg_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "ocg_company". */
export type Ocg_Company_Order_By = {
  id?: Maybe<Order_By>;
  is_supported?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: ocg_company */
export type Ocg_Company_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "ocg_company" */
export enum Ocg_Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "ocg_company" */
export type Ocg_Company_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "ocg_company" */
export type Ocg_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ocg_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ocg_Company_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "ocg_company" */
export enum Ocg_Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

export type Ocg_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ocg_Company_Set_Input>;
  where: Ocg_Company_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment" */
export type Payment = {
  __typename?: 'payment';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamp'];
  finance_id: Scalars['Int'];
  /** An array relationship */
  gdpr_offer_payments: Array<Gdpr_Offer_Payment>;
  /** An aggregate relationship */
  gdpr_offer_payments_aggregate: Gdpr_Offer_Payment_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  offer: Base_Offer;
  offer_id: Scalars['uuid'];
  recipient_iban: Scalars['String'];
  state: Payment_State_Enum;
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "payment" */
export type PaymentGdpr_Offer_PaymentsArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};


/** columns and relationships of "payment" */
export type PaymentGdpr_Offer_Payments_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};

/** aggregated selection of "payment" */
export type Payment_Aggregate = {
  __typename?: 'payment_aggregate';
  aggregate?: Maybe<Payment_Aggregate_Fields>;
  nodes: Array<Payment>;
};

export type Payment_Aggregate_Bool_Exp = {
  count?: Maybe<Payment_Aggregate_Bool_Exp_Count>;
};

export type Payment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Payment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment" */
export type Payment_Aggregate_Fields = {
  __typename?: 'payment_aggregate_fields';
  avg?: Maybe<Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Max_Fields>;
  min?: Maybe<Payment_Min_Fields>;
  stddev?: Maybe<Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Sum_Fields>;
  var_pop?: Maybe<Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Var_Samp_Fields>;
  variance?: Maybe<Payment_Variance_Fields>;
};


/** aggregate fields of "payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment" */
export type Payment_Aggregate_Order_By = {
  avg?: Maybe<Payment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Max_Order_By>;
  min?: Maybe<Payment_Min_Order_By>;
  stddev?: Maybe<Payment_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Sum_Order_By>;
  var_pop?: Maybe<Payment_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment" */
export type Payment_Arr_Rel_Insert_Input = {
  data: Array<Payment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Avg_Fields = {
  __typename?: 'payment_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  finance_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment" */
export type Payment_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  _and?: Maybe<Array<Payment_Bool_Exp>>;
  _not?: Maybe<Payment_Bool_Exp>;
  _or?: Maybe<Array<Payment_Bool_Exp>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  finance_id?: Maybe<Int_Comparison_Exp>;
  gdpr_offer_payments?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
  gdpr_offer_payments_aggregate?: Maybe<Gdpr_Offer_Payment_Aggregate_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Base_Offer_Bool_Exp>;
  offer_id?: Maybe<Uuid_Comparison_Exp>;
  recipient_iban?: Maybe<String_Comparison_Exp>;
  state?: Maybe<Payment_State_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentPkey = 'payment_pkey'
}

/** input type for incrementing numeric columns in table "payment" */
export type Payment_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  finance_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment" */
export type Payment_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  finance_id?: Maybe<Scalars['Int']>;
  gdpr_offer_payments?: Maybe<Gdpr_Offer_Payment_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Base_Offer_Obj_Rel_Insert_Input>;
  offer_id?: Maybe<Scalars['uuid']>;
  recipient_iban?: Maybe<Scalars['String']>;
  state?: Maybe<Payment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Payment_Max_Fields = {
  __typename?: 'payment_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  finance_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  recipient_iban?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "payment" */
export type Payment_Max_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  recipient_iban?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Min_Fields = {
  __typename?: 'payment_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  finance_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  recipient_iban?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "payment" */
export type Payment_Min_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer_id?: Maybe<Order_By>;
  recipient_iban?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "payment" */
export type Payment_Mutation_Response = {
  __typename?: 'payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment>;
};

/** input type for inserting object relation for remote table "payment" */
export type Payment_Obj_Rel_Insert_Input = {
  data: Payment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Payment_On_Conflict>;
};

/** on_conflict condition type for table "payment" */
export type Payment_On_Conflict = {
  constraint: Payment_Constraint;
  update_columns?: Array<Payment_Update_Column>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "payment". */
export type Payment_Order_By = {
  amount?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
  gdpr_offer_payments_aggregate?: Maybe<Gdpr_Offer_Payment_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  offer?: Maybe<Base_Offer_Order_By>;
  offer_id?: Maybe<Order_By>;
  recipient_iban?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: payment */
export type Payment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payment" */
export enum Payment_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinanceId = 'finance_id',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  RecipientIban = 'recipient_iban',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "payment" */
export type Payment_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  finance_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  recipient_iban?: Maybe<Scalars['String']>;
  state?: Maybe<Payment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** columns and relationships of "payment_state" */
export type Payment_State = {
  __typename?: 'payment_state';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "payment_state" */
export type Payment_State_Aggregate = {
  __typename?: 'payment_state_aggregate';
  aggregate?: Maybe<Payment_State_Aggregate_Fields>;
  nodes: Array<Payment_State>;
};

/** aggregate fields of "payment_state" */
export type Payment_State_Aggregate_Fields = {
  __typename?: 'payment_state_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_State_Max_Fields>;
  min?: Maybe<Payment_State_Min_Fields>;
};


/** aggregate fields of "payment_state" */
export type Payment_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_State_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_state". All fields are combined with a logical 'AND'. */
export type Payment_State_Bool_Exp = {
  _and?: Maybe<Array<Payment_State_Bool_Exp>>;
  _not?: Maybe<Payment_State_Bool_Exp>;
  _or?: Maybe<Array<Payment_State_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_state" */
export enum Payment_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentStatePkey = 'payment_state_pkey'
}

export enum Payment_State_Enum {
  /** Payment deleted */
  Deleted = 'DELETED',
  /** Payment failed */
  Failed = 'FAILED',
  /** Payment initialized */
  Initialized = 'INITIALIZED',
  /** Payment successful */
  Successful = 'SUCCESSFUL'
}

/** Boolean expression to compare columns of type "payment_state_enum". All fields are combined with logical 'AND'. */
export type Payment_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Payment_State_Enum>;
  _in?: Maybe<Array<Payment_State_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Payment_State_Enum>;
  _nin?: Maybe<Array<Payment_State_Enum>>;
};

/** input type for inserting data into table "payment_state" */
export type Payment_State_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_State_Max_Fields = {
  __typename?: 'payment_state_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_State_Min_Fields = {
  __typename?: 'payment_state_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_state" */
export type Payment_State_Mutation_Response = {
  __typename?: 'payment_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_State>;
};

/** on_conflict condition type for table "payment_state" */
export type Payment_State_On_Conflict = {
  constraint: Payment_State_Constraint;
  update_columns?: Array<Payment_State_Update_Column>;
  where?: Maybe<Payment_State_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_state". */
export type Payment_State_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: payment_state */
export type Payment_State_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "payment_state" */
export enum Payment_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_state" */
export type Payment_State_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_state" */
export type Payment_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_State_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "payment_state" */
export enum Payment_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Payment_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payment_State_Set_Input>;
  where: Payment_State_Bool_Exp;
};

/** aggregate stddev on columns */
export type Payment_Stddev_Fields = {
  __typename?: 'payment_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  finance_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment" */
export type Payment_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Stddev_Pop_Fields = {
  __typename?: 'payment_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  finance_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment" */
export type Payment_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Stddev_Samp_Fields = {
  __typename?: 'payment_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  finance_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment" */
export type Payment_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "payment" */
export type Payment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamp']>;
  finance_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  offer_id?: Maybe<Scalars['uuid']>;
  recipient_iban?: Maybe<Scalars['String']>;
  state?: Maybe<Payment_State_Enum>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Payment_Sum_Fields = {
  __typename?: 'payment_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  finance_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment" */
export type Payment_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** update columns of table "payment" */
export enum Payment_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FinanceId = 'finance_id',
  /** column name */
  Id = 'id',
  /** column name */
  OfferId = 'offer_id',
  /** column name */
  RecipientIban = 'recipient_iban',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Payment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Payment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Var_Pop_Fields = {
  __typename?: 'payment_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  finance_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment" */
export type Payment_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Var_Samp_Fields = {
  __typename?: 'payment_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  finance_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment" */
export type Payment_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Variance_Fields = {
  __typename?: 'payment_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  finance_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment" */
export type Payment_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  finance_id?: Maybe<Order_By>;
};

/** columns and relationships of "pkv_claim" */
export type Pkv_Claim = {
  __typename?: 'pkv_claim';
  /** An object relationship */
  case: Base_Case;
  case_id: Scalars['uuid'];
  case_type?: Maybe<Scalars['String']>;
  /** An object relationship */
  company?: Maybe<Pkv_Company>;
  company_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  insurance_increase_date?: Maybe<Scalars['date']>;
  insured_persons: Scalars['jsonb'];
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  other_company?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pkv_claim" */
export type Pkv_ClaimInsured_PersonsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pkv_claim" */
export type Pkv_Claim_Aggregate = {
  __typename?: 'pkv_claim_aggregate';
  aggregate?: Maybe<Pkv_Claim_Aggregate_Fields>;
  nodes: Array<Pkv_Claim>;
};

/** aggregate fields of "pkv_claim" */
export type Pkv_Claim_Aggregate_Fields = {
  __typename?: 'pkv_claim_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pkv_Claim_Max_Fields>;
  min?: Maybe<Pkv_Claim_Min_Fields>;
};


/** aggregate fields of "pkv_claim" */
export type Pkv_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pkv_Claim_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pkv_Claim_Append_Input = {
  insured_persons?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pkv_claim". All fields are combined with a logical 'AND'. */
export type Pkv_Claim_Bool_Exp = {
  _and?: Maybe<Array<Pkv_Claim_Bool_Exp>>;
  _not?: Maybe<Pkv_Claim_Bool_Exp>;
  _or?: Maybe<Array<Pkv_Claim_Bool_Exp>>;
  case?: Maybe<Base_Case_Bool_Exp>;
  case_id?: Maybe<Uuid_Comparison_Exp>;
  case_type?: Maybe<String_Comparison_Exp>;
  company?: Maybe<Pkv_Company_Bool_Exp>;
  company_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  insurance_increase_date?: Maybe<Date_Comparison_Exp>;
  insured_persons?: Maybe<Jsonb_Comparison_Exp>;
  is_estimated_claim_amount?: Maybe<Boolean_Comparison_Exp>;
  other_company?: Maybe<String_Comparison_Exp>;
  source_of_estimation?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pkv_claim" */
export enum Pkv_Claim_Constraint {
  /** unique or primary key constraint on columns "case_id" */
  PkvClaimCaseIdKey = 'pkv_claim_case_id_key',
  /** unique or primary key constraint on columns "id" */
  PkvClaimPkey = 'pkv_claim_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pkv_Claim_Delete_At_Path_Input = {
  insured_persons?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pkv_Claim_Delete_Elem_Input = {
  insured_persons?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pkv_Claim_Delete_Key_Input = {
  insured_persons?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "pkv_claim" */
export type Pkv_Claim_Insert_Input = {
  case?: Maybe<Base_Case_Obj_Rel_Insert_Input>;
  case_id?: Maybe<Scalars['uuid']>;
  case_type?: Maybe<Scalars['String']>;
  company?: Maybe<Pkv_Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  insured_persons?: Maybe<Scalars['jsonb']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  other_company?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pkv_Claim_Max_Fields = {
  __typename?: 'pkv_claim_max_fields';
  case_id?: Maybe<Scalars['uuid']>;
  case_type?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  other_company?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pkv_Claim_Min_Fields = {
  __typename?: 'pkv_claim_min_fields';
  case_id?: Maybe<Scalars['uuid']>;
  case_type?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  other_company?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pkv_claim" */
export type Pkv_Claim_Mutation_Response = {
  __typename?: 'pkv_claim_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pkv_Claim>;
};

/** input type for inserting object relation for remote table "pkv_claim" */
export type Pkv_Claim_Obj_Rel_Insert_Input = {
  data: Pkv_Claim_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Pkv_Claim_On_Conflict>;
};

/** on_conflict condition type for table "pkv_claim" */
export type Pkv_Claim_On_Conflict = {
  constraint: Pkv_Claim_Constraint;
  update_columns?: Array<Pkv_Claim_Update_Column>;
  where?: Maybe<Pkv_Claim_Bool_Exp>;
};

/** Ordering options when selecting data from "pkv_claim". */
export type Pkv_Claim_Order_By = {
  case?: Maybe<Base_Case_Order_By>;
  case_id?: Maybe<Order_By>;
  case_type?: Maybe<Order_By>;
  company?: Maybe<Pkv_Company_Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  insurance_increase_date?: Maybe<Order_By>;
  insured_persons?: Maybe<Order_By>;
  is_estimated_claim_amount?: Maybe<Order_By>;
  other_company?: Maybe<Order_By>;
  source_of_estimation?: Maybe<Order_By>;
};

/** primary key columns input for table: pkv_claim */
export type Pkv_Claim_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pkv_Claim_Prepend_Input = {
  insured_persons?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pkv_claim" */
export enum Pkv_Claim_Select_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CaseType = 'case_type',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceIncreaseDate = 'insurance_increase_date',
  /** column name */
  InsuredPersons = 'insured_persons',
  /** column name */
  IsEstimatedClaimAmount = 'is_estimated_claim_amount',
  /** column name */
  OtherCompany = 'other_company',
  /** column name */
  SourceOfEstimation = 'source_of_estimation'
}

/** input type for updating data in table "pkv_claim" */
export type Pkv_Claim_Set_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  case_type?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  insured_persons?: Maybe<Scalars['jsonb']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  other_company?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "pkv_claim" */
export type Pkv_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pkv_Claim_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pkv_Claim_Stream_Cursor_Value_Input = {
  case_id?: Maybe<Scalars['uuid']>;
  case_type?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  insurance_increase_date?: Maybe<Scalars['date']>;
  insured_persons?: Maybe<Scalars['jsonb']>;
  is_estimated_claim_amount?: Maybe<Scalars['Boolean']>;
  other_company?: Maybe<Scalars['String']>;
  source_of_estimation?: Maybe<Scalars['String']>;
};

/** update columns of table "pkv_claim" */
export enum Pkv_Claim_Update_Column {
  /** column name */
  CaseId = 'case_id',
  /** column name */
  CaseType = 'case_type',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  InsuranceIncreaseDate = 'insurance_increase_date',
  /** column name */
  InsuredPersons = 'insured_persons',
  /** column name */
  IsEstimatedClaimAmount = 'is_estimated_claim_amount',
  /** column name */
  OtherCompany = 'other_company',
  /** column name */
  SourceOfEstimation = 'source_of_estimation'
}

export type Pkv_Claim_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Pkv_Claim_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Pkv_Claim_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Pkv_Claim_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Pkv_Claim_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Pkv_Claim_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pkv_Claim_Set_Input>;
  where: Pkv_Claim_Bool_Exp;
};

/** columns and relationships of "pkv_company" */
export type Pkv_Company = {
  __typename?: 'pkv_company';
  id: Scalars['String'];
  is_supported: Scalars['Boolean'];
  name: Scalars['String'];
};

/** aggregated selection of "pkv_company" */
export type Pkv_Company_Aggregate = {
  __typename?: 'pkv_company_aggregate';
  aggregate?: Maybe<Pkv_Company_Aggregate_Fields>;
  nodes: Array<Pkv_Company>;
};

/** aggregate fields of "pkv_company" */
export type Pkv_Company_Aggregate_Fields = {
  __typename?: 'pkv_company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pkv_Company_Max_Fields>;
  min?: Maybe<Pkv_Company_Min_Fields>;
};


/** aggregate fields of "pkv_company" */
export type Pkv_Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pkv_Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "pkv_company". All fields are combined with a logical 'AND'. */
export type Pkv_Company_Bool_Exp = {
  _and?: Maybe<Array<Pkv_Company_Bool_Exp>>;
  _not?: Maybe<Pkv_Company_Bool_Exp>;
  _or?: Maybe<Array<Pkv_Company_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  is_supported?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pkv_company" */
export enum Pkv_Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkvCompanyPkey = 'pkv_company_pkey'
}

/** input type for inserting data into table "pkv_company" */
export type Pkv_Company_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pkv_Company_Max_Fields = {
  __typename?: 'pkv_company_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Pkv_Company_Min_Fields = {
  __typename?: 'pkv_company_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "pkv_company" */
export type Pkv_Company_Mutation_Response = {
  __typename?: 'pkv_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pkv_Company>;
};

/** input type for inserting object relation for remote table "pkv_company" */
export type Pkv_Company_Obj_Rel_Insert_Input = {
  data: Pkv_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Pkv_Company_On_Conflict>;
};

/** on_conflict condition type for table "pkv_company" */
export type Pkv_Company_On_Conflict = {
  constraint: Pkv_Company_Constraint;
  update_columns?: Array<Pkv_Company_Update_Column>;
  where?: Maybe<Pkv_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "pkv_company". */
export type Pkv_Company_Order_By = {
  id?: Maybe<Order_By>;
  is_supported?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: pkv_company */
export type Pkv_Company_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "pkv_company" */
export enum Pkv_Company_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "pkv_company" */
export type Pkv_Company_Set_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "pkv_company" */
export type Pkv_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pkv_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pkv_Company_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  is_supported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "pkv_company" */
export enum Pkv_Company_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSupported = 'is_supported',
  /** column name */
  Name = 'name'
}

export type Pkv_Company_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pkv_Company_Set_Input>;
  where: Pkv_Company_Bool_Exp;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: Maybe<Array<Product_Bool_Exp>>;
  _not?: Maybe<Product_Bool_Exp>;
  _or?: Maybe<Array<Product_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProductPkey = 'product_pkey'
}

export enum Product_Enum {
  /** Fitness product */
  Fit = 'FIT',
  /** GDPR Data leak product */
  Gdpr = 'GDPR',
  /** Flight No Show */
  Gff = 'GFF',
  /** Netflix Subscription */
  Nflx = 'NFLX',
  /** Energy cancellation product */
  Nrg = 'NRG',
  /** Online Casinos and Gambling */
  Ocg = 'OCG',
  /** Private Health Insurance */
  Pkv = 'PKV'
}

/** Boolean expression to compare columns of type "product_enum". All fields are combined with logical 'AND'. */
export type Product_Enum_Comparison_Exp = {
  _eq?: Maybe<Product_Enum>;
  _in?: Maybe<Array<Product_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Product_Enum>;
  _nin?: Maybe<Array<Product_Enum>>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: Maybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  description?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Product_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};

/** columns and relationships of "ps_debtor" */
export type Ps_Debtor = {
  __typename?: 'ps_debtor';
  account_id: Scalars['numeric'];
  claim_owner: Scalars['String'];
  ibans: Scalars['jsonb'];
  id: Scalars['uuid'];
  k3_template?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sender_name: Scalars['String'];
  vertical: Scalars['String'];
};


/** columns and relationships of "ps_debtor" */
export type Ps_DebtorIbansArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ps_debtor" */
export type Ps_Debtor_Aggregate = {
  __typename?: 'ps_debtor_aggregate';
  aggregate?: Maybe<Ps_Debtor_Aggregate_Fields>;
  nodes: Array<Ps_Debtor>;
};

/** aggregate fields of "ps_debtor" */
export type Ps_Debtor_Aggregate_Fields = {
  __typename?: 'ps_debtor_aggregate_fields';
  avg?: Maybe<Ps_Debtor_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ps_Debtor_Max_Fields>;
  min?: Maybe<Ps_Debtor_Min_Fields>;
  stddev?: Maybe<Ps_Debtor_Stddev_Fields>;
  stddev_pop?: Maybe<Ps_Debtor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ps_Debtor_Stddev_Samp_Fields>;
  sum?: Maybe<Ps_Debtor_Sum_Fields>;
  var_pop?: Maybe<Ps_Debtor_Var_Pop_Fields>;
  var_samp?: Maybe<Ps_Debtor_Var_Samp_Fields>;
  variance?: Maybe<Ps_Debtor_Variance_Fields>;
};


/** aggregate fields of "ps_debtor" */
export type Ps_Debtor_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ps_Debtor_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ps_Debtor_Append_Input = {
  ibans?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Ps_Debtor_Avg_Fields = {
  __typename?: 'ps_debtor_avg_fields';
  account_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ps_debtor". All fields are combined with a logical 'AND'. */
export type Ps_Debtor_Bool_Exp = {
  _and?: Maybe<Array<Ps_Debtor_Bool_Exp>>;
  _not?: Maybe<Ps_Debtor_Bool_Exp>;
  _or?: Maybe<Array<Ps_Debtor_Bool_Exp>>;
  account_id?: Maybe<Numeric_Comparison_Exp>;
  claim_owner?: Maybe<String_Comparison_Exp>;
  ibans?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  k3_template?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  sender_name?: Maybe<String_Comparison_Exp>;
  vertical?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ps_debtor" */
export enum Ps_Debtor_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_9974170ceac96168fb586b08761 = 'PK_9974170ceac96168fb586b08761',
  /** unique or primary key constraint on columns "account_id" */
  Uq_8adaa7e0a18fbbe686b8bc57336 = 'UQ_8adaa7e0a18fbbe686b8bc57336'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ps_Debtor_Delete_At_Path_Input = {
  ibans?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ps_Debtor_Delete_Elem_Input = {
  ibans?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ps_Debtor_Delete_Key_Input = {
  ibans?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ps_debtor" */
export type Ps_Debtor_Inc_Input = {
  account_id?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "ps_debtor" */
export type Ps_Debtor_Insert_Input = {
  account_id?: Maybe<Scalars['numeric']>;
  claim_owner?: Maybe<Scalars['String']>;
  ibans?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  k3_template?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ps_Debtor_Max_Fields = {
  __typename?: 'ps_debtor_max_fields';
  account_id?: Maybe<Scalars['numeric']>;
  claim_owner?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  k3_template?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ps_Debtor_Min_Fields = {
  __typename?: 'ps_debtor_min_fields';
  account_id?: Maybe<Scalars['numeric']>;
  claim_owner?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  k3_template?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ps_debtor" */
export type Ps_Debtor_Mutation_Response = {
  __typename?: 'ps_debtor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ps_Debtor>;
};

/** input type for inserting object relation for remote table "ps_debtor" */
export type Ps_Debtor_Obj_Rel_Insert_Input = {
  data: Ps_Debtor_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Ps_Debtor_On_Conflict>;
};

/** on_conflict condition type for table "ps_debtor" */
export type Ps_Debtor_On_Conflict = {
  constraint: Ps_Debtor_Constraint;
  update_columns?: Array<Ps_Debtor_Update_Column>;
  where?: Maybe<Ps_Debtor_Bool_Exp>;
};

/** Ordering options when selecting data from "ps_debtor". */
export type Ps_Debtor_Order_By = {
  account_id?: Maybe<Order_By>;
  claim_owner?: Maybe<Order_By>;
  ibans?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  k3_template?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  sender_name?: Maybe<Order_By>;
  vertical?: Maybe<Order_By>;
};

/** primary key columns input for table: ps_debtor */
export type Ps_Debtor_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ps_Debtor_Prepend_Input = {
  ibans?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "ps_debtor" */
export enum Ps_Debtor_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClaimOwner = 'claim_owner',
  /** column name */
  Ibans = 'ibans',
  /** column name */
  Id = 'id',
  /** column name */
  K3Template = 'k3_template',
  /** column name */
  Name = 'name',
  /** column name */
  SenderName = 'sender_name',
  /** column name */
  Vertical = 'vertical'
}

/** input type for updating data in table "ps_debtor" */
export type Ps_Debtor_Set_Input = {
  account_id?: Maybe<Scalars['numeric']>;
  claim_owner?: Maybe<Scalars['String']>;
  ibans?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  k3_template?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Ps_Debtor_Stddev_Fields = {
  __typename?: 'ps_debtor_stddev_fields';
  account_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ps_Debtor_Stddev_Pop_Fields = {
  __typename?: 'ps_debtor_stddev_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ps_Debtor_Stddev_Samp_Fields = {
  __typename?: 'ps_debtor_stddev_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ps_debtor" */
export type Ps_Debtor_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ps_Debtor_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ps_Debtor_Stream_Cursor_Value_Input = {
  account_id?: Maybe<Scalars['numeric']>;
  claim_owner?: Maybe<Scalars['String']>;
  ibans?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  k3_template?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sender_name?: Maybe<Scalars['String']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Ps_Debtor_Sum_Fields = {
  __typename?: 'ps_debtor_sum_fields';
  account_id?: Maybe<Scalars['numeric']>;
};

/** update columns of table "ps_debtor" */
export enum Ps_Debtor_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClaimOwner = 'claim_owner',
  /** column name */
  Ibans = 'ibans',
  /** column name */
  Id = 'id',
  /** column name */
  K3Template = 'k3_template',
  /** column name */
  Name = 'name',
  /** column name */
  SenderName = 'sender_name',
  /** column name */
  Vertical = 'vertical'
}

export type Ps_Debtor_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Ps_Debtor_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Ps_Debtor_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Ps_Debtor_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Ps_Debtor_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Ps_Debtor_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Ps_Debtor_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Ps_Debtor_Set_Input>;
  where: Ps_Debtor_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ps_Debtor_Var_Pop_Fields = {
  __typename?: 'ps_debtor_var_pop_fields';
  account_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ps_Debtor_Var_Samp_Fields = {
  __typename?: 'ps_debtor_var_samp_fields';
  account_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ps_Debtor_Variance_Fields = {
  __typename?: 'ps_debtor_variance_fields';
  account_id?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  admin_get_signed_urls?: Maybe<AdminGetSignedUrlsOutput>;
  admin_list_cases?: Maybe<AdminListCasesOutput>;
  /** fetch data from the table: "api_keys" */
  api_keys: Array<Api_Keys>;
  /** fetch aggregated fields from the table: "api_keys" */
  api_keys_aggregate: Api_Keys_Aggregate;
  /** fetch data from the table: "api_keys" using primary key columns */
  api_keys_by_pk?: Maybe<Api_Keys>;
  /** fetch data from the table: "assignment" */
  assignment: Array<Assignment>;
  /** fetch aggregated fields from the table: "assignment" */
  assignment_aggregate: Assignment_Aggregate;
  /** fetch data from the table: "assignment" using primary key columns */
  assignment_by_pk?: Maybe<Assignment>;
  /** fetch data from the table: "assignment_state" */
  assignment_state: Array<Assignment_State>;
  /** fetch aggregated fields from the table: "assignment_state" */
  assignment_state_aggregate: Assignment_State_Aggregate;
  /** fetch data from the table: "assignment_state" using primary key columns */
  assignment_state_by_pk?: Maybe<Assignment_State>;
  /** fetch data from the table: "bank_account" */
  bank_account: Array<Bank_Account>;
  /** fetch aggregated fields from the table: "bank_account" */
  bank_account_aggregate: Bank_Account_Aggregate;
  /** fetch data from the table: "bank_account" using primary key columns */
  bank_account_by_pk?: Maybe<Bank_Account>;
  /** fetch data from the table: "base_case" */
  base_case: Array<Base_Case>;
  /** fetch aggregated fields from the table: "base_case" */
  base_case_aggregate: Base_Case_Aggregate;
  /** fetch data from the table: "base_case" using primary key columns */
  base_case_by_pk?: Maybe<Base_Case>;
  /** fetch data from the table: "base_case_document" */
  base_case_document: Array<Base_Case_Document>;
  /** fetch aggregated fields from the table: "base_case_document" */
  base_case_document_aggregate: Base_Case_Document_Aggregate;
  /** fetch data from the table: "base_case_document" using primary key columns */
  base_case_document_by_pk?: Maybe<Base_Case_Document>;
  /** fetch data from the table: "base_case_document_category" */
  base_case_document_category: Array<Base_Case_Document_Category>;
  /** fetch aggregated fields from the table: "base_case_document_category" */
  base_case_document_category_aggregate: Base_Case_Document_Category_Aggregate;
  /** fetch data from the table: "base_case_document_category" using primary key columns */
  base_case_document_category_by_pk?: Maybe<Base_Case_Document_Category>;
  /** fetch data from the table: "base_offer" */
  base_offer: Array<Base_Offer>;
  /** fetch aggregated fields from the table: "base_offer" */
  base_offer_aggregate: Base_Offer_Aggregate;
  /** fetch data from the table: "base_offer" using primary key columns */
  base_offer_by_pk?: Maybe<Base_Offer>;
  /** fetch data from the table: "bi_tableau_kpi_full" */
  bi_tableau_kpi_full: Array<Bi_Tableau_Kpi_Full>;
  /** fetch aggregated fields from the table: "bi_tableau_kpi_full" */
  bi_tableau_kpi_full_aggregate: Bi_Tableau_Kpi_Full_Aggregate;
  /** fetch data from the table: "case_comment" */
  case_comment: Array<Case_Comment>;
  /** fetch aggregated fields from the table: "case_comment" */
  case_comment_aggregate: Case_Comment_Aggregate;
  /** fetch data from the table: "case_comment" using primary key columns */
  case_comment_by_pk?: Maybe<Case_Comment>;
  case_docs_upload_get?: Maybe<CaseDocsUploadGetOutput>;
  /** fetch data from the table: "case_list_view" */
  case_list_view: Array<Case_List_View>;
  /** fetch aggregated fields from the table: "case_list_view" */
  case_list_view_aggregate: Case_List_View_Aggregate;
  cps_unified_case_get?: Maybe<CpsUnifiedCaseGetOutput>;
  /** fetch data from the table: "customer" */
  customer: Array<Customer>;
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>;
  /** To be called from heyflow to validate a GDPR related data */
  customer_validate_gdpr_data?: Maybe<CustomerValidateGdprDataOutput>;
  /** fetch data from the table: "email_queue" */
  email_queue: Array<Email_Queue>;
  /** fetch aggregated fields from the table: "email_queue" */
  email_queue_aggregate: Email_Queue_Aggregate;
  /** fetch data from the table: "email_queue" using primary key columns */
  email_queue_by_pk?: Maybe<Email_Queue>;
  /** fetch data from the table: "fit_claim" */
  fit_claim: Array<Fit_Claim>;
  /** fetch aggregated fields from the table: "fit_claim" */
  fit_claim_aggregate: Fit_Claim_Aggregate;
  /** fetch data from the table: "fit_claim" using primary key columns */
  fit_claim_by_pk?: Maybe<Fit_Claim>;
  /** fetch data from the table: "fit_company" */
  fit_company: Array<Fit_Company>;
  /** fetch aggregated fields from the table: "fit_company" */
  fit_company_aggregate: Fit_Company_Aggregate;
  /** fetch data from the table: "fit_company" using primary key columns */
  fit_company_by_pk?: Maybe<Fit_Company>;
  /** fetch data from the table: "fit_fts_transaction" */
  fit_fts_transaction: Array<Fit_Fts_Transaction>;
  /** fetch aggregated fields from the table: "fit_fts_transaction" */
  fit_fts_transaction_aggregate: Fit_Fts_Transaction_Aggregate;
  /** fetch data from the table: "fit_fts_transaction" using primary key columns */
  fit_fts_transaction_by_pk?: Maybe<Fit_Fts_Transaction>;
  /** fetch data from the table: "fs_bank_transaction" */
  fs_bank_transaction: Array<Fs_Bank_Transaction>;
  /** fetch aggregated fields from the table: "fs_bank_transaction" */
  fs_bank_transaction_aggregate: Fs_Bank_Transaction_Aggregate;
  /** fetch data from the table: "fs_bank_transaction" using primary key columns */
  fs_bank_transaction_by_pk?: Maybe<Fs_Bank_Transaction>;
  /** fetch data from the table: "fs_customer_payout" */
  fs_customer_payout: Array<Fs_Customer_Payout>;
  /** fetch aggregated fields from the table: "fs_customer_payout" */
  fs_customer_payout_aggregate: Fs_Customer_Payout_Aggregate;
  /** fetch data from the table: "fs_customer_payout" using primary key columns */
  fs_customer_payout_by_pk?: Maybe<Fs_Customer_Payout>;
  /** fetch data from the table: "fs_customer_payout_queue" */
  fs_customer_payout_queue: Array<Fs_Customer_Payout_Queue>;
  /** fetch aggregated fields from the table: "fs_customer_payout_queue" */
  fs_customer_payout_queue_aggregate: Fs_Customer_Payout_Queue_Aggregate;
  /** fetch data from the table: "fs_customer_payout_queue" using primary key columns */
  fs_customer_payout_queue_by_pk?: Maybe<Fs_Customer_Payout_Queue>;
  /** fetch data from the table: "gdpr_case" */
  gdpr_case: Array<Gdpr_Case>;
  /** fetch aggregated fields from the table: "gdpr_case" */
  gdpr_case_aggregate: Gdpr_Case_Aggregate;
  /** fetch data from the table: "gdpr_case" using primary key columns */
  gdpr_case_by_pk?: Maybe<Gdpr_Case>;
  /** fetch data from the table: "gdpr_case_rejection_reason" */
  gdpr_case_rejection_reason: Array<Gdpr_Case_Rejection_Reason>;
  /** fetch aggregated fields from the table: "gdpr_case_rejection_reason" */
  gdpr_case_rejection_reason_aggregate: Gdpr_Case_Rejection_Reason_Aggregate;
  /** fetch data from the table: "gdpr_case_rejection_reason" using primary key columns */
  gdpr_case_rejection_reason_by_pk?: Maybe<Gdpr_Case_Rejection_Reason>;
  /** fetch data from the table: "gdpr_case_state" */
  gdpr_case_state: Array<Gdpr_Case_State>;
  /** fetch aggregated fields from the table: "gdpr_case_state" */
  gdpr_case_state_aggregate: Gdpr_Case_State_Aggregate;
  /** fetch data from the table: "gdpr_case_state" using primary key columns */
  gdpr_case_state_by_pk?: Maybe<Gdpr_Case_State>;
  /** fetch data from the table: "gdpr_claim" */
  gdpr_claim: Array<Gdpr_Claim>;
  /** fetch aggregated fields from the table: "gdpr_claim" */
  gdpr_claim_aggregate: Gdpr_Claim_Aggregate;
  /** fetch data from the table: "gdpr_claim" using primary key columns */
  gdpr_claim_by_pk?: Maybe<Gdpr_Claim>;
  /** fetch data from the table: "gdpr_claim_company" */
  gdpr_claim_company: Array<Gdpr_Claim_Company>;
  /** fetch aggregated fields from the table: "gdpr_claim_company" */
  gdpr_claim_company_aggregate: Gdpr_Claim_Company_Aggregate;
  /** fetch data from the table: "gdpr_claim_company" using primary key columns */
  gdpr_claim_company_by_pk?: Maybe<Gdpr_Claim_Company>;
  /** fetch data from the table: "gdpr_company" */
  gdpr_company: Array<Gdpr_Company>;
  /** fetch aggregated fields from the table: "gdpr_company" */
  gdpr_company_aggregate: Gdpr_Company_Aggregate;
  /** fetch data from the table: "gdpr_company" using primary key columns */
  gdpr_company_by_pk?: Maybe<Gdpr_Company>;
  /** fetch data from the table: "gdpr_offer" */
  gdpr_offer: Array<Gdpr_Offer>;
  /** fetch aggregated fields from the table: "gdpr_offer" */
  gdpr_offer_aggregate: Gdpr_Offer_Aggregate;
  /** fetch data from the table: "gdpr_offer_assignment" */
  gdpr_offer_assignment: Array<Gdpr_Offer_Assignment>;
  /** fetch aggregated fields from the table: "gdpr_offer_assignment" */
  gdpr_offer_assignment_aggregate: Gdpr_Offer_Assignment_Aggregate;
  /** fetch data from the table: "gdpr_offer_assignment" using primary key columns */
  gdpr_offer_assignment_by_pk?: Maybe<Gdpr_Offer_Assignment>;
  /** fetch data from the table: "gdpr_offer" using primary key columns */
  gdpr_offer_by_pk?: Maybe<Gdpr_Offer>;
  /** fetch data from the table: "gdpr_offer_payment" */
  gdpr_offer_payment: Array<Gdpr_Offer_Payment>;
  /** fetch aggregated fields from the table: "gdpr_offer_payment" */
  gdpr_offer_payment_aggregate: Gdpr_Offer_Payment_Aggregate;
  /** fetch data from the table: "gdpr_offer_payment" using primary key columns */
  gdpr_offer_payment_by_pk?: Maybe<Gdpr_Offer_Payment>;
  /** fetch data from the table: "gdpr_offer_state" */
  gdpr_offer_state: Array<Gdpr_Offer_State>;
  /** fetch aggregated fields from the table: "gdpr_offer_state" */
  gdpr_offer_state_aggregate: Gdpr_Offer_State_Aggregate;
  /** fetch data from the table: "gdpr_offer_state" using primary key columns */
  gdpr_offer_state_by_pk?: Maybe<Gdpr_Offer_State>;
  /** fetch data from the table: "generated_report" */
  generated_report: Array<Generated_Report>;
  /** fetch aggregated fields from the table: "generated_report" */
  generated_report_aggregate: Generated_Report_Aggregate;
  /** fetch data from the table: "generated_report" using primary key columns */
  generated_report_by_pk?: Maybe<Generated_Report>;
  /** fetch data from the table: "gff_airport" */
  gff_airport: Array<Gff_Airport>;
  /** fetch aggregated fields from the table: "gff_airport" */
  gff_airport_aggregate: Gff_Airport_Aggregate;
  /** fetch data from the table: "gff_airport" using primary key columns */
  gff_airport_by_pk?: Maybe<Gff_Airport>;
  /** fetch data from the table: "gff_claim" */
  gff_claim: Array<Gff_Claim>;
  /** fetch aggregated fields from the table: "gff_claim" */
  gff_claim_aggregate: Gff_Claim_Aggregate;
  /** fetch data from the table: "gff_claim" using primary key columns */
  gff_claim_by_pk?: Maybe<Gff_Claim>;
  /** fetch data from the table: "gff_company" */
  gff_company: Array<Gff_Company>;
  /** fetch aggregated fields from the table: "gff_company" */
  gff_company_aggregate: Gff_Company_Aggregate;
  /** fetch data from the table: "gff_company" using primary key columns */
  gff_company_by_pk?: Maybe<Gff_Company>;
  /** fetch data from the table: "haveibeenpwned_api_log" */
  haveibeenpwned_api_log: Array<Haveibeenpwned_Api_Log>;
  /** fetch aggregated fields from the table: "haveibeenpwned_api_log" */
  haveibeenpwned_api_log_aggregate: Haveibeenpwned_Api_Log_Aggregate;
  /** fetch data from the table: "haveibeenpwned_api_log" using primary key columns */
  haveibeenpwned_api_log_by_pk?: Maybe<Haveibeenpwned_Api_Log>;
  lawsuitnow_case_view?: Maybe<LawsuitnowCaseViewOutput>;
  /** fetch data from the table: "lost_assignment" */
  lost_assignment: Array<Lost_Assignment>;
  /** fetch aggregated fields from the table: "lost_assignment" */
  lost_assignment_aggregate: Lost_Assignment_Aggregate;
  /** fetch data from the table: "lost_assignment" using primary key columns */
  lost_assignment_by_pk?: Maybe<Lost_Assignment>;
  /** fetch data from the table: "lost_assignment_state" */
  lost_assignment_state: Array<Lost_Assignment_State>;
  /** fetch aggregated fields from the table: "lost_assignment_state" */
  lost_assignment_state_aggregate: Lost_Assignment_State_Aggregate;
  /** fetch data from the table: "lost_assignment_state" using primary key columns */
  lost_assignment_state_by_pk?: Maybe<Lost_Assignment_State>;
  /** fetch data from the table: "ls_case" */
  ls_case: Array<Ls_Case>;
  /** fetch aggregated fields from the table: "ls_case" */
  ls_case_aggregate: Ls_Case_Aggregate;
  /** fetch data from the table: "ls_case" using primary key columns */
  ls_case_by_pk?: Maybe<Ls_Case>;
  /** fetch data from the table: "ls_lawsuit" */
  ls_lawsuit: Array<Ls_Lawsuit>;
  /** fetch aggregated fields from the table: "ls_lawsuit" */
  ls_lawsuit_aggregate: Ls_Lawsuit_Aggregate;
  /** fetch data from the table: "ls_lawsuit" using primary key columns */
  ls_lawsuit_by_pk?: Maybe<Ls_Lawsuit>;
  /** fetch data from the table: "ls_lawsuit_court" */
  ls_lawsuit_court: Array<Ls_Lawsuit_Court>;
  /** fetch aggregated fields from the table: "ls_lawsuit_court" */
  ls_lawsuit_court_aggregate: Ls_Lawsuit_Court_Aggregate;
  /** fetch data from the table: "ls_lawsuit_court" using primary key columns */
  ls_lawsuit_court_by_pk?: Maybe<Ls_Lawsuit_Court>;
  /** fetch data from the table: "ls_lawsuit_document" */
  ls_lawsuit_document: Array<Ls_Lawsuit_Document>;
  /** fetch aggregated fields from the table: "ls_lawsuit_document" */
  ls_lawsuit_document_aggregate: Ls_Lawsuit_Document_Aggregate;
  /** fetch data from the table: "ls_lawsuit_document" using primary key columns */
  ls_lawsuit_document_by_pk?: Maybe<Ls_Lawsuit_Document>;
  /** fetch data from the table: "ls_lawsuit_document_category" */
  ls_lawsuit_document_category: Array<Ls_Lawsuit_Document_Category>;
  /** fetch aggregated fields from the table: "ls_lawsuit_document_category" */
  ls_lawsuit_document_category_aggregate: Ls_Lawsuit_Document_Category_Aggregate;
  /** fetch data from the table: "ls_lawsuit_document_category" using primary key columns */
  ls_lawsuit_document_category_by_pk?: Maybe<Ls_Lawsuit_Document_Category>;
  /** fetch data from the table: "ls_lawsuit_expense" */
  ls_lawsuit_expense: Array<Ls_Lawsuit_Expense>;
  /** fetch aggregated fields from the table: "ls_lawsuit_expense" */
  ls_lawsuit_expense_aggregate: Ls_Lawsuit_Expense_Aggregate;
  /** fetch data from the table: "ls_lawsuit_expense" using primary key columns */
  ls_lawsuit_expense_by_pk?: Maybe<Ls_Lawsuit_Expense>;
  /** fetch data from the table: "ls_lawsuit_history" */
  ls_lawsuit_history: Array<Ls_Lawsuit_History>;
  /** fetch aggregated fields from the table: "ls_lawsuit_history" */
  ls_lawsuit_history_aggregate: Ls_Lawsuit_History_Aggregate;
  /** fetch data from the table: "ls_lawsuit_history" using primary key columns */
  ls_lawsuit_history_by_pk?: Maybe<Ls_Lawsuit_History>;
  /** fetch data from the table: "ls_lawsuit_lawyer" */
  ls_lawsuit_lawyer: Array<Ls_Lawsuit_Lawyer>;
  /** fetch aggregated fields from the table: "ls_lawsuit_lawyer" */
  ls_lawsuit_lawyer_aggregate: Ls_Lawsuit_Lawyer_Aggregate;
  /** fetch data from the table: "ls_lawsuit_lawyer" using primary key columns */
  ls_lawsuit_lawyer_by_pk?: Maybe<Ls_Lawsuit_Lawyer>;
  /** fetch data from the table: "nflx_claim" */
  nflx_claim: Array<Nflx_Claim>;
  /** fetch aggregated fields from the table: "nflx_claim" */
  nflx_claim_aggregate: Nflx_Claim_Aggregate;
  /** fetch data from the table: "nflx_claim" using primary key columns */
  nflx_claim_by_pk?: Maybe<Nflx_Claim>;
  /** fetch data from the table: "nflx_company" */
  nflx_company: Array<Nflx_Company>;
  /** fetch aggregated fields from the table: "nflx_company" */
  nflx_company_aggregate: Nflx_Company_Aggregate;
  /** fetch data from the table: "nflx_company" using primary key columns */
  nflx_company_by_pk?: Maybe<Nflx_Company>;
  /** fetch data from the table: "nrg_claim" */
  nrg_claim: Array<Nrg_Claim>;
  /** fetch aggregated fields from the table: "nrg_claim" */
  nrg_claim_aggregate: Nrg_Claim_Aggregate;
  /** fetch data from the table: "nrg_claim" using primary key columns */
  nrg_claim_by_pk?: Maybe<Nrg_Claim>;
  /** fetch data from the table: "nrg_company" */
  nrg_company: Array<Nrg_Company>;
  /** fetch aggregated fields from the table: "nrg_company" */
  nrg_company_aggregate: Nrg_Company_Aggregate;
  /** fetch data from the table: "nrg_company" using primary key columns */
  nrg_company_by_pk?: Maybe<Nrg_Company>;
  /** fetch data from the table: "ocg_claim" */
  ocg_claim: Array<Ocg_Claim>;
  /** fetch aggregated fields from the table: "ocg_claim" */
  ocg_claim_aggregate: Ocg_Claim_Aggregate;
  /** fetch data from the table: "ocg_claim" using primary key columns */
  ocg_claim_by_pk?: Maybe<Ocg_Claim>;
  /** fetch data from the table: "ocg_company" */
  ocg_company: Array<Ocg_Company>;
  /** fetch aggregated fields from the table: "ocg_company" */
  ocg_company_aggregate: Ocg_Company_Aggregate;
  /** fetch data from the table: "ocg_company" using primary key columns */
  ocg_company_by_pk?: Maybe<Ocg_Company>;
  offer_get?: Maybe<OfferGetOutput>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "payment_state" */
  payment_state: Array<Payment_State>;
  /** fetch aggregated fields from the table: "payment_state" */
  payment_state_aggregate: Payment_State_Aggregate;
  /** fetch data from the table: "payment_state" using primary key columns */
  payment_state_by_pk?: Maybe<Payment_State>;
  /** fetch data from the table: "pkv_claim" */
  pkv_claim: Array<Pkv_Claim>;
  /** fetch aggregated fields from the table: "pkv_claim" */
  pkv_claim_aggregate: Pkv_Claim_Aggregate;
  /** fetch data from the table: "pkv_claim" using primary key columns */
  pkv_claim_by_pk?: Maybe<Pkv_Claim>;
  /** fetch data from the table: "pkv_company" */
  pkv_company: Array<Pkv_Company>;
  /** fetch aggregated fields from the table: "pkv_company" */
  pkv_company_aggregate: Pkv_Company_Aggregate;
  /** fetch data from the table: "pkv_company" using primary key columns */
  pkv_company_by_pk?: Maybe<Pkv_Company>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "ps_debtor" */
  ps_debtor: Array<Ps_Debtor>;
  /** fetch aggregated fields from the table: "ps_debtor" */
  ps_debtor_aggregate: Ps_Debtor_Aggregate;
  /** fetch data from the table: "ps_debtor" using primary key columns */
  ps_debtor_by_pk?: Maybe<Ps_Debtor>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "third_party_raw_data" */
  third_party_raw_data: Array<Third_Party_Raw_Data>;
  /** fetch aggregated fields from the table: "third_party_raw_data" */
  third_party_raw_data_aggregate: Third_Party_Raw_Data_Aggregate;
  /** fetch data from the table: "third_party_raw_data" using primary key columns */
  third_party_raw_data_by_pk?: Maybe<Third_Party_Raw_Data>;
  /** fetch data from the table: "unified_case" */
  unified_case: Array<Unified_Case>;
  /** fetch aggregated fields from the table: "unified_case" */
  unified_case_aggregate: Unified_Case_Aggregate;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
};


export type Query_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAdmin_Get_Signed_UrlsArgs = {
  input: AdminGetSignedUrlsInput;
};


export type Query_RootAdmin_List_CasesArgs = {
  input: AdminListCasesInput;
};


export type Query_RootApi_KeysArgs = {
  distinct_on?: Maybe<Array<Api_Keys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Keys_Order_By>>;
  where?: Maybe<Api_Keys_Bool_Exp>;
};


export type Query_RootApi_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Keys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Keys_Order_By>>;
  where?: Maybe<Api_Keys_Bool_Exp>;
};


export type Query_RootApi_Keys_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootAssignmentArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Query_RootAssignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Query_RootAssignment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAssignment_StateArgs = {
  distinct_on?: Maybe<Array<Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_State_Order_By>>;
  where?: Maybe<Assignment_State_Bool_Exp>;
};


export type Query_RootAssignment_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_State_Order_By>>;
  where?: Maybe<Assignment_State_Bool_Exp>;
};


export type Query_RootAssignment_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootBank_AccountArgs = {
  distinct_on?: Maybe<Array<Bank_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Account_Order_By>>;
  where?: Maybe<Bank_Account_Bool_Exp>;
};


export type Query_RootBank_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Bank_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Account_Order_By>>;
  where?: Maybe<Bank_Account_Bool_Exp>;
};


export type Query_RootBank_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBase_CaseArgs = {
  distinct_on?: Maybe<Array<Base_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Order_By>>;
  where?: Maybe<Base_Case_Bool_Exp>;
};


export type Query_RootBase_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Order_By>>;
  where?: Maybe<Base_Case_Bool_Exp>;
};


export type Query_RootBase_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBase_Case_DocumentArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Order_By>>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};


export type Query_RootBase_Case_Document_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Order_By>>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};


export type Query_RootBase_Case_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBase_Case_Document_CategoryArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Category_Order_By>>;
  where?: Maybe<Base_Case_Document_Category_Bool_Exp>;
};


export type Query_RootBase_Case_Document_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Category_Order_By>>;
  where?: Maybe<Base_Case_Document_Category_Bool_Exp>;
};


export type Query_RootBase_Case_Document_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootBase_OfferArgs = {
  distinct_on?: Maybe<Array<Base_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Offer_Order_By>>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};


export type Query_RootBase_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Offer_Order_By>>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};


export type Query_RootBase_Offer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBi_Tableau_Kpi_FullArgs = {
  distinct_on?: Maybe<Array<Bi_Tableau_Kpi_Full_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bi_Tableau_Kpi_Full_Order_By>>;
  where?: Maybe<Bi_Tableau_Kpi_Full_Bool_Exp>;
};


export type Query_RootBi_Tableau_Kpi_Full_AggregateArgs = {
  distinct_on?: Maybe<Array<Bi_Tableau_Kpi_Full_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bi_Tableau_Kpi_Full_Order_By>>;
  where?: Maybe<Bi_Tableau_Kpi_Full_Bool_Exp>;
};


export type Query_RootCase_CommentArgs = {
  distinct_on?: Maybe<Array<Case_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Comment_Order_By>>;
  where?: Maybe<Case_Comment_Bool_Exp>;
};


export type Query_RootCase_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Comment_Order_By>>;
  where?: Maybe<Case_Comment_Bool_Exp>;
};


export type Query_RootCase_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCase_Docs_Upload_GetArgs = {
  input: CaseDocsUploadGetInput;
};


export type Query_RootCase_List_ViewArgs = {
  distinct_on?: Maybe<Array<Case_List_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_List_View_Order_By>>;
  where?: Maybe<Case_List_View_Bool_Exp>;
};


export type Query_RootCase_List_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_List_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_List_View_Order_By>>;
  where?: Maybe<Case_List_View_Bool_Exp>;
};


export type Query_RootCps_Unified_Case_GetArgs = {
  input: CpsUnifiedCaseGetInput;
};


export type Query_RootCustomerArgs = {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Query_RootCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCustomer_Validate_Gdpr_DataArgs = {
  input: CustomerValidateGdprDataInput;
};


export type Query_RootEmail_QueueArgs = {
  distinct_on?: Maybe<Array<Email_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Queue_Order_By>>;
  where?: Maybe<Email_Queue_Bool_Exp>;
};


export type Query_RootEmail_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Queue_Order_By>>;
  where?: Maybe<Email_Queue_Bool_Exp>;
};


export type Query_RootEmail_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFit_ClaimArgs = {
  distinct_on?: Maybe<Array<Fit_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Claim_Order_By>>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};


export type Query_RootFit_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Fit_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Claim_Order_By>>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};


export type Query_RootFit_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFit_CompanyArgs = {
  distinct_on?: Maybe<Array<Fit_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Company_Order_By>>;
  where?: Maybe<Fit_Company_Bool_Exp>;
};


export type Query_RootFit_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Fit_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Company_Order_By>>;
  where?: Maybe<Fit_Company_Bool_Exp>;
};


export type Query_RootFit_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootFit_Fts_TransactionArgs = {
  distinct_on?: Maybe<Array<Fit_Fts_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Fts_Transaction_Order_By>>;
  where?: Maybe<Fit_Fts_Transaction_Bool_Exp>;
};


export type Query_RootFit_Fts_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Fit_Fts_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Fts_Transaction_Order_By>>;
  where?: Maybe<Fit_Fts_Transaction_Bool_Exp>;
};


export type Query_RootFit_Fts_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFs_Bank_TransactionArgs = {
  distinct_on?: Maybe<Array<Fs_Bank_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Bank_Transaction_Order_By>>;
  where?: Maybe<Fs_Bank_Transaction_Bool_Exp>;
};


export type Query_RootFs_Bank_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Fs_Bank_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Bank_Transaction_Order_By>>;
  where?: Maybe<Fs_Bank_Transaction_Bool_Exp>;
};


export type Query_RootFs_Bank_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFs_Customer_PayoutArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Bool_Exp>;
};


export type Query_RootFs_Customer_Payout_AggregateArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Bool_Exp>;
};


export type Query_RootFs_Customer_Payout_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFs_Customer_Payout_QueueArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Queue_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Queue_Bool_Exp>;
};


export type Query_RootFs_Customer_Payout_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Queue_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Queue_Bool_Exp>;
};


export type Query_RootFs_Customer_Payout_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGdpr_CaseArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Order_By>>;
  where?: Maybe<Gdpr_Case_Bool_Exp>;
};


export type Query_RootGdpr_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Order_By>>;
  where?: Maybe<Gdpr_Case_Bool_Exp>;
};


export type Query_RootGdpr_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGdpr_Case_Rejection_ReasonArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Rejection_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Rejection_Reason_Order_By>>;
  where?: Maybe<Gdpr_Case_Rejection_Reason_Bool_Exp>;
};


export type Query_RootGdpr_Case_Rejection_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Rejection_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Rejection_Reason_Order_By>>;
  where?: Maybe<Gdpr_Case_Rejection_Reason_Bool_Exp>;
};


export type Query_RootGdpr_Case_Rejection_Reason_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootGdpr_Case_StateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_State_Order_By>>;
  where?: Maybe<Gdpr_Case_State_Bool_Exp>;
};


export type Query_RootGdpr_Case_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_State_Order_By>>;
  where?: Maybe<Gdpr_Case_State_Bool_Exp>;
};


export type Query_RootGdpr_Case_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootGdpr_ClaimArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Order_By>>;
  where?: Maybe<Gdpr_Claim_Bool_Exp>;
};


export type Query_RootGdpr_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Order_By>>;
  where?: Maybe<Gdpr_Claim_Bool_Exp>;
};


export type Query_RootGdpr_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGdpr_Claim_CompanyArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Company_Order_By>>;
  where?: Maybe<Gdpr_Claim_Company_Bool_Exp>;
};


export type Query_RootGdpr_Claim_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Company_Order_By>>;
  where?: Maybe<Gdpr_Claim_Company_Bool_Exp>;
};


export type Query_RootGdpr_Claim_Company_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootGdpr_CompanyArgs = {
  distinct_on?: Maybe<Array<Gdpr_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Company_Order_By>>;
  where?: Maybe<Gdpr_Company_Bool_Exp>;
};


export type Query_RootGdpr_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Company_Order_By>>;
  where?: Maybe<Gdpr_Company_Bool_Exp>;
};


export type Query_RootGdpr_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootGdpr_OfferArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Order_By>>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};


export type Query_RootGdpr_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Order_By>>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};


export type Query_RootGdpr_Offer_AssignmentArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Assignment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};


export type Query_RootGdpr_Offer_Assignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Assignment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};


export type Query_RootGdpr_Offer_Assignment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGdpr_Offer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGdpr_Offer_PaymentArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};


export type Query_RootGdpr_Offer_Payment_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};


export type Query_RootGdpr_Offer_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGdpr_Offer_StateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_State_Order_By>>;
  where?: Maybe<Gdpr_Offer_State_Bool_Exp>;
};


export type Query_RootGdpr_Offer_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_State_Order_By>>;
  where?: Maybe<Gdpr_Offer_State_Bool_Exp>;
};


export type Query_RootGdpr_Offer_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootGenerated_ReportArgs = {
  distinct_on?: Maybe<Array<Generated_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Generated_Report_Order_By>>;
  where?: Maybe<Generated_Report_Bool_Exp>;
};


export type Query_RootGenerated_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Generated_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Generated_Report_Order_By>>;
  where?: Maybe<Generated_Report_Bool_Exp>;
};


export type Query_RootGenerated_Report_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGff_AirportArgs = {
  distinct_on?: Maybe<Array<Gff_Airport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Airport_Order_By>>;
  where?: Maybe<Gff_Airport_Bool_Exp>;
};


export type Query_RootGff_Airport_AggregateArgs = {
  distinct_on?: Maybe<Array<Gff_Airport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Airport_Order_By>>;
  where?: Maybe<Gff_Airport_Bool_Exp>;
};


export type Query_RootGff_Airport_By_PkArgs = {
  code: Scalars['String'];
};


export type Query_RootGff_ClaimArgs = {
  distinct_on?: Maybe<Array<Gff_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Claim_Order_By>>;
  where?: Maybe<Gff_Claim_Bool_Exp>;
};


export type Query_RootGff_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Gff_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Claim_Order_By>>;
  where?: Maybe<Gff_Claim_Bool_Exp>;
};


export type Query_RootGff_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGff_CompanyArgs = {
  distinct_on?: Maybe<Array<Gff_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Company_Order_By>>;
  where?: Maybe<Gff_Company_Bool_Exp>;
};


export type Query_RootGff_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Gff_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Company_Order_By>>;
  where?: Maybe<Gff_Company_Bool_Exp>;
};


export type Query_RootGff_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootHaveibeenpwned_Api_LogArgs = {
  distinct_on?: Maybe<Array<Haveibeenpwned_Api_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Haveibeenpwned_Api_Log_Order_By>>;
  where?: Maybe<Haveibeenpwned_Api_Log_Bool_Exp>;
};


export type Query_RootHaveibeenpwned_Api_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Haveibeenpwned_Api_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Haveibeenpwned_Api_Log_Order_By>>;
  where?: Maybe<Haveibeenpwned_Api_Log_Bool_Exp>;
};


export type Query_RootHaveibeenpwned_Api_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLawsuitnow_Case_ViewArgs = {
  input: LawsuitnowCaseViewInput;
};


export type Query_RootLost_AssignmentArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_Order_By>>;
  where?: Maybe<Lost_Assignment_Bool_Exp>;
};


export type Query_RootLost_Assignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_Order_By>>;
  where?: Maybe<Lost_Assignment_Bool_Exp>;
};


export type Query_RootLost_Assignment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLost_Assignment_StateArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_State_Order_By>>;
  where?: Maybe<Lost_Assignment_State_Bool_Exp>;
};


export type Query_RootLost_Assignment_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_State_Order_By>>;
  where?: Maybe<Lost_Assignment_State_Bool_Exp>;
};


export type Query_RootLost_Assignment_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLs_CaseArgs = {
  distinct_on?: Maybe<Array<Ls_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Case_Order_By>>;
  where?: Maybe<Ls_Case_Bool_Exp>;
};


export type Query_RootLs_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Case_Order_By>>;
  where?: Maybe<Ls_Case_Bool_Exp>;
};


export type Query_RootLs_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLs_LawsuitArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLs_Lawsuit_CourtArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Court_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Court_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Court_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Court_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Court_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Court_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLs_Lawsuit_DocumentArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Document_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLs_Lawsuit_Document_CategoryArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Category_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Document_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Category_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Document_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootLs_Lawsuit_ExpenseArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Expense_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Expense_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Expense_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Expense_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Expense_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Expense_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Expense_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Expense_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLs_Lawsuit_HistoryArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_History_Order_By>>;
  where?: Maybe<Ls_Lawsuit_History_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_History_Order_By>>;
  where?: Maybe<Ls_Lawsuit_History_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLs_Lawsuit_LawyerArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Lawyer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Lawyer_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Lawyer_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Lawyer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Lawyer_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
};


export type Query_RootLs_Lawsuit_Lawyer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNflx_ClaimArgs = {
  distinct_on?: Maybe<Array<Nflx_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Claim_Order_By>>;
  where?: Maybe<Nflx_Claim_Bool_Exp>;
};


export type Query_RootNflx_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Nflx_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Claim_Order_By>>;
  where?: Maybe<Nflx_Claim_Bool_Exp>;
};


export type Query_RootNflx_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNflx_CompanyArgs = {
  distinct_on?: Maybe<Array<Nflx_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Company_Order_By>>;
  where?: Maybe<Nflx_Company_Bool_Exp>;
};


export type Query_RootNflx_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Nflx_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Company_Order_By>>;
  where?: Maybe<Nflx_Company_Bool_Exp>;
};


export type Query_RootNflx_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootNrg_ClaimArgs = {
  distinct_on?: Maybe<Array<Nrg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Claim_Order_By>>;
  where?: Maybe<Nrg_Claim_Bool_Exp>;
};


export type Query_RootNrg_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Nrg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Claim_Order_By>>;
  where?: Maybe<Nrg_Claim_Bool_Exp>;
};


export type Query_RootNrg_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNrg_CompanyArgs = {
  distinct_on?: Maybe<Array<Nrg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Company_Order_By>>;
  where?: Maybe<Nrg_Company_Bool_Exp>;
};


export type Query_RootNrg_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Nrg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Company_Order_By>>;
  where?: Maybe<Nrg_Company_Bool_Exp>;
};


export type Query_RootNrg_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootOcg_ClaimArgs = {
  distinct_on?: Maybe<Array<Ocg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Claim_Order_By>>;
  where?: Maybe<Ocg_Claim_Bool_Exp>;
};


export type Query_RootOcg_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Ocg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Claim_Order_By>>;
  where?: Maybe<Ocg_Claim_Bool_Exp>;
};


export type Query_RootOcg_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOcg_CompanyArgs = {
  distinct_on?: Maybe<Array<Ocg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Company_Order_By>>;
  where?: Maybe<Ocg_Company_Bool_Exp>;
};


export type Query_RootOcg_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Ocg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Company_Order_By>>;
  where?: Maybe<Ocg_Company_Bool_Exp>;
};


export type Query_RootOcg_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootOffer_GetArgs = {
  input: OfferGetInput;
};


export type Query_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPayment_StateArgs = {
  distinct_on?: Maybe<Array<Payment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_State_Order_By>>;
  where?: Maybe<Payment_State_Bool_Exp>;
};


export type Query_RootPayment_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_State_Order_By>>;
  where?: Maybe<Payment_State_Bool_Exp>;
};


export type Query_RootPayment_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPkv_ClaimArgs = {
  distinct_on?: Maybe<Array<Pkv_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Claim_Order_By>>;
  where?: Maybe<Pkv_Claim_Bool_Exp>;
};


export type Query_RootPkv_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Pkv_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Claim_Order_By>>;
  where?: Maybe<Pkv_Claim_Bool_Exp>;
};


export type Query_RootPkv_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPkv_CompanyArgs = {
  distinct_on?: Maybe<Array<Pkv_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Company_Order_By>>;
  where?: Maybe<Pkv_Company_Bool_Exp>;
};


export type Query_RootPkv_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Pkv_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Company_Order_By>>;
  where?: Maybe<Pkv_Company_Bool_Exp>;
};


export type Query_RootPkv_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPs_DebtorArgs = {
  distinct_on?: Maybe<Array<Ps_Debtor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ps_Debtor_Order_By>>;
  where?: Maybe<Ps_Debtor_Bool_Exp>;
};


export type Query_RootPs_Debtor_AggregateArgs = {
  distinct_on?: Maybe<Array<Ps_Debtor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ps_Debtor_Order_By>>;
  where?: Maybe<Ps_Debtor_Bool_Exp>;
};


export type Query_RootPs_Debtor_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootThird_Party_Raw_DataArgs = {
  distinct_on?: Maybe<Array<Third_Party_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Third_Party_Raw_Data_Order_By>>;
  where?: Maybe<Third_Party_Raw_Data_Bool_Exp>;
};


export type Query_RootThird_Party_Raw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Third_Party_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Third_Party_Raw_Data_Order_By>>;
  where?: Maybe<Third_Party_Raw_Data_Bool_Exp>;
};


export type Query_RootThird_Party_Raw_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUnified_CaseArgs = {
  distinct_on?: Maybe<Array<Unified_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Unified_Case_Order_By>>;
  where?: Maybe<Unified_Case_Bool_Exp>;
};


export type Query_RootUnified_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Unified_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Unified_Case_Order_By>>;
  where?: Maybe<Unified_Case_Bool_Exp>;
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_RoleArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Query_RootUser_Role_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Role_Aggregate;
};


/** columns and relationships of "role" */
export type RoleUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "role" */
export type RoleUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: Maybe<Array<Role_Bool_Exp>>;
  _not?: Maybe<Role_Bool_Exp>;
  _or?: Maybe<Array<Role_Bool_Exp>>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  user_roles?: Maybe<User_Role_Bool_Exp>;
  user_roles_aggregate?: Maybe<User_Role_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolePkey = 'role_pkey'
}

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  user_roles?: Maybe<User_Role_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** input type for inserting object relation for remote table "role" */
export type Role_Obj_Rel_Insert_Input = {
  data: Role_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Role_On_Conflict>;
};

/** on_conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: Maybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  user_roles_aggregate?: Maybe<User_Role_Aggregate_Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "role" */
export type Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "api_keys" */
  api_keys: Array<Api_Keys>;
  /** fetch aggregated fields from the table: "api_keys" */
  api_keys_aggregate: Api_Keys_Aggregate;
  /** fetch data from the table: "api_keys" using primary key columns */
  api_keys_by_pk?: Maybe<Api_Keys>;
  /** fetch data from the table in a streaming manner: "api_keys" */
  api_keys_stream: Array<Api_Keys>;
  /** fetch data from the table: "assignment" */
  assignment: Array<Assignment>;
  /** fetch aggregated fields from the table: "assignment" */
  assignment_aggregate: Assignment_Aggregate;
  /** fetch data from the table: "assignment" using primary key columns */
  assignment_by_pk?: Maybe<Assignment>;
  /** fetch data from the table: "assignment_state" */
  assignment_state: Array<Assignment_State>;
  /** fetch aggregated fields from the table: "assignment_state" */
  assignment_state_aggregate: Assignment_State_Aggregate;
  /** fetch data from the table: "assignment_state" using primary key columns */
  assignment_state_by_pk?: Maybe<Assignment_State>;
  /** fetch data from the table in a streaming manner: "assignment_state" */
  assignment_state_stream: Array<Assignment_State>;
  /** fetch data from the table in a streaming manner: "assignment" */
  assignment_stream: Array<Assignment>;
  /** fetch data from the table: "bank_account" */
  bank_account: Array<Bank_Account>;
  /** fetch aggregated fields from the table: "bank_account" */
  bank_account_aggregate: Bank_Account_Aggregate;
  /** fetch data from the table: "bank_account" using primary key columns */
  bank_account_by_pk?: Maybe<Bank_Account>;
  /** fetch data from the table in a streaming manner: "bank_account" */
  bank_account_stream: Array<Bank_Account>;
  /** fetch data from the table: "base_case" */
  base_case: Array<Base_Case>;
  /** fetch aggregated fields from the table: "base_case" */
  base_case_aggregate: Base_Case_Aggregate;
  /** fetch data from the table: "base_case" using primary key columns */
  base_case_by_pk?: Maybe<Base_Case>;
  /** fetch data from the table: "base_case_document" */
  base_case_document: Array<Base_Case_Document>;
  /** fetch aggregated fields from the table: "base_case_document" */
  base_case_document_aggregate: Base_Case_Document_Aggregate;
  /** fetch data from the table: "base_case_document" using primary key columns */
  base_case_document_by_pk?: Maybe<Base_Case_Document>;
  /** fetch data from the table: "base_case_document_category" */
  base_case_document_category: Array<Base_Case_Document_Category>;
  /** fetch aggregated fields from the table: "base_case_document_category" */
  base_case_document_category_aggregate: Base_Case_Document_Category_Aggregate;
  /** fetch data from the table: "base_case_document_category" using primary key columns */
  base_case_document_category_by_pk?: Maybe<Base_Case_Document_Category>;
  /** fetch data from the table in a streaming manner: "base_case_document_category" */
  base_case_document_category_stream: Array<Base_Case_Document_Category>;
  /** fetch data from the table in a streaming manner: "base_case_document" */
  base_case_document_stream: Array<Base_Case_Document>;
  /** fetch data from the table in a streaming manner: "base_case" */
  base_case_stream: Array<Base_Case>;
  /** fetch data from the table: "base_offer" */
  base_offer: Array<Base_Offer>;
  /** fetch aggregated fields from the table: "base_offer" */
  base_offer_aggregate: Base_Offer_Aggregate;
  /** fetch data from the table: "base_offer" using primary key columns */
  base_offer_by_pk?: Maybe<Base_Offer>;
  /** fetch data from the table in a streaming manner: "base_offer" */
  base_offer_stream: Array<Base_Offer>;
  /** fetch data from the table: "bi_tableau_kpi_full" */
  bi_tableau_kpi_full: Array<Bi_Tableau_Kpi_Full>;
  /** fetch aggregated fields from the table: "bi_tableau_kpi_full" */
  bi_tableau_kpi_full_aggregate: Bi_Tableau_Kpi_Full_Aggregate;
  /** fetch data from the table in a streaming manner: "bi_tableau_kpi_full" */
  bi_tableau_kpi_full_stream: Array<Bi_Tableau_Kpi_Full>;
  /** fetch data from the table: "case_comment" */
  case_comment: Array<Case_Comment>;
  /** fetch aggregated fields from the table: "case_comment" */
  case_comment_aggregate: Case_Comment_Aggregate;
  /** fetch data from the table: "case_comment" using primary key columns */
  case_comment_by_pk?: Maybe<Case_Comment>;
  /** fetch data from the table in a streaming manner: "case_comment" */
  case_comment_stream: Array<Case_Comment>;
  /** fetch data from the table: "case_list_view" */
  case_list_view: Array<Case_List_View>;
  /** fetch aggregated fields from the table: "case_list_view" */
  case_list_view_aggregate: Case_List_View_Aggregate;
  /** fetch data from the table in a streaming manner: "case_list_view" */
  case_list_view_stream: Array<Case_List_View>;
  /** fetch data from the table: "customer" */
  customer: Array<Customer>;
  /** fetch aggregated fields from the table: "customer" */
  customer_aggregate: Customer_Aggregate;
  /** fetch data from the table: "customer" using primary key columns */
  customer_by_pk?: Maybe<Customer>;
  /** fetch data from the table in a streaming manner: "customer" */
  customer_stream: Array<Customer>;
  /** fetch data from the table: "email_queue" */
  email_queue: Array<Email_Queue>;
  /** fetch aggregated fields from the table: "email_queue" */
  email_queue_aggregate: Email_Queue_Aggregate;
  /** fetch data from the table: "email_queue" using primary key columns */
  email_queue_by_pk?: Maybe<Email_Queue>;
  /** fetch data from the table in a streaming manner: "email_queue" */
  email_queue_stream: Array<Email_Queue>;
  /** fetch data from the table: "fit_claim" */
  fit_claim: Array<Fit_Claim>;
  /** fetch aggregated fields from the table: "fit_claim" */
  fit_claim_aggregate: Fit_Claim_Aggregate;
  /** fetch data from the table: "fit_claim" using primary key columns */
  fit_claim_by_pk?: Maybe<Fit_Claim>;
  /** fetch data from the table in a streaming manner: "fit_claim" */
  fit_claim_stream: Array<Fit_Claim>;
  /** fetch data from the table: "fit_company" */
  fit_company: Array<Fit_Company>;
  /** fetch aggregated fields from the table: "fit_company" */
  fit_company_aggregate: Fit_Company_Aggregate;
  /** fetch data from the table: "fit_company" using primary key columns */
  fit_company_by_pk?: Maybe<Fit_Company>;
  /** fetch data from the table in a streaming manner: "fit_company" */
  fit_company_stream: Array<Fit_Company>;
  /** fetch data from the table: "fit_fts_transaction" */
  fit_fts_transaction: Array<Fit_Fts_Transaction>;
  /** fetch aggregated fields from the table: "fit_fts_transaction" */
  fit_fts_transaction_aggregate: Fit_Fts_Transaction_Aggregate;
  /** fetch data from the table: "fit_fts_transaction" using primary key columns */
  fit_fts_transaction_by_pk?: Maybe<Fit_Fts_Transaction>;
  /** fetch data from the table in a streaming manner: "fit_fts_transaction" */
  fit_fts_transaction_stream: Array<Fit_Fts_Transaction>;
  /** fetch data from the table: "fs_bank_transaction" */
  fs_bank_transaction: Array<Fs_Bank_Transaction>;
  /** fetch aggregated fields from the table: "fs_bank_transaction" */
  fs_bank_transaction_aggregate: Fs_Bank_Transaction_Aggregate;
  /** fetch data from the table: "fs_bank_transaction" using primary key columns */
  fs_bank_transaction_by_pk?: Maybe<Fs_Bank_Transaction>;
  /** fetch data from the table in a streaming manner: "fs_bank_transaction" */
  fs_bank_transaction_stream: Array<Fs_Bank_Transaction>;
  /** fetch data from the table: "fs_customer_payout" */
  fs_customer_payout: Array<Fs_Customer_Payout>;
  /** fetch aggregated fields from the table: "fs_customer_payout" */
  fs_customer_payout_aggregate: Fs_Customer_Payout_Aggregate;
  /** fetch data from the table: "fs_customer_payout" using primary key columns */
  fs_customer_payout_by_pk?: Maybe<Fs_Customer_Payout>;
  /** fetch data from the table: "fs_customer_payout_queue" */
  fs_customer_payout_queue: Array<Fs_Customer_Payout_Queue>;
  /** fetch aggregated fields from the table: "fs_customer_payout_queue" */
  fs_customer_payout_queue_aggregate: Fs_Customer_Payout_Queue_Aggregate;
  /** fetch data from the table: "fs_customer_payout_queue" using primary key columns */
  fs_customer_payout_queue_by_pk?: Maybe<Fs_Customer_Payout_Queue>;
  /** fetch data from the table in a streaming manner: "fs_customer_payout_queue" */
  fs_customer_payout_queue_stream: Array<Fs_Customer_Payout_Queue>;
  /** fetch data from the table in a streaming manner: "fs_customer_payout" */
  fs_customer_payout_stream: Array<Fs_Customer_Payout>;
  /** fetch data from the table: "gdpr_case" */
  gdpr_case: Array<Gdpr_Case>;
  /** fetch aggregated fields from the table: "gdpr_case" */
  gdpr_case_aggregate: Gdpr_Case_Aggregate;
  /** fetch data from the table: "gdpr_case" using primary key columns */
  gdpr_case_by_pk?: Maybe<Gdpr_Case>;
  /** fetch data from the table: "gdpr_case_rejection_reason" */
  gdpr_case_rejection_reason: Array<Gdpr_Case_Rejection_Reason>;
  /** fetch aggregated fields from the table: "gdpr_case_rejection_reason" */
  gdpr_case_rejection_reason_aggregate: Gdpr_Case_Rejection_Reason_Aggregate;
  /** fetch data from the table: "gdpr_case_rejection_reason" using primary key columns */
  gdpr_case_rejection_reason_by_pk?: Maybe<Gdpr_Case_Rejection_Reason>;
  /** fetch data from the table in a streaming manner: "gdpr_case_rejection_reason" */
  gdpr_case_rejection_reason_stream: Array<Gdpr_Case_Rejection_Reason>;
  /** fetch data from the table: "gdpr_case_state" */
  gdpr_case_state: Array<Gdpr_Case_State>;
  /** fetch aggregated fields from the table: "gdpr_case_state" */
  gdpr_case_state_aggregate: Gdpr_Case_State_Aggregate;
  /** fetch data from the table: "gdpr_case_state" using primary key columns */
  gdpr_case_state_by_pk?: Maybe<Gdpr_Case_State>;
  /** fetch data from the table in a streaming manner: "gdpr_case_state" */
  gdpr_case_state_stream: Array<Gdpr_Case_State>;
  /** fetch data from the table in a streaming manner: "gdpr_case" */
  gdpr_case_stream: Array<Gdpr_Case>;
  /** fetch data from the table: "gdpr_claim" */
  gdpr_claim: Array<Gdpr_Claim>;
  /** fetch aggregated fields from the table: "gdpr_claim" */
  gdpr_claim_aggregate: Gdpr_Claim_Aggregate;
  /** fetch data from the table: "gdpr_claim" using primary key columns */
  gdpr_claim_by_pk?: Maybe<Gdpr_Claim>;
  /** fetch data from the table: "gdpr_claim_company" */
  gdpr_claim_company: Array<Gdpr_Claim_Company>;
  /** fetch aggregated fields from the table: "gdpr_claim_company" */
  gdpr_claim_company_aggregate: Gdpr_Claim_Company_Aggregate;
  /** fetch data from the table: "gdpr_claim_company" using primary key columns */
  gdpr_claim_company_by_pk?: Maybe<Gdpr_Claim_Company>;
  /** fetch data from the table in a streaming manner: "gdpr_claim_company" */
  gdpr_claim_company_stream: Array<Gdpr_Claim_Company>;
  /** fetch data from the table in a streaming manner: "gdpr_claim" */
  gdpr_claim_stream: Array<Gdpr_Claim>;
  /** fetch data from the table: "gdpr_company" */
  gdpr_company: Array<Gdpr_Company>;
  /** fetch aggregated fields from the table: "gdpr_company" */
  gdpr_company_aggregate: Gdpr_Company_Aggregate;
  /** fetch data from the table: "gdpr_company" using primary key columns */
  gdpr_company_by_pk?: Maybe<Gdpr_Company>;
  /** fetch data from the table in a streaming manner: "gdpr_company" */
  gdpr_company_stream: Array<Gdpr_Company>;
  /** fetch data from the table: "gdpr_offer" */
  gdpr_offer: Array<Gdpr_Offer>;
  /** fetch aggregated fields from the table: "gdpr_offer" */
  gdpr_offer_aggregate: Gdpr_Offer_Aggregate;
  /** fetch data from the table: "gdpr_offer_assignment" */
  gdpr_offer_assignment: Array<Gdpr_Offer_Assignment>;
  /** fetch aggregated fields from the table: "gdpr_offer_assignment" */
  gdpr_offer_assignment_aggregate: Gdpr_Offer_Assignment_Aggregate;
  /** fetch data from the table: "gdpr_offer_assignment" using primary key columns */
  gdpr_offer_assignment_by_pk?: Maybe<Gdpr_Offer_Assignment>;
  /** fetch data from the table in a streaming manner: "gdpr_offer_assignment" */
  gdpr_offer_assignment_stream: Array<Gdpr_Offer_Assignment>;
  /** fetch data from the table: "gdpr_offer" using primary key columns */
  gdpr_offer_by_pk?: Maybe<Gdpr_Offer>;
  /** fetch data from the table: "gdpr_offer_payment" */
  gdpr_offer_payment: Array<Gdpr_Offer_Payment>;
  /** fetch aggregated fields from the table: "gdpr_offer_payment" */
  gdpr_offer_payment_aggregate: Gdpr_Offer_Payment_Aggregate;
  /** fetch data from the table: "gdpr_offer_payment" using primary key columns */
  gdpr_offer_payment_by_pk?: Maybe<Gdpr_Offer_Payment>;
  /** fetch data from the table in a streaming manner: "gdpr_offer_payment" */
  gdpr_offer_payment_stream: Array<Gdpr_Offer_Payment>;
  /** fetch data from the table: "gdpr_offer_state" */
  gdpr_offer_state: Array<Gdpr_Offer_State>;
  /** fetch aggregated fields from the table: "gdpr_offer_state" */
  gdpr_offer_state_aggregate: Gdpr_Offer_State_Aggregate;
  /** fetch data from the table: "gdpr_offer_state" using primary key columns */
  gdpr_offer_state_by_pk?: Maybe<Gdpr_Offer_State>;
  /** fetch data from the table in a streaming manner: "gdpr_offer_state" */
  gdpr_offer_state_stream: Array<Gdpr_Offer_State>;
  /** fetch data from the table in a streaming manner: "gdpr_offer" */
  gdpr_offer_stream: Array<Gdpr_Offer>;
  /** fetch data from the table: "generated_report" */
  generated_report: Array<Generated_Report>;
  /** fetch aggregated fields from the table: "generated_report" */
  generated_report_aggregate: Generated_Report_Aggregate;
  /** fetch data from the table: "generated_report" using primary key columns */
  generated_report_by_pk?: Maybe<Generated_Report>;
  /** fetch data from the table in a streaming manner: "generated_report" */
  generated_report_stream: Array<Generated_Report>;
  /** fetch data from the table: "gff_airport" */
  gff_airport: Array<Gff_Airport>;
  /** fetch aggregated fields from the table: "gff_airport" */
  gff_airport_aggregate: Gff_Airport_Aggregate;
  /** fetch data from the table: "gff_airport" using primary key columns */
  gff_airport_by_pk?: Maybe<Gff_Airport>;
  /** fetch data from the table in a streaming manner: "gff_airport" */
  gff_airport_stream: Array<Gff_Airport>;
  /** fetch data from the table: "gff_claim" */
  gff_claim: Array<Gff_Claim>;
  /** fetch aggregated fields from the table: "gff_claim" */
  gff_claim_aggregate: Gff_Claim_Aggregate;
  /** fetch data from the table: "gff_claim" using primary key columns */
  gff_claim_by_pk?: Maybe<Gff_Claim>;
  /** fetch data from the table in a streaming manner: "gff_claim" */
  gff_claim_stream: Array<Gff_Claim>;
  /** fetch data from the table: "gff_company" */
  gff_company: Array<Gff_Company>;
  /** fetch aggregated fields from the table: "gff_company" */
  gff_company_aggregate: Gff_Company_Aggregate;
  /** fetch data from the table: "gff_company" using primary key columns */
  gff_company_by_pk?: Maybe<Gff_Company>;
  /** fetch data from the table in a streaming manner: "gff_company" */
  gff_company_stream: Array<Gff_Company>;
  /** fetch data from the table: "haveibeenpwned_api_log" */
  haveibeenpwned_api_log: Array<Haveibeenpwned_Api_Log>;
  /** fetch aggregated fields from the table: "haveibeenpwned_api_log" */
  haveibeenpwned_api_log_aggregate: Haveibeenpwned_Api_Log_Aggregate;
  /** fetch data from the table: "haveibeenpwned_api_log" using primary key columns */
  haveibeenpwned_api_log_by_pk?: Maybe<Haveibeenpwned_Api_Log>;
  /** fetch data from the table in a streaming manner: "haveibeenpwned_api_log" */
  haveibeenpwned_api_log_stream: Array<Haveibeenpwned_Api_Log>;
  /** fetch data from the table: "lost_assignment" */
  lost_assignment: Array<Lost_Assignment>;
  /** fetch aggregated fields from the table: "lost_assignment" */
  lost_assignment_aggregate: Lost_Assignment_Aggregate;
  /** fetch data from the table: "lost_assignment" using primary key columns */
  lost_assignment_by_pk?: Maybe<Lost_Assignment>;
  /** fetch data from the table: "lost_assignment_state" */
  lost_assignment_state: Array<Lost_Assignment_State>;
  /** fetch aggregated fields from the table: "lost_assignment_state" */
  lost_assignment_state_aggregate: Lost_Assignment_State_Aggregate;
  /** fetch data from the table: "lost_assignment_state" using primary key columns */
  lost_assignment_state_by_pk?: Maybe<Lost_Assignment_State>;
  /** fetch data from the table in a streaming manner: "lost_assignment_state" */
  lost_assignment_state_stream: Array<Lost_Assignment_State>;
  /** fetch data from the table in a streaming manner: "lost_assignment" */
  lost_assignment_stream: Array<Lost_Assignment>;
  /** fetch data from the table: "ls_case" */
  ls_case: Array<Ls_Case>;
  /** fetch aggregated fields from the table: "ls_case" */
  ls_case_aggregate: Ls_Case_Aggregate;
  /** fetch data from the table: "ls_case" using primary key columns */
  ls_case_by_pk?: Maybe<Ls_Case>;
  /** fetch data from the table in a streaming manner: "ls_case" */
  ls_case_stream: Array<Ls_Case>;
  /** fetch data from the table: "ls_lawsuit" */
  ls_lawsuit: Array<Ls_Lawsuit>;
  /** fetch aggregated fields from the table: "ls_lawsuit" */
  ls_lawsuit_aggregate: Ls_Lawsuit_Aggregate;
  /** fetch data from the table: "ls_lawsuit" using primary key columns */
  ls_lawsuit_by_pk?: Maybe<Ls_Lawsuit>;
  /** fetch data from the table: "ls_lawsuit_court" */
  ls_lawsuit_court: Array<Ls_Lawsuit_Court>;
  /** fetch aggregated fields from the table: "ls_lawsuit_court" */
  ls_lawsuit_court_aggregate: Ls_Lawsuit_Court_Aggregate;
  /** fetch data from the table: "ls_lawsuit_court" using primary key columns */
  ls_lawsuit_court_by_pk?: Maybe<Ls_Lawsuit_Court>;
  /** fetch data from the table in a streaming manner: "ls_lawsuit_court" */
  ls_lawsuit_court_stream: Array<Ls_Lawsuit_Court>;
  /** fetch data from the table: "ls_lawsuit_document" */
  ls_lawsuit_document: Array<Ls_Lawsuit_Document>;
  /** fetch aggregated fields from the table: "ls_lawsuit_document" */
  ls_lawsuit_document_aggregate: Ls_Lawsuit_Document_Aggregate;
  /** fetch data from the table: "ls_lawsuit_document" using primary key columns */
  ls_lawsuit_document_by_pk?: Maybe<Ls_Lawsuit_Document>;
  /** fetch data from the table: "ls_lawsuit_document_category" */
  ls_lawsuit_document_category: Array<Ls_Lawsuit_Document_Category>;
  /** fetch aggregated fields from the table: "ls_lawsuit_document_category" */
  ls_lawsuit_document_category_aggregate: Ls_Lawsuit_Document_Category_Aggregate;
  /** fetch data from the table: "ls_lawsuit_document_category" using primary key columns */
  ls_lawsuit_document_category_by_pk?: Maybe<Ls_Lawsuit_Document_Category>;
  /** fetch data from the table in a streaming manner: "ls_lawsuit_document_category" */
  ls_lawsuit_document_category_stream: Array<Ls_Lawsuit_Document_Category>;
  /** fetch data from the table in a streaming manner: "ls_lawsuit_document" */
  ls_lawsuit_document_stream: Array<Ls_Lawsuit_Document>;
  /** fetch data from the table: "ls_lawsuit_expense" */
  ls_lawsuit_expense: Array<Ls_Lawsuit_Expense>;
  /** fetch aggregated fields from the table: "ls_lawsuit_expense" */
  ls_lawsuit_expense_aggregate: Ls_Lawsuit_Expense_Aggregate;
  /** fetch data from the table: "ls_lawsuit_expense" using primary key columns */
  ls_lawsuit_expense_by_pk?: Maybe<Ls_Lawsuit_Expense>;
  /** fetch data from the table in a streaming manner: "ls_lawsuit_expense" */
  ls_lawsuit_expense_stream: Array<Ls_Lawsuit_Expense>;
  /** fetch data from the table: "ls_lawsuit_history" */
  ls_lawsuit_history: Array<Ls_Lawsuit_History>;
  /** fetch aggregated fields from the table: "ls_lawsuit_history" */
  ls_lawsuit_history_aggregate: Ls_Lawsuit_History_Aggregate;
  /** fetch data from the table: "ls_lawsuit_history" using primary key columns */
  ls_lawsuit_history_by_pk?: Maybe<Ls_Lawsuit_History>;
  /** fetch data from the table in a streaming manner: "ls_lawsuit_history" */
  ls_lawsuit_history_stream: Array<Ls_Lawsuit_History>;
  /** fetch data from the table: "ls_lawsuit_lawyer" */
  ls_lawsuit_lawyer: Array<Ls_Lawsuit_Lawyer>;
  /** fetch aggregated fields from the table: "ls_lawsuit_lawyer" */
  ls_lawsuit_lawyer_aggregate: Ls_Lawsuit_Lawyer_Aggregate;
  /** fetch data from the table: "ls_lawsuit_lawyer" using primary key columns */
  ls_lawsuit_lawyer_by_pk?: Maybe<Ls_Lawsuit_Lawyer>;
  /** fetch data from the table in a streaming manner: "ls_lawsuit_lawyer" */
  ls_lawsuit_lawyer_stream: Array<Ls_Lawsuit_Lawyer>;
  /** fetch data from the table in a streaming manner: "ls_lawsuit" */
  ls_lawsuit_stream: Array<Ls_Lawsuit>;
  /** fetch data from the table: "nflx_claim" */
  nflx_claim: Array<Nflx_Claim>;
  /** fetch aggregated fields from the table: "nflx_claim" */
  nflx_claim_aggregate: Nflx_Claim_Aggregate;
  /** fetch data from the table: "nflx_claim" using primary key columns */
  nflx_claim_by_pk?: Maybe<Nflx_Claim>;
  /** fetch data from the table in a streaming manner: "nflx_claim" */
  nflx_claim_stream: Array<Nflx_Claim>;
  /** fetch data from the table: "nflx_company" */
  nflx_company: Array<Nflx_Company>;
  /** fetch aggregated fields from the table: "nflx_company" */
  nflx_company_aggregate: Nflx_Company_Aggregate;
  /** fetch data from the table: "nflx_company" using primary key columns */
  nflx_company_by_pk?: Maybe<Nflx_Company>;
  /** fetch data from the table in a streaming manner: "nflx_company" */
  nflx_company_stream: Array<Nflx_Company>;
  /** fetch data from the table: "nrg_claim" */
  nrg_claim: Array<Nrg_Claim>;
  /** fetch aggregated fields from the table: "nrg_claim" */
  nrg_claim_aggregate: Nrg_Claim_Aggregate;
  /** fetch data from the table: "nrg_claim" using primary key columns */
  nrg_claim_by_pk?: Maybe<Nrg_Claim>;
  /** fetch data from the table in a streaming manner: "nrg_claim" */
  nrg_claim_stream: Array<Nrg_Claim>;
  /** fetch data from the table: "nrg_company" */
  nrg_company: Array<Nrg_Company>;
  /** fetch aggregated fields from the table: "nrg_company" */
  nrg_company_aggregate: Nrg_Company_Aggregate;
  /** fetch data from the table: "nrg_company" using primary key columns */
  nrg_company_by_pk?: Maybe<Nrg_Company>;
  /** fetch data from the table in a streaming manner: "nrg_company" */
  nrg_company_stream: Array<Nrg_Company>;
  /** fetch data from the table: "ocg_claim" */
  ocg_claim: Array<Ocg_Claim>;
  /** fetch aggregated fields from the table: "ocg_claim" */
  ocg_claim_aggregate: Ocg_Claim_Aggregate;
  /** fetch data from the table: "ocg_claim" using primary key columns */
  ocg_claim_by_pk?: Maybe<Ocg_Claim>;
  /** fetch data from the table in a streaming manner: "ocg_claim" */
  ocg_claim_stream: Array<Ocg_Claim>;
  /** fetch data from the table: "ocg_company" */
  ocg_company: Array<Ocg_Company>;
  /** fetch aggregated fields from the table: "ocg_company" */
  ocg_company_aggregate: Ocg_Company_Aggregate;
  /** fetch data from the table: "ocg_company" using primary key columns */
  ocg_company_by_pk?: Maybe<Ocg_Company>;
  /** fetch data from the table in a streaming manner: "ocg_company" */
  ocg_company_stream: Array<Ocg_Company>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch aggregated fields from the table: "payment" */
  payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "payment_state" */
  payment_state: Array<Payment_State>;
  /** fetch aggregated fields from the table: "payment_state" */
  payment_state_aggregate: Payment_State_Aggregate;
  /** fetch data from the table: "payment_state" using primary key columns */
  payment_state_by_pk?: Maybe<Payment_State>;
  /** fetch data from the table in a streaming manner: "payment_state" */
  payment_state_stream: Array<Payment_State>;
  /** fetch data from the table in a streaming manner: "payment" */
  payment_stream: Array<Payment>;
  /** fetch data from the table: "pkv_claim" */
  pkv_claim: Array<Pkv_Claim>;
  /** fetch aggregated fields from the table: "pkv_claim" */
  pkv_claim_aggregate: Pkv_Claim_Aggregate;
  /** fetch data from the table: "pkv_claim" using primary key columns */
  pkv_claim_by_pk?: Maybe<Pkv_Claim>;
  /** fetch data from the table in a streaming manner: "pkv_claim" */
  pkv_claim_stream: Array<Pkv_Claim>;
  /** fetch data from the table: "pkv_company" */
  pkv_company: Array<Pkv_Company>;
  /** fetch aggregated fields from the table: "pkv_company" */
  pkv_company_aggregate: Pkv_Company_Aggregate;
  /** fetch data from the table: "pkv_company" using primary key columns */
  pkv_company_by_pk?: Maybe<Pkv_Company>;
  /** fetch data from the table in a streaming manner: "pkv_company" */
  pkv_company_stream: Array<Pkv_Company>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>;
  /** fetch data from the table: "ps_debtor" */
  ps_debtor: Array<Ps_Debtor>;
  /** fetch aggregated fields from the table: "ps_debtor" */
  ps_debtor_aggregate: Ps_Debtor_Aggregate;
  /** fetch data from the table: "ps_debtor" using primary key columns */
  ps_debtor_by_pk?: Maybe<Ps_Debtor>;
  /** fetch data from the table in a streaming manner: "ps_debtor" */
  ps_debtor_stream: Array<Ps_Debtor>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table in a streaming manner: "role" */
  role_stream: Array<Role>;
  /** fetch data from the table: "third_party_raw_data" */
  third_party_raw_data: Array<Third_Party_Raw_Data>;
  /** fetch aggregated fields from the table: "third_party_raw_data" */
  third_party_raw_data_aggregate: Third_Party_Raw_Data_Aggregate;
  /** fetch data from the table: "third_party_raw_data" using primary key columns */
  third_party_raw_data_by_pk?: Maybe<Third_Party_Raw_Data>;
  /** fetch data from the table in a streaming manner: "third_party_raw_data" */
  third_party_raw_data_stream: Array<Third_Party_Raw_Data>;
  /** fetch data from the table: "unified_case" */
  unified_case: Array<Unified_Case>;
  /** fetch aggregated fields from the table: "unified_case" */
  unified_case_aggregate: Unified_Case_Aggregate;
  /** fetch data from the table in a streaming manner: "unified_case" */
  unified_case_stream: Array<Unified_Case>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
  /** fetch data from the table in a streaming manner: "user_role" */
  user_role_stream: Array<User_Role>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Address_Stream_Cursor_Input>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootApi_KeysArgs = {
  distinct_on?: Maybe<Array<Api_Keys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Keys_Order_By>>;
  where?: Maybe<Api_Keys_Bool_Exp>;
};


export type Subscription_RootApi_Keys_AggregateArgs = {
  distinct_on?: Maybe<Array<Api_Keys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Api_Keys_Order_By>>;
  where?: Maybe<Api_Keys_Bool_Exp>;
};


export type Subscription_RootApi_Keys_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootApi_Keys_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Api_Keys_Stream_Cursor_Input>>;
  where?: Maybe<Api_Keys_Bool_Exp>;
};


export type Subscription_RootAssignmentArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Subscription_RootAssignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_Order_By>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Subscription_RootAssignment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAssignment_StateArgs = {
  distinct_on?: Maybe<Array<Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_State_Order_By>>;
  where?: Maybe<Assignment_State_Bool_Exp>;
};


export type Subscription_RootAssignment_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Assignment_State_Order_By>>;
  where?: Maybe<Assignment_State_Bool_Exp>;
};


export type Subscription_RootAssignment_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootAssignment_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Assignment_State_Stream_Cursor_Input>>;
  where?: Maybe<Assignment_State_Bool_Exp>;
};


export type Subscription_RootAssignment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Assignment_Stream_Cursor_Input>>;
  where?: Maybe<Assignment_Bool_Exp>;
};


export type Subscription_RootBank_AccountArgs = {
  distinct_on?: Maybe<Array<Bank_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Account_Order_By>>;
  where?: Maybe<Bank_Account_Bool_Exp>;
};


export type Subscription_RootBank_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Bank_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Account_Order_By>>;
  where?: Maybe<Bank_Account_Bool_Exp>;
};


export type Subscription_RootBank_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBank_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bank_Account_Stream_Cursor_Input>>;
  where?: Maybe<Bank_Account_Bool_Exp>;
};


export type Subscription_RootBase_CaseArgs = {
  distinct_on?: Maybe<Array<Base_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Order_By>>;
  where?: Maybe<Base_Case_Bool_Exp>;
};


export type Subscription_RootBase_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Order_By>>;
  where?: Maybe<Base_Case_Bool_Exp>;
};


export type Subscription_RootBase_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBase_Case_DocumentArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Order_By>>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};


export type Subscription_RootBase_Case_Document_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Order_By>>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};


export type Subscription_RootBase_Case_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBase_Case_Document_CategoryArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Category_Order_By>>;
  where?: Maybe<Base_Case_Document_Category_Bool_Exp>;
};


export type Subscription_RootBase_Case_Document_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Case_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Case_Document_Category_Order_By>>;
  where?: Maybe<Base_Case_Document_Category_Bool_Exp>;
};


export type Subscription_RootBase_Case_Document_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootBase_Case_Document_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Base_Case_Document_Category_Stream_Cursor_Input>>;
  where?: Maybe<Base_Case_Document_Category_Bool_Exp>;
};


export type Subscription_RootBase_Case_Document_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Base_Case_Document_Stream_Cursor_Input>>;
  where?: Maybe<Base_Case_Document_Bool_Exp>;
};


export type Subscription_RootBase_Case_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Base_Case_Stream_Cursor_Input>>;
  where?: Maybe<Base_Case_Bool_Exp>;
};


export type Subscription_RootBase_OfferArgs = {
  distinct_on?: Maybe<Array<Base_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Offer_Order_By>>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};


export type Subscription_RootBase_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Base_Offer_Order_By>>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};


export type Subscription_RootBase_Offer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBase_Offer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Base_Offer_Stream_Cursor_Input>>;
  where?: Maybe<Base_Offer_Bool_Exp>;
};


export type Subscription_RootBi_Tableau_Kpi_FullArgs = {
  distinct_on?: Maybe<Array<Bi_Tableau_Kpi_Full_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bi_Tableau_Kpi_Full_Order_By>>;
  where?: Maybe<Bi_Tableau_Kpi_Full_Bool_Exp>;
};


export type Subscription_RootBi_Tableau_Kpi_Full_AggregateArgs = {
  distinct_on?: Maybe<Array<Bi_Tableau_Kpi_Full_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bi_Tableau_Kpi_Full_Order_By>>;
  where?: Maybe<Bi_Tableau_Kpi_Full_Bool_Exp>;
};


export type Subscription_RootBi_Tableau_Kpi_Full_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Bi_Tableau_Kpi_Full_Stream_Cursor_Input>>;
  where?: Maybe<Bi_Tableau_Kpi_Full_Bool_Exp>;
};


export type Subscription_RootCase_CommentArgs = {
  distinct_on?: Maybe<Array<Case_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Comment_Order_By>>;
  where?: Maybe<Case_Comment_Bool_Exp>;
};


export type Subscription_RootCase_Comment_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_Comment_Order_By>>;
  where?: Maybe<Case_Comment_Bool_Exp>;
};


export type Subscription_RootCase_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCase_Comment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Case_Comment_Stream_Cursor_Input>>;
  where?: Maybe<Case_Comment_Bool_Exp>;
};


export type Subscription_RootCase_List_ViewArgs = {
  distinct_on?: Maybe<Array<Case_List_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_List_View_Order_By>>;
  where?: Maybe<Case_List_View_Bool_Exp>;
};


export type Subscription_RootCase_List_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_List_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Case_List_View_Order_By>>;
  where?: Maybe<Case_List_View_Bool_Exp>;
};


export type Subscription_RootCase_List_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Case_List_View_Stream_Cursor_Input>>;
  where?: Maybe<Case_List_View_Bool_Exp>;
};


export type Subscription_RootCustomerArgs = {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_AggregateArgs = {
  distinct_on?: Maybe<Array<Customer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Customer_Order_By>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Subscription_RootCustomer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCustomer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Customer_Stream_Cursor_Input>>;
  where?: Maybe<Customer_Bool_Exp>;
};


export type Subscription_RootEmail_QueueArgs = {
  distinct_on?: Maybe<Array<Email_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Queue_Order_By>>;
  where?: Maybe<Email_Queue_Bool_Exp>;
};


export type Subscription_RootEmail_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Queue_Order_By>>;
  where?: Maybe<Email_Queue_Bool_Exp>;
};


export type Subscription_RootEmail_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEmail_Queue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Email_Queue_Stream_Cursor_Input>>;
  where?: Maybe<Email_Queue_Bool_Exp>;
};


export type Subscription_RootFit_ClaimArgs = {
  distinct_on?: Maybe<Array<Fit_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Claim_Order_By>>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};


export type Subscription_RootFit_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Fit_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Claim_Order_By>>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};


export type Subscription_RootFit_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFit_Claim_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fit_Claim_Stream_Cursor_Input>>;
  where?: Maybe<Fit_Claim_Bool_Exp>;
};


export type Subscription_RootFit_CompanyArgs = {
  distinct_on?: Maybe<Array<Fit_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Company_Order_By>>;
  where?: Maybe<Fit_Company_Bool_Exp>;
};


export type Subscription_RootFit_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Fit_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Company_Order_By>>;
  where?: Maybe<Fit_Company_Bool_Exp>;
};


export type Subscription_RootFit_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootFit_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fit_Company_Stream_Cursor_Input>>;
  where?: Maybe<Fit_Company_Bool_Exp>;
};


export type Subscription_RootFit_Fts_TransactionArgs = {
  distinct_on?: Maybe<Array<Fit_Fts_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Fts_Transaction_Order_By>>;
  where?: Maybe<Fit_Fts_Transaction_Bool_Exp>;
};


export type Subscription_RootFit_Fts_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Fit_Fts_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fit_Fts_Transaction_Order_By>>;
  where?: Maybe<Fit_Fts_Transaction_Bool_Exp>;
};


export type Subscription_RootFit_Fts_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFit_Fts_Transaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fit_Fts_Transaction_Stream_Cursor_Input>>;
  where?: Maybe<Fit_Fts_Transaction_Bool_Exp>;
};


export type Subscription_RootFs_Bank_TransactionArgs = {
  distinct_on?: Maybe<Array<Fs_Bank_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Bank_Transaction_Order_By>>;
  where?: Maybe<Fs_Bank_Transaction_Bool_Exp>;
};


export type Subscription_RootFs_Bank_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Fs_Bank_Transaction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Bank_Transaction_Order_By>>;
  where?: Maybe<Fs_Bank_Transaction_Bool_Exp>;
};


export type Subscription_RootFs_Bank_Transaction_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFs_Bank_Transaction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fs_Bank_Transaction_Stream_Cursor_Input>>;
  where?: Maybe<Fs_Bank_Transaction_Bool_Exp>;
};


export type Subscription_RootFs_Customer_PayoutArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Bool_Exp>;
};


export type Subscription_RootFs_Customer_Payout_AggregateArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Bool_Exp>;
};


export type Subscription_RootFs_Customer_Payout_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFs_Customer_Payout_QueueArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Queue_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Queue_Bool_Exp>;
};


export type Subscription_RootFs_Customer_Payout_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Fs_Customer_Payout_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Fs_Customer_Payout_Queue_Order_By>>;
  where?: Maybe<Fs_Customer_Payout_Queue_Bool_Exp>;
};


export type Subscription_RootFs_Customer_Payout_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFs_Customer_Payout_Queue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fs_Customer_Payout_Queue_Stream_Cursor_Input>>;
  where?: Maybe<Fs_Customer_Payout_Queue_Bool_Exp>;
};


export type Subscription_RootFs_Customer_Payout_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Fs_Customer_Payout_Stream_Cursor_Input>>;
  where?: Maybe<Fs_Customer_Payout_Bool_Exp>;
};


export type Subscription_RootGdpr_CaseArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Order_By>>;
  where?: Maybe<Gdpr_Case_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Order_By>>;
  where?: Maybe<Gdpr_Case_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGdpr_Case_Rejection_ReasonArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Rejection_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Rejection_Reason_Order_By>>;
  where?: Maybe<Gdpr_Case_Rejection_Reason_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_Rejection_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_Rejection_Reason_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_Rejection_Reason_Order_By>>;
  where?: Maybe<Gdpr_Case_Rejection_Reason_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_Rejection_Reason_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGdpr_Case_Rejection_Reason_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Case_Rejection_Reason_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Case_Rejection_Reason_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_StateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_State_Order_By>>;
  where?: Maybe<Gdpr_Case_State_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Case_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Case_State_Order_By>>;
  where?: Maybe<Gdpr_Case_State_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGdpr_Case_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Case_State_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Case_State_Bool_Exp>;
};


export type Subscription_RootGdpr_Case_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Case_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Case_Bool_Exp>;
};


export type Subscription_RootGdpr_ClaimArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Order_By>>;
  where?: Maybe<Gdpr_Claim_Bool_Exp>;
};


export type Subscription_RootGdpr_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Order_By>>;
  where?: Maybe<Gdpr_Claim_Bool_Exp>;
};


export type Subscription_RootGdpr_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGdpr_Claim_CompanyArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Company_Order_By>>;
  where?: Maybe<Gdpr_Claim_Company_Bool_Exp>;
};


export type Subscription_RootGdpr_Claim_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Claim_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Claim_Company_Order_By>>;
  where?: Maybe<Gdpr_Claim_Company_Bool_Exp>;
};


export type Subscription_RootGdpr_Claim_Company_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGdpr_Claim_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Claim_Company_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Claim_Company_Bool_Exp>;
};


export type Subscription_RootGdpr_Claim_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Claim_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Claim_Bool_Exp>;
};


export type Subscription_RootGdpr_CompanyArgs = {
  distinct_on?: Maybe<Array<Gdpr_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Company_Order_By>>;
  where?: Maybe<Gdpr_Company_Bool_Exp>;
};


export type Subscription_RootGdpr_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Company_Order_By>>;
  where?: Maybe<Gdpr_Company_Bool_Exp>;
};


export type Subscription_RootGdpr_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootGdpr_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Company_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Company_Bool_Exp>;
};


export type Subscription_RootGdpr_OfferArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Order_By>>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Order_By>>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_AssignmentArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Assignment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_Assignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Assignment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_Assignment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGdpr_Offer_Assignment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Offer_Assignment_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Offer_Assignment_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGdpr_Offer_PaymentArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_Payment_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_Payment_Order_By>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGdpr_Offer_Payment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Offer_Payment_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Offer_Payment_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_StateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_State_Order_By>>;
  where?: Maybe<Gdpr_Offer_State_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Gdpr_Offer_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gdpr_Offer_State_Order_By>>;
  where?: Maybe<Gdpr_Offer_State_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootGdpr_Offer_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Offer_State_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Offer_State_Bool_Exp>;
};


export type Subscription_RootGdpr_Offer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gdpr_Offer_Stream_Cursor_Input>>;
  where?: Maybe<Gdpr_Offer_Bool_Exp>;
};


export type Subscription_RootGenerated_ReportArgs = {
  distinct_on?: Maybe<Array<Generated_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Generated_Report_Order_By>>;
  where?: Maybe<Generated_Report_Bool_Exp>;
};


export type Subscription_RootGenerated_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Generated_Report_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Generated_Report_Order_By>>;
  where?: Maybe<Generated_Report_Bool_Exp>;
};


export type Subscription_RootGenerated_Report_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGenerated_Report_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Generated_Report_Stream_Cursor_Input>>;
  where?: Maybe<Generated_Report_Bool_Exp>;
};


export type Subscription_RootGff_AirportArgs = {
  distinct_on?: Maybe<Array<Gff_Airport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Airport_Order_By>>;
  where?: Maybe<Gff_Airport_Bool_Exp>;
};


export type Subscription_RootGff_Airport_AggregateArgs = {
  distinct_on?: Maybe<Array<Gff_Airport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Airport_Order_By>>;
  where?: Maybe<Gff_Airport_Bool_Exp>;
};


export type Subscription_RootGff_Airport_By_PkArgs = {
  code: Scalars['String'];
};


export type Subscription_RootGff_Airport_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gff_Airport_Stream_Cursor_Input>>;
  where?: Maybe<Gff_Airport_Bool_Exp>;
};


export type Subscription_RootGff_ClaimArgs = {
  distinct_on?: Maybe<Array<Gff_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Claim_Order_By>>;
  where?: Maybe<Gff_Claim_Bool_Exp>;
};


export type Subscription_RootGff_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Gff_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Claim_Order_By>>;
  where?: Maybe<Gff_Claim_Bool_Exp>;
};


export type Subscription_RootGff_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGff_Claim_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gff_Claim_Stream_Cursor_Input>>;
  where?: Maybe<Gff_Claim_Bool_Exp>;
};


export type Subscription_RootGff_CompanyArgs = {
  distinct_on?: Maybe<Array<Gff_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Company_Order_By>>;
  where?: Maybe<Gff_Company_Bool_Exp>;
};


export type Subscription_RootGff_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Gff_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Gff_Company_Order_By>>;
  where?: Maybe<Gff_Company_Bool_Exp>;
};


export type Subscription_RootGff_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootGff_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Gff_Company_Stream_Cursor_Input>>;
  where?: Maybe<Gff_Company_Bool_Exp>;
};


export type Subscription_RootHaveibeenpwned_Api_LogArgs = {
  distinct_on?: Maybe<Array<Haveibeenpwned_Api_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Haveibeenpwned_Api_Log_Order_By>>;
  where?: Maybe<Haveibeenpwned_Api_Log_Bool_Exp>;
};


export type Subscription_RootHaveibeenpwned_Api_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Haveibeenpwned_Api_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Haveibeenpwned_Api_Log_Order_By>>;
  where?: Maybe<Haveibeenpwned_Api_Log_Bool_Exp>;
};


export type Subscription_RootHaveibeenpwned_Api_Log_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootHaveibeenpwned_Api_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Haveibeenpwned_Api_Log_Stream_Cursor_Input>>;
  where?: Maybe<Haveibeenpwned_Api_Log_Bool_Exp>;
};


export type Subscription_RootLost_AssignmentArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_Order_By>>;
  where?: Maybe<Lost_Assignment_Bool_Exp>;
};


export type Subscription_RootLost_Assignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_Order_By>>;
  where?: Maybe<Lost_Assignment_Bool_Exp>;
};


export type Subscription_RootLost_Assignment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLost_Assignment_StateArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_State_Order_By>>;
  where?: Maybe<Lost_Assignment_State_Bool_Exp>;
};


export type Subscription_RootLost_Assignment_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Lost_Assignment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Lost_Assignment_State_Order_By>>;
  where?: Maybe<Lost_Assignment_State_Bool_Exp>;
};


export type Subscription_RootLost_Assignment_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLost_Assignment_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Lost_Assignment_State_Stream_Cursor_Input>>;
  where?: Maybe<Lost_Assignment_State_Bool_Exp>;
};


export type Subscription_RootLost_Assignment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Lost_Assignment_Stream_Cursor_Input>>;
  where?: Maybe<Lost_Assignment_Bool_Exp>;
};


export type Subscription_RootLs_CaseArgs = {
  distinct_on?: Maybe<Array<Ls_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Case_Order_By>>;
  where?: Maybe<Ls_Case_Bool_Exp>;
};


export type Subscription_RootLs_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Case_Order_By>>;
  where?: Maybe<Ls_Case_Bool_Exp>;
};


export type Subscription_RootLs_Case_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLs_Case_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Case_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Case_Bool_Exp>;
};


export type Subscription_RootLs_LawsuitArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLs_Lawsuit_CourtArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Court_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Court_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Court_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Court_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Court_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Court_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLs_Lawsuit_Court_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Lawsuit_Court_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Lawsuit_Court_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_DocumentArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Document_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Document_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLs_Lawsuit_Document_CategoryArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Category_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Document_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Document_Category_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Document_Category_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Document_Category_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootLs_Lawsuit_Document_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Lawsuit_Document_Category_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Lawsuit_Document_Category_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Document_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Lawsuit_Document_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Lawsuit_Document_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_ExpenseArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Expense_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Expense_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Expense_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Expense_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Expense_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Expense_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Expense_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Expense_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLs_Lawsuit_Expense_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Lawsuit_Expense_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Lawsuit_Expense_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_HistoryArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_History_Order_By>>;
  where?: Maybe<Ls_Lawsuit_History_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_History_Order_By>>;
  where?: Maybe<Ls_Lawsuit_History_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_History_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLs_Lawsuit_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Lawsuit_History_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Lawsuit_History_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_LawyerArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Lawyer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Lawyer_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Lawyer_AggregateArgs = {
  distinct_on?: Maybe<Array<Ls_Lawsuit_Lawyer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ls_Lawsuit_Lawyer_Order_By>>;
  where?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_Lawyer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLs_Lawsuit_Lawyer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Lawsuit_Lawyer_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
};


export type Subscription_RootLs_Lawsuit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ls_Lawsuit_Stream_Cursor_Input>>;
  where?: Maybe<Ls_Lawsuit_Bool_Exp>;
};


export type Subscription_RootNflx_ClaimArgs = {
  distinct_on?: Maybe<Array<Nflx_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Claim_Order_By>>;
  where?: Maybe<Nflx_Claim_Bool_Exp>;
};


export type Subscription_RootNflx_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Nflx_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Claim_Order_By>>;
  where?: Maybe<Nflx_Claim_Bool_Exp>;
};


export type Subscription_RootNflx_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNflx_Claim_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Nflx_Claim_Stream_Cursor_Input>>;
  where?: Maybe<Nflx_Claim_Bool_Exp>;
};


export type Subscription_RootNflx_CompanyArgs = {
  distinct_on?: Maybe<Array<Nflx_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Company_Order_By>>;
  where?: Maybe<Nflx_Company_Bool_Exp>;
};


export type Subscription_RootNflx_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Nflx_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nflx_Company_Order_By>>;
  where?: Maybe<Nflx_Company_Bool_Exp>;
};


export type Subscription_RootNflx_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootNflx_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Nflx_Company_Stream_Cursor_Input>>;
  where?: Maybe<Nflx_Company_Bool_Exp>;
};


export type Subscription_RootNrg_ClaimArgs = {
  distinct_on?: Maybe<Array<Nrg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Claim_Order_By>>;
  where?: Maybe<Nrg_Claim_Bool_Exp>;
};


export type Subscription_RootNrg_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Nrg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Claim_Order_By>>;
  where?: Maybe<Nrg_Claim_Bool_Exp>;
};


export type Subscription_RootNrg_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNrg_Claim_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Nrg_Claim_Stream_Cursor_Input>>;
  where?: Maybe<Nrg_Claim_Bool_Exp>;
};


export type Subscription_RootNrg_CompanyArgs = {
  distinct_on?: Maybe<Array<Nrg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Company_Order_By>>;
  where?: Maybe<Nrg_Company_Bool_Exp>;
};


export type Subscription_RootNrg_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Nrg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Nrg_Company_Order_By>>;
  where?: Maybe<Nrg_Company_Bool_Exp>;
};


export type Subscription_RootNrg_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootNrg_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Nrg_Company_Stream_Cursor_Input>>;
  where?: Maybe<Nrg_Company_Bool_Exp>;
};


export type Subscription_RootOcg_ClaimArgs = {
  distinct_on?: Maybe<Array<Ocg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Claim_Order_By>>;
  where?: Maybe<Ocg_Claim_Bool_Exp>;
};


export type Subscription_RootOcg_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Ocg_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Claim_Order_By>>;
  where?: Maybe<Ocg_Claim_Bool_Exp>;
};


export type Subscription_RootOcg_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootOcg_Claim_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ocg_Claim_Stream_Cursor_Input>>;
  where?: Maybe<Ocg_Claim_Bool_Exp>;
};


export type Subscription_RootOcg_CompanyArgs = {
  distinct_on?: Maybe<Array<Ocg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Company_Order_By>>;
  where?: Maybe<Ocg_Company_Bool_Exp>;
};


export type Subscription_RootOcg_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Ocg_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ocg_Company_Order_By>>;
  where?: Maybe<Ocg_Company_Bool_Exp>;
};


export type Subscription_RootOcg_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootOcg_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ocg_Company_Stream_Cursor_Input>>;
  where?: Maybe<Ocg_Company_Bool_Exp>;
};


export type Subscription_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPayment_StateArgs = {
  distinct_on?: Maybe<Array<Payment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_State_Order_By>>;
  where?: Maybe<Payment_State_Bool_Exp>;
};


export type Subscription_RootPayment_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_State_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_State_Order_By>>;
  where?: Maybe<Payment_State_Bool_Exp>;
};


export type Subscription_RootPayment_State_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPayment_State_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payment_State_Stream_Cursor_Input>>;
  where?: Maybe<Payment_State_Bool_Exp>;
};


export type Subscription_RootPayment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Payment_Stream_Cursor_Input>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Subscription_RootPkv_ClaimArgs = {
  distinct_on?: Maybe<Array<Pkv_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Claim_Order_By>>;
  where?: Maybe<Pkv_Claim_Bool_Exp>;
};


export type Subscription_RootPkv_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Pkv_Claim_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Claim_Order_By>>;
  where?: Maybe<Pkv_Claim_Bool_Exp>;
};


export type Subscription_RootPkv_Claim_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPkv_Claim_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Pkv_Claim_Stream_Cursor_Input>>;
  where?: Maybe<Pkv_Claim_Bool_Exp>;
};


export type Subscription_RootPkv_CompanyArgs = {
  distinct_on?: Maybe<Array<Pkv_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Company_Order_By>>;
  where?: Maybe<Pkv_Company_Bool_Exp>;
};


export type Subscription_RootPkv_Company_AggregateArgs = {
  distinct_on?: Maybe<Array<Pkv_Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pkv_Company_Order_By>>;
  where?: Maybe<Pkv_Company_Bool_Exp>;
};


export type Subscription_RootPkv_Company_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootPkv_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Pkv_Company_Stream_Cursor_Input>>;
  where?: Maybe<Pkv_Company_Bool_Exp>;
};


export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Product_Stream_Cursor_Input>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootPs_DebtorArgs = {
  distinct_on?: Maybe<Array<Ps_Debtor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ps_Debtor_Order_By>>;
  where?: Maybe<Ps_Debtor_Bool_Exp>;
};


export type Subscription_RootPs_Debtor_AggregateArgs = {
  distinct_on?: Maybe<Array<Ps_Debtor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ps_Debtor_Order_By>>;
  where?: Maybe<Ps_Debtor_Bool_Exp>;
};


export type Subscription_RootPs_Debtor_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPs_Debtor_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Ps_Debtor_Stream_Cursor_Input>>;
  where?: Maybe<Ps_Debtor_Bool_Exp>;
};


export type Subscription_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRole_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Role_Stream_Cursor_Input>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootThird_Party_Raw_DataArgs = {
  distinct_on?: Maybe<Array<Third_Party_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Third_Party_Raw_Data_Order_By>>;
  where?: Maybe<Third_Party_Raw_Data_Bool_Exp>;
};


export type Subscription_RootThird_Party_Raw_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Third_Party_Raw_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Third_Party_Raw_Data_Order_By>>;
  where?: Maybe<Third_Party_Raw_Data_Bool_Exp>;
};


export type Subscription_RootThird_Party_Raw_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootThird_Party_Raw_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Third_Party_Raw_Data_Stream_Cursor_Input>>;
  where?: Maybe<Third_Party_Raw_Data_Bool_Exp>;
};


export type Subscription_RootUnified_CaseArgs = {
  distinct_on?: Maybe<Array<Unified_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Unified_Case_Order_By>>;
  where?: Maybe<Unified_Case_Bool_Exp>;
};


export type Subscription_RootUnified_Case_AggregateArgs = {
  distinct_on?: Maybe<Array<Unified_Case_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Unified_Case_Order_By>>;
  where?: Maybe<Unified_Case_Bool_Exp>;
};


export type Subscription_RootUnified_Case_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Unified_Case_Stream_Cursor_Input>>;
  where?: Maybe<Unified_Case_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_RoleArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Role_Stream_Cursor_Input>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Stream_Cursor_Input>>;
  where?: Maybe<User_Bool_Exp>;
};

/** The raw data comes from 3rd party services like bubble, heyflow etc. */
export type Third_Party_Raw_Data = {
  __typename?: 'third_party_raw_data';
  created_at: Scalars['timestamp'];
  data: Scalars['jsonb'];
  id: Scalars['uuid'];
  source: Scalars['String'];
  source_id: Scalars['String'];
  updated_at: Scalars['timestamp'];
};


/** The raw data comes from 3rd party services like bubble, heyflow etc. */
export type Third_Party_Raw_DataDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "third_party_raw_data" */
export type Third_Party_Raw_Data_Aggregate = {
  __typename?: 'third_party_raw_data_aggregate';
  aggregate?: Maybe<Third_Party_Raw_Data_Aggregate_Fields>;
  nodes: Array<Third_Party_Raw_Data>;
};

/** aggregate fields of "third_party_raw_data" */
export type Third_Party_Raw_Data_Aggregate_Fields = {
  __typename?: 'third_party_raw_data_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Third_Party_Raw_Data_Max_Fields>;
  min?: Maybe<Third_Party_Raw_Data_Min_Fields>;
};


/** aggregate fields of "third_party_raw_data" */
export type Third_Party_Raw_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Third_Party_Raw_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Raw_Data_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "third_party_raw_data". All fields are combined with a logical 'AND'. */
export type Third_Party_Raw_Data_Bool_Exp = {
  _and?: Maybe<Array<Third_Party_Raw_Data_Bool_Exp>>;
  _not?: Maybe<Third_Party_Raw_Data_Bool_Exp>;
  _or?: Maybe<Array<Third_Party_Raw_Data_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  source_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "third_party_raw_data" */
export enum Third_Party_Raw_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  ThirdPartyRawDataPkey = 'third_party_raw_data_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Third_Party_Raw_Data_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Third_Party_Raw_Data_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Third_Party_Raw_Data_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "third_party_raw_data" */
export type Third_Party_Raw_Data_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Third_Party_Raw_Data_Max_Fields = {
  __typename?: 'third_party_raw_data_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Third_Party_Raw_Data_Min_Fields = {
  __typename?: 'third_party_raw_data_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "third_party_raw_data" */
export type Third_Party_Raw_Data_Mutation_Response = {
  __typename?: 'third_party_raw_data_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Third_Party_Raw_Data>;
};

/** on_conflict condition type for table "third_party_raw_data" */
export type Third_Party_Raw_Data_On_Conflict = {
  constraint: Third_Party_Raw_Data_Constraint;
  update_columns?: Array<Third_Party_Raw_Data_Update_Column>;
  where?: Maybe<Third_Party_Raw_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "third_party_raw_data". */
export type Third_Party_Raw_Data_Order_By = {
  created_at?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  source_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: third_party_raw_data */
export type Third_Party_Raw_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Raw_Data_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "third_party_raw_data" */
export enum Third_Party_Raw_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "third_party_raw_data" */
export type Third_Party_Raw_Data_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "third_party_raw_data" */
export type Third_Party_Raw_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Third_Party_Raw_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Third_Party_Raw_Data_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  source_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "third_party_raw_data" */
export enum Third_Party_Raw_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceId = 'source_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Third_Party_Raw_Data_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Third_Party_Raw_Data_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Third_Party_Raw_Data_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Third_Party_Raw_Data_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Third_Party_Raw_Data_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Third_Party_Raw_Data_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Third_Party_Raw_Data_Set_Input>;
  where: Third_Party_Raw_Data_Bool_Exp;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};

/** columns and relationships of "unified_case" */
export type Unified_Case = {
  __typename?: 'unified_case';
  alias_id?: Maybe<Scalars['String']>;
  claim?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Scalars['jsonb']>;
  vertical?: Maybe<Scalars['String']>;
};


/** columns and relationships of "unified_case" */
export type Unified_CaseClaimArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "unified_case" */
export type Unified_CaseCustomerArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "unified_case" */
export type Unified_CaseOfferArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "unified_case" */
export type Unified_Case_Aggregate = {
  __typename?: 'unified_case_aggregate';
  aggregate?: Maybe<Unified_Case_Aggregate_Fields>;
  nodes: Array<Unified_Case>;
};

/** aggregate fields of "unified_case" */
export type Unified_Case_Aggregate_Fields = {
  __typename?: 'unified_case_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Unified_Case_Max_Fields>;
  min?: Maybe<Unified_Case_Min_Fields>;
};


/** aggregate fields of "unified_case" */
export type Unified_Case_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Unified_Case_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "unified_case". All fields are combined with a logical 'AND'. */
export type Unified_Case_Bool_Exp = {
  _and?: Maybe<Array<Unified_Case_Bool_Exp>>;
  _not?: Maybe<Unified_Case_Bool_Exp>;
  _or?: Maybe<Array<Unified_Case_Bool_Exp>>;
  alias_id?: Maybe<String_Comparison_Exp>;
  claim?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  customer?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  offer?: Maybe<Jsonb_Comparison_Exp>;
  vertical?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Unified_Case_Max_Fields = {
  __typename?: 'unified_case_max_fields';
  alias_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  vertical?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Unified_Case_Min_Fields = {
  __typename?: 'unified_case_min_fields';
  alias_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  vertical?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "unified_case". */
export type Unified_Case_Order_By = {
  alias_id?: Maybe<Order_By>;
  claim?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  customer?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  offer?: Maybe<Order_By>;
  vertical?: Maybe<Order_By>;
};

/** select columns of table "unified_case" */
export enum Unified_Case_Select_Column {
  /** column name */
  AliasId = 'alias_id',
  /** column name */
  Claim = 'claim',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Customer = 'customer',
  /** column name */
  Id = 'id',
  /** column name */
  Offer = 'offer',
  /** column name */
  Vertical = 'vertical'
}

/** Streaming cursor of the table "unified_case" */
export type Unified_Case_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Unified_Case_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Unified_Case_Stream_Cursor_Value_Input = {
  alias_id?: Maybe<Scalars['String']>;
  claim?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamp']>;
  customer?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  offer?: Maybe<Scalars['jsonb']>;
  vertical?: Maybe<Scalars['String']>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  email: Scalars['String'];
  id: Scalars['uuid'];
  is_email_confirmed: Scalars['Boolean'];
  /** An object relationship */
  lawyer_info?: Maybe<Ls_Lawsuit_Lawyer>;
  password_hash: Scalars['String'];
  password_salt: Scalars['String'];
  /** An array relationship */
  user_roles: Array<User_Role>;
  /** An aggregate relationship */
  user_roles_aggregate: User_Role_Aggregate;
};


/** columns and relationships of "user" */
export type UserUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_email_confirmed?: Maybe<Boolean_Comparison_Exp>;
  lawyer_info?: Maybe<Ls_Lawsuit_Lawyer_Bool_Exp>;
  password_hash?: Maybe<String_Comparison_Exp>;
  password_salt?: Maybe<String_Comparison_Exp>;
  user_roles?: Maybe<User_Role_Bool_Exp>;
  user_roles_aggregate?: Maybe<User_Role_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_email_confirmed?: Maybe<Scalars['Boolean']>;
  lawyer_info?: Maybe<Ls_Lawsuit_Lawyer_Obj_Rel_Insert_Input>;
  password_hash?: Maybe<Scalars['String']>;
  password_salt?: Maybe<Scalars['String']>;
  user_roles?: Maybe<User_Role_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password_hash?: Maybe<Scalars['String']>;
  password_salt?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password_hash?: Maybe<Scalars['String']>;
  password_salt?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_email_confirmed?: Maybe<Order_By>;
  lawyer_info?: Maybe<Ls_Lawsuit_Lawyer_Order_By>;
  password_hash?: Maybe<Order_By>;
  password_salt?: Maybe<Order_By>;
  user_roles_aggregate?: Maybe<User_Role_Aggregate_Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role';
  id: Scalars['uuid'];
  /** An object relationship */
  role: Role;
  role_id: Scalars['uuid'];
  /** An object relationship */
  user: User;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate';
  aggregate?: Maybe<User_Role_Aggregate_Fields>;
  nodes: Array<User_Role>;
};

export type User_Role_Aggregate_Bool_Exp = {
  count?: Maybe<User_Role_Aggregate_Bool_Exp_Count>;
};

export type User_Role_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<User_Role_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Role_Max_Fields>;
  min?: Maybe<User_Role_Min_Fields>;
};


/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_role" */
export type User_Role_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Role_Max_Order_By>;
  min?: Maybe<User_Role_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_role" */
export type User_Role_Arr_Rel_Insert_Input = {
  data: Array<User_Role_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<User_Role_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: Maybe<Array<User_Role_Bool_Exp>>;
  _not?: Maybe<User_Role_Bool_Exp>;
  _or?: Maybe<Array<User_Role_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<Role_Bool_Exp>;
  role_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolePkey = 'user_role_pkey',
  /** unique or primary key constraint on columns "role_id", "user_id" */
  UserRoleRoleIdUserIdKey = 'user_role_role_id_user_id_key'
}

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Role_Obj_Rel_Insert_Input>;
  role_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<User_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields';
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_role" */
export type User_Role_Max_Order_By = {
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields';
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_role" */
export type User_Role_Min_Order_By = {
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>;
};

/** on_conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint;
  update_columns?: Array<User_Role_Update_Column>;
  where?: Maybe<User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  id?: Maybe<Order_By>;
  role?: Maybe<Role_Order_By>;
  role_id?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_role" */
export type User_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Role_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Role_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Role_Set_Input>;
  where: User_Role_Bool_Exp;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsEmailConfirmed = 'is_email_confirmed',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  PasswordSalt = 'password_salt'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_email_confirmed?: Maybe<Scalars['Boolean']>;
  password_hash?: Maybe<Scalars['String']>;
  password_salt?: Maybe<Scalars['String']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_email_confirmed?: Maybe<Scalars['Boolean']>;
  password_hash?: Maybe<Scalars['String']>;
  password_salt?: Maybe<Scalars['String']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsEmailConfirmed = 'is_email_confirmed',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  PasswordSalt = 'password_salt'
}

export type User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type GetDocsSignUrlMutationVariables = Exact<{
  docType: Scalars['String'];
  name: Scalars['String'];
  contentType: Scalars['String'];
}>;


export type GetDocsSignUrlMutation = (
  { __typename?: 'mutation_root' }
  & { docs_sign_url?: Maybe<(
    { __typename?: 'DocsSignURLOutput' }
    & Pick<DocsSignUrlOutput, 'fields' | 'id' | 'url'>
  )> }
);

export type CustomerAcceptOfferMutationVariables = Exact<{
  input: CustomerAcceptOfferInput;
}>;


export type CustomerAcceptOfferMutation = (
  { __typename?: 'mutation_root' }
  & { customer_accept_offer?: Maybe<(
    { __typename?: 'CustomerAcceptOfferOutput' }
    & Pick<CustomerAcceptOfferOutput, 'offerId' | 'aliasId' | 'state'>
  )> }
);

export type OfferGetQueryVariables = Exact<{
  offer_id: Scalars['uuid'];
}>;


export type OfferGetQuery = (
  { __typename?: 'query_root' }
  & { offer_get?: Maybe<(
    { __typename?: 'OfferGetOutput' }
    & Pick<OfferGetOutput, 'id' | 'offer_amount' | 'product_id' | 'alias_id' | 'state' | 'company' | 'isSpv' | 'case_id'>
  )> }
);

export type CustomerAddDocumentsMutationVariables = Exact<{
  input: CustomerAddDocumentsInput;
}>;


export type CustomerAddDocumentsMutation = (
  { __typename?: 'mutation_root' }
  & { customer_add_documents?: Maybe<(
    { __typename?: 'CustomerAddDocumentsOutput' }
    & Pick<CustomerAddDocumentsOutput, 'id' | 'aliasId' | 'state'>
  )> }
);

export type CaseDocsUploadGetQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CaseDocsUploadGetQuery = (
  { __typename?: 'query_root' }
  & { case_docs_upload_get?: Maybe<(
    { __typename?: 'CaseDocsUploadGetOutput' }
    & Pick<CaseDocsUploadGetOutput, 'id' | 'missingDocuments' | 'companyId' | 'vertical'>
  )> }
);


export const GetDocsSignUrlDocument = gql`
    mutation GetDocsSignUrl($docType: String!, $name: String!, $contentType: String!) {
  docs_sign_url(
    input: {docType: $docType, name: $name, contentType: $contentType}
  ) {
    fields
    id
    url
  }
}
    `;
export type GetDocsSignUrlMutationFn = Apollo.MutationFunction<GetDocsSignUrlMutation, GetDocsSignUrlMutationVariables>;

/**
 * __useGetDocsSignUrlMutation__
 *
 * To run a mutation, you first call `useGetDocsSignUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDocsSignUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDocsSignUrlMutation, { data, loading, error }] = useGetDocsSignUrlMutation({
 *   variables: {
 *      docType: // value for 'docType'
 *      name: // value for 'name'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGetDocsSignUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetDocsSignUrlMutation, GetDocsSignUrlMutationVariables>) {
        return Apollo.useMutation<GetDocsSignUrlMutation, GetDocsSignUrlMutationVariables>(GetDocsSignUrlDocument, baseOptions);
      }
export type GetDocsSignUrlMutationHookResult = ReturnType<typeof useGetDocsSignUrlMutation>;
export type GetDocsSignUrlMutationResult = Apollo.MutationResult<GetDocsSignUrlMutation>;
export type GetDocsSignUrlMutationOptions = Apollo.BaseMutationOptions<GetDocsSignUrlMutation, GetDocsSignUrlMutationVariables>;
export const CustomerAcceptOfferDocument = gql`
    mutation CustomerAcceptOffer($input: CustomerAcceptOfferInput!) {
  customer_accept_offer(input: $input) {
    offerId
    aliasId
    state
  }
}
    `;
export type CustomerAcceptOfferMutationFn = Apollo.MutationFunction<CustomerAcceptOfferMutation, CustomerAcceptOfferMutationVariables>;

/**
 * __useCustomerAcceptOfferMutation__
 *
 * To run a mutation, you first call `useCustomerAcceptOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAcceptOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAcceptOfferMutation, { data, loading, error }] = useCustomerAcceptOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerAcceptOfferMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAcceptOfferMutation, CustomerAcceptOfferMutationVariables>) {
        return Apollo.useMutation<CustomerAcceptOfferMutation, CustomerAcceptOfferMutationVariables>(CustomerAcceptOfferDocument, baseOptions);
      }
export type CustomerAcceptOfferMutationHookResult = ReturnType<typeof useCustomerAcceptOfferMutation>;
export type CustomerAcceptOfferMutationResult = Apollo.MutationResult<CustomerAcceptOfferMutation>;
export type CustomerAcceptOfferMutationOptions = Apollo.BaseMutationOptions<CustomerAcceptOfferMutation, CustomerAcceptOfferMutationVariables>;
export const OfferGetDocument = gql`
    query OfferGet($offer_id: uuid!) {
  offer_get(input: {id: $offer_id}) {
    id
    offer_amount
    product_id
    alias_id
    state
    company
    isSpv
    case_id
  }
}
    `;

/**
 * __useOfferGetQuery__
 *
 * To run a query within a React component, call `useOfferGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferGetQuery({
 *   variables: {
 *      offer_id: // value for 'offer_id'
 *   },
 * });
 */
export function useOfferGetQuery(baseOptions: Apollo.QueryHookOptions<OfferGetQuery, OfferGetQueryVariables>) {
        return Apollo.useQuery<OfferGetQuery, OfferGetQueryVariables>(OfferGetDocument, baseOptions);
      }
export function useOfferGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfferGetQuery, OfferGetQueryVariables>) {
          return Apollo.useLazyQuery<OfferGetQuery, OfferGetQueryVariables>(OfferGetDocument, baseOptions);
        }
export type OfferGetQueryHookResult = ReturnType<typeof useOfferGetQuery>;
export type OfferGetLazyQueryHookResult = ReturnType<typeof useOfferGetLazyQuery>;
export type OfferGetQueryResult = Apollo.QueryResult<OfferGetQuery, OfferGetQueryVariables>;
export const CustomerAddDocumentsDocument = gql`
    mutation CustomerAddDocuments($input: CustomerAddDocumentsInput!) {
  customer_add_documents(input: $input) {
    id
    aliasId
    state
  }
}
    `;
export type CustomerAddDocumentsMutationFn = Apollo.MutationFunction<CustomerAddDocumentsMutation, CustomerAddDocumentsMutationVariables>;

/**
 * __useCustomerAddDocumentsMutation__
 *
 * To run a mutation, you first call `useCustomerAddDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAddDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAddDocumentsMutation, { data, loading, error }] = useCustomerAddDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerAddDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAddDocumentsMutation, CustomerAddDocumentsMutationVariables>) {
        return Apollo.useMutation<CustomerAddDocumentsMutation, CustomerAddDocumentsMutationVariables>(CustomerAddDocumentsDocument, baseOptions);
      }
export type CustomerAddDocumentsMutationHookResult = ReturnType<typeof useCustomerAddDocumentsMutation>;
export type CustomerAddDocumentsMutationResult = Apollo.MutationResult<CustomerAddDocumentsMutation>;
export type CustomerAddDocumentsMutationOptions = Apollo.BaseMutationOptions<CustomerAddDocumentsMutation, CustomerAddDocumentsMutationVariables>;
export const CaseDocsUploadGetDocument = gql`
    query caseDocsUploadGet($id: uuid!) {
  case_docs_upload_get(input: {id: $id}) {
    id
    missingDocuments
    companyId
    vertical
  }
}
    `;

/**
 * __useCaseDocsUploadGetQuery__
 *
 * To run a query within a React component, call `useCaseDocsUploadGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseDocsUploadGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseDocsUploadGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseDocsUploadGetQuery(baseOptions: Apollo.QueryHookOptions<CaseDocsUploadGetQuery, CaseDocsUploadGetQueryVariables>) {
        return Apollo.useQuery<CaseDocsUploadGetQuery, CaseDocsUploadGetQueryVariables>(CaseDocsUploadGetDocument, baseOptions);
      }
export function useCaseDocsUploadGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CaseDocsUploadGetQuery, CaseDocsUploadGetQueryVariables>) {
          return Apollo.useLazyQuery<CaseDocsUploadGetQuery, CaseDocsUploadGetQueryVariables>(CaseDocsUploadGetDocument, baseOptions);
        }
export type CaseDocsUploadGetQueryHookResult = ReturnType<typeof useCaseDocsUploadGetQuery>;
export type CaseDocsUploadGetLazyQueryHookResult = ReturnType<typeof useCaseDocsUploadGetLazyQuery>;
export type CaseDocsUploadGetQueryResult = Apollo.QueryResult<CaseDocsUploadGetQuery, CaseDocsUploadGetQueryVariables>;