import React from "react";
import { InputLabel, MenuItem, FormControl, Select, FormHelperText } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import "./index.scss";
import { ISelectBoxOption } from "types/common";

interface Props {
  disabled?: boolean;
  children?: React.ReactNode;
  onChange: (value: string, name: string) => void;
  options: ISelectBoxOption[];
  label?: string;
  value?: string;
  name: string;
  errorMessage?: string;
}

const BaseSelect: React.FC<Props> = ({
  options,
  onChange,
  label,
  value,
  disabled,
  name,
  errorMessage,
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string, name);
  };
  return (
    <FormControl variant="filled" className="form-control" disabled={disabled}>
      <InputLabel className="select">{label}</InputLabel>
      <Select
        value={value}
        disableUnderline
        IconComponent={ExpandMore}
        onChange={handleChange}
        error={errorMessage !== undefined}
        displayEmpty
      >
        {options.map((option, idx) => (
          <MenuItem key={idx} value={option.value} disabled={option.disabled}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default BaseSelect;
