import React from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { makeStyles, Theme } from "@material-ui/core";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: "10px",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ErrorAlert: React.FC<{ error: string; children?: React.ReactNode }> = ({ error }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity="error">{error}</Alert>
    </div>
  );
};

export default ErrorAlert;
