import { Grid } from "@material-ui/core";
import { BaseCard, BaseInputNew as BaseInput, BaseSelect } from "components/common";
import { getValidationErrors } from "helpers/utils";
import React from "react";
import { COUNTRY_OPTION_OCG } from "./const";

interface IProps {
  onChange: (value: string, name: string) => void;
  error: string;
  cardDetails: { title: string; description: string };
  city: string;
  street: string;
  postalCode: string;
  country: string;
  product: string;
  language: "de" | "en";
}

const titlesByLanguage: {
  street: Record<string, string>;
  postalCode: Record<string, string>;
  city: Record<string, string>;
} = {
  street: {
    de: "Straße und Nr.",
    en: "House number and street",
  },
  postalCode: {
    de: "PLZ",
    en: "Postal code",
  },
  city: {
    de: "Ort",
    en: "City",
  },
};

const AddressCard: React.FC<IProps> = ({
  onChange,
  error,
  cardDetails,
  city,
  street,
  postalCode,
  country,
  product,
  language,
}) => {
  const validationErrors: Record<string, string> = getValidationErrors(error);

  return (
    <BaseCard {...cardDetails}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} item>
          <BaseInput
            placeholder={titlesByLanguage.street[language]}
            name="street"
            required
            onChange={onChange}
            errorMessage={validationErrors?.street}
            value={street}
          />
        </Grid>

        <Grid xs={6} sm={4} md={4} item>
          <BaseInput
            placeholder={titlesByLanguage.postalCode[language]}
            name="postalCode"
            required
            onChange={onChange}
            errorMessage={validationErrors?.postalCode}
            value={postalCode}
          />
        </Grid>
        <Grid xs={12} sm={8} item>
          <BaseInput
            placeholder={titlesByLanguage.city[language]}
            name="city"
            required
            onChange={onChange}
            errorMessage={validationErrors?.city}
            value={city}
          />
        </Grid>
        {["OCG"].includes(product) ? (
          <Grid xs={12} sm={7} item>
            <BaseSelect
              options={COUNTRY_OPTION_OCG}
              name="countryCode"
              onChange={onChange}
              errorMessage={validationErrors?.countryCode}
              value={country}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </BaseCard>
  );
};

export default AddressCard;
