import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  baseUpload: {
    marginTop: "40px",
    marginBottom: "40px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
}));
