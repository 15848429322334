import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  content: {
    width: "840px",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    "& .action-wrap": {
      marginTop: 68,
    },
  },
  contentTop: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "18px",
    },
  },
}));
