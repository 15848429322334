import React from "react";
import { BaseCard } from "components/common";
import { Grid } from "@material-ui/core";
import SigCanvas from "./SignatureCanvas";
import "./styles.scss";

interface IProps {
  handleChange: (value: string, name: string) => void;
  cardDetails: { title: string; description: string };
}

const SignatureCard: React.FC<IProps> = ({handleChange, cardDetails}) => {

  return (
    <BaseCard {...cardDetails}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} item>
          <SigCanvas handleChange={handleChange} />
        </Grid>
      </Grid>
    </BaseCard>
  );
};

export default SignatureCard;
