import React from "react";
import { styleClasses } from "./style";
import LogoSection from "./LogoSection";
import BodySection from "./BodySection";

interface IProps {
  children: any;
  hasStepper?: boolean;
  product: string;
}

const ContentWrap: React.FC<IProps> = ({ hasStepper, product, children }) => {
  const classes = styleClasses();
  return (
    <div className={classes.contentWrap}>
      <LogoSection />
      <BodySection hasStepper={hasStepper} product={product}>
        {children}
      </BodySection>
    </div>
  );
};

export default ContentWrap;
