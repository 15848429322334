import React from "react";

function OfferExpired(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="228"
      height="149"
      fill="none"
      viewBox="0 0 228 149"
    >
      <path
        fill="url(#paint0_linear)"
        d="M148.826 64.558c26.392 4.84 78.093-5.008 79.166 17.624.779 16.35-53.184 43.368-113.53 50.326-24.839 2.865-48.136 16.785-67.067 16.487-13.505-.212-24.814-5.129-32.998-12.01C5.06 129.112-.129 117.112.002 104.642c.334-27.755 7.87-49.579 39.882-59.452.895-.273 1.81-.505 2.726-.712 62.349-13.749 60.098 9.883 106.216 20.08z"
      ></path>
      <path
        fill="#E0E0E0"
        d="M129.576 12.433H99.8v.93h29.776v-.93zM134.229 38.93h-29.776v.93h29.776v-.93zM134.927 41.492h-29.776v.93h29.776v-.93zM136.328 44.043h-29.776v.93h29.776v-.93zM134.229 80.075h-29.776v.93h29.776v-.93zM134.927 82.626h-29.776v.93h29.776v-.93zM136.328 85.188h-29.776v.93h29.776v-.93z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        d="M94.205 0L70.804 117.633l87.629 17.397 23.401-117.633L94.204 0z"
        opacity="0.5"
      ></path>
      <path
        fill="#fff"
        d="M95.026 1.824l-22.584 113.53 84.89 16.853 22.585-113.53-84.89-16.853z"
      ></path>
      <path
        fill="#E0E0E0"
        d="M100.159 8.288l-.181.912 60.244 11.96.182-.911-60.245-11.96zM83.014 94.465l-.181.912 60.245 11.96.181-.911-60.245-11.96zM81.97 99.704l-.18.912 46.552 9.242.182-.911-46.553-9.243z"
      ></path>
      <path
        fill="#055FF0"
        d="M80.927 104.943l-.181.912 17.343 3.443.181-.912-17.343-3.443zM99.11 13.526l-.182.912 30.118 5.979.181-.912-30.117-5.98z"
        opacity="0.7"
      ></path>
      <path
        fill="#E0E0E0"
        d="M98.066 18.773l-.181.912 54.311 10.783.182-.912-54.312-10.783z"
      ></path>
      <path
        fill="#3AD29F"
        d="M97.026 24.025l-.181.912 13.235 2.628.182-.912-13.236-2.628z"
        opacity="0.7"
      ></path>
      <path
        fill="#E0E0E0"
        d="M95.985 29.264l-.181.912 60.244 11.96.182-.911-60.245-11.96zM94.941 34.505l-.181.911 60.245 11.961.181-.912-60.245-11.96z"
      ></path>
      <path
        fill="url(#paint2_linear)"
        d="M127.886 65.007a1.937 1.937 0 00-1.994 2.296 1.944 1.944 0 003.558.668 1.937 1.937 0 00-.275-2.397 1.944 1.944 0 00-1.289-.567z"
      ></path>
      <path
        fill="#D94444"
        d="M145.7 96.27l-7.48 4.956-35.947-52.125 7.486-4.967L145.7 96.27z"
      ></path>
      <path
        fill="#D94444"
        d="M147.46 51.663l5.139 7.478-52.096 34.561-5.134-7.483 52.091-34.556z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="52.519"
          x2="102.652"
          y1="68.758"
          y2="191.177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4184F0"></stop>
          <stop offset="0.47" stopColor="#41DAE5"></stop>
          <stop offset="1" stopColor="#53FAD4"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="82.753"
          x2="200.579"
          y1="61.529"
          y2="84.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity="0.25"></stop>
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12"></stop>
          <stop offset="1" stopColor="gray" stopOpacity="0.1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="128.114"
          x2="128.114"
          y1="67.651"
          y2="71.527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.12"></stop>
          <stop offset="0.55" stopOpacity="0.09"></stop>
          <stop offset="1" stopOpacity="0.02"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default OfferExpired;
