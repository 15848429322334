// ===== content for Accept Offer page =====

type Translatable = {
  [key: string]: string;
};

export const OFFER_ACCEPT: {
  pageContent: {
    title: Translatable;
    cardInformation: Record<string, string[]>;
  };
  addressCard: {
    cardDetails: {
      title: Translatable;
      description: Translatable;
    };
  };
  iBanCard: {
    cardDetails: {
      title: Translatable;
      description: Translatable;
    };
  };
  OfferAmount: {
    cardTitle: Translatable;
  };
  SignatureCard: {
    cardDetails: {
      title: Translatable;
      description: Translatable;
    };
  };
  notice: Translatable;
  buttonText: Translatable;
} = {
  pageContent: {
    title: {
      de: "Unser Angebot",
      en: "Our offer",
    },
    cardInformation: {
      GFF: ["Du hast einen Anspruch auf Erstattung der Steuern & Gebühren des Flugtickets."],
      FIT: [
        "Du hast zu viele Fitnessstudio-Beiträge gezahlt!",
        "Aber keine Sorge - wir helfen Dir!",
      ],
      OCG: ["Du hast Geld im Online Casino verloren"],
      PKV: ["Sie haben einen Anspruch auf Erstattung Ihrer privaten Krankenversicherungsbeiträge."],
      NRG: ["Du hast einen Anspruch auf Schadensersatz für Deine erhöhten Energiepreise."],
      NFLX: [
        "Du hast einen Anspruch auf Rückerstattung für Deine zuviel gezahlten Netflix-Beiträge.",
      ],
    },
  },
  addressCard: {
    cardDetails: {
      title: {
        deInformal: "Deine Adresse",
        deFormal: "Ihre Adresse",
        en: "Your address",
      },
      description: {
        de: "An welche Adresse sollen wir die Abtretungserklärung senden?",
        en: "Which address should we send the Assignment Agreement to?",
      },
    },
  },
  iBanCard: {
    cardDetails: {
      title: {
        deInformal: "Deine Bankverbindung",
        deFormal: "Ihre Bankverbindung",
        en: "Your bank details",
      },
      description: {
        de: "Auf welches Konto sollen wir den Betrag überweisen?",
        en: "Which account should we transfer the payment to?",
      },
    },
  },
  OfferAmount: {
    cardTitle: {
      deInformal: "Für Deinen Fall bieten wir Dir:",
      deFormal: "Für Ihren Fall bieten wir Ihnen:",
      en: "For your case we offer you:",
    },
  },
  SignatureCard: {
    cardDetails: {
      title: {
        deInformal: "Deine Unterschrift*",
        deFormal: "Ihre Unterschrift*",
        en: "Your signature*",
      },
      description: {
        deInformal:
          "Klicke in das Feld und halte die linke Maustaste gedrückt, um Deine Unterschrift zu zeichnen. Es muss nicht perfekt sein.",
        deFormal:
          "Klicken Sie in das Feld und halten Sie die linke Maustaste gedrückt, um Ihre Unterschrift zu zeichnen. Es muss nicht perfekt sein.",
        en:
          "Click on the field and hold down the mouse button to draw your signature. It doesn't need to be perfect.",
      },
    },
  },
  notice: {
    de: "* Pflichtfeld",
    en: "* Compulsory field",
  },
  buttonText: {
    de: "Angebot annehmen",
    en: "Accept offer",
  },
};

// ===== content for Expired Offer page =====

export const EXPIRED_OFFER: {
  title: Record<string, string>;
  description: Record<string, string>;
  subDescription: Record<string, string>;
  buttonName: Record<string, string>;
} = {
  title: {
    deInformal: "Angebot abgelaufen!",
    deFormal: "Angebot abgelaufen!",
    en: "Offer expired!",
  },
  description: {
    deInformal: "Scheint, als wurde das Angebot nicht rechtzeitig angenommen.",
    deFormal: "Scheint, als wurde das Angebot nicht rechtzeitig angenommen.",
    en: "The offer wasn't accepted in time.",
  },
  subDescription: {
    deInformal:
      "Wir danken Dir für Dein Vertrauen und hoffe, Dich bald bei einem weiteren Fall unterstützen zu können.",
    deFormal:
      "Wir danken Ihnen für Ihr Vertrauen und hoffe, Sie bald bei einem weiteren Fall unterstützen zu können.",
    en: "We hope to be able to assist you with another case soon.",
  },
  buttonName: {
    deInformal: "Support kontaktieren",
    deFormal: "Support kontaktieren",
    en: "Contact Support",
  },
};

export const EXPIRED_OFFER_MAILTO = {
  email: "support@rightnow.de",
  subject: "Angebot angelaufen | Nachfrage",
  body: "Case Alias Id: ",
};
