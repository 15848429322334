import React, { useState, useMemo, useEffect } from "react";
import {
  PageContent,
  ActionsSection,
  BaseUpload,
  ErrorAlert,
  PageNotFound,
  PageWrap,
  LoadingSpinner,
} from "components/common";
import { useParams, useHistory } from "react-router-dom";
import { useCaseDocsUploadGetQuery, DocType, Product_Enum } from "types/graphql.types";
import { URL_UPLOAD_DOCUMENT_SUCCESS } from "../../../../constants";
import { UPLOAD_CARD_DATA, DOCUMENTS_REQUIRED } from "./const";
import { IFile } from "types/common";
import { useCustomerAddDocumentsMutation } from "../../../../types/graphql.types";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const Upload: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [documentsRecordNew, setDocumentsRecordNew] = useState<IFile[]>();
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();

  const { data, loading } = useCaseDocsUploadGetQuery({
    variables: {
      id: id,
    },
  });
  const [customerAddDocuments] = useCustomerAddDocumentsMutation();

  const isDisabled = useMemo(() => {
    let docLength = 0;

    if (documentsRecordNew) {
      for (const record of Object.entries(documentsRecordNew)) {
        docLength += record !== null && record[1] !== null ? 1 : 0;
      }
    }

    return !docLength;
  }, [documentsRecordNew]);

  useEffect(() => {
    if (data?.case_docs_upload_get?.companyId) {
      window.dataLayer.push({ company_id: data.case_docs_upload_get.companyId });
    }
  }, [data?.case_docs_upload_get?.companyId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!data?.case_docs_upload_get) {
    return <PageNotFound />;
  }

  const product = (data?.case_docs_upload_get.vertical as unknown) as Product_Enum;
  const documents = data?.case_docs_upload_get.missingDocuments as DocType[];

  const documentRequired = documents.map(
    (document: DocType) => DOCUMENTS_REQUIRED[document] || null
  );

  const updateDocumentsRecord = (files: IFile[]) => {
    setDocumentsRecordNew((current) => (current ? [...current, ...files] : files));
  };

  const submitDocuments = () => {
    if (documentsRecordNew) {
      const variables = {
        input: {
          configName: `${product.toLowerCase()}-claim-purchasing`,
          input: {
            case: {
              id,
              documents: documentsRecordNew,
            },
          },
        },
      };
      customerAddDocuments({
        variables,
      }).then((res) => {
        if (res?.errors) {
          setError(true);
          return;
        } else {
          history.push(URL_UPLOAD_DOCUMENT_SUCCESS);
        }
      });
    }
  };

  return (
    <PageWrap product={""}>
      <PageContent
        title={UPLOAD_CARD_DATA[product]?.title || ""}
        cardInformation={UPLOAD_CARD_DATA[product]?.cardInformation || [""]}
        cardInfoAlignment={UPLOAD_CARD_DATA[product]?.cardInfoAlignment}
      />
      {documentRequired
        .filter((doc) => doc !== null)
        .map(
          (item, index) =>
            item && (
              <BaseUpload
                key={index}
                description={item.description}
                title={item.title}
                updateDocumentsRecord={updateDocumentsRecord}
                docType={item.docType}
                isZipFile={item.isZipFile}
              />
            )
        )}
      {error ? <ErrorAlert error={"Es ist ein Fehler aufgetreten."} /> : <></>}
      <ActionsSection handleNext={submitDocuments} disabled={isDisabled} nextButton="Weiter" />
    </PageWrap>
  );
};

export default Upload;
