import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as BaseApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/link-error";
import { GRAPH_QL_ERROR, NETWORK_ERROR } from "../app-debug";
import Debug from "../app-debug";

const ApolloProvider: React.FC = ({ children }) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_APOLLO_CLIENT_URI}`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        Debug(GRAPH_QL_ERROR)(`Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) {
      Debug(NETWORK_ERROR)(networkError);
    }
  });

  const client = new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([(errorLink as unknown) as ApolloLink, authLink.concat(httpLink)]),
    cache: new InMemoryCache(),
    defaultOptions: {
      mutate: {
        errorPolicy: "all",
      },
      query: {
        errorPolicy: "all",
      },
    },
  });

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};

export default ApolloProvider;
