const buttonToHomepage = {
  buttonName: "Zur Startseite",
  href: process.env.REACT_APP_MAIN_URL,
};

export const SUCCESS_STEP_DATA = {
  title: "Geschafft!",
  description: "Wir prüfen Deinen Anspruch und melden uns schnellstmöglich bei Dir zurück!",
  subDescription: "",
  ...buttonToHomepage,
};
