import React from "react";
import { styleClasses } from "./style";
import { CircularProgress } from "@material-ui/core";

const LoadingSpinner: React.FC = () => {
  const classes = styleClasses();

  return (
    <div className={classes.loadingSpinner}>
      <CircularProgress />
    </div>
  );
};

export default LoadingSpinner;
