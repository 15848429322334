import React from "react";
import clsx from "clsx";
import { Container, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.brand.darkgrey,
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      minHeight: 500,
    },
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(9),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 31,
    paddingRight: 31,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    }
  },
  backdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.brand.darkgrey,
    opacity: 0.5,
    zIndex: -1,
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundRepeat: "no-repeat",
    zIndex: -2,
  },
}));

interface SectionLayoutProps {
  rootClassName: string;
  backgroundClassName: string;
  children: React.ReactNode;
}

const SectionLayout: React.FC<SectionLayoutProps> = ({
  rootClassName,
  backgroundClassName,
  children,
}) => {
  const classes = useStyles();

  return (
    <section className={clsx(classes.root, rootClassName)}>
      <Container fixed className={classes.container}>
        {children}
        <div className={clsx(classes.background, backgroundClassName)} />
      </Container>
    </section>
  );
};

export default SectionLayout;
