import { makeStyles } from "@material-ui/core/styles";
import Icon from "assets/images/zahltag.svg";

export const styleClasses = makeStyles((theme) => ({
  offerAmount: {
    backgroundImage: `url(${Icon})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    backgroundPositionX: "left",
    backgroundColor: theme.palette.common.white,
    backgroundSize: "15%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "35px",
    borderRadius: "10px",
    marginTop: "40px",
    marginBottom: "48px",
    [theme.breakpoints.down("xs")]: {
      padding: "25px",
      marginTop: "25px",
      marginBottom: "40px",
    },
  },
}));
