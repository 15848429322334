export interface IStep {
  label: string;
  done: boolean;
}

export const STEPS: Record<string, Record<string, IStep[]>> = {
  fit: {
    desktop: [
      {
        label: "Fitness-Studio",
        done: false,
      },
      {
        label: "Studio-Beiträge",
        done: false,
      },
      {
        label: "Kontaktdaten",
        done: false,
      },
    ],
    mobile: [
      {
        label: "Studio",
        done: false,
      },
      {
        label: "Beiträge",
        done: false,
      },
      {
        label: "Kontakt",
        done: false,
      },
    ],
  },
};

export const SKIPPED_STEP = 1;
