import React from "react";
import { DoneImage } from "components/image-components";
import { PageWrap, BasePageContent } from "components/common";

import { SUCCESS_STEP_DATA } from "./const";

const Success: React.FC = () => {
  const getImage = () => <DoneImage />;
  return (
    <PageWrap product={""}>
      <BasePageContent getImage={getImage} {...SUCCESS_STEP_DATA} />
    </PageWrap>
  );
};

export default Success;
