import { createMuiTheme } from "@material-ui/core/styles";
import { indigo } from "@material-ui/core/colors";

const MainTheme = createMuiTheme({
  typography: {
    fontSize: 18,
    fontFamily: "'Overpass', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeightHeavy: 900,
    h1: {
      fontWeight: 900,
      fontSize: "32px",
      "@media (min-width:600px)": {
        fontSize: "48px",
      },
    },
    h2: {
      fontWeight: 900,
      fontSize: "24px",
      "@media (min-width:600px)": {
        fontSize: "32px",
      },
    },
    h3: {
      fontWeight: 700,
      fontSize: "24px",
      "@media (min-width:600px)": {
        fontSize: "24px",
      },
    },
    h4: {
      fontWeight: 700,
      fontSize: "18px",
      "@media (min-width:600px)": {
        fontSize: "24px",
      },
    },
    body1: {
      fontSize: "18px",
      "@media (min-width:600px)": {
        fontSize: "18px",
      },
    },
    body2: {
      fontSize: "14px",
      "@media (min-width:600px)": {
        fontSize: "14px",
      },
    },
  },
  shape: {
    borderRadius: 4,
    borderRadiusLarge: 10,
  },
  palette: {
    common: {
      white: "#FFFFFF",
    },
    primary: {
      main: "#055FF0", // royalblue
    },
    secondary: {
      main: indigo[900],
    },
    text: {
      secondary: "#9A9BA7", // grey
    },
    brand: {
      aquamarine: "#05F0DE",
      darkgrey: "#2D333D",
      grey: "#9A9BA7",
      offwhite: "#EFF6FA",
      aliceblue: "#EEF4FF",
      pink: "#F0B9E6",
      royalblue: "#055FF0",
      aquamarineSecondary: "#B7FBED",
    },
  },
  shadows: [
    "none",
    "0px 3px 20px #11348333", // 1 basic
    "0px 4px 36px #11348333", // 2 section
    "0px 3px 20px #2F5FF199", // 3 button primary enabled
    "0px 4px 36px #2F5FF199", // 4 button primary hover
    "0px 3px 20px #90909C33", // 5 button primary disabled
    "0px 3px 20px #11348333", // 6 button secondary enabled
    "0px 4px 36px #11348333", // 7 button secondary hover
    "0px 3px 20px #1134831A", // 8 button secondary disabled
    "0px 10px 7px -3px rgba(0, 0, 0, 0.3),0px 1px 14px 1px rgba(0, 0, 0, 0.06),0px 3px 16px 2px rgba(0, 0, 0, 0.04)",
    "0px 11px 7px -3px rgba(0, 0, 0, 0.3),0px 2px 16px 1px rgba(0, 0, 0, 0.06),0px 4px 18px 3px rgba(0, 0, 0, 0.04)",
    "0px 11px 8px -4px rgba(0, 0, 0, 0.3),0px 3px 17px 1px rgba(0, 0, 0, 0.06),0px 4px 20px 3px rgba(0, 0, 0, 0.04)",
    "0px 13px 9px -4px rgba(0, 0, 0, 0.3),0px 4px 19px 2px rgba(0, 0, 0, 0.06),0px 5px 22px 4px rgba(0, 0, 0, 0.04)",
    "0px 13px 9px -4px rgba(0, 0, 0, 0.3),0px 5px 21px 2px rgba(0, 0, 0, 0.06),0px 5px 24px 4px rgba(0, 0, 0, 0.04)",
    "0px 13px 10px -4px rgba(0, 0, 0, 0.3),0px 6px 23px 2px rgba(0, 0, 0, 0.06),0px 5px 26px 4px rgba(0, 0, 0, 0.04)",
    "0px 15px 10px -5px rgba(0, 0, 0, 0.3),0px 7px 24px 2px rgba(0, 0, 0, 0.06),0px 6px 28px 5px rgba(0, 0, 0, 0.04)",
    "0px 15px 12px -5px rgba(0, 0, 0, 0.3),0px 8px 26px 2px rgba(0, 0, 0, 0.06),0px 6px 30px 5px rgba(0, 0, 0, 0.04)",
    "0px 15px 13px -5px rgba(0, 0, 0, 0.3),0px 9px 28px 2px rgba(0, 0, 0, 0.06),0px 6px 32px 5px rgba(0, 0, 0, 0.04)",
    "0px 17px 13px -5px rgba(0, 0, 0, 0.3),0px 10px 30px 2px rgba(0, 0, 0, 0.06),0px 7px 34px 6px rgba(0, 0, 0, 0.04)",
    "0px 17px 14px -6px rgba(0, 0, 0, 0.3),0px 11px 31px 2px rgba(0, 0, 0, 0.06),0px 7px 36px 6px rgba(0, 0, 0, 0.04)",
    "0px 19px 15px -6px rgba(0, 0, 0, 0.3),0px 12px 33px 3px rgba(0, 0, 0, 0.06),0px 8px 38px 7px rgba(0, 0, 0, 0.04)",
    "0px 19px 15px -6px rgba(0, 0, 0, 0.3),0px 13px 35px 3px rgba(0, 0, 0, 0.06),0px 8px 40px 7px rgba(0, 0, 0, 0.04)",
    "0px 19px 16px -6px rgba(0, 0, 0, 0.3),0px 14px 37px 3px rgba(0, 0, 0, 0.06),0px 8px 42px 7px rgba(0, 0, 0, 0.04)",
    "0px 20px 16px -7px rgba(0, 0, 0, 0.3),0px 15px 38px 3px rgba(0, 0, 0, 0.06),0px 9px 44px 8px rgba(0, 0, 0, 0.04)",
    "0px 20px 18px -7px rgba(0, 0, 0, 0.3),0px 16px 40px 3px rgba(0, 0, 0, 0.06),0px 9px 46px 8px rgba(0, 0, 0, 0.04)",
  ],
});
export default MainTheme;
