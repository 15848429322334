import React from "react";

function UploadImage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="203"
      height="129"
      fill="none"
      viewBox="0 0 203 129"
      {...props}
    >
      <path
        fill="url(#paint0_linear)"
        d="M190.287 66.266c-11.411-47.247-59.891-40.05-76-31.194-45.365 18.354-66.15-1.093-87.564 23.66-2.951 3.986-2.935 3.407-5.024 7.545-13.204 23.64 19.138 50.415 64.767 57.9 45.628 7.484 111.527 7.389 103.821-57.91z"
        opacity="0.7"
      ></path>
      <path
        fill="#055FF0"
        d="M68.72 22.273l12.474 38.73-46.392 28.134-20.82-64.635 36.73-11.745 18.007 9.516z"
      ></path>
      <path
        fill="#FAFAFA"
        d="M68.72 22.273L87.872 73.54l-53.07 15.597-20.82-64.635 36.73-11.745 18.006 9.516z"
      ></path>
      <path fill="#055FF0" d="M68.719 22.274l-13.546 4.331-4.46-13.848 18.006 9.517z"></path>
      <path
        fill="#055FF0"
        fillRule="evenodd"
        d="M75.192 56.835l3.58 12.338L40.75 79.947l-2.148-7.402 4.982-9.423 6.184 3.588 9.963-18.846 15.461 8.971zm-33.04 1.352c-1.98.56-3.873.318-5.676-.728-1.804-1.047-3.005-2.599-3.601-4.655-.597-2.056-.41-4 .558-5.833.968-1.832 2.443-3.028 4.423-3.59 1.98-.56 3.872-.318 5.676.728 1.804 1.047 3.004 2.599 3.601 4.655s.41 4-.558 5.833c-.968 1.832-2.443 3.029-4.423 3.59z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#055FF0"
        d="M106.834 50.607l-.305-.367a32.732 32.732 0 013.974-2.703l.247.407a32.35 32.35 0 00-3.916 2.663zM96.892 64.136l-.453-.166c1.642-4.292 4.266-8.277 7.59-11.525l.342.335c-3.274 3.2-5.86 7.126-7.48 11.356z"
      ></path>
      <path
        fill="#055FF0"
        d="M190.714 84.998c-.119-13.828-11.62-25.004-25.796-25.004-3.416 0-6.674.655-9.658 1.833-5.066-10.13-15.709-17.11-28.023-17.11-14.891 0-27.336 10.208-30.43 23.853-14.005.88-25.088 12.255-25.088 26.167 0 14.485 12.014 26.228 26.835 26.228h85.386c10.505 0 19.022-8.324 19.022-18.592 0-7.936-5.088-14.707-12.248-17.375z"
      ></path>
      <path
        fill="#263238"
        d="M171.506 76.52h-68.33c-1.155 0-2.099-.922-2.099-2.05 0-1.129.944-2.051 2.099-2.051h68.33c1.154 0 2.098.922 2.098 2.05 0 1.129-.944 2.052-2.098 2.052z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M161.199 104.258h-47.716c-3.888 0-7.051-3.092-7.051-6.892 0-3.8 3.163-6.892 7.051-6.892h47.716c3.888 0 7.051 3.091 7.051 6.892 0 3.8-3.163 6.892-7.051 6.892zm-47.716-13.31c-3.621 0-6.567 2.879-6.567 6.418s2.946 6.418 6.567 6.418h47.716c3.621 0 6.566-2.879 6.566-6.418 0-3.54-2.945-6.419-6.566-6.419h-47.716z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M124.626 102.601h-11.144c-2.945 0-5.356-2.356-5.356-5.235 0-2.88 2.411-5.235 5.356-5.235h11.144c2.947 0 5.357 2.356 5.357 5.235 0 2.879-2.41 5.235-5.357 5.235z"
      ></path>
      <path
        fill="#055FF0"
        d="M173.457 36.733l-9.059 37.737h-54.054l15.118-62.98 37.43 8.584 10.565 16.659z"
      ></path>
      <path
        fill="#FAFAFA"
        d="M173.457 36.733l-9.059 37.737h-54.054l15.118-62.98 37.43 8.584 10.565 16.659z"
      ></path>
      <path fill="#055FF0" d="M173.457 36.733l-13.804-3.166 3.239-13.493 10.565 16.659z"></path>
      <path
        fill="#055FF0"
        fillRule="evenodd"
        d="M125.741 50.62l1.894.374 1.45-5.371 3.224.638c3.023.598 4.825-1.458 5.347-3.394.56-2.073.067-4.438-3.065-5.058l-5.118-1.013-3.732 13.823zm7.094-6.187l-3.41-.672 1.242-4.59 3.411.671c1.714.338 1.826 1.739 1.559 2.725-.294 1.087-1.18 2.185-2.802 1.866zm8.89-4.843l-3.733 13.837 3.502.692c4.688.926 7.174-2.518 8.032-5.7.923-3.42.225-7.244-4.226-8.123l-3.575-.706zm.62 12.629l-1.9-.377 2.666-9.916 2.098.416c2.586.513 2.987 3.204 2.32 5.683-.624 2.322-2.291 4.768-5.184 4.194zm8.92 4.166l1.879.373 1.648-6.136 3.849.763.518-1.927-3.849-.764 1.014-3.776 5.964 1.184.518-1.928-7.843-1.556-3.698 13.767z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="82.49"
          x2="13.335"
          y1="28.671"
          y2="127.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4184F0"></stop>
          <stop offset="0.468" stopColor="#41DAE5"></stop>
          <stop offset="1" stopColor="#53FAD4"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default UploadImage;
