export const PAGE_CONTENT = {
  title: "Kontaktdaten",
  cardInformation: ["Wir brauchen nur wenige Infos, um Deinen Fall zu prüfen."],
};

const formCardContentCommon = { title: "Dein Kontakt" };

export const FORM_CARD_CONTENT: Record<
  string,
  {
    title: string;
    description: string;
  }
> = {
  fit: {
    description: "Wie können wir Dich erreichen?",
    ...formCardContentCommon,
  },
};

export const ACTION_SECTION_BUTTON_TEXT = {
  nextButton: "Prüfen",
  backButton: "Zurück",
};
