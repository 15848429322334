export const OFFER_ACCEPTED_SUCCESS: {
  title: Record<string, string>;
  description: Record<string, string>;
  subDescription: Record<string, string>;
  buttonName: Record<string, string>;
} = {
  title: {
    deInformal: "Angebot angenommen!",
    deFormal: "Angebot angenommen!",
    en: "Offer accepted",
  },
  description: {
    deInformal: "Toll, dass Du unser Angebot angenommen hast!",
    deFormal: "Vielen Dank für Ihr Vertrauen.",
    en: "Thank you for placing your trust in us.",
  },
  subDescription: {
    deInformal:
      "Wir senden Dir jetzt die Abtretungserklärung zu. Sende ein Exemplar unterschrieben an uns zurück und wir überweisen Dir sofort Dein Geld.",
    deFormal:
      "Wir senden Ihnen schnellstmöglich die Abtretungserklärung zu. Senden Sie bitte ein Exemplar unterschrieben an uns zurück und wir überweisen Ihnen nach Erhalt das Geld.",
    en: "We will send you the Assignment Agreement within the next couple of days. Please return a signed copy to us - we will transfer your payment as soon as we receive it.",
  },
  buttonName: {
    deInformal: "Zur Startseite",
    deFormal: "Zur Startseite",
    en: "Return to our homepage (German)",
  },
};
