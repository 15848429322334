import React from "react";
import { styleClasses } from "./style";
import TuevLogo from "assets/images/tuev_2x.png";

const LogoSection: React.FC = () => {
  const classes = styleClasses();
  return (
    <div className={classes.logoWrapper}>
      <a target="_blank" href="https://www.tuev-saar.de/zertifikat/sc45082/" rel="noreferrer">
        <img src={TuevLogo} alt="TuevLogo" className={classes.logo} />
      </a>
    </div>
  );
};

export default LogoSection;
