import React from "react";
import clsx from "clsx";
import { Container } from "@material-ui/core";
import { styleClasses } from "./style";

interface IProps {
  children?: any;
  hasStepper?: boolean;
}

const MainWrap: React.FC<IProps> = ({ hasStepper, children }) => {
  const classes = styleClasses();
  return (
    <Container className={clsx(classes.container, hasStepper && classes.hasMargin)}>
      <div className={classes.cardSection}>
        <div className={classes.typography}>
          <div className={classes.wrapContainer}>{children}</div>
        </div>
      </div>
    </Container>
  );
};

export default MainWrap;
