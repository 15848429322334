import { SalutationType } from "types/graphql.types";

export const checkIfValidationError = (message: string) => {
  return message.startsWith("[{");
};

export const getErrorMessagebyId = (message: string, id: string) => {
  if (checkIfValidationError(message)) {
    let errorMessage: string[] = [];
    const error: {
      property: string;
      constraints: {
        [k: string]: string;
      };
    }[] = JSON.parse(message);

    error.forEach((e) => {
      if (e.property === id) {
        for (const [, value] of Object.entries(e.constraints)) {
          errorMessage.push(value);
        }
      }
    });
    return errorMessage.length
      ? errorMessage.join(", ").replace(/, ([^,]*)$/, " and $1")
      : undefined;
  }
};

export async function uploadFileToS3(presignedPostData: any, file: File) {
  const formData = new FormData();
  Object.keys(presignedPostData.fields).forEach((key) => {
    formData.append(key, presignedPostData.fields[key]);
  });
  formData.append("file", file);

  const response = await fetch(presignedPostData.url, {
    method: "POST",
    body: formData,
  });
  return response.status === 204;
}

export const getFormData = (queryObject: Record<string, string>) => {
  return {
    email: queryObject.email ? queryObject.email : "",
    firstName: queryObject.first_name ? queryObject.first_name : "",
    lastName: queryObject.last_name ? queryObject.last_name : "",
    promoCode: !!queryObject.promo_code,
    promoCodeValue: queryObject.promo_code ? queryObject.promo_code : "",
    salutation: queryObject.salutation ? (queryObject.salutation as SalutationType) : null,
  };
};

export const extractMarketingData = (
  formFields: Array<string>,
  queryObject: Record<string, string>
) => {
  const clonedQueryObject = { ...queryObject };
  formFields.forEach((field) => {
    delete clonedQueryObject[field];
  });

  return clonedQueryObject;
};

export const getRequestActivationMailToLink = (args: {
  email: string;
  subject: string;
  body: string;
  aliasId: string;
}) => {
  return `mailto:${args.email}?subject=${args.subject}&body=${args.body + args.aliasId}`;
};

export const getValidationErrors = (message: string) => {
  if (checkIfValidationError(message)) {
    const errors: {
      property: string;
      constraints: {
        [k: string]: string;
      };
    }[] = JSON.parse(message);

    const validationErrors = errors.map((e) => {
      let errorMessage: string[] = [];

      for (const [, value] of Object.entries(e.constraints)) {
        errorMessage.push(value);
      }
      return { [e.property]: errorMessage.join(", ").replace(/, ([^,]*)$/, " and $1") };
    });
    return Object.assign({}, ...validationErrors);
  }
};
