import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
  Button as MuiButton,
  ButtonProps,
} from "@material-ui/core";
import * as React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderRadius: "0px 4px 4px 0px",
      padding: theme.spacing(2, 4),
      boxShadow: theme.shadows[0],
      "&:active, &:focus": {
        boxShadow: theme.shadows[0],
      },
    },
  });

function Button<C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }> & WithStyles<typeof styles>
) {
  return <MuiButton {...props} />;
}

export default withStyles(styles)(Button);
