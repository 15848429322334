import React from "react";
import { TextField } from "@material-ui/core";
import "./index.scss";

interface Props {
  disabled?: boolean;
  icon?: React.ReactNode;
  placeholder: string;
  name: string;
  onChange: (value: string, name: string, index?: number) => void;
  required?: boolean;
  error?: boolean;
  value?: string;
  index?: number;
  id?: string;
  errorMessage?: string;
}

const BaseInputNew: React.FC<Props> = ({
  icon,
  disabled,
  placeholder,
  name,
  onChange,
  error,
  value,
  index,
  id,
  errorMessage,
}) => {
  const label = icon ? (
    <>
      {icon}
      {placeholder}
    </>
  ) : (
    placeholder
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value, e.target.name, index);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) =>
    onChange(e.target.value.trim(), e.target.name, index);

  return (
    <TextField
      data-cy={id}
      autoComplete="off"
      label={label}
      onChange={handleChange}
      name={name}
      variant="filled"
      required
      className="text-field"
      error={errorMessage !== undefined}
      value={value}
      disabled={disabled}
      helperText={errorMessage}
      onBlur={handleBlur}
    />
  );
};

export default BaseInputNew;
