import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./styles.scss";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import SignaturePad from "signature_pad";

interface IProps {
  handleChange: (value: string, name: string) => void;
}

const SigCanvas: React.FC<IProps> = ({ handleChange }) => {
  const [signatureData, setSignatureData] = useState<SignaturePad.Point[][]>([]);
  const sigPad = useRef<SignatureCanvas | null>();

  const handlePrevious = (): void => {
    if (sigPad.current && signatureData.length) {
      const currentRender = sigPad.current.toData();
      if (!signatureData.length && !!currentRender.length) {
        setSignatureData(currentRender);
      }
      sigPad.current.fromData(currentRender.slice(0, Math.max(0, currentRender.length - 1)));
      updateSignatureState(!!Math.max(0, currentRender.length - 1));
    }
  };

  const handleNext = (): void => {
    if (sigPad.current && signatureData.length) {
      const currentRender = sigPad.current.toData();
      if (currentRender.length !== signatureData.length) {
        sigPad.current.fromData(signatureData.slice(0, currentRender.length + 1));
        updateSignatureState(!!(currentRender.length + 1));
      }
    }
  };

  const handlePenStroke = (): void => {
    const currentRender = sigPad.current?.toData();
    if (currentRender) {
      setSignatureData(currentRender);
      updateSignatureState(!!currentRender.length);
    }
  };

  const updateSignatureState = (doesContainSignature: boolean): void => {
    if (doesContainSignature && sigPad.current) {
      handleChange(sigPad.current?.toDataURL("image/png"), "signature");
    } else {
      handleChange("", "signature");
    }
  };

  return (
    <div className="sigContainer">
      <SignatureCanvas
        ref={(ref) => {
          sigPad.current = ref;
        }}
        penColor="#055ff0"
        minWidth={2}
        maxWidth={4}
        canvasProps={{ className: "sigPad" }}
        onEnd={handlePenStroke}
      />
      <div className="signatureButtonFlexbox">
        <div className="signatureButtonContainer" onClick={handlePrevious}>
          <UndoIcon color="action" />
        </div>
        <div className="signatureButtonContainer">
          <RedoIcon color="action" onClick={handleNext} />
        </div>
      </div>
    </div>
  );
};

export default SigCanvas;
