import React from "react";
import { Button, makeStyles } from "@material-ui/core";

interface IProps {
  disabled?: boolean;
  onClick?: () => void;
  name: string;
  href?: string;
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: "15px",
    boxShadow: "0px 3px 20px #11348333",
    borderRadius: "4px",
    opacity: 1,
    textTransform: "none",
    minWidth: "193px",
    minHeight: "56px",
    padding: "0 23px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "152px",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.brand.darkgrey,
    "&:active, &:focus, &:hover, &:visited, &:link": {
      textDecoration: "none",
    },
  },
}));

const BaseButton: React.FC<IProps> = ({ disabled, onClick, name, href }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (href) {
      //open link
      window.location.href = href;
    } else if (onClick) {
      onClick();
    }
  };
  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
      className={classes.button}
      data-cy={"base-button"}
    >
      {name}
    </Button>
  );
};

export default BaseButton;
