import { NavLink } from "react-router-dom";

export type TCheckBoxData = {
  fieldName: string;
  required: boolean;
  description: JSX.Element;
};

const checkBoxData: TCheckBoxData[] = [
  {
    fieldName: "termsAndConditions",
    required: true,
    description: (
      <p>
        Ich habe die{" "}
        <NavLink
          className="link"
          to={{ pathname: `${process.env.REACT_APP_MAIN_URL}/agb` }}
          target="_blank"
          rel="noopener noreferrer"
        >
          allgemeinen Geschäftsbedingungen
        </NavLink>{" "}
        sowie die{" "}
        <NavLink
          className="link"
          to={{ pathname: `${process.env.REACT_APP_MAIN_URL}/datenschutzerklarung` }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Datenschutzbestimmungen
        </NavLink>{" "}
        gelesen und akzeptiere diese. Weiterhin bestätige ich, dass meine gemachten Angaben der
        Wahrheit entsprechen. *
      </p>
    ),
  },
  {
    fieldName: "newsLetter",
    required: false,
    description: (
      <p>
        Ich möchte Informationen (insbesondere Newsletter) über aktuelle Produkte und
        Dienstleistungen von der RightNow Group erhalten und per E-Mail kontaktiert werden.
      </p>
    ),
  },
  {
    fieldName: "promoCode",
    required: false,
    description: <p>Ich habe einen Promo-Code.</p>,
  },
];

export default checkBoxData;
