import React from "react";
import File from "./File";
import { IFile } from "types/common";

interface IProps {
  files: IFile[];
  deleteFile: (id: string) => void;
}

const FilesList: React.FC<IProps> = ({ files, deleteFile }) => {
  return (
    <>
      {files.map((file) => (
        <File key={file.id} deleteFile={deleteFile} file={file} />
      ))}
    </>
  );
};

export default FilesList;
