import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  file: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.brand.aquamarineSecondary,
    borderRadius: "10px",
    boxShadow: "0px 3px 20px rgb(5 95 240) / 8%)",
    padding: "0 10px",
    marginTop: "26px",
  },
  deleteIcon: {
    color: theme.palette.brand.royalblue,
    cursor: "pointer",
    flex: 1,
  },
  noteIcon: {
    transform: "rotate(-90deg)",
    color: theme.palette.brand.royalblue,
    cursor: "pointer",
    flex: 1,
  },
  fileName: {
    flex: 8,
  },
}));
