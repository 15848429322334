import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  loadingSpinner: {
    height: "calc(100vh - 80px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
