import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  contentWrap: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      flexDirection: "column-reverse",
    },
  },
}));
