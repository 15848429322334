import { BasePageContent, PageWrap } from "components/common";
import { OfferAccepted } from "components/image-components";
import { verticalLanguageSettings } from "../const";
import { OFFER_ACCEPTED_SUCCESS } from "./const";

const Success = () => {
  const getImage = () => <OfferAccepted />;
  let params = new URLSearchParams(window.location.search);
  const formalLanguage = verticalLanguageSettings[params.get("product") || "PKV"];

  return (
    <PageWrap product="">
      <BasePageContent
        title={OFFER_ACCEPTED_SUCCESS.title[formalLanguage]}
        description={OFFER_ACCEPTED_SUCCESS.description[formalLanguage]}
        subDescription={OFFER_ACCEPTED_SUCCESS.subDescription[formalLanguage]}
        buttonName={OFFER_ACCEPTED_SUCCESS.buttonName[formalLanguage]}
        getImage={getImage}
        href={process.env.REACT_APP_MAIN_URL}
      />
    </PageWrap>
  );
};

export default Success;
