import React, { useState, useCallback, useLayoutEffect } from "react";
import clsx from "clsx";
import { StepperComponent } from "components/common";
import { STEPS } from "constants/steps";
import { small } from "helpers/media";
import { styleClasses } from "./style";
import MainWrap from "./MainWrap";

interface IProps {
  product: string;
  children?: any;
  hasStepper?: boolean;
}

const BodySection: React.FC<IProps> = ({ product, children, hasStepper }) => {
  const [isMobile, setMobile] = useState<boolean>(window.matchMedia(small).matches);
  const classes = styleClasses();

  const handleResize = useCallback(() => {
    const { matches } = window.matchMedia(small);
    if (matches !== isMobile) setMobile(matches);
  }, [isMobile]);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <div className={clsx(classes.content, !hasStepper && classes.contentTop)}>
      {hasStepper && (
        <StepperComponent
          activeStep={0}
          steps={isMobile ? STEPS[product].mobile : STEPS[product].desktop}
        />
      )}
      <MainWrap hasStepper={hasStepper}>{children}</MainWrap>
    </div>
  );
};

export default BodySection;
