import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  caption: {
    color: theme.palette.brand.grey,
    marginBottom: "40px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px",
    },
  },
}));
