import React from "react";
import { Note, HighlightOff } from "@material-ui/icons";
import { IFile } from "types/common";

import { styleClasses } from "./style";

interface IProps {
  file: IFile;
  deleteFile: (id: string) => void;
}

const File: React.FC<IProps> = ({ file, deleteFile }) => {
  const classes = styleClasses();
  return (
    <div className={classes.file}>
      <Note className={classes.noteIcon} />
      <p className={classes.fileName}>{file.fileName}</p>
      <HighlightOff onClick={() => deleteFile(file.id)} className={classes.deleteIcon} />
    </div>
  );
};

export default File;
