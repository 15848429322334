import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  logoWrapper: {
    marginLeft: "121px",
    width: "150px",
    height: "79px",
    position: "absolute",
    [theme.breakpoints.down("lg")]: {
      marginLeft: "50px",
    },
    [theme.breakpoints.down("md")]: {
      position: "unset",
      alignSelf: "flex-start",
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "24px",
      marginLeft: "24px",
    },
  },
  logo: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "120px",
      height: "63px",
    },
  },
}));
