import DebugLib from "debug";
const Debug = DebugLib;

export const GRAPH_QL_ERROR = "GraphQL Error";
export const NETWORK_ERROR = "Network Error";
export const SERVICE_WORKER = "ServiceWorker";
export const SERVICE_WORKER_ERROR = "ServiceWorker Error";
export const STATE_MACHINE = "StateMachine";

if (process.env.NODE_ENV !== "production") {
    localStorage.debug = "*";
} else {
    localStorage.debug = [
        GRAPH_QL_ERROR,
        NETWORK_ERROR,
        SERVICE_WORKER_ERROR
    ];
}

Debug.log = console.info.bind(console);

export default Debug;
