import React, { useState } from "react";
import { uploadFileToS3 } from "helpers/utils";
import _ from "lodash";
import { IFile } from "types/common";
import FilesList from "./FilesList";
import UploadCard from "./UploadCard";
import { styleClasses } from "./style";
import { DocType, useGetDocsSignUrlMutation } from "types/graphql.types";
import { ErrorAlert } from "components/common";

interface IProps {
  title: string;
  key?: number;
  description: string;
  updateDocumentsRecord: (file: IFile[]) => void;
  docType: DocType;
  isUploadButtonHidden?: boolean;
  isZipFile?: boolean;
}

const BaseUpload: React.FC<IProps> = ({
  title,
  description,
  updateDocumentsRecord,
  docType,
  isUploadButtonHidden,
  isZipFile,
}) => {
  const [files, setFiles] = useState<IFile[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [getDocsSignUrlMutation] = useGetDocsSignUrlMutation();

  const uploadFileHandler = (file: File, name: string) => {
    if (file && file !== null) {
      setErrorMsg("");
      const trimmedFileName = file.name.trim();
      if (trimmedFileName.length < 3) {
        setErrorMsg(`Der Dateiname für "${file.name}" ist zu kurz`);
      } else {
        getDocsSignUrlMutation({
          variables: { docType: docType, name: trimmedFileName, contentType: file.type },
        }).then((res) => {
          if (res.data && res.data.docs_sign_url) {
            const fileObj: IFile = {
              id: res.data.docs_sign_url?.id,
              fileName: trimmedFileName,
              contentType: file.type,
              category: docType,
            };
            const newState = _.concat(files, fileObj);
            const fileWithTrimmedName = new File([file], trimmedFileName, {
              type: file.type,
            });
            uploadFileToS3(res.data.docs_sign_url, fileWithTrimmedName).then((isUploaded) => {
              if (isUploaded === false) {
                setErrorMsg("Hochladen fehlgeschlagen");
              } else if (isUploaded === true) {
                setFiles(newState);
                updateDocumentsRecord(newState);
              }
            });
          }
        });
      }
    }
  };

  const deleteFileHandler = (id: string) => {
    const newState = files.filter((file) => file.id !== id);
    setFiles(newState);
    updateDocumentsRecord(newState);
  };

  const classes = styleClasses();
  return (
    <div className={classes.baseUpload}>
      <UploadCard
        title={title}
        description={description}
        uploadFileHandler={uploadFileHandler}
        name={"documents"}
        isUploadButtonHidden={isUploadButtonHidden}
        isZipFile={isZipFile}
      />
      {files?.length ? <FilesList deleteFile={deleteFileHandler} files={files} /> : undefined}
      {errorMsg ? <ErrorAlert error={errorMsg} /> : undefined}
    </div>
  );
};

export default BaseUpload;
