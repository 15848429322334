import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Box, Typography, makeStyles } from "@material-ui/core";
import copyrightImage from "assets/images/icons/icon_metro_copyright.svg";

const useStyles = makeStyles((theme) => ({
  inner: {
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.values.md,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: theme.breakpoints.values.xl,
    },
  },
  subsection: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.brand.darkgrey,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
  },
  copyright: {
    color: theme.palette.grey[500],
    fontSize: 18,
    paddingLeft: 20,
    position: "relative",
    marginTop: 0,
    textDecoration: "none",
    "&:before": {
      backgroundImage: `url(${copyrightImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "absolute",
      width: 18,
      height: 18,
      left: 0,
      top: 4,
      content: "''",
    },
  },
  link: {
    fontSize: 18,
    textDecoration: "none",
    color: theme.palette.brand.darkgrey,
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box pt={4} pb={6} className={clsx(classes.subsection, classes.inner)} id="rights-footer">
      <NavLink
        className={classes.link}
        to={{ pathname: `${process.env.REACT_APP_MAIN_URL}/impressum` }}
        target="_blank">
        Impressum
      </NavLink>
      <Typography className={classes.copyright}>
        RightNow Group Copyright {moment().format("YYYY")}
      </Typography>
    </Box>
  );
};

export default Footer;
