import React from "react";

function OfferAccepted(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="261"
    height="153"
    fill="none"
    viewBox="0 0 261 153"
  >
    <path
      fill="url(#paint0_linear)"
      d="M248.061 59.014c.401.442.775.909 1.12 1.396 38.162 54.689-31.365 87.04-63.267 81.753-82.692-8.542-70.884 1.685-126.645-2.1-41.238-1.171-78.9-25.137-45.725-43.743.452-.257.926-.525 1.362-.792C52.852 70.475 35.3 59.166 52.952 44.119c28.803-27.457 49.78-9.896 68.564-12.6 19.225-2.719 61.873-43.974 126.545 27.495z"
    ></path>
    <path
      fill="#F0F4FF"
      d="M230.44 5.669h-83.771s-15.091 13.707-15.091 47.097c0 28.691 11.44 67.599 7.143 83.528-3.24 12.038-18.936 14.075-26.479 14.369h79.737s26.231 1.575 30.508-14.369c4.276-15.944-7.144-54.837-7.144-83.528.011-33.39 15.097-47.097 15.097-47.097z"
    ></path>
    <path
      fill="#F0F4FF"
      d="M230.44 5.669h-83.771s-15.091 13.707-15.091 47.097c0 28.691 11.44 67.599 7.143 83.528-3.24 12.038-18.936 14.075-26.479 14.369h79.737s26.231 1.575 30.508-14.369c4.276-15.944-7.144-54.837-7.144-83.528.011-33.39 15.097-47.097 15.097-47.097z"
      opacity="0.7"
    ></path>
    <path
      fill="#055FF0"
      d="M207.653 40.875h-67.002c1.947-14.7 7.007-23.242 9.837-26.974h64.566c-3.14 6.536-6.128 15.424-7.401 26.974z"
      opacity="0.8"
    ></path>
    <path
      fill="#055FF0"
      d="M207.085 48.64h-67.086c.053-1.334.137-2.625.242-3.885h67.054c-.089 1.27-.157 2.562-.21 3.885zM161.039 56.42h-21.072a127.984 127.984 0 01-.063-3.675v-.21h21.103v.21c0 1.224 0 2.452.032 3.675zM207.495 64.2h-67.049a126.59 126.59 0 01-.279-3.869h67.07c.058 1.276.153 2.594.258 3.87zM208.232 71.987h-66.986a174.628 174.628 0 01-.436-3.885h67.012c.126 1.296.268 2.588.41 3.885zM209.2 79.767h-66.945a276.678 276.678 0 01-.526-3.874H208.7a160.3 160.3 0 00.5 3.874zM210.293 87.547h-66.902a542.091 542.091 0 00-.579-3.88h66.934c.179 1.308.373 2.589.547 3.88zM211.503 95.328h-66.917c-.111-.704-.216-1.428-.327-2.142l-.268-1.738h66.902l.61 3.88zM145.753 103.124c-.189-1.265-.378-2.556-.578-3.869h66.918c.194 1.265.394 2.583.583 3.869h-66.923zM146.805 110.889c-.152-1.27-.326-2.536-.526-3.88h55.809c.189 1.313.363 2.625.526 3.88h-55.809zM147.631 118.675a150.858 150.858 0 00-.362-3.885h66.986c.157 1.333.294 2.628.41 3.885h-67.034zM148.016 126.45c0-1.27-.048-2.536-.121-3.88h35.347c.089 1.355.142 2.657.152 3.88h-35.378z"
      opacity="0.3"
    ></path>
    <path
      fill="#055FF0"
      d="M198.412 146.458c-4.255.766-8.558.184-12.829 0a34.271 34.271 0 00-6.144.157c-2.004.263-3.992.777-6.012.63.666-.356 1.293-.78 1.872-1.265a.188.188 0 00.046-.066.196.196 0 00-.046-.223.156.156 0 00-.089-.052c-6.275-2.174-11.987 3.748-18.236 2.362 1.762-1.296 4.265-1.323 5.786-3.024a.212.212 0 000-.294.187.187 0 00-.137-.057c-5.597-.084-10.909 2.058-16.422 2.535-.263 0-.263.436 0 .41 5.365-.462 10.52-2.483 15.912-2.536-1.615 1.428-4.077 1.475-5.728 2.914a.204.204 0 000 .289.171.171 0 00.084.052c6.312 1.717 12.04-4.2 18.262-2.383-.65.5-1.356.924-2.104 1.265a.203.203 0 00-.093.127.2.2 0 00.025.156.177.177 0 00.05.059.181.181 0 00.071.031c2.051.346 4.055-.09 6.075-.41a33.85 33.85 0 016.838-.262c4.319.204 8.653.782 12.95 0 .248-.032.132-.473-.131-.415z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M84.944 25.928c.352.441.8.961 1.215 1.45.415.488.862.97 1.283 1.443.9.945 1.804 1.9 2.762 2.735a24.76 24.76 0 006.107 4.2c.268.121.525.236.799.331.123.051.25.093.379.126.12.047.242.084.368.11a9.427 9.427 0 001.625.252 17.614 17.614 0 003.682-.21 34.343 34.343 0 003.851-.803c1.283-.32 2.566-.735 3.803-1.16l1.125 2.178a31.198 31.198 0 01-7.948 3.492 19.227 19.227 0 01-4.534.724c-.83.03-1.662-.023-2.483-.157a2.913 2.913 0 01-.641-.137 12.73 12.73 0 01-1.783-.54 26.38 26.38 0 01-7.648-4.773 39.453 39.453 0 01-3.156-2.998 38.284 38.284 0 01-1.447-1.606c-.468-.556-.915-1.087-1.394-1.727l4.035-2.93z"
    ></path>
    <path
      fill="#055FF0"
      d="M79.3 24.374c-.127 3.077 1.083 8.616 1.083 8.616l7.695-.925s.284-8.105-1.21-10.069c-2.503-3.292-7.437-.52-7.569 2.378z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M109.812 35.22l3.619-3.26.463 3.508s-1.499 1.979-3.208 1.15l-.874-1.397z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M115.246 32.17l1.525 1.974-2.882 1.345-.452-3.528 1.809.21z"
    ></path>
    <path
      fill="#263238"
      d="M114.326 23.261h.868c.115-.01.232-.01.347 0l.289.037c.248.032.483.126.684.273.105.11.173.25.195.399l.126.42.526 1.664 1.052 3.34 2.067 6.667c1.357 4.43 2.778 8.867 4.093 13.34 2.693 8.925 5.26 17.85 7.753 26.775 2.493 8.925 4.871 17.97 7.285 26.933 2.414 8.961 4.592 18.012 5.36 27.41a2.727 2.727 0 01-1.503 2.647 2.733 2.733 0 01-1.032.282 2.768 2.768 0 01-2.725-1.575c-3.95-8.573-6.554-17.498-8.821-26.523-2.267-9.024-4.65-17.997-6.864-27.027-2.215-9.03-4.366-18.07-6.412-27.142-1.052-4.531-1.989-9.083-2.983-13.65l-1.478-6.825-.736-3.407-.374-1.707-.089-.43a.8.8 0 01-.032-.447c.106-.225.267-.42.468-.567l.232-.173a3.52 3.52 0 01.294-.189c.237-.131.411-.252.758-.42.207-.084.428-.13.652-.136v.031z"
    ></path>
    <path
      fill="#055FF0"
      d="M144.413 131.905a.604.604 0 00-.726-.42l-1.225.315-1.215.31a.594.594 0 00-.432.714c.763 3.454 0 5.932.816 8.977.841 3.197 4.486 4.841 4.486 4.841l-.121-1.371.569 1.266s2.382-3.15 1.541-6.384c-.773-3.056-2.667-4.867-3.693-8.248z"
    ></path>
    <path
      fill="#055FF0"
      d="M144.413 131.905a.604.604 0 00-.726-.42l-1.225.315-1.215.31a.594.594 0 00-.432.714c.763 3.454 0 5.932.816 8.977.841 3.197 4.486 4.841 4.486 4.841l-.121-1.371.569 1.266s2.382-3.15 1.541-6.384c-.773-3.056-2.667-4.867-3.693-8.248z"
      opacity="0.3"
    ></path>
    <path
      fill="#263238"
      d="M145.365 139.874a.763.763 0 11-.931-.525.754.754 0 01.578.084c.17.097.296.255.353.441z"
    ></path>
    <path
      fill="#263238"
      d="M146.044 145.439a.171.171 0 01-.21-.126l-1.373-5.25a.161.161 0 01.007-.129.168.168 0 01.098-.086.18.18 0 01.133.015.183.183 0 01.088.101l1.373 5.25a.175.175 0 01-.02.128.179.179 0 01-.101.082l.005.015z"
    ></path>
    <path
      fill="#055FF0"
      d="M141.547 113.042l-7.485 1.9a.818.818 0 01-.989-.572.796.796 0 01.573-.971l7.496-1.901a.796.796 0 011.002.872.772.772 0 01-.1.294.766.766 0 01-.487.367l-.01.011z"
    ></path>
    <path
      fill="#263238"
      d="M103.506 12.572c-.126.331-.063.672.153.767.215.094.526-.1.641-.43.116-.332.069-.673-.152-.767-.221-.095-.505.1-.642.43z"
    ></path>
    <path
      fill="#263238"
      d="M104.048 12.12l.894.116s-.668.446-.894-.115z"
    ></path>
    <path
      fill="#DB9FD0"
      d="M103.674 13.008c-.007.886.106 1.77.337 2.625a1.583 1.583 0 01-1.283-.294l.946-2.331z"
    ></path>
    <path
      fill="#263238"
      d="M104.852 10.567a.27.27 0 01-.094-.053 1.763 1.763 0 00-1.499-.425.216.216 0 01-.263-.147.201.201 0 01.076-.223.21.21 0 01.076-.034 2.198 2.198 0 011.925.525c.039.039.06.092.06.147a.208.208 0 01-.06.147.24.24 0 01-.205.073l-.016-.01z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M91.724 12.399c.242 2.672-.642 7.046-2.71 8.542 0 0 1.542 2.809 6.465 3.465 5.429.719 2.883-2.3 2.883-2.3-2.888-1.081-2.567-3.223-1.847-5.176l-4.791-4.531zM106.494 26.663c.426 2.505.952 5.067 1.504 7.602.295 1.266.589 2.536.936 3.76.348 1.223.684 2.467 1.052 3.674.216.593.4 1.203.642 1.79l.337.883.073.215v.063a.594.594 0 00.042.1.866.866 0 00.526.398c.44.152.899.238 1.363.258a15.816 15.816 0 003.556-.289c1.23-.215 2.524-.525 3.734-.84l1.021 2.226c-.642.378-1.247.682-1.894.987-.647.304-1.283.578-1.962.824a16.583 16.583 0 01-4.366 1.024 7.968 7.968 0 01-2.63-.178 5.064 5.064 0 01-3.345-2.405c-.074-.126-.137-.252-.205-.383l-.105-.247-.427-.956c-.289-.635-.526-1.265-.794-1.9a57.164 57.164 0 01-1.325-3.911c-.39-1.313-.752-2.625-1.094-3.938-.642-2.651-1.2-5.25-1.605-8l4.966-.757z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M119.722 44.434l2.152-1.685.962 3.271s-2.94 2.1-3.724-.378l.61-1.208z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M123.804 43.332l.768 2.383-1.735.305-.963-3.27 1.93.582zM83.718 147.975l4.492.326 1.33-10.175-4.486-.32-1.336 10.169zM54.992 135.034l3.914 2.336 6.412-7.975-3.846-2.331-6.48 7.97z"
    ></path>
    <path
      fill="#055FF0"
      d="M61.594 138.525a.832.832 0 01-.158 0c-.573 0-1.22-.861-1.578-1.365a.097.097 0 01-.032-.071.097.097 0 01.032-.071.114.114 0 01.09 0c.184.042 1.788.368 1.998.903a.348.348 0 01-.052.347.611.611 0 01-.3.257zm-1.383-1.265c.468.635.925 1.05 1.23 1.05a.398.398 0 00.327-.189.155.155 0 000-.152c-.106-.279-.942-.562-1.578-.709h.02z"
    ></path>
    <path
      fill="#055FF0"
      d="M61.347 137.165c-.459.129-.94.155-1.41.074a.188.188 0 01-.079-.173c.042-.037.92-.93 1.578-.903a.56.56 0 01.437.204.373.373 0 01.058.462 1.051 1.051 0 01-.584.336zm-1.157-.094a2.294 2.294 0 001.578-.352.225.225 0 00-.042-.215.39.39 0 00-.28-.137 2.375 2.375 0 00-1.256.704z"
    ></path>
    <path
      fill="#263238"
      d="M60.048 136.514l-3.682-3.507a.356.356 0 00-.474 0l-3.156 2.394a.714.714 0 00-.262.494.705.705 0 00.178.53l.058.063c1.283 1.208 2.004 1.722 3.619 3.26 1 .951 2.361 2.431 3.734 3.744 1.373 1.312 2.725.068 2.267-.594-2.056-2.982-1.746-4.095-1.925-5.617a1.174 1.174 0 00-.347-.74l-.01-.027z"
    ></path>
    <path
      fill="#055FF0"
      d="M90.15 148.426a7.034 7.034 0 01-1.41-.157.107.107 0 01-.066-.043.106.106 0 01-.017-.078.132.132 0 01.052-.079c.206-.099 2.025-1.008 2.562-.703a.327.327 0 01.174.278.618.618 0 01-.206.525 1.685 1.685 0 01-1.088.257zm-1.051-.315c.994.158 1.73.116 2.009-.12a.424.424 0 00.131-.347.113.113 0 00-.016-.061.115.115 0 00-.047-.044c-.3-.152-1.33.236-2.083.572h.006z"
    ></path>
    <path
      fill="#055FF0"
      d="M88.757 148.274h-.063a.105.105 0 01-.033-.042.107.107 0 01-.01-.052c0-.084.216-1.901 1.216-1.901h.09c.288.032.373.179.394.289.084.525-1 1.465-1.547 1.706h-.047zm1.125-1.795c-.641 0-.904 1.05-.983 1.501.557-.325 1.283-1.081 1.246-1.386 0 0 0-.094-.205-.115h-.058z"
    ></path>
    <path
      fill="#263238"
      d="M88.483 147.655H83.44a.359.359 0 00-.363.304l-.679 3.954a.689.689 0 00.338.694.69.69 0 00.257.088h.105c1.757-.032 2.63-.131 4.823-.131 1.363 0 4.182.136 6.065.136s2.036-1.837 1.284-2c-3.493-.746-4.856-1.775-6.007-2.762a1.146 1.146 0 00-.779-.283z"
    ></path>
    <path
      fill="#2D333D"
      d="M89.535 138.11l-.741 5.665-4.435-.724.684-5.25 4.492.309zM61.473 127.064l3.845 2.337-2.672 3.291-3.971-2.236 2.798-3.392z"
      opacity="0.2"
    ></path>
    <path
      fill="#055FF0"
      d="M81.45 47.627l18.169 2.41c7.059-25.153 4.96-25.846 4.96-25.846a18.074 18.074 0 00-2.472-.956 53.766 53.766 0 00-2.567-.84 46.853 46.853 0 00-15.896-1.806 2.376 2.376 0 00-1.783.998 4.34 4.34 0 00-.19.34 2.478 2.478 0 00-.252 1.371c1.547 13.204 1.052 17.577.032 24.329z"
    ></path>
    <path
      fill="#2D333D"
      d="M101.381 31.908a14.828 14.828 0 00.984 7.712c1.215-4.908 1.862-8.237 2.188-10.542-1.405-.078-2.825.43-3.172 2.83z"
      opacity="0.2"
    ></path>
    <path
      fill="#055FF0"
      d="M104.447 24.122c3.114 1.754 5.26 5.943 5.928 8.4l-6.66 6.006s-3.429-7.875-3.156-10.337c.274-2.462 2.683-4.735 3.888-4.069z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M92.702 6.881c-1.389 4.158-2.067 5.896-.847 8.873 1.84 4.436 7.317 5.402 9.931 1.774 2.362-3.265 4.25-9.502.889-12.857a5.982 5.982 0 00-8.39-.247 5.859 5.859 0 00-1.578 2.473l-.005-.016z"
    ></path>
    <path
      fill="#F0B9E6"
      d="M99.614 10.577a3.946 3.946 0 00-.132 2.389c.279 1.108 1.11 1.187 1.625.473a2.813 2.813 0 00.316-2.899c-.558-.918-1.462-.808-1.81.037z"
    ></path>
    <path
      fill="#263238"
      d="M94.206 1.7C91.202.05 85.79 4.85 84.443 12.725c-.968 5.627 11.21 8.352 18.899 8.231-.526-1.322-3.682-5.318-1.662-9.166 1.483-2.793 3.209-2.147 3.282-5.402C105.089 2.34 99.618-.4 94.206 1.7z"
    ></path>
    <path
      fill="#263238"
      d="M101.045 3.469c3.487-.336 7.001 3.055 4.392 6.431a5.551 5.551 0 00-4.577-1.092c-1.588.341.185-5.34.185-5.34zM94.826 49.401S87.773 78.843 84.48 95.753c-3.477 17.85-20.162 36.43-20.162 36.43l-5.665-3.108s10.52-16.968 13.897-33.626c6.912-33.816 2.23-40.63 8.9-47.807l13.376 1.759z"
    ></path>
    <path
      fill="#055FF0"
      d="M64.876 132.708l-7.054-3.896 1.052-2.068 7.89 3.628-1.888 2.336z"
    ></path>
    <path
      fill="#2D333D"
      d="M91.076 55.8c-5.486 4.7-4.891 21.384-4.318 28.876 2.304-10.69 5.065-22.575 6.69-29.479-.546-.404-1.346-.257-2.372.604z"
      opacity="0.2"
    ></path>
    <path
      fill="#263238"
      d="M99.35 50.052s3.157 34.95 1.437 48.3c-1.794 13.871-10.957 44.037-10.957 44.037l-6.47-.824s5.26-29.894 6.186-43.628c1.02-14.962-3.498-49.623-3.498-49.623l13.303 1.738z"
    ></path>
    <path
      fill="#055FF0"
      d="M91.202 142.725l-9.205-1.176.142-2.693 9.994.961-.93 2.908zM81.298 46.55l-.994 1.46c-.079.11.058.257.268.288l19.052 2.552c.163 0 .31-.037.326-.142l.337-1.575c0-.11-.111-.22-.29-.241l-18.41-2.426a.32.32 0 00-.289.084z"
    ></path>
    <path
      fill="#2D333D"
      d="M81.298 46.55l-.994 1.46c-.079.11.058.257.268.288l19.052 2.552c.163 0 .31-.037.326-.142l.337-1.575c0-.11-.111-.22-.29-.241l-18.41-2.426a.32.32 0 00-.289.084z"
      opacity="0.5"
    ></path>
    <path
      fill="#263238"
      d="M83.55 48.892l-.495-.063c-.095 0-.163-.069-.153-.132l.458-2.042c0-.052.1-.094.195-.078l.494.063c.1 0 .168.068.158.13l-.458 2.043c-.01.058-.105.094-.2.079zM95.989 50.572l-.495-.069c-.094 0-.168-.068-.152-.126l.452-2.042c0-.063.106-.1.206-.089l.494.063c.095 0 .168.079.153.137l-.453 2.037c-.016.057-.084.105-.205.089z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="179.74"
        x2="-9.111"
        y1="88.851"
        y2="-9.047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4184F0"></stop>
        <stop offset="0.47" stopColor="#41DAE5"></stop>
        <stop offset="1" stopColor="#53FAD4"></stop>
      </linearGradient>
    </defs>
  </svg>
  );
}

export default OfferAccepted;
