import React from "react";
import { Switch, Route } from "react-router-dom";
import { URL_UPLOAD_DOCUMENT_SUCCESS } from "../../constants";
import { Upload, Success } from "./components";

const UploadDocument: React.FC = () => {
  return (
    <Switch>
      <Route path={URL_UPLOAD_DOCUMENT_SUCCESS}>
        <Success />
      </Route>
      <Route>
        <Upload />
      </Route>
    </Switch>
  );
};

export default UploadDocument;
