import React from "react";
import { BaseCard, BaseInputNew as BaseInput } from "components/common";
import { getValidationErrors } from "helpers/utils";
import { Grid } from "@material-ui/core";

interface IProps {
  onChange: (value: string, name: string) => void;
  error: string;
  cardDetails: { title: string; description: string };
  value: string;
}

const IBanCard: React.FC<IProps> = ({ onChange, error, cardDetails, value }) => {
  const validationErrors: Record<string, string> = getValidationErrors(error);

  return (
    <BaseCard {...cardDetails}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={7} item>
          <BaseInput
            placeholder="IBAN"
            name="iban"
            onChange={onChange}
            errorMessage={validationErrors?.iban}
            value={value}
          />
        </Grid>
      </Grid>
    </BaseCard>
  );
};

export default IBanCard;
