import * as React from "react";
import { IconButton, InputBase, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.brand.darkgrey,
    boxShadow: theme.shadows[0],
    borderBottom: `1px solid ${theme.palette.brand.pink}`,
    borderRadius: 0,
    display: "flex",
    alignItems: "center",
    width: "80%",
  },
  input: {
    color: theme.palette.brand.pink,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    color: theme.palette.brand.pink,
    padding: 10,
  },
}));

interface Props {
  color: string;
}

const EmailSubmit: React.FC<Props> = ({ color }) => {
  const classes = useStyles();

  return (
    <Box component="form" className={classes.root} mt={10}>
      <InputBase className={classes.input} placeholder="Deine Email-Adresse" />
      <IconButton type="submit" className={classes.iconButton}>
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export default EmailSubmit;
