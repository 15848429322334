import React from "react";
import { BasePageContent, PageWrap } from "components/common";
import { OfferExpired as OfferExpiredIcon } from "components/image-components";
import { getRequestActivationMailToLink } from "helpers/utils";
import { EXPIRED_OFFER, EXPIRED_OFFER_MAILTO } from "./../const";
import { verticalLanguageSettings } from "../../const";

const OfferExpired: React.FC<{ aliasId: string; product: string }> = ({ aliasId, product }) => {
  const formalLanguage = verticalLanguageSettings[product] as string;

  const getImage = () => <OfferExpiredIcon />;
  const supportLink = getRequestActivationMailToLink({ ...EXPIRED_OFFER_MAILTO, aliasId });
  return (
    <PageWrap product="">
      <BasePageContent
        title={EXPIRED_OFFER.title[formalLanguage]}
        description={EXPIRED_OFFER.description[formalLanguage]}
        subDescription={EXPIRED_OFFER.subDescription[formalLanguage]}
        buttonName={EXPIRED_OFFER.buttonName[formalLanguage]}
        href={supportLink}
        getImage={getImage}
      />
    </PageWrap>
  );
};

export default OfferExpired;
