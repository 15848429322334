import React from "react";

function StepReadyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={16} cy={16} r={15.5} stroke="#9A9BA7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.147 21.436a.429.429 0 01-.37-.105l-4.584-3.994a.595.595 0 01-.106-.749.42.42 0 01.648-.091l4.37 3.808 9.105-9.17a.418.418 0 01.652.046.598.598 0 01-.054.755l-9.302 9.37a.427.427 0 01-.36.13z"
        fill="#9A9BA7"
      />
    </svg>
  );
}

export default StepReadyIcon;
