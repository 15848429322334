import React from "react";
import { Typography } from "@material-ui/core";
import { styleClasses } from "./style";

interface IProps {
  notice: string;
  isSpv: boolean;
  company: string;
  language: "de" | "en";
}

const TermsAndConditions: React.FC<IProps> = ({ notice, isSpv, company, language }) => {
  const classes = styleClasses();
  const rightNowCompany = isSpv ? "Legal Claims Holding AG" : "RightNow GmbH";

  const legalText = (language: string): string => {
    if (language === "en") {
      return `By clicking on “Accept Offer” I hereby authorise ${rightNowCompany} to pursue any further legal proceedings in relation to the collection of my claim against ${company} in its own name.`;
    } else {
      return `Mit einem Klick auf „Angebot annehmen“ erlaube ich der ${rightNowCompany} mit meinen Daten, im eigenen Namen gegen ${company} vorzugehen.`;
    }
  };

  return (
    <div>
      <Typography variant="caption" display="block" className={classes.caption}>
        {legalText(language)}
      </Typography>
      <Typography variant="caption" display="block" className={classes.caption}>
        {notice}
      </Typography>
    </div>
  );
};

export default TermsAndConditions;
