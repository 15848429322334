import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageNotFound, LoadingSpinner } from "components/common";
import { Product_Enum, useOfferGetQuery } from "types/graphql.types";
import AcceptOffer from "./AcceptOffer";
import OfferExpired from "./OfferExpired";
import { EXPIRED } from "constants/states";
import { READ_ONLY_VERTICALS } from "../../../../constants";

interface IProps {
  setAliasId: (value: string) => void;
}

const OfferScreen: React.FC<IProps> = ({ setAliasId }) => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useOfferGetQuery({
    variables: {
      offer_id: id,
    },
  });
  useEffect(() => {
    if (data?.offer_get?.alias_id) setAliasId(data.offer_get.alias_id);
  }, [data?.offer_get?.alias_id, setAliasId]);

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error && error.graphQLErrors[0]?.extensions?.code === "404") {
    return <PageNotFound />;
  } else if (error) {
    return <PageNotFound error="Something went wrong." />;
  }
  if (!data || !data.offer_get) return <PageNotFound error="Something went wrong." />;

  if (
    data.offer_get.state === EXPIRED ||
    READ_ONLY_VERTICALS.includes(data.offer_get.product_id as Product_Enum)
  ) {
    return <OfferExpired aliasId={data.offer_get.alias_id} product={data.offer_get.product_id} />;
  }
  const acceptOfferProps = {
    offerId: id,
    amount:
      data.offer_get.offer_amount &&
      Number(data.offer_get.offer_amount).toFixed(2).replace(".", ","),
    product: data.offer_get.product_id,
    aliasId: data.offer_get.alias_id,
    company: data.offer_get.company,
    isSpv: data.offer_get.isSpv,
    caseId: data.offer_get.case_id,
  };

  setAliasId(data.offer_get.alias_id);

  return <AcceptOffer {...acceptOfferProps} />;
};

export default OfferScreen;
