import { makeStyles } from "@material-ui/core/styles";
import Pfad_Left from "assets/images/pfad_left.svg";
import Pfad_Right from "assets/images/pfad_right.svg";

export const styleClasses = makeStyles((theme) => ({
  pageWrap: {
    marginTop: "109px",
    position: "relative",
    backgroundImage: `url(${Pfad_Left})`,
    backgroundColor: theme.palette.common.white,
    backgroundPosition: "top 300px left -425px",
    backgroundSize: "65%",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "top 4px left -137px",
      marginTop: "87px",
    },
    "&:before": {
      backgroundImage: `url(${Pfad_Right})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "absolute",
      top: "46px",
      right: 0,
      width: "378px",
      height: "150px",
      content: "''",
      [theme.breakpoints.down("md")]: {
        width: "216px",
      },
      [theme.breakpoints.down("md")]: {
        width: "128px",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
}));
