import { SalutationType } from "types/graphql.types";

export const salutationOptions = [
  {
    label: "Herr",
    value: SalutationType.Masculine,
  },
  {
    label: "Frau",
    value: SalutationType.Feminine,
  },
];
