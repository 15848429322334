import React from "react";
import { Typography, Card } from "@material-ui/core";
import "./index.scss";

interface Props {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const BaseCard: React.FC<Props> = ({ title, description, children, className, onClick }) => {
  return (
    <Card className={`card ${className}`} onClick={onClick}>
      <Typography variant={"h4"} className="card-title">
        {title}
      </Typography>
      <Typography variant={"body2"} className="card-description">
        {description}
      </Typography>
      {children}
    </Card>
  );
};

export default BaseCard;
