import { makeStyles } from "@material-ui/core/styles";

export const styleClasses = makeStyles((theme) => ({
  container: {
    minHeight: "730px",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  hasMargin: {
    padding: "30px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 0",
    },
  },
  cardSection: {
    background: `${theme.palette.common.white} 0% 0% no-repeat padding-box`,
    borderRadius: "10px",
  },
  wrapContainer: {
    padding: "55px 40px",
    [theme.breakpoints.down("xs")]: {
      padding: "39px 16px",
    },
  },
  typography: {
    width: "100%",
    boxShadow: theme.shadows[1],
    borderRadius: "10px",
  },
}));
