import * as React from "react";
import { AppBar, Toolbar, Container, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "assets/images/logo_blue.svg";

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.brand.darkgrey,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    position: "fixed",
    top: 0,
    "& .MuiToolbar-gutters": {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "26px"
      }
    }
  },
  navbarDisplayFlex: {
    display: "flex",
    maxWidth: "initial",
    paddingLeft: "97px",
    minHeight: "80px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,

    }
  },
  navDisplayFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  linkText: {
    textDecoration: "none",
    color: theme.palette.brand.darkgrey,
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Container maxWidth="md" className={classes.navbarDisplayFlex}>
          <IconButton edge="start" color="inherit" aria-label="home">
            <NavLink target="_blank" to={{ pathname: `${process.env.REACT_APP_MAIN_URL}` }}>
              <Logo />
            </NavLink>
          </IconButton>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
